import { Tooltip } from 'antd';

import { Permission } from 'api/requests/generated/generated.schemas';
import Dropdown from 'components/Dropdown';
import { Dots as DotsIcon } from 'components/Icons';

interface IProps {
  onUpdate: (permission: Permission) => void;
  onRemove: () => void;
  disabled?: boolean;
  permission: Permission;
  reasonOfDisabled?: string;
}

const Menu = ({ permission, onRemove, onUpdate, reasonOfDisabled, disabled }: IProps) => {
  const items = [
    {
      label: 'Make Billing Admin',
      key: 'billing-admin',
      hidden: permission === Permission.ADMIN,
      disabled: disabled,
      onClick: () => onUpdate(Permission.ADMIN),
    },
    {
      label: 'Change to Billing User',
      key: 'billing-user',
      hidden: permission === Permission.READ,
      onClick: () => onUpdate(Permission.READ),
    },
    {
      type: 'divider',
    },
    {
      label: 'Remove access',
      danger: true,
      key: 'delete',
      onClick: onRemove,
    },
  ];

  return (
    <Dropdown items={items} disabled={disabled}>
      <Tooltip title={disabled ? reasonOfDisabled : null} key="leftButton">
        <a
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DotsIcon />
        </a>
      </Tooltip>
    </Dropdown>
  );
};

export default Menu;
