import { Flex, Layout } from 'antd';
import styled from 'styled-components';

export const Footer = styled(Layout.Footer)`
  height: 96px;
  padding: 24px 32px;
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px ${(props) => props.theme.colors.black}1a;
`;

export const TermsWrapper = styled('div')`
  width: calc(100% - 80px);
`;

export const ButtonsWrapper = styled(Flex)`
  align-self: center;
  gap: 16px;
`;
