import { onboardingApi } from 'api/config/onboardingApi';
import { HttpError } from 'lib/helpers/httpError';

interface IOnboardingStatus {
  userId: number;
  status: OnboardingStatusEnum;
  user: {
    id: number;
    login: string;
    origin: string;
  };
}

export enum OnboardingStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  DONE = 'DONE',
}

export enum USER_ORIGIN {
  google_dma = 'google_one_click',
  internal_support = 'internal_support',
  self_onboard = 'self_onboard',
}

export const getOnboardingStatus = async (userId: string) => {
  try {
    return (await onboardingApi.get<IOnboardingStatus>('/onboarding/status', { params: { user: userId } })).data;
  } catch (error) {
    throw new HttpError(error);
  }
};

export const setOnboardingStatus = async (status: OnboardingStatusEnum) => {
  try {
    return (await onboardingApi.put<IOnboardingStatus>('/onboarding/status', { status })).data;
  } catch (error) {
    throw new HttpError(error);
  }
};
