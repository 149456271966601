import { Spin } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import UpgradePlan from 'components/Modal/UpgradePlan';
import openSettingInAI from 'lib/helpers/openSettingInAI';
import { useSelectedCompany, useTrial, useUpgradePlan } from 'lib/hooks';

const Upgrade = () => {
  const { subscriptionsInTrial, readonly, shouldShowTrialBar } = useTrial();
  const { setPlanToUpgrade, subscription } = useUpgradePlan();
  const { id: companyId } = useSelectedCompany();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const settingId = searchParams.get('settingId') || '';

  if (!subscriptionsInTrial) {
    return <Spin fullscreen />;
  }

  if (!subscriptionsInTrial.length || !shouldShowTrialBar) {
    navigate(`/company/${companyId}/configurations`);
    return null;
  }

  if (!subscription) {
    setPlanToUpgrade(subscriptionsInTrial[0]);
  }

  const onClose = () => {
    openSettingInAI(settingId, 'V2', '_self');
    setPlanToUpgrade(null);
  };

  return subscription && <UpgradePlan readonly={readonly} open onClose={onClose} isAllFeaturesVisible={false} />;
};

export default Upgrade;
