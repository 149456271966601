import { Identify, identify, track } from '@amplitude/analytics-browser';

import { TrackingEvents, TrackingVariables } from 'interfaces/enums';

export const trackEvent = (
  eventName: TrackingEvents,
  properties?: Partial<Record<TrackingVariables, string | boolean>>,
  callback?: Function,
) => {
  track(eventName, { ...properties, version: process.env.REACT_APP_VERSION }).promise.then(() => {
    callback?.();
  });
};

export const updateAmplitudeUserProperty = (propertyName: string, value: any) => {
  const identifyEvent = new Identify();
  identifyEvent.set(propertyName, value);

  identify(identifyEvent);
};
