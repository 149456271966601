import { AccountOutDto, SubscriptionStatus } from 'api/requests/generated/generated.schemas';

export const getOneClickAccounts = (
  accounts: AccountOutDto[],
): { oneclickAccounts: AccountOutDto[]; settingIds: string[] } => {
  return accounts.reduce(
    (prevResult, currentAccount) => {
      if (!currentAccount.primaryPaymentSourceId) {
        const subscriptionsInCurrentAccount = currentAccount.subscriptions.filter(
          (subscriptionItem) =>
            subscriptionItem.status === SubscriptionStatus.in_trial && subscriptionItem.package?.includes('One-Click'),
        );

        if (subscriptionsInCurrentAccount.length > 0) {
          return {
            oneclickAccounts: [...prevResult.oneclickAccounts, currentAccount],
            settingIds: [
              ...prevResult.settingIds,
              ...subscriptionsInCurrentAccount.flatMap((subscription) =>
                subscription.settingSubscriptions.map((setting) => setting.settingId),
              ),
            ],
          };
        }
      }

      return prevResult;
    },
    { oneclickAccounts: [] as AccountOutDto[], settingIds: [] as string[] },
  );
};
