import { Layout as AntLayout, Flex } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Expand } from 'components/Icons';

const { Header: AntHeader } = AntLayout;

interface IProps {
  $hideInfo?: boolean;
}

export const Header = styled(AntHeader)<IProps>`
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
  height: min-content;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 100;
`;

export const HeaderItem = styled(NavLink)`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[80]};

  &.active {
    color: ${({ theme }) => theme.colors.blue[100]};
  }
`;

export const Delimiter = styled(Expand)`
  transform: rotate(-90deg);
`;

export const UserWrapper = styled(Flex)`
  cursor: pointer;
  height: 100%;

  svg {
    width: 24px;
    height: 24px;

    & > g rect:hover {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  .anticon {
    border-radius: 100px;
    padding: 3px;
    vertical-align: middle;
  }

  & .ant-dropdown-trigger {
    margin: 0 5px;
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  padding: 0 16px 12px 16px;

  & #user-placeholder {
    width: 36px;
    height: 36px;
    margin: 16px 16px 0 0;
  }
`;

export const UserName = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const DateLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
`;

export const DropdownItem = styled.div`
  margin: 5px 0;
`;
