import { Collapse as AntCollapse, List } from 'antd';
import styled from 'styled-components';

export const ListItem = styled(List.Item)`
  &.ant-list-item {
    padding: 5px 8px;
    border: 0;
    letter-spacing: 0.2px;

    &:nth-child(even) {
      background: ${(props) => props.theme.colors.white};
    }

    &:nth-child(odd) {
      background: ${(props) => props.theme.colors.gray[10]};
    }

    &:nth-child(3) {
      background: ${(props) => props.theme.colors.blue[8]};
      padding: 12px;
    }
  }
`;

export const CodeWrapper = styled.div`
  padding: 12px 16px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.blue[24]};
  width: 100%;
  box-shadow: 0px 4px 8px 0px ${(props) => props.theme.colors.black}1a;
`;

export const Collapse = styled(AntCollapse)`
  && .ant-collapse-header {
    padding: 0;

    & .ant-collapse-header-text {
      margin-inline-end: 0;
      flex: none;
    }
  }
`;
