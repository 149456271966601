import { createContext, Dispatch, SetStateAction, useState } from 'react';

import {
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
} from 'api/requests/generated/generated.schemas';
import { colors } from 'lib/theme/colors';

// TODO use generated schema
export enum SecondLayerVariant {
  SIDE = 'SIDE',
  CENTER = 'CENTER',
}

interface ICustomizationDetails {
  layout: ICustomizationLayout;
  styling: ICustomizationStyling;
  privacyTrigger: ICustomizationPrivacyTrigger;
}

export interface ICustomizationPrivacyTrigger {
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant;
  privacyButtonBackground: string;
  privacyButtonIcon: string;
  privacyButtonSizeDesktop: number;
  privacyButtonSizeMobile: number;
}

export interface ICustomizationLayout {
  firstLayerVariant: CreateSettingBodyFirstLayerVariant;
  // TODO use generated schema
  secondLayerVariant: SecondLayerVariant;
}

export interface ICustomizationStyling extends IStylingLayoutColors, IStylingToggleColors, IStylingButtonsColors {
  isOverlayEnabled: boolean;
  overlay: string;
  overlayOpacity: number;
}

export interface IStylingButtonsColors {
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

export interface IStylingLayoutColors {
  layerBackground: string;
  linkFont: string;
  secondLayerTab: string;
  tabsBorderColor: string;
  text: string;
}

export interface IStylingToggleColors {
  toggleActiveBackground: string;
  toggleActiveIcon: string;
  toggleDisabledBackground: string;
  toggleDisabledIcon: string;
  toggleInactiveBackground: string;
  toggleInactiveIcon: string;
}

interface IOnboardingContext {
  customizationDetails: ICustomizationDetails;
  setCustomizationDetails: Dispatch<SetStateAction<ICustomizationDetails>>;
}

const initialLayoutValues: ICustomizationLayout = {
  firstLayerVariant: CreateSettingBodyFirstLayerVariant.WALL,
  secondLayerVariant: SecondLayerVariant.CENTER,
};

const initialStylingValues: ICustomizationStyling = {
  isOverlayEnabled: true,
  overlay: colors.primaryText,
  overlayOpacity: 0.7,

  buttonBackgroundColor: colors.primary,
  buttonTextColor: colors.white,

  layerBackground: colors.primary,
  linkFont: colors.primary,
  secondLayerTab: colors.white,
  tabsBorderColor: colors.primary,
  text: colors.white,

  toggleActiveBackground: colors.primary,
  toggleActiveIcon: colors.white,
  toggleDisabledBackground: colors.primary,
  toggleDisabledIcon: colors.white,
  toggleInactiveBackground: colors.primary,
  toggleInactiveIcon: colors.white,
};

const initialPrivacyTriggerValues: ICustomizationPrivacyTrigger = {
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant.fingerprint,
  privacyButtonBackground: colors.primary,
  privacyButtonIcon: colors.white,
  privacyButtonSizeDesktop: 64,
  privacyButtonSizeMobile: 44,
};

const initialContextValues: IOnboardingContext = {
  customizationDetails: {
    layout: initialLayoutValues,
    privacyTrigger: initialPrivacyTriggerValues,
    styling: initialStylingValues,
  },
  setCustomizationDetails: () => null,
};

export const OnboardingContext = createContext<IOnboardingContext>(initialContextValues);

export const OnboardingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [customizationDetails, setCustomizationDetails] = useState(initialContextValues.customizationDetails);

  return (
    <OnboardingContext.Provider value={{ customizationDetails, setCustomizationDetails }}>
      {children}
    </OnboardingContext.Provider>
  );
};
