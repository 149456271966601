import useOnboarding from 'lib/hooks/useOnboarding';

import { Container } from './styled';

const Preview = () => {
  const { customizationDetails } = useOnboarding();
  // To be deleted, leaving it here for testing
  return <Container>{JSON.stringify(customizationDetails)}</Container>;
};

export default Preview;
