import { surveyApi } from 'api/config/surveyApi';
import { GetSurveyByIdResponse, IPostSurveyAnswersRequest, IsSurveyAnswered } from 'interfaces/responses';
import { HttpError } from 'lib/helpers/httpError';

export const getSurveyById = async (surveyId: string): Promise<GetSurveyByIdResponse> => {
  try {
    return (await surveyApi.get(`/survey/${surveyId}`)).data;
  } catch (error) {
    console.error(error);
    throw new HttpError(error);
  }
};

export const postSurveyAnswers = async (surveyId: string, data: IPostSurveyAnswersRequest): Promise<void> => {
  try {
    return (await surveyApi.post(`/survey/${surveyId}/answer`, data)).data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const isSurveyAnswered = async (surveyId: string): Promise<IsSurveyAnswered> => {
  try {
    return (await surveyApi.get(`/survey-answer/${surveyId}`)).data;
  } catch (error) {
    throw error.response.data.message;
  }
};
