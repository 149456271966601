import { Flex } from 'antd';
import styled from 'styled-components';

export const Account = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.gray[50]};
  border-radius: 4px;
  padding: 16px 20px;
`;

export const Login = styled(Flex)`
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const FormContainer = styled.div`
  margin: 24px 0;
  padding: 20px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.gray[8]};
`;

export const PermissionRow = styled(Flex)`
  padding: 16px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[16]};
  }
`;
