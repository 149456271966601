import { Form } from 'antd';

import { ICustomizationLayout } from 'lib/contexts/Onboarding';
import useOnboarding from 'lib/hooks/useOnboarding';
import { FieldData } from 'types';

import FirstLayer from './FirstLayer';
import SecondLayer from './SecondLayer';
import TabTemplate from '../../TabTemplate';

const LayoutTab = () => {
  const [layoutForm] = Form.useForm<ICustomizationLayout>();
  const { customizationDetails, setCustomizationDetails } = useOnboarding();

  const onFieldsChange = (changedFields: FieldData[]) => {
    if (!changedFields.length) {
      return;
    }

    const changedValues = changedFields.reduce<Partial<ICustomizationLayout>>((acc, curr) => {
      return {
        ...acc,
        [curr.name[0]]: curr.value,
      };
    }, {});

    setCustomizationDetails((prev) => ({ ...prev, layout: { ...prev?.layout, ...changedValues } }));
  };

  return (
    <Form form={layoutForm} initialValues={customizationDetails?.layout} onFieldsChange={onFieldsChange}>
      <TabTemplate
        items={[
          {
            title: 'First Layer',
            key: 'first-layer',
            content: <FirstLayer />,
          },
          {
            title: 'Second Layer',
            key: 'second-layer',
            content: <SecondLayer />,
          },
        ]}
      />
    </Form>
  );
};

export default LayoutTab;
