export const getConnection = (search: string) => {
  try {
    return search
      .split('&')
      .find((p) => p.includes('connection'))
      ?.split('=')[1];
  } catch (e) {
    return 'usercentrics';
  }
};

export const COOKIE_AUTH_ORGANIZATION = 'auth0_organization';

export const saveOrganization = (value: string) => {
  const date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

  const expires = `; expires=${date.toUTCString()}`;

  document.cookie = `${COOKIE_AUTH_ORGANIZATION}=${encodeURIComponent(value)}${expires}; path=/`;
};

export const getOrganization = () => {
  const cookieName = `${COOKIE_AUTH_ORGANIZATION}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split('; ');

  const foundCookie = cookieArray.find((cookie) => cookie.startsWith(cookieName));

  if (foundCookie) {
    const cookieValue = foundCookie.substring(cookieName.length);
    return cookieValue;
  }

  return null;
};

export const clearOrganization = () =>
  (document.cookie = `${COOKIE_AUTH_ORGANIZATION}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`);
