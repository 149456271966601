import useSWR from 'swr';
import type { SWRConfiguration, KeyedMutator } from 'swr';

export const commonSwrConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
};

type SWRKey = string | null | (string | null | Object)[];

export default <T>(
  url: SWRKey | (() => SWRKey),
  opt?: SWRConfiguration,
): { data: T; mutate: KeyedMutator<T>; isLoading: boolean; isError: boolean } => {
  const { data, error, mutate } = useSWR(url, { ...commonSwrConfig, ...opt });

  return {
    mutate,
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
  };
};
