import { LegalFramework, SubscriptionType } from 'api/requests/generated/generated.schemas';

export interface ICreateSetting {
  legalFramework: LegalFramework;
  domain: string;
  name: string;
  dataController: string;
  type: SubscriptionType;
}

export type ICreateSettingForm = Pick<ICreateSetting, 'name' | 'dataController' | 'domain'>;

export const ExtendedLegalFrameworkName = {
  ['TCF 2.2']: 'TCF 2.2',
} as const;

export interface IFramework {
  name: LegalFramework | keyof typeof ExtendedLegalFrameworkName;
  value: LegalFramework;
  expansion: string;
  icon: 'eu' | 'iab' | 'us';
  region: 'Europe' | 'California' | 'Colorado' | 'Connecticut' | 'Utah' | 'Virginia';
  isPremium?: true;
}
