import { EditFilled } from '@ant-design/icons';
import { Col, Form, Row, Space, Card } from 'antd';
import { useState, useEffect } from 'react';

import { AccountOutDto } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import Collapse from 'components/Collapse';
import { Item, Input } from 'components/Form';
import CountriesSelect from 'components/Select/CountriesSelect';
import { Text } from 'components/Typography';
import { getPrefix, getVatPlaceholder } from 'components/VatInput';

import EditModal from './EditModal';

interface ICompanyDetailsProps {
  isReadOnly?: boolean;
  initialValues: Omit<
    AccountOutDto,
    'chargebeeCustomerId' | 'companies' | 'paymentSources' | 'primaryPaymentSourceId'
  > & { email: string };
}

const AccountDetails = ({ initialValues, isReadOnly }: ICompanyDetailsProps) => {
  const [accountForm] = Form.useForm();
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    accountForm.setFieldsValue(initialValues);
  }, [initialValues, accountForm]);

  return (
    <Collapse
      expandIconPosition="end"
      defaultActiveKey="details"
      items={[
        {
          key: 'details',
          label: (
            <Text strong size="md">
              Account Information
            </Text>
          ),
          extra: !isReadOnly && (
            <Button icon={<EditFilled />} onClick={() => setShowEdit(true)}>
              Edit
            </Button>
          ),
          children: (
            <Card bordered={false}>
              <Space size="middle" direction="vertical">
                <Form form={accountForm} initialValues={initialValues} name={`account-details-${initialValues.id}`}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Item label="Account Name" name="name" colon={false}>
                        <Input placeholder="Account Name" disabled />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item label="Billing Email" name="email" colon={false}>
                        <Input placeholder="Billing Email" disabled />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item label="Street" name="street" colon={false}>
                        <Input placeholder="Street" disabled />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item label="ZIP Code" name="postcode" colon={false}>
                        <Input placeholder="ZIP Code" disabled />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item label="City" name="city" colon={false}>
                        <Input placeholder="City" disabled />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item label="Country" name="country" colon={false}>
                        <CountriesSelect disabled />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        colon={false}
                        label="VAT ID"
                        name="vatNumber"
                        hasFeedback
                        tooltip="The value-added tax identification number (VAT ID) is required to be able to apply the zero VAT rate for intra-community supplies of goods in the EU."
                      >
                        <Input
                          addonBefore={getPrefix(initialValues.country)}
                          size="large"
                          placeholder={getVatPlaceholder(initialValues.country)}
                          data-testid="input:account-vat"
                          disabled
                        />
                      </Item>
                    </Col>
                  </Row>
                </Form>
              </Space>
              {showEdit && (
                <EditModal initialValues={initialValues} open={showEdit} onClose={() => setShowEdit(false)} />
              )}
            </Card>
          ),
        },
      ]}
    />
  );
};

export default AccountDetails;
