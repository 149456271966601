import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m11.9734 4.3333.5267-1.1667 1.1667-.5266c.26-.12.26-.4867 0-.6067l-1.1667-.5267-.5267-1.1733c-.12-.26-.4866-.26-.6066 0L10.8401 1.5l-1.1733.5266c-.26.12-.26.4867 0 .6067l1.1666.5267.5267 1.1733c.12.26.4933.26.6133 0Zm-5.3066 1L5.6068 3c-.2334-.52-.98-.52-1.2134 0l-1.06 2.3333-2.3333 1.06c-.52.24-.52.98 0 1.2133l2.3333 1.06L4.3934 11c.24.52.98.52 1.2134 0l1.06-2.3334L9 7.6066c.52-.24.52-.98 0-1.2133l-2.3333-1.06ZM11.36 9.6666l-.5267 1.1667-1.1666.5267c-.26.12-.26.4866 0 .6066l1.1666.5267.5267 1.1733c.12.26.4867.26.6067 0l.5266-1.1666 1.1734-.5267c.26-.12.26-.4867 0-.6067l-1.1667-.5266-.5267-1.1734c-.12-.26-.4933-.26-.6133 0Z"
      fill="#fff"
    />
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
