import { Flex } from 'antd';

import { Title } from 'components/Typography';
import { limitNumber, numberWithCommas } from 'lib/helpers';

import { ProgressContainer, Status } from './styled';

interface IProgressProps {
  min: number;
  max: number;
  value: number;
}

const Progress = ({ min, max, value }: IProgressProps) => {
  const percents = limitNumber((value / (max - min)) * 100, 0, 100);

  return (
    <Flex vertical align="center">
      <ProgressContainer>
        <Status style={{ width: `calc(${percents}% - 2px)` }} $completed={value > max} />
      </ProgressContainer>
      <Flex>
        <Title $noMargin level={5} color={value >= max ? 'orange' : 'black'}>
          {numberWithCommas(value)}
        </Title>
        &nbsp;of&nbsp;
        <Title $noMargin level={5}>
          {numberWithCommas(max)}
        </Title>
      </Flex>
    </Flex>
  );
};

export default Progress;
