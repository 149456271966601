import { ButtonProps, Checkbox, Flex, ModalProps } from 'antd';

import Button from 'components/Button';
import CloseIcon from 'components/Icons/Close';
import { Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { Container, Label, Content } from './styled';

export interface IButtonProps extends ButtonProps {
  label?: string;
}

export interface IModalProps extends ModalProps {
  description?: string;
  isDefaultFooter?: boolean;
  primaryButton?: IButtonProps | null;
  secondaryButton?: IButtonProps | null;
  checkbox?: boolean;
  isConfirmation?: boolean;
  hideFooterBorder?: boolean;
  bodyHeight?: string | number;
  checkboxLabel?: string;
  onCheckboxChange?: (isChecked: boolean) => void;
  onClose: () => void;
}

const Modal = ({
  open,
  children,
  title,
  isDefaultFooter = true,
  checkboxLabel,
  primaryButton,
  secondaryButton,
  checkbox,
  isConfirmation,
  hideFooterBorder,
  bodyHeight,
  onClose,
  width,
  onCheckboxChange,
  ...props
}: IModalProps) => {
  return (
    <Container
      styles={{
        mask: {
          backgroundColor: colors.blue[8],
          opacity: 0.8,
        },
      }}
      open={open}
      centered
      title={
        <>
          <Title level={4} $noMargin color="white">
            {title}
          </Title>
        </>
      }
      onCancel={onClose}
      hideFooterBorder={hideFooterBorder}
      closeIcon={<CloseIcon />}
      footer={
        <>
          {checkbox && onCheckboxChange && (
            <div>
              <Checkbox onChange={(e) => onCheckboxChange(e.target.checked)} />
              {checkboxLabel && <Label>{checkboxLabel}</Label>}
            </div>
          )}
          <Flex
            justify={isDefaultFooter ? 'space-between' : 'end'}
            style={isDefaultFooter ? { width: '100%' } : { marginLeft: 'auto' }}
          >
            {secondaryButton && (
              <Button key="secondaryButton" data-testid="button:cancel" {...secondaryButton}>
                {secondaryButton.label}
              </Button>
            )}
            {primaryButton && (
              <Button size="large" key="primaryButton" type="primary" data-testid="button:submit" {...primaryButton}>
                {primaryButton.label}
              </Button>
            )}
          </Flex>
        </>
      }
      bodyHeight={bodyHeight}
      width={width}
      {...props}
    >
      <Content isConfirmation={isConfirmation}>{children}</Content>
    </Container>
  );
};

export default Modal;
