import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { TRIAL_COOKIES } from 'lib/consts';

import { authInterceptor } from './interceptors';

const onboardingApi = axios.create({
  baseURL: process.env.REACT_APP_API_ONBOARDING || 'https://onboarding.service.usercentrics-sandbox.eu',
});

const notAuthorizedInterceptor = (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.status === 401) {
    let urlTrialParam = '';
    if (Cookies.get(TRIAL_COOKIES.TRIAL_USED)) {
      urlTrialParam = `?flow=${Cookies.get(TRIAL_COOKIES.TRIAL_USED)}`;
    }

    window.location.replace(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${window.location.origin}${urlTrialParam}`,
    );
  }

  return Promise.reject(error);
};

onboardingApi.interceptors.request.use(authInterceptor);
onboardingApi.interceptors.response.use((r) => r, notAuthorizedInterceptor);

export { onboardingApi };
