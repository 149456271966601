import { Typography } from 'antd';
import React from 'react';

import { Title } from '../Typography';

const { Paragraph } = Typography;
interface IPageWrapper {
  title: string | React.ReactElement;
  description: string | React.ReactElement;
}

const PageInfo = ({ title, description }: IPageWrapper) => (
  <div>
    <Title level={1}>{title}</Title>
    <Paragraph>{description}</Paragraph>
    <br />
  </div>
);

export default PageInfo;
