import { Row, Col, Typography } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';

import Table from 'components/Table';
import { ISettingDetails } from 'interfaces/ISetting';

const { Title, Paragraph } = Typography;

interface ISelectConfigurationsProps {
  configurations: ISettingDetails[];
  rowSelection: TableRowSelection<ISettingDetails>;
}

const generateColumns = () => [
  {
    title: 'Name/Setting-ID',
    key: 'settingId',
    render: (setting: ISettingDetails) => (
      <div data-testid={`manage-configurations-row[${setting.id}]:setting-id`}>
        {setting.aliasName} [{setting.id}]
      </div>
    ),
  },
  {
    title: 'Domain/App-IDs',
    key: 'domainOrAppId',
    render: ({ appIds, domains }: ISettingDetails) => <> {domains?.join(', ') || '' || appIds?.join(', ')} </>,
  },
  {
    title: 'Data Controller',
    dataIndex: 'dataController',
    key: 'dataController',
  },
];

const SelectConfigurations = ({ configurations, rowSelection }: ISelectConfigurationsProps) => {
  return (
    <Row>
      <Col span={24}>
        <Title level={3}>Overview</Title>
      </Col>
      <Col span={24}>
        <Paragraph>Choose which configurations you want to move to a company.</Paragraph>
      </Col>
      <Col span={24}>
        <Table<ISettingDetails>
          dataSource={configurations.map((setting) => ({ key: setting.id, ...setting }))}
          columns={generateColumns()}
          pagination
          rowSelection={rowSelection}
        />
      </Col>
    </Row>
  );
};

export default SelectConfigurations;
