import { getOrganization } from 'lib/helpers/auth0';

const openSettingInAI = (settingId: string, version: string, target?: '_blank' | '_self') => {
  const auth0Organization = getOrganization();
  const orgQueryParam = auth0Organization ? `&organization=${auth0Organization}` : '';

  const v1 = `setting/general?settingsId=${settingId}${orgQueryParam}`;
  const v2 = `${version?.toLowerCase()}?settingsId=${settingId}${orgQueryParam}`;

  window.open(`${process.env.REACT_APP_ADMIN_UC_URL}/#/${version === 'V2' ? v2 : v1}`, target || '_blank');
};

export default openSettingInAI;
