import { deleteSetting } from 'api/requests';
import Modal from 'components/Modal/Modal';
import { useMessage } from 'lib/hooks';

interface IDeleteSettingProps {
  open: boolean;
  onClose: () => void;
  settingId: string;
}

const DeleteSetting = ({ open, onClose, settingId }: IDeleteSettingProps) => {
  const message = useMessage();

  const onDelete = async () => {
    try {
      await deleteSetting(settingId);
      message.success(`${settingId} was deleted successfully.`);
    } catch (error) {
      message.error(error?.message);
    } finally {
      onClose();
    }
  };
  return (
    <Modal
      open={open}
      title="Delete Configuration?"
      primaryButton={{
        label: 'Delete',
        onClick: onDelete,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      isDefaultFooter={false}
      isConfirmation
      onClose={onClose}
    >
      <span>Please confirm that you want to delete this configuration. </span>
    </Modal>
  );
};

export default DeleteSetting;
