import { Col, Row, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { SearchSettingOutDto } from 'api/requests/generated/generated.schemas';
import { Review as ReviewIcon } from 'components/Icons';
import SubscriptionType from 'components/SubscriptionType';
import Tag from 'components/Tag';
import { Text } from 'components/Typography';
import { sort, sortByNameAndId } from 'lib/helpers/sort';

import { UnassignedLabel } from './styled';

const { Paragraph } = Typography;

export const generateConfigurationColumns = (newSettings: string[]) => {
  return [
    {
      title: 'Configuration Name [ID]',
      showSorterTooltip: false,
      key: 'settingId',
      width: '25%',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sortByNameAndId(a, b),
      ellipsis: true,
      render: (record: SearchSettingOutDto) => {
        const text = `${record?.aliasName} [${record.id}]`;

        return (
          <Row justify="start" gutter={[10, 0]}>
            {record?.subscription && (
              <Col>
                <SubscriptionType type={record.subscription.type} />
              </Col>
            )}
            {record?.reviewMode && (
              <Col>
                <ReviewIcon />
              </Col>
            )}
            <Col>
              <Text ellipsis={{ tooltip: true }}>{text}</Text>
            </Col>
            <Col>
              {newSettings.includes(record.id) && (
                <Tag color="blue" bordered>
                  New
                </Tag>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Company',
      showSorterTooltip: false,
      key: 'parentId',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sort(a.parentName, b.parentName),
      ellipsis: true,
      render: (record: SearchSettingOutDto) =>
        record.parentName ? (
          <Tooltip title={record.parentName}>
            <Link onClick={(e) => e.stopPropagation()} to={`/company/${record.parentId}/configurations`}>
              {record.parentName}
            </Link>
          </Tooltip>
        ) : (
          <UnassignedLabel>unassigned</UnassignedLabel>
        ),
    },
    {
      title: 'Domain / App-ID',
      showSorterTooltip: false,
      key: 'domainOrAppId',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) =>
        sort(a.domains?.join() || '', b.domains?.join() || ''),
      ellipsis: true,
      render: (record: SearchSettingOutDto) => {
        const text = record.domains?.join(' ') || record.appIds?.join(' ') || '-';

        return (
          <Tooltip title={text} placement="topLeft">
            <span data-testid={`domain:${record.id}`}>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Controller',
      showSorterTooltip: false,
      key: 'dataController',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sort(a.dataController, b.dataController),
      ellipsis: true,
      render: (record: SearchSettingOutDto) => {
        return <Tooltip title={record.dataController}>{record.dataController || '-'}</Tooltip>;
      },
    },
    {
      title: 'Framework',
      showSorterTooltip: false,
      dataIndex: 'legalFramework',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sort(a.legalFramework, b.legalFramework),
      key: 'legalFramework',
      ellipsis: true,
      render: (legalFramework: string, record: SearchSettingOutDto) => {
        return (
          <Tooltip title={legalFramework}>
            <span data-testid={`framework:${record.id}`}>{legalFramework}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Ruleset',
      showSorterTooltip: false,
      key: 'ruleset',
      dataIndex: 'ruleSets',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) =>
        sort(a.ruleSets?.join() || '', b.ruleSets?.join() || ''),
      ellipsis: true,
      render: (rulesets: { name: string; id: string }[], record: SearchSettingOutDto) =>
        rulesets.length > 0 ? (
          <Paragraph
            ellipsis={{
              tooltip: { placement: 'topLeft' },
              expandable: rulesets.length > 1,
              rows: 3,
              symbol: <div>Show more</div>,
              onExpand: (e) => e.stopPropagation(),
            }}
            data-testid={`rulesets:${record.id}`}
          >
            {rulesets.map((r) => (
              <Link
                to={{
                  pathname: `/company/${record.parentId}/geo-rulesets`,
                  hash: `#${r.id}`,
                }}
                onClick={(e) => e.stopPropagation()}
                key={r.id}
              >
                {r?.name} <br />
              </Link>
            ))}
          </Paragraph>
        ) : (
          <UnassignedLabel>unassigned</UnassignedLabel>
        ),
    },
  ];
};
