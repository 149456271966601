import { Row, Skeleton, Space, Typography, Flex } from 'antd';
import { useEffect } from 'react';

import { TrialSubscriptionOutDto, EstimationOutDto, SubscriptionType } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Success as SuccessIcon, VerifiedWave } from 'components/Icons';
import TermsAndConditions from 'components/TermsAndConditions';
import { Title, Text, Link } from 'components/Typography';
import { Currency, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { getPlanName, isDmaCoupon, numberWithCommas } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import useAmplitude from 'lib/hooks/useAmplitude';

import { Content, Discount, ListContainer, ListItem, Section } from './styled';

const { Paragraph } = Typography;

interface IUpgradePlan {
  subscription: TrialSubscriptionOutDto;
  isButtonDisabled: boolean;
  isLockout: boolean;
  readonly?: boolean;
  isAllFeaturesVisible?: boolean;
  setIsButtonDisabled: (value: boolean) => void;
  estimation?: EstimationOutDto;
  handleNext: () => void;
}

const getAmount = (estimation: EstimationOutDto | undefined) => numberWithCommas(estimation?.tier.maxValue || 50000);
const getUnits = (type: SubscriptionType) => (type === SubscriptionType.app ? 'DAU (Daily Active Users)' : 'sessions');

const getPlanInfo = (estimation: EstimationOutDto | undefined, sessions: string) => {
  if (!estimation) {
    return { price: '', description: '', priceBeforeDiscount: '' };
  }

  const currencySign = Currency[estimation.currencyCode as keyof typeof Currency];
  const taxableAmountPerMonth = estimation.perMonth.totalWithoutDiscount;
  const price = `${currencySign}${estimation.perMonth.taxableAmount}`;

  const inclVatSuffix = estimation.perMonth.total === estimation.perMonth.taxableAmount ? '' : ' (incl. VAT)';
  const fixedTotal = estimation.perYear.total.toFixed(2);

  const description = `The contract period is 12 months, billed at ${currencySign}${fixedTotal} per year${inclVatSuffix}, up to ${sessions} per month. This is equivalent to ${currencySign}${taxableAmountPerMonth} per month, the contract period starts on the first day after the end of the free-trial-phase. It is automatically extended by another year respectively. Period of notice: 4 weeks to the end of each contract period.`;

  return { price, description };
};

const featuresList = [
  'Keep full access to all configurations',
  'Remain legally compliant',
  'Optimize your CMP performance',
  'Get access to upcoming features',
];

const Upgrade = ({
  readonly,
  subscription,
  isLockout,
  handleNext,
  isButtonDisabled,
  setIsButtonDisabled,
  isAllFeaturesVisible = true,
  estimation,
}: IUpgradePlan) => {
  const amountOfSessions = `${getAmount(estimation)} ${getUnits(subscription.type)}`;
  const { price, description } = getPlanInfo(estimation, amountOfSessions);
  const { deviceId } = useAmplitude();
  const discount = estimation?.discount?.discountPercentage;

  if (!subscription) return null;

  /**
   * Tracking events
   */
  useEffect(() => {
    trackEvent(TrackingEvents.PAYMENT_WALL_VIEWED, {
      [TrackingVariables.PLAN_NAME]: getPlanName(subscription?.pricePlan, subscription?.type),
    });
  }, []);
  /**
   * ======
   */
  return (
    <>
      <Flex justify="space-between">
        <Section withBackground>
          <Title level={3}>
            {isLockout
              ? 'Upgrade your plan to regain full access to your account'
              : 'Retain the full potential of collecting consents'}
          </Title>
          {isLockout && (
            <Paragraph>Otherwise your configurations and companies will be removed after 30 days</Paragraph>
          )}
          <Content>
            {featuresList.map((feature) => (
              <ListItem key={feature}>
                <SuccessIcon /> <span>{feature}</span>
              </ListItem>
            ))}
            {isAllFeaturesVisible && (
              <Link strong href={`https://usercentrics.com/pricing?deviceId=${deviceId}`} target="_blank">
                See all features included
              </Link>
            )}
          </Content>
        </Section>
        <Section>
          <Skeleton loading={!estimation}>
            <Space direction="vertical" size={18}>
              {discount && (
                <Discount
                  message={isDmaCoupon(subscription?.couponId) ? '1 month for free' : `${discount}% discount applied`}
                  icon={<VerifiedWave />}
                  showIcon
                />
              )}
              <Row justify="space-between">
                <div>
                  <Text size="sm">Your Plan</Text>
                  <Title level={3} $noMargin>
                    {getPlanName(subscription.pricePlan, subscription.type)}
                  </Title>
                </div>
                <div>
                  <Title level={4}>{price}</Title>
                  <Row>
                    <Text size="sm">per month</Text>
                  </Row>
                </div>
              </Row>
              <ListContainer>
                <ul>
                  <li>Unlimited configurations</li>
                  <li>Unlimited domains</li>
                  <li>Up to {amountOfSessions}</li>
                </ul>
              </ListContainer>
            </Space>
            <Text size="sm">{description}</Text>
          </Skeleton>
        </Section>
      </Flex>
      {isLockout && readonly && (
        <Content alignToCenter data-testid="div:upgrade-email-info">
          Please sign in with your Billing Account <br /> {estimation?.billingEmail} to proceed with the upgrade
        </Content>
      )}
      {!readonly && (
        <Content data-testid="div:terms-and-conditions">
          <TermsAndConditions setIsCheckboxValid={(isValid) => setIsButtonDisabled(!isValid)} />
        </Content>
      )}

      <Button
        type="primary"
        disabled={isButtonDisabled}
        data-testid="button:buy-now"
        block
        style={{ padding: 12, margin: '20px 0' }}
        onClick={handleNext}
      >
        Buy Now
      </Button>
    </>
  );
};

export default Upgrade;
