import { useContext } from 'react';

import { OnboardingContext } from 'lib/contexts/Onboarding';

const useOnboarding = () => {
  const values = useContext(OnboardingContext);

  return values;
};

export default useOnboarding;
