import { SubscriptionPricePlan } from 'api/requests/generated/generated.schemas';

export enum ConfigurationSearchFilter {
  All = 'q',
  ConfigurationId = 'settingIds',
  ConfigurationName = 'aliasName',
  AppId = 'appId',
  DataController = 'dataController',
  Domain = 'domain',
  Email = 'email',
  SubscriptionId = 'subscriptionId',
}

export enum Currency {
  EUR = '€',
  USD = '$',
  GBP = '£',
}

export enum Links {
  TERMS_AND_CONDITIONS = 'https://usercentrics.com/terms-and-conditions/',
  TERMS_AND_CONDITIONS_USA = 'https://usercentrics.com/terms-and-conditions-usa/',
  DPA_US = 'https://usercentrics.com/wp-content/uploads/2023/08/Final_Joint-Product-DPA_UC.pdf',
  USERCENTRICS_EMAIL_UNSUBSCRIBE = 'unsubscribe@usercentrics.com',
}

export enum TrackingEvents {
  ACCOUNT_BILLING_PAGE_VISITED = 'Account Billing Page Visited', // When customer visits the Account & Billing section
  COMPANY_CREATED = 'Company Created', // Tracks when the user created a company in the UI
  CONFIGURATION_CREATED = 'Configuration Created', // Tracks when the user created a configuration in the UI
  CREDIT_CARD_DATA_STORED = 'Credit Card Data Stored', // Tracks when the customer filled out card information and clicked to proceed payment
  CTA_FREE_TRIAL = 'CTA Free Trial - Clicked',
  CTA_QUOTA_BAR_UPGRADE = 'CTA Quota Bar Upgrade - Clicked',
  CTA_TRIAL_BAR_UPGRADE = 'CTA Trial Bar Upgrade - Clicked',
  EMAIL_VERIFICATION_COMPLETED = 'Email Verification - Completed',
  EMAIL_VERIFICATION_VISITED = 'Email Verification - Visited',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  MANAGE_BILLING_ACCOUNT_ACCESS_VIEWED = 'Manage Billing Account Access Viewed',
  ONBOARDING_CONFIGURATION_SELECTED = 'Onboarding - Configuration Selected',
  ONBOARDING_DETAILS_FILLED = 'Onboarding - Details Filled',
  ONBOARDING_SETUP_COMPLETED = 'Onboarding - Setup Completed',
  ONBOARDING_SURVEY_SUBMITTED = 'Onboarding - Survey Submitted',
  ONBOARDING_TRIAL_START_FAILED = 'Onboarding - Trial Start Failed',
  ONBOARDING_TRIAL_STARTED = 'Onboarding - Trial Started',
  ONBOARDING_DOMAIN_ENTERED = 'Onboarding Domain Entered',
  ONBOARDING_CUSTOMIZATION_COMPLETED = 'Onboarding Customization Completed',
  ONBOARDING_IMPLEMENTATION_COMPLETED = 'Onboarding Implementation Completed',
  UPGRADE_INITIATED = 'Upgrade Initiated',
  PURCHASE_INITIATED = 'Purchase Initiated',
  PAYMENT_DETAILS_VIEWED = 'Payment Details Viewed', // Tracks when the customer clicks Buy button to fill card details
  PAYMENT_WALL_VIEWED = 'Payment Wall Viewed', // Tracks when the customer sees the payment wall (via upgrade button, lock screen, trial bar)
  SET_DEVICE_ID = 'setDeviceId',
  SET_USER_ID = 'setUserId',
  SIGNUP = 'Signup',
  SUBSCRIPTION_UPGRADED = 'Subscription Upgraded', // When customer exceeded usage (sessions/DAU depending on subscription plan) consecutively for last 3 months & upgraded the subscription via the Upgrade button in Account & Billing section
  TRIAL_CONVERTED = 'Trial Converted', // Tracks when the user paid and got their subscription upgraded
  TRIAL_EXPIRED = 'TRIAL Expired',
  // TODO: These 2 can be removed after 07-08-2024
  UC_UPSELL_FEATURE_DISMISSED = 'UC Upsell Feature Dismissed',
  UC_UPSELL_FEATURE_EXPLORED = 'UC Upsell Feature Explored',
  // =======
  USAGE_OVERVIEW_VIEWED = 'Usage Overview Viewed', // User clicks on "See usage overview" in the notification bar
  USER_GRANTED_ACCESS_TO_BILLING_ACCOUNT = 'User Granted Access to Billing Account',
  UPGRADE_SUBSCRIPTION_VIEWED = 'Upgrade Subscription Viewed', // User opens a modal window after clicking upgrade subscription
  UPGRADE_SUBSCRIPTION_DISMISSED = 'Upgrade Subscription Dismissed',
}

export enum TrackingVariables {
  COMPANY_BILLING_ACCOUNT = 'Company Billing Account',
  COMPANY_CITY = 'Company City',
  COMPANY_COUNTRY = 'Company Country',
  COMPANY_ID = 'Company Id',
  COMPANY_NAME = 'Company Name',
  CONFIGURATION_FRAMEWORK = 'Configuration Framework',
  DEVICE_ID = 'DeviceID',
  ERROR = 'Error',
  LEAD_SOURCE = 'uc_mktg',
  PLAN_NAME = 'Plan Name',
  PLATFORM = 'Platform',
  SOURCE_PAGE = 'Source Page',
  TRIAL_EXPIRED_DATE = 'Trial Expired Date',
  TRIAL_TYPE = 'Trial Type',
  USER_ID = 'User ID',
  SUBSCRIPTION_ID = 'Subscription-ID',
  BILLING_ACCOUNT_ID = 'Billing Account ID',
  FORCIBLY_OPENED = 'Forcibly Opened',
  SCENARIO = 'Scenario',
  REFERRER_SECTION = 'Referrer Section',
  REFERRER_URL = 'Referrer URL',
  FIRST_LAYER_BANNER_LAYOUT_CUSTOM = 'Is First Layer Banner Layout Customised',
  BUTTON_COLOR_CUSTOM = 'Is Button Color Customised',
  PRIVACY_BUTTON_LAYOUT_CUSTOM = 'Is Privacy Button Layout Customised',
  IS_AUTO_BLOCKING_METHOD_SELECTED = 'Is Auto Blocking Method Selected',
}

export enum SCENARIO {
  TRIAL_UPGRADE = 'Trial Upgrade',
  PLAN_UPGRADE = 'Plan Upgrade',
  SESSION_UPGRADE = 'Session Upgrade',
  FREE_UPGRADE = 'Free Upgrade',
}

export enum CMP_EVENTS {
  ACCEPT_ALL = 'ACCEPT_ALL',
  DENY_ALL = 'DENY_ALL',
  SAVE = 'SAVE',
}

// These are keys we use to store information in local storage, some of them are temporary
export enum LS_KEYS {
  UC_UPSELL_NEW_FEATURE_DISSMISS = 'uc_upsell_newFeatureDissmissed',
  UC_NEW_SETTINGS = 'ucNewSettings',
  UC_FORCE_SHOW_UPGRADE_SUBS = 'ucForceShowUpgradeSubs', // Whether to show or not upgrade window for subscription when user opens company page
}

export const OnePricingPricePlans: Partial<SubscriptionPricePlan[]> = [
  SubscriptionPricePlan.FreeSimplified,
  SubscriptionPricePlan.EssentialSimplified,
  SubscriptionPricePlan.ProSimplified,
  SubscriptionPricePlan.BusinessSimplified,
  SubscriptionPricePlan.FreeExtended,
  SubscriptionPricePlan.EssentialExtended,
  SubscriptionPricePlan.PlusExtended,
  SubscriptionPricePlan.ProExtended,
  SubscriptionPricePlan.BusinessExtended,
];

export const OnePricingPlanNames: { [key in SubscriptionPricePlan]?: string } = {
  [SubscriptionPricePlan.FreeSimplified]: 'Free',
  [SubscriptionPricePlan.FreeExtended]: 'Free',
  [SubscriptionPricePlan.EssentialSimplified]: 'Essential',
  [SubscriptionPricePlan.EssentialExtended]: 'Essential',
  [SubscriptionPricePlan.PlusExtended]: 'Plus',
  [SubscriptionPricePlan.ProSimplified]: 'Pro',
  [SubscriptionPricePlan.ProExtended]: 'Pro',
  [SubscriptionPricePlan.BusinessExtended]: 'Business',
  [SubscriptionPricePlan.BusinessSimplified]: 'Business',
};

export enum OnboardingType {
  ONE_CLICK = 'ONE_CLICK',
  REGULAR = 'REGULAR',
}

export enum QuotaNames {
  CMP_LANGUAGES_PER_CONFIG = 'cmp-languages-per-config',
  FRAMEWORK_TCF = 'framework-tcf',
  NUMBER_OF_CONFIGS = 'number-of-configs',
  UNIQUE_DOMAINS = 'unique-domains',
}

// These are only entitlements used in Account UI
export enum Entitlements {
  GEOTARGETING = 'geotargeting',
  ACCOUNT_USER_ROLES = 'account-user-roles',
}
