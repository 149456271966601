import { Collapse as AntCollapse } from 'antd';
import styled from 'styled-components';

export const Collapse = styled(AntCollapse)`
  && .ant-collapse-header {
    padding: 0;

    & .ant-collapse-header-text {
      margin-inline-end: 0;
      flex: none;
    }
  }
`;
