import { Flex, Row } from 'antd';
import styled from 'styled-components';

export const Box = styled(Row)`
  background: ${({ theme }) => theme.colors.white};
  padding: 12px 16px 12px 12px;
  border-radius: 4px;
  cursor: pointer;
`;

export const IconWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.gray[8]};
  padding: 3px;
  justify-content: center;
  border-radius: 3px;
  width: 32px;
  height: 32px;
`;
