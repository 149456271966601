import axios, { AxiosError } from 'axios';

import { apiErrorInterceptor, authInterceptor } from './interceptors';

const surveyApi = axios.create({
  baseURL: process.env.REACT_APP_API_SURVEY || 'https://api.survey.usercentrics-sandbox.eu/',
});

const notAuthorizedInterceptor = (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.status === 401) {
    window.location.replace(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${window.location.origin}/login`,
    );
  }

  return Promise.reject(error);
};

surveyApi.interceptors.request.use(authInterceptor);
surveyApi.interceptors.response.use((r) => r, notAuthorizedInterceptor);
surveyApi.interceptors.response.use((r) => r, apiErrorInterceptor);

export { surveyApi };
