import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="page/sidebar-geolocation-inactive" transform="translate(-8, -8)">
        <g id="public_black_24dp" transform="translate(8.000000, 10.000000)">
          <polygon id="Path" points="0 0 20 0 20 20 0 20" />
          <path
            d="M10,1.66666667 C5.4,1.66666667 1.66666667,5.4 1.66666667,10 C1.66666667,14.6 5.4,18.3333333 10,18.3333333 C14.6,18.3333333 18.3333333,14.6 18.3333333,10 C18.3333333,5.4 14.6,1.66666667 10,1.66666667 Z M9.16666667,16.6083333 C5.875,16.2 3.33333333,13.4 3.33333333,10 C3.33333333,9.48333333 3.4,8.99166667 3.50833333,8.50833333 L7.5,12.5 L7.5,13.3333333 C7.5,14.25 8.25,15 9.16666667,15 L9.16666667,16.6083333 Z M14.9166667,14.4916667 C14.7,13.8166667 14.0833333,13.3333333 13.3333333,13.3333333 L12.5,13.3333333 L12.5,10.8333333 C12.5,10.375 12.125,10 11.6666667,10 L6.66666667,10 L6.66666667,8.33333333 L8.33333333,8.33333333 C8.79166667,8.33333333 9.16666667,7.95833333 9.16666667,7.5 L9.16666667,5.83333333 L10.8333333,5.83333333 C11.75,5.83333333 12.5,5.08333333 12.5,4.16666667 L12.5,3.825 C14.9416667,4.81666667 16.6666667,7.20833333 16.6666667,10 C16.6666667,11.7333333 16,13.3083333 14.9166667,14.4916667 Z"
            id="Shape"
            fill="#303030"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
