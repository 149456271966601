import styled from 'styled-components';

import backgroundImage from 'assets/background.svg';
import { Text } from 'components/Typography';
import { center, pr } from 'shared-components/styled';

export const Top = styled.div`
  text-align: center;
  padding: 80px 0;
  width: 100vw;
  height: 16.25rem;
  ${pr}
  margin-left: -50vw;
  background: no-repeat url(${backgroundImage});
  background-size: cover;
  left: 50%;
  color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div`
  width: 400px;
  padding: 24px;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  border-radius: 4px;
  ${center};
  top: 100%;
`;

export const LogButton = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;
