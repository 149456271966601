import { useCallback, useState } from 'react';

import useMessage from 'lib/hooks/useMessage';

import Modal from './Modal';

interface IDeleteAdminProps {
  open: boolean;
  onClose: () => void;
  onDelete: (arg?: string) => Promise<void>;
}

const DeleteAdmin = ({ open, onClose, onDelete }: IDeleteAdminProps) => {
  const [loading, setLoading] = useState(false);
  const message = useMessage();

  const onRemoveClick = useCallback(() => {
    setLoading(true);
    onDelete()
      .then(onClose)
      .catch((e) => {
        onClose();
        message.error(e.response?.data.error?.msg);
        setLoading(false);
      });
  }, [setLoading, onDelete]);

  return (
    <Modal
      open={open}
      title="Remove Access?"
      primaryButton={{
        label: 'Remove',
        loading,
        onClick: onRemoveClick,
        danger: true,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      hideFooterBorder
      isConfirmation
      width={370}
      onClose={onClose}
    >
      <span>
        Please confirm that you want to remove the access for this user. The user will no longer have access to the
        Configurations (Settings-IDs) assigned to this company. This action cannot be undone.
      </span>
    </Modal>
  );
};

export default DeleteAdmin;
