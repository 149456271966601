import { Layout, Flex } from 'antd';

import { Desktop } from 'components/Icons';
import Logo from 'components/Logo';
import { Text, Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { Content, Header, IconWrapper } from './styled';

const VerifyEmail = ({ children }: React.PropsWithChildren) => (
  <Layout>
    <Header>
      <Flex vertical gap={40}>
        <Flex justify="center">
          <Logo />
        </Flex>

        <Flex vertical gap={10}>
          <Text style={{ color: colors.primary }} strong>
            Usercentrics Consent Management Platform.
          </Text>
          <Title $noMargin ellipsis>
            Start your trial
          </Title>
        </Flex>
      </Flex>
    </Header>
    <Content>
      <IconWrapper justify="center" align="center">
        <Desktop />
      </IconWrapper>
      <Flex vertical gap={8}>
        <Title align="center" $noMargin>
          Verify your email
        </Title>
        <Text style={{ textAlign: 'center' }}>
          Verifying your email is crucial to the security of your account and ensuring you receive important updates and
          notifications from us.
        </Text>
        <Text style={{ textAlign: 'center' }}>
          An email will be sent to the email address you've provided. Please check your inbox and click on the 'Verify
          email address' button provided in the email.
        </Text>
      </Flex>

      <Flex justify="center">{children}</Flex>
      <Text size="sm" align="center">
        If you did not receive the email, please check your spam folder. Please make sure to enter your email address
        correctly.
      </Text>
    </Content>
  </Layout>
);

export default VerifyEmail;
