import styled from 'styled-components';

export const FormContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  border-radius: 4px;
  padding: 20px;
  margin-top: 24px;
`;

export const NoUsers = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  margin-top: 20px;
  border-radius: 4px;

  p {
    font-size: 16px;
    font-weight: 500;
  }

  div {
    background-color: ${({ theme }) => theme.colors.gray[4]};
    width: 100%;
    padding: 17px;
    text-align: center;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray[80]};
  }
`;
