import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    width="24px"
    height="16px"
    viewBox="0 0 24 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Platform Indicator</title>
    <defs>
      <path
        d="M4,3 C4,2.45 4.45,2 5,2 L21,2 C21.55,2 22,1.55 22,1 C22,0.45 21.55,0 21,0 L4,0 C2.9,0 2,0.9 2,2 L2,13 L1.5,13 C0.67,13 0,13.67 0,14.5 C0,15.33 0.67,16 1.5,16 L14,16 L14,13 L4,13 L4,3 Z M23,4 L17,4 C16.45,4 16,4.45 16,5 L16,15 C16,15.55 16.45,16 17,16 L23,16 C23.55,16 24,15.55 24,15 L24,5 C24,4.45 23.55,4 23,4 Z M22,13 L18,13 L18,6 L22,6 L22,13 Z"
        id="path-17"
      />
    </defs>
    <g id="🚧-[DSPD-XXXX]-CSA-Subscription-Selection" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="⚠️-[Usecase]-Existing-AM-Customer-w/o-Subscription" transform="translate(-308.000000, -708.000000)">
        <g id="card-company" transform="translate(272.000000, 618.000000)">
          <g id="Subscription" transform="translate(20.000000, 62.000000)">
            <g id="Platform-Indicator" transform="translate(16.000000, 28.000000)">
              <mask id="mask-26" fill="white">
                <use xlinkHref="#path-17" />
              </mask>
              <g id="icon/device/devices_24px" fillRule="nonzero" />
              <g id="↳-Color" mask="url(#mask-26)" fill="#979797">
                <g transform="translate(0.000000, -4.000000)" id="Rectangle">
                  <rect x="0" y="0" width="24" height="24" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
