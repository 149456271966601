/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import type { Arguments } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  CustomerLinkageControllerLink200,
  CustomerLinkageControllerLinkParams,
  CustomerLinkageControllerUnlink200,
  CustomerLinkageControllerUnlinkParams,
  CustomerLinkageControllerValidateLink200,
  CustomerLinkageControllerValidateLinkParams,
  CustomerLinkageControllerValidateUnlink200,
  CustomerLinkageControllerValidateUnlinkParams,
  FailResponseDto,
  LinkInDto,
  UnlinkInDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Validates if it is possible to link a company to a billing account
 */
export const customerLinkageControllerValidateLink = (
  linkInDto: LinkInDto,
  params?: CustomerLinkageControllerValidateLinkParams,
) => {
  return customInstance<CustomerLinkageControllerValidateLink200>({
    url: `/customer-linkage/validate/link`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: linkInDto,
    params,
  });
};

export const getCustomerLinkageControllerValidateLinkMutationFetcher = (
  params?: CustomerLinkageControllerValidateLinkParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerLinkageControllerValidateLink200> => {
    return customerLinkageControllerValidateLink(arg as LinkInDto, params);
  };
};
export const getCustomerLinkageControllerValidateLinkMutationKey = () => `/customer-linkage/validate/link` as const;

export type CustomerLinkageControllerValidateLinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerLinkageControllerValidateLink>>
>;
export type CustomerLinkageControllerValidateLinkMutationError = FailResponseDto;

/**
 * @summary Validates if it is possible to link a company to a billing account
 */
export const useCustomerLinkageControllerValidateLink = <TError = FailResponseDto>(
  params?: CustomerLinkageControllerValidateLinkParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerLinkageControllerValidateLink>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerLinkageControllerValidateLink>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerLinkageControllerValidateLinkMutationKey();
  const swrFn = getCustomerLinkageControllerValidateLinkMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Links company to billing account
 */
export const customerLinkageControllerLink = (linkInDto: LinkInDto, params?: CustomerLinkageControllerLinkParams) => {
  return customInstance<CustomerLinkageControllerLink200>({
    url: `/customer-linkage/link`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: linkInDto,
    params,
  });
};

export const getCustomerLinkageControllerLinkMutationFetcher = (params?: CustomerLinkageControllerLinkParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerLinkageControllerLink200> => {
    return customerLinkageControllerLink(arg as LinkInDto, params);
  };
};
export const getCustomerLinkageControllerLinkMutationKey = () => `/customer-linkage/link` as const;

export type CustomerLinkageControllerLinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerLinkageControllerLink>>
>;
export type CustomerLinkageControllerLinkMutationError = FailResponseDto;

/**
 * @summary Links company to billing account
 */
export const useCustomerLinkageControllerLink = <TError = FailResponseDto>(
  params?: CustomerLinkageControllerLinkParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerLinkageControllerLink>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerLinkageControllerLink>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerLinkageControllerLinkMutationKey();
  const swrFn = getCustomerLinkageControllerLinkMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Validates if it is possible to unlink a company from a billing account
 */
export const customerLinkageControllerValidateUnlink = (
  linkInDto: LinkInDto,
  params?: CustomerLinkageControllerValidateUnlinkParams,
) => {
  return customInstance<CustomerLinkageControllerValidateUnlink200>({
    url: `/customer-linkage/validate/unlink`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: linkInDto,
    params,
  });
};

export const getCustomerLinkageControllerValidateUnlinkMutationFetcher = (
  params?: CustomerLinkageControllerValidateUnlinkParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerLinkageControllerValidateUnlink200> => {
    return customerLinkageControllerValidateUnlink(arg as LinkInDto, params);
  };
};
export const getCustomerLinkageControllerValidateUnlinkMutationKey = () => `/customer-linkage/validate/unlink` as const;

export type CustomerLinkageControllerValidateUnlinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerLinkageControllerValidateUnlink>>
>;
export type CustomerLinkageControllerValidateUnlinkMutationError = FailResponseDto;

/**
 * @summary Validates if it is possible to unlink a company from a billing account
 */
export const useCustomerLinkageControllerValidateUnlink = <TError = FailResponseDto>(
  params?: CustomerLinkageControllerValidateUnlinkParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerLinkageControllerValidateUnlink>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerLinkageControllerValidateUnlink>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerLinkageControllerValidateUnlinkMutationKey();
  const swrFn = getCustomerLinkageControllerValidateUnlinkMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Unlinks company from billing account
 */
export const customerLinkageControllerUnlink = (
  unlinkInDto: UnlinkInDto,
  params?: CustomerLinkageControllerUnlinkParams,
) => {
  return customInstance<CustomerLinkageControllerUnlink200>({
    url: `/customer-linkage/unlink`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: unlinkInDto,
    params,
  });
};

export const getCustomerLinkageControllerUnlinkMutationFetcher = (params?: CustomerLinkageControllerUnlinkParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerLinkageControllerUnlink200> => {
    return customerLinkageControllerUnlink(arg as UnlinkInDto, params);
  };
};
export const getCustomerLinkageControllerUnlinkMutationKey = () => `/customer-linkage/unlink` as const;

export type CustomerLinkageControllerUnlinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerLinkageControllerUnlink>>
>;
export type CustomerLinkageControllerUnlinkMutationError = FailResponseDto;

/**
 * @summary Unlinks company from billing account
 */
export const useCustomerLinkageControllerUnlink = <TError = FailResponseDto>(
  params?: CustomerLinkageControllerUnlinkParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerLinkageControllerUnlink>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerLinkageControllerUnlink>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerLinkageControllerUnlinkMutationKey();
  const swrFn = getCustomerLinkageControllerUnlinkMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
