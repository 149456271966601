import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import styled from 'styled-components';

import { Tooltip as TooltipIcon } from 'components/Icons';
import { colors } from 'lib/theme/colors';

export const overlayInnerStyle = {
  fontSize: '12px',
  borderRadius: '4px',
  padding: '10px',
  background: colors.primaryText,
};

const StyledTooltip = styled(AntdTooltip)`
  & svg path {
    fill: ${({ theme }) => theme.colors.gray[24]};
  }
`;

interface IProps {
  placement: TooltipPlacement;
  title: string;
}

const Tooltip = ({ placement, title }: IProps) => (
  <StyledTooltip placement={placement} title={title} overlayInnerStyle={overlayInnerStyle}>
    <TooltipIcon />
  </StyledTooltip>
);

export default Tooltip;
