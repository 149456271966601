import { Badge } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.blue[8]};
  z-index: 999;
`;

export const Counter = styled(Badge)`
  & .ant-badge-count {
    padding: 0 8px;
    font-weight: 600;
    line-height: 18px;
    color: ${(props) => (!props.count ? props.theme.colors.gray[80] : props.theme.colors.primary)};
    border: ${(props) => `1px solid ${props.theme.colors.primary}1a`};
    background-color: ${({ theme }) => theme.colors.blue[8]};
  }
`;

export const Container = styled.div<{ minWidth?: number }>`
  max-width: 900px;
  ${(props) => props.minWidth && ` min-width: ${props.minWidth}px`};
  width: 100%;
`;
