/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  CreateCustomerForAccountInDto,
  CreateCustomerInDto,
  CustomerControllerCreate200,
  CustomerControllerCreateForAccount200,
  CustomerControllerCreateParams,
  CustomerControllerDelete200,
  CustomerControllerDeleteParams,
  CustomerControllerGet200,
  CustomerControllerGetBaseInfo200,
  CustomerControllerGetBaseInfoParams,
  CustomerControllerGetCustomerRuleSets200,
  CustomerControllerGetCustomerRuleSetsParams,
  CustomerControllerGetParams,
  CustomerControllerGetSettingsToExport200,
  CustomerControllerGetSettingsToExportParams,
  CustomerControllerList200,
  CustomerControllerListParams,
  CustomerControllerListV2200,
  CustomerControllerListV2Params,
  CustomerControllerSetPremiumAddon200,
  CustomerControllerSetPremiumAddonParams,
  CustomerControllerSetReviewMode200,
  CustomerControllerSetReviewModeParams,
  CustomerControllerSetWhiteLabeling200,
  CustomerControllerSetWhiteLabelingParams,
  CustomerControllerUpdate200,
  CustomerControllerUpdateParams,
  FailResponseDto,
  UpdateCustomerInDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Searches companies current user has access to (used in SelectCompany Modal)
 */
export const customerControllerList = (params: CustomerControllerListParams) => {
  return customInstance<CustomerControllerList200>({ url: `/customer`, method: 'GET', params });
};

export const getCustomerControllerListKey = (params: CustomerControllerListParams) =>
  [`/customer`, ...(params ? [params] : [])] as const;

export type CustomerControllerListQueryResult = NonNullable<Awaited<ReturnType<typeof customerControllerList>>>;
export type CustomerControllerListQueryError = FailResponseDto;

/**
 * @summary Searches companies current user has access to (used in SelectCompany Modal)
 */
export const useCustomerControllerList = <TError = FailResponseDto>(
  params: CustomerControllerListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerControllerList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerControllerListKey(params) : null));
  const swrFn = () => customerControllerList(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Creates new company
 */
export const customerControllerCreate = (
  createCustomerInDto: CreateCustomerInDto,
  params?: CustomerControllerCreateParams,
) => {
  return customInstance<CustomerControllerCreate200>({
    url: `/customer`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createCustomerInDto,
    params,
  });
};

export const getCustomerControllerCreateMutationFetcher = (params?: CustomerControllerCreateParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerCreate200> => {
    return customerControllerCreate(arg as CreateCustomerInDto, params);
  };
};
export const getCustomerControllerCreateMutationKey = () => `/customer` as const;

export type CustomerControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof customerControllerCreate>>>;
export type CustomerControllerCreateMutationError = FailResponseDto;

/**
 * @summary Creates new company
 */
export const useCustomerControllerCreate = <TError = FailResponseDto>(
  params?: CustomerControllerCreateParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerCreate>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerCreate>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerCreateMutationKey();
  const swrFn = getCustomerControllerCreateMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Searches companies (possible with settings) current user has access to (used in CompaniesList Page)
 */
export const customerControllerListV2 = (params: CustomerControllerListV2Params) => {
  return customInstance<CustomerControllerListV2200>({ url: `/customer/v2`, method: 'GET', params });
};

export const getCustomerControllerListV2Key = (params: CustomerControllerListV2Params) =>
  [`/customer/v2`, ...(params ? [params] : [])] as const;

export type CustomerControllerListV2QueryResult = NonNullable<Awaited<ReturnType<typeof customerControllerListV2>>>;
export type CustomerControllerListV2QueryError = FailResponseDto;

/**
 * @summary Searches companies (possible with settings) current user has access to (used in CompaniesList Page)
 */
export const useCustomerControllerListV2 = <TError = FailResponseDto>(
  params: CustomerControllerListV2Params,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerControllerListV2>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerControllerListV2Key(params) : null));
  const swrFn = () => customerControllerListV2(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns company info (with rule sets)
 */
export const customerControllerGet = (id: string, params?: CustomerControllerGetParams) => {
  return customInstance<CustomerControllerGet200>({ url: `/customer/${id}`, method: 'GET', params });
};

export const getCustomerControllerGetKey = (id: string, params?: CustomerControllerGetParams) =>
  [`/customer/${id}`, ...(params ? [params] : [])] as const;

export type CustomerControllerGetQueryResult = NonNullable<Awaited<ReturnType<typeof customerControllerGet>>>;
export type CustomerControllerGetQueryError = FailResponseDto;

/**
 * @summary Returns company info (with rule sets)
 */
export const useCustomerControllerGet = <TError = FailResponseDto>(
  id: string,
  params?: CustomerControllerGetParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerControllerGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerControllerGetKey(id, params) : null));
  const swrFn = () => customerControllerGet(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Updates company
 */
export const customerControllerUpdate = (
  id: string,
  updateCustomerInDto: UpdateCustomerInDto,
  params?: CustomerControllerUpdateParams,
) => {
  return customInstance<CustomerControllerUpdate200>({
    url: `/customer/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: updateCustomerInDto,
    params,
  });
};

export const getCustomerControllerUpdateMutationFetcher = (id: string, params?: CustomerControllerUpdateParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerUpdate200> => {
    return customerControllerUpdate(id, arg as UpdateCustomerInDto, params);
  };
};
export const getCustomerControllerUpdateMutationKey = (id: string) => `/customer/${id}` as const;

export type CustomerControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof customerControllerUpdate>>>;
export type CustomerControllerUpdateMutationError = FailResponseDto;

/**
 * @summary Updates company
 */
export const useCustomerControllerUpdate = <TError = FailResponseDto>(
  id: string,
  params?: CustomerControllerUpdateParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerUpdate>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerUpdate>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerUpdateMutationKey(id);
  const swrFn = getCustomerControllerUpdateMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Removes company
 */
export const customerControllerDelete = (id: string, params?: CustomerControllerDeleteParams) => {
  return customInstance<CustomerControllerDelete200>({ url: `/customer/${id}`, method: 'DELETE', params });
};

export const getCustomerControllerDeleteMutationFetcher = (id: string, params?: CustomerControllerDeleteParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerDelete200> => {
    return customerControllerDelete(id, params);
  };
};
export const getCustomerControllerDeleteMutationKey = (id: string) => `/customer/${id}` as const;

export type CustomerControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof customerControllerDelete>>>;
export type CustomerControllerDeleteMutationError = FailResponseDto;

/**
 * @summary Removes company
 */
export const useCustomerControllerDelete = <TError = FailResponseDto>(
  id: string,
  params?: CustomerControllerDeleteParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerDelete>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerDelete>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerDeleteMutationKey(id);
  const swrFn = getCustomerControllerDeleteMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Creates new company and links billing account to it
 */
export const customerControllerCreateForAccount = (
  billingAccountId: string,
  createCustomerForAccountInDto: CreateCustomerForAccountInDto,
) => {
  return customInstance<CustomerControllerCreateForAccount200>({
    url: `/accounts/${billingAccountId}/customer`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createCustomerForAccountInDto,
  });
};

export const getCustomerControllerCreateForAccountMutationFetcher = (billingAccountId: string) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerCreateForAccount200> => {
    return customerControllerCreateForAccount(billingAccountId, arg as CreateCustomerForAccountInDto);
  };
};
export const getCustomerControllerCreateForAccountMutationKey = (billingAccountId: string) =>
  `/accounts/${billingAccountId}/customer` as const;

export type CustomerControllerCreateForAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerCreateForAccount>>
>;
export type CustomerControllerCreateForAccountMutationError = FailResponseDto;

/**
 * @summary Creates new company and links billing account to it
 */
export const useCustomerControllerCreateForAccount = <TError = FailResponseDto>(
  billingAccountId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerCreateForAccount>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerCreateForAccount>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerCreateForAccountMutationKey(billingAccountId);
  const swrFn = getCustomerControllerCreateForAccountMutationFetcher(billingAccountId);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of company rule sets
 */
export const customerControllerGetCustomerRuleSets = (
  id: string,
  params?: CustomerControllerGetCustomerRuleSetsParams,
) => {
  return customInstance<CustomerControllerGetCustomerRuleSets200>({
    url: `/customer/${id}/ruleSets`,
    method: 'GET',
    params,
  });
};

export const getCustomerControllerGetCustomerRuleSetsKey = (
  id: string,
  params?: CustomerControllerGetCustomerRuleSetsParams,
) => [`/customer/${id}/ruleSets`, ...(params ? [params] : [])] as const;

export type CustomerControllerGetCustomerRuleSetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerGetCustomerRuleSets>>
>;
export type CustomerControllerGetCustomerRuleSetsQueryError = FailResponseDto;

/**
 * @summary Returns list of company rule sets
 */
export const useCustomerControllerGetCustomerRuleSets = <TError = FailResponseDto>(
  id: string,
  params?: CustomerControllerGetCustomerRuleSetsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerControllerGetCustomerRuleSets>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerControllerGetCustomerRuleSetsKey(id, params) : null));
  const swrFn = () => customerControllerGetCustomerRuleSets(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Sets default value for premium addon for newly created settings under this company
 */
export const customerControllerSetPremiumAddon = (id: string, params: CustomerControllerSetPremiumAddonParams) => {
  return customInstance<CustomerControllerSetPremiumAddon200>({
    url: `/customer/${id}/premiumAddon`,
    method: 'PUT',
    params,
  });
};

export const getCustomerControllerSetPremiumAddonMutationFetcher = (
  id: string,
  params: CustomerControllerSetPremiumAddonParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerSetPremiumAddon200> => {
    return customerControllerSetPremiumAddon(id, params);
  };
};
export const getCustomerControllerSetPremiumAddonMutationKey = (id: string) => `/customer/${id}/premiumAddon` as const;

export type CustomerControllerSetPremiumAddonMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerSetPremiumAddon>>
>;
export type CustomerControllerSetPremiumAddonMutationError = FailResponseDto;

/**
 * @summary Sets default value for premium addon for newly created settings under this company
 */
export const useCustomerControllerSetPremiumAddon = <TError = FailResponseDto>(
  id: string,
  params: CustomerControllerSetPremiumAddonParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerSetPremiumAddon>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerSetPremiumAddon>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerSetPremiumAddonMutationKey(id);
  const swrFn = getCustomerControllerSetPremiumAddonMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Sets default value for review mode for newly created settings under this company
 */
export const customerControllerSetReviewMode = (id: string, params: CustomerControllerSetReviewModeParams) => {
  return customInstance<CustomerControllerSetReviewMode200>({
    url: `/customer/${id}/reviewMode`,
    method: 'PUT',
    params,
  });
};

export const getCustomerControllerSetReviewModeMutationFetcher = (
  id: string,
  params: CustomerControllerSetReviewModeParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerSetReviewMode200> => {
    return customerControllerSetReviewMode(id, params);
  };
};
export const getCustomerControllerSetReviewModeMutationKey = (id: string) => `/customer/${id}/reviewMode` as const;

export type CustomerControllerSetReviewModeMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerSetReviewMode>>
>;
export type CustomerControllerSetReviewModeMutationError = FailResponseDto;

/**
 * @summary Sets default value for review mode for newly created settings under this company
 */
export const useCustomerControllerSetReviewMode = <TError = FailResponseDto>(
  id: string,
  params: CustomerControllerSetReviewModeParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerSetReviewMode>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerSetReviewMode>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerSetReviewModeMutationKey(id);
  const swrFn = getCustomerControllerSetReviewModeMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Sets poweredBy feature enabled on a company level. All configurations will have it against configuration level
 */
export const customerControllerSetWhiteLabeling = (id: string, params: CustomerControllerSetWhiteLabelingParams) => {
  return customInstance<CustomerControllerSetWhiteLabeling200>({
    url: `/customer/${id}/whiteLabeling`,
    method: 'PUT',
    params,
  });
};

export const getCustomerControllerSetWhiteLabelingMutationFetcher = (
  id: string,
  params: CustomerControllerSetWhiteLabelingParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerControllerSetWhiteLabeling200> => {
    return customerControllerSetWhiteLabeling(id, params);
  };
};
export const getCustomerControllerSetWhiteLabelingMutationKey = (id: string) =>
  `/customer/${id}/whiteLabeling` as const;

export type CustomerControllerSetWhiteLabelingMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerSetWhiteLabeling>>
>;
export type CustomerControllerSetWhiteLabelingMutationError = FailResponseDto;

/**
 * @summary Sets poweredBy feature enabled on a company level. All configurations will have it against configuration level
 */
export const useCustomerControllerSetWhiteLabeling = <TError = FailResponseDto>(
  id: string,
  params: CustomerControllerSetWhiteLabelingParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerControllerSetWhiteLabeling>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerControllerSetWhiteLabeling>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerControllerSetWhiteLabelingMutationKey(id);
  const swrFn = getCustomerControllerSetWhiteLabelingMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns base info for particular company
 */
export const customerControllerGetBaseInfo = (id: string, params?: CustomerControllerGetBaseInfoParams) => {
  return customInstance<CustomerControllerGetBaseInfo200>({ url: `/customer/${id}/base-info`, method: 'GET', params });
};

export const getCustomerControllerGetBaseInfoKey = (id: string, params?: CustomerControllerGetBaseInfoParams) =>
  [`/customer/${id}/base-info`, ...(params ? [params] : [])] as const;

export type CustomerControllerGetBaseInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerGetBaseInfo>>
>;
export type CustomerControllerGetBaseInfoQueryError = FailResponseDto;

/**
 * @summary Returns base info for particular company
 */
export const useCustomerControllerGetBaseInfo = <TError = FailResponseDto>(
  id: string,
  params?: CustomerControllerGetBaseInfoParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerControllerGetBaseInfo>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerControllerGetBaseInfoKey(id, params) : null));
  const swrFn = () => customerControllerGetBaseInfo(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of settings for CSV export (contains settingId and createdAt)
 */
export const customerControllerGetSettingsToExport = (
  id: string,
  params?: CustomerControllerGetSettingsToExportParams,
) => {
  return customInstance<CustomerControllerGetSettingsToExport200>({
    url: `/customer/${id}/export-settings`,
    method: 'GET',
    params,
  });
};

export const getCustomerControllerGetSettingsToExportKey = (
  id: string,
  params?: CustomerControllerGetSettingsToExportParams,
) => [`/customer/${id}/export-settings`, ...(params ? [params] : [])] as const;

export type CustomerControllerGetSettingsToExportQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerControllerGetSettingsToExport>>
>;
export type CustomerControllerGetSettingsToExportQueryError = FailResponseDto;

/**
 * @summary Returns list of settings for CSV export (contains settingId and createdAt)
 */
export const useCustomerControllerGetSettingsToExport = <TError = FailResponseDto>(
  id: string,
  params?: CustomerControllerGetSettingsToExportParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerControllerGetSettingsToExport>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerControllerGetSettingsToExportKey(id, params) : null));
  const swrFn = () => customerControllerGetSettingsToExport(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
