import Icon from '@ant-design/icons';

import { IconProps } from 'types';

type Color = 'gradient-blue' | 'gradient-gold' | string;

const getFill = (color?: Color) => {
  switch (color) {
    case 'gradient-blue':
      return 'url(#paint_blue)';
    case 'gradient-gold':
      return 'url(#paint_gold)';
    default:
      return '#303030';
  }
};

const CustomIcon = ({ color }: { color?: Color }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_670_3690)">
      <path
        d="M3.11331 12C2.87998 12 2.69998 11.7933 2.73331 11.56L3.33331 7.33333H0.999981C0.413314 7.33333 0.779981 6.83333 0.793314 6.81333C1.63331 5.32667 2.89331 3.12667 4.55998 0.193333C4.62665 0.0733333 4.75998 0 4.89331 0C5.12665 0 5.30665 0.206667 5.27331 0.44L4.67331 4.66667H7.01331C7.27998 4.66667 7.42665 4.79333 7.27998 5.10667C5.08665 8.93333 3.81331 11.1667 3.44665 11.8067C3.37998 11.9267 3.25331 12 3.11331 12Z"
        fill={getFill(color)}
      />
    </g>
    <defs>
      <linearGradient id="paint_blue" x1="11" y1="11" x2="30.0211" y2="11.0223" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
    </defs>
    <defs>
      <linearGradient
        id="paint_gold"
        x1="8.00008"
        y1="2.00055"
        x2="8.00008"
        y2="14.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD401" />
        <stop offset="1" stopColor="#F25800" />
      </linearGradient>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
