import TagManager from 'react-gtm-module';

import { TrackingEvents } from 'interfaces/enums';
import { ISurveyRequestAnswer } from 'interfaces/responses';

import { trackEvent, updateAmplitudeUserProperty } from './amplitude';

export const trackOnboardingSurveySubmitEvent = (answers: ISurveyRequestAnswer[]) => {
  TagManager.dataLayer({
    dataLayer: {
      event: TrackingEvents.ONBOARDING_SURVEY_SUBMITTED,
    },
  });
  trackEvent(TrackingEvents.ONBOARDING_SURVEY_SUBMITTED);

  answers.forEach((item) => {
    updateAmplitudeUserProperty(item.questionId, item.value);
  });
};
