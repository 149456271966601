import { Flex } from 'antd';
import styled from 'styled-components';

import { PremiumTag } from 'components/Tag';

export const Container = styled(Flex)`
  background: linear-gradient(90deg, #fff6cc 0%, #ffdfcc 100%);
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: fit-content;
`;

export const Tag = styled(PremiumTag)`
  background: white;
  & svg {
    width: 12px;
    height: 22px;
  }
`;
