import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import styled from 'styled-components';

export const StyledCardComponent = styled(CardComponent)`
  width: 100%;

  & .chargebee-input {
    padding: 10px 12px;
    border: 1px solid #cecece;
    border-radius: 4px;
    margin-top: 8px;
    width: 100%;
  }
`;
