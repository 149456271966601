import { Input } from 'antd';
import styled, { css } from 'styled-components';

interface IInputProps {
  $isButtonDisabled?: boolean;
}

const disabledStyles = css<IInputProps>`
  background: ${({ theme }) => theme.colors.gray[24]};
  cursor: not-allowed;
  &:hover {
    border: none;
  }
`;

export const StyledSearch = styled(Input.Search)<IInputProps>`
  & .ant-input-wrapper {
    margin-bottom: 20px;

    & .ant-input {
      padding: 4px;
    }
  }

  & .ant-btn {
    height: 40px;
    width: 40px;
    ${(props) => props.$isButtonDisabled && disabledStyles};
  }

  & svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

export const StyledFilterButton = styled.span`
  cursor: pointer;
`;
