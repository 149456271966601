import { Flex } from 'antd';

import Button from 'components/Button';
import { Stars, Close } from 'components/Icons';
import StatusBar from 'components/StatusBar';
import { Text } from 'components/Typography';

interface IProps {
  feature: string;
  onClose: () => void;
  onExplore: () => void;
}

const NewFeatureBar = ({ feature, onClose, onExplore }: IProps) => (
  <StatusBar type="info">
    <Flex align="center">
      <Text strong style={{ color: 'white' }}>
        <Stars />
        &nbsp; New feature
      </Text>
      &nbsp;
      {feature}
    </Flex>
    <Flex align="center" gap={20}>
      <Button ghost onClick={onExplore}>
        Explore now
        <Stars />
      </Button>
      <Close onClick={onClose} />
    </Flex>
  </StatusBar>
);

export default NewFeatureBar;
