/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import type { Arguments } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  CreatePaymentIntentInDto,
  CreatePaymentSourceByPaymentIntentInDto,
  FailResponseDto,
  PaymentControllerCreatePaymentIntent200,
  PaymentControllerCreatePaymentIntentParams,
  PaymentControllerCreatePaymentSource200,
  PaymentControllerCreatePaymentSourceParams,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Creates payment intent for particular billing account
 */
export const paymentControllerCreatePaymentIntent = (
  billingAccountId: string,
  createPaymentIntentInDto: CreatePaymentIntentInDto,
  params?: PaymentControllerCreatePaymentIntentParams,
) => {
  return customInstance<PaymentControllerCreatePaymentIntent200>({
    url: `/accounts/${billingAccountId}/payment-intent`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPaymentIntentInDto,
    params,
  });
};

export const getPaymentControllerCreatePaymentIntentMutationFetcher = (
  billingAccountId: string,
  params?: PaymentControllerCreatePaymentIntentParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<PaymentControllerCreatePaymentIntent200> => {
    return paymentControllerCreatePaymentIntent(billingAccountId, arg as CreatePaymentIntentInDto, params);
  };
};
export const getPaymentControllerCreatePaymentIntentMutationKey = (billingAccountId: string) =>
  `/accounts/${billingAccountId}/payment-intent` as const;

export type PaymentControllerCreatePaymentIntentMutationResult = NonNullable<
  Awaited<ReturnType<typeof paymentControllerCreatePaymentIntent>>
>;
export type PaymentControllerCreatePaymentIntentMutationError = FailResponseDto;

/**
 * @summary Creates payment intent for particular billing account
 */
export const usePaymentControllerCreatePaymentIntent = <TError = FailResponseDto>(
  billingAccountId: string,
  params?: PaymentControllerCreatePaymentIntentParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof paymentControllerCreatePaymentIntent>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof paymentControllerCreatePaymentIntent>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getPaymentControllerCreatePaymentIntentMutationKey(billingAccountId);
  const swrFn = getPaymentControllerCreatePaymentIntentMutationFetcher(billingAccountId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Creates payment source by payment intent for particular billing account
 */
export const paymentControllerCreatePaymentSource = (
  billingAccountId: string,
  createPaymentSourceByPaymentIntentInDto: CreatePaymentSourceByPaymentIntentInDto,
  params?: PaymentControllerCreatePaymentSourceParams,
) => {
  return customInstance<PaymentControllerCreatePaymentSource200>({
    url: `/accounts/${billingAccountId}/payment-source`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPaymentSourceByPaymentIntentInDto,
    params,
  });
};

export const getPaymentControllerCreatePaymentSourceMutationFetcher = (
  billingAccountId: string,
  params?: PaymentControllerCreatePaymentSourceParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<PaymentControllerCreatePaymentSource200> => {
    return paymentControllerCreatePaymentSource(
      billingAccountId,
      arg as CreatePaymentSourceByPaymentIntentInDto,
      params,
    );
  };
};
export const getPaymentControllerCreatePaymentSourceMutationKey = (billingAccountId: string) =>
  `/accounts/${billingAccountId}/payment-source` as const;

export type PaymentControllerCreatePaymentSourceMutationResult = NonNullable<
  Awaited<ReturnType<typeof paymentControllerCreatePaymentSource>>
>;
export type PaymentControllerCreatePaymentSourceMutationError = FailResponseDto;

/**
 * @summary Creates payment source by payment intent for particular billing account
 */
export const usePaymentControllerCreatePaymentSource = <TError = FailResponseDto>(
  billingAccountId: string,
  params?: PaymentControllerCreatePaymentSourceParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof paymentControllerCreatePaymentSource>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof paymentControllerCreatePaymentSource>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getPaymentControllerCreatePaymentSourceMutationKey(billingAccountId);
  const swrFn = getPaymentControllerCreatePaymentSourceMutationFetcher(billingAccountId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
