import { Segmented } from 'antd';
import styled from 'styled-components';

const StyledSegmented: typeof Segmented = styled(Segmented)`
  .ant-segmented-item {
    &.ant-segmented-item-selected {
      font-weight: 600;
    }
    .ant-segmented-item-label {
      padding 4px 12px;
    }
  }
`;

export default StyledSegmented;
