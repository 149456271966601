import { Collapse } from 'antd';
import styled from 'styled-components';

const StyledCollapse = styled(Collapse)`
  & .ant-collapse-item {
    & .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 20px;

      & .ant-collapse-arrow {
        display: flex;
        border: 1px solid ${(props) => props.theme.colors.gray[50]};
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }
  }
`;

export default StyledCollapse;
