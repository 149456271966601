import { Flex } from 'antd';

import DotStepper from 'components/DotStepper';
import Tag from 'components/Tag';
import { Title, Text } from 'components/Typography';

import { HeaderContainer, StyledModal, StyledTabs } from './styled';
import LayoutTab from './Tabs/Layout';
import PrivacyTriggerTab from './Tabs/PrivacyTrigger';
import StylingTab from './Tabs/Styling';
import { Breadcrumbs } from '../../types';

interface ICustomizationModalProps {
  breadcrumbs: string[];
  selectedFramework: string;
}

const CustomizationModal = ({ breadcrumbs, selectedFramework }: ICustomizationModalProps) => {
  return (
    <StyledModal
      open
      footer={null}
      width="95%"
      closable={false}
      centered
      styles={{ mask: { backdropFilter: 'blur(4px)' } }}
    >
      <Flex vertical gap={24}>
        <HeaderContainer justify="space-between" gap={8} align="start">
          <Flex vertical gap={16}>
            <DotStepper
              name="modal-breadcrumbs"
              steps={breadcrumbs}
              activeStep={breadcrumbs.indexOf(Breadcrumbs.CONSENT_CUSTOMIZATION)}
            />
            <Title $noMargin weight={700} fontSize={28}>
              Customize Consent Banner
            </Title>
          </Flex>
          <Flex gap={8} align="center">
            <Text>Legal framework:</Text>
            <Tag color="blue-inverse" $bolder size="medium" bordered>
              {selectedFramework}
            </Tag>
          </Flex>
        </HeaderContainer>
        <StyledTabs
          defaultActiveKey="1"
          type="card"
          tabBarStyle={{ paddingLeft: 32, margin: 0 }}
          items={[
            {
              label: 'Layout',
              key: 'layout',
              children: <LayoutTab />,
            },
            {
              label: 'Styling',
              key: 'styling',
              children: <StylingTab />,
            },
            {
              label: 'Privacy Trigger',
              key: 'privacyTrigger',
              children: <PrivacyTriggerTab />,
            },
          ]}
        />
      </Flex>
    </StyledModal>
  );
};

export default CustomizationModal;
