import { EUCountries } from '@usercentrics/i18n-iso3166-2';
import { Form, Flex } from 'antd';
import { useState } from 'react';

import { updateBillingAccountBillingInfo } from 'api/requests';
import Button from 'components/Button';
import { Item, Input } from 'components/Form';
import { COUNTRIES_WITH_MANDATORY_VAT_ID } from 'lib/consts';
import { isAustriaVatNumberValid, isNorwayVatNumberValid, isSwissVatNumberValid } from 'lib/helpers/validation';

enum VatValidationStatus {
  EMPTY = 'empty',
  NOT_VALIDATED = 'not_validated',
  VALIDATING = 'validating',
  VALID = 'valid',
  INVALID = 'invalid',
}

const VAT_PREFIX_MAP: Record<string, string> = {
  CH: 'CHE',
  NO: '',
};

const EU_COUNTRIES_WITHOUT_VAT = ['DE'];

const COUNTRIES_WITH_VAT = [
  ...EUCountries.filter((x) => !EU_COUNTRIES_WITHOUT_VAT.includes(x)),
  ...COUNTRIES_WITH_MANDATORY_VAT_ID,
];

interface IVatInput {
  country: string;
  accountId: string;
  initialVatNumber: string;
  onChange?: (value: string) => void;
  onIsValidChanged?: (isValid: boolean) => void;
  isReadOnly?: boolean;
  hideTooltip?: boolean;
  disableValidate?: boolean;
  address?: {
    country: string;
    zip: string;
    street: string;
    city: string;
  };
}

export const getVatPlaceholder = (country: string) => {
  switch (country) {
    case 'CH':
      return 'e.g. 123.456.789, 123.456.789TVA';
    case 'NO':
      return 'e.g. 123456789MVA';
    default:
      return 'e.g. 1234567890, U12345678';
  }
};

export const shouldShowInput = (country: string) => COUNTRIES_WITH_VAT.includes(country);

export const getPrefix = (country: string) => (country in VAT_PREFIX_MAP ? VAT_PREFIX_MAP[country] : country);

const VatInput = ({
  address,
  country,
  accountId,
  initialVatNumber = '',
  onChange = () => undefined,
  onIsValidChanged = () => undefined,
  isReadOnly,
  hideTooltip,
  disableValidate,
}: IVatInput) => {
  const [vatForm] = Form.useForm();
  const [vatValidationStatus, setVatValidationStatus] = useState(VatValidationStatus.VALID);

  const isVatNumberInputShown = shouldShowInput(country);

  if (!isVatNumberInputShown) {
    return null;
  }

  const vatPrefix = getPrefix(country);
  const isValidateButtonDisabled =
    disableValidate ||
    isReadOnly ||
    [VatValidationStatus.VALID, VatValidationStatus.VALIDATING].includes(vatValidationStatus);

  const getValidationStatus = () => {
    const vatNumber = vatForm.getFieldValue('vatNumber');

    switch (vatValidationStatus) {
      case VatValidationStatus.VALID:
        return vatNumber ? 'success' : undefined;
      case VatValidationStatus.INVALID:
        return 'error';
      case VatValidationStatus.VALIDATING:
        return 'validating';
      default:
        return undefined;
    }
  };

  const onVatIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setVatValidationStatus(VatValidationStatus.NOT_VALIDATED);
    } else {
      setVatValidationStatus(VatValidationStatus.EMPTY);
    }

    onChange(event.target.value);
  };

  const validateVat = async () => {
    setVatValidationStatus(VatValidationStatus.VALIDATING);
    const vatNumber = vatForm.getFieldValue('vatNumber');

    try {
      switch (country) {
        case 'CH':
          if (!isSwissVatNumberValid(vatNumber)) {
            throw new Error('CH VAT number invalid!');
          }
          break;
        case 'NO':
          if (!isNorwayVatNumberValid(vatNumber)) {
            throw new Error('NO VAT number invalid!');
          }
          break;
      }

      const { body: result } = await updateBillingAccountBillingInfo(accountId, { ...(address || {}), vatNumber });

      if (result.vatNumberStatus === 'undetermined') {
        switch (country) {
          case 'AT':
            if (!isAustriaVatNumberValid(vatNumber)) {
              throw new Error('AT VAT number invalid!');
            }
        }
      }

      setVatValidationStatus(VatValidationStatus.VALID);
      onIsValidChanged(true);
    } catch (e) {
      setVatValidationStatus(VatValidationStatus.INVALID);
      onIsValidChanged(false);
    }
  };

  return (
    <Form form={vatForm} initialValues={{ vatNumber: initialVatNumber }}>
      <Flex align="start" gap={15}>
        <Item
          colon={false}
          label="VAT ID"
          name="vatNumber"
          hasFeedback
          validateStatus={getValidationStatus()}
          help={vatValidationStatus === VatValidationStatus.INVALID ? 'Invalid VAT ID. Please try again.' : ''}
          tooltip={
            hideTooltip
              ? ''
              : 'The value-added tax identification number (VAT ID) is required to be able to apply the zero VAT rate for intra-community supplies of goods in the EU.'
          }
        >
          <Input
            addonBefore={vatPrefix}
            size="large"
            placeholder={getVatPlaceholder(country)}
            data-testid="input:account-vat"
            onChange={onVatIdChange}
            disabled={isReadOnly}
          />
        </Item>
        <Item colon={false} label="&nbsp;">
          <Button
            disabled={isValidateButtonDisabled}
            onClick={validateVat}
            data-testid="button:validate-vat"
            size="large"
            type="primary"
            htmlType="submit"
          >
            Validate
          </Button>
        </Item>
      </Flex>
    </Form>
  );
};

export default VatInput;
