import { CaretDownFilled } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useEffect, useState } from 'react';

import { StyledFilterButton } from './styled';

interface props {
  items?: MenuItemType[];
  defaultFilterOption?: MenuItemType;
  onChange?: (option: MenuItemType) => void;
}

export default ({ items = [], defaultFilterOption, onChange }: props) => {
  const [currentOption, setCurrentOption] = useState<MenuItemType | null | undefined>(defaultFilterOption || null);

  const handleClick = (selectedKey: string) => {
    const selectedOption = items.find(({ key }) => key === selectedKey);

    if (!selectedOption) {
      return;
    }

    if (onChange) {
      onChange(selectedOption);
    }

    setCurrentOption(selectedOption);
  };

  useEffect(() => {
    if (!defaultFilterOption && items[0]) {
      setCurrentOption(items[0]);
    }
    if (onChange) {
      onChange(defaultFilterOption || items[0]);
    }
  }, [items]);

  return (
    <Dropdown
      menu={{ items, onClick: (item) => handleClick(item?.key) }}
      placement="bottomLeft"
      align={{ offset: [-10, 15] }}
    >
      <StyledFilterButton>
        {currentOption?.label} <CaretDownFilled style={{ fontSize: '10px' }} />
      </StyledFilterButton>
    </Dropdown>
  );
};
