import { ManageSubscriptionSteps } from 'lib/contexts/ManageSubscription';
import { useManageSubscription } from 'lib/hooks';

import Payment from './Steps/Payment';
import PlanSelect from './Steps/PlanSelect';
import Success from './Steps/Success';

export const ManageSubscriptionPage = () => {
  const { step } = useManageSubscription();

  return (
    <>
      {step === ManageSubscriptionSteps.SELECT && <PlanSelect />}
      {step === ManageSubscriptionSteps.PAYMENT && <Payment />}
      {step === ManageSubscriptionSteps.SUCCESS && <Success />}
    </>
  );
};

export default ManageSubscriptionPage;
