import { Row, Flex, Col, FormInstance } from 'antd';

import ColorPicker from 'components/ColorPicker';
import { Text } from 'components/Typography';

interface ICustomizationProps {
  form: FormInstance;
}

const ButtonsColors = ({ form }: ICustomizationProps) => {
  const { setFieldValue, getFieldsValue } = form;
  const { buttonBackgroundColor, buttonTextColor } = getFieldsValue(true);

  return (
    <Row justify="space-between" gutter={[20, 20]}>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Text weight={500}>Background Color</Text>
          <ColorPicker
            name="buttonBackgroundColor"
            colorValue={buttonBackgroundColor}
            setColorValue={(value) => setFieldValue('buttonBackgroundColor', value)}
          />
        </Flex>
      </Col>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Text weight={500}>Text Color</Text>
          <ColorPicker
            name="buttonTextColor"
            colorValue={buttonTextColor}
            setColorValue={(value) => setFieldValue('buttonTextColor', value)}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default ButtonsColors;
