import { Layout } from 'antd';
import styled from 'styled-components';

import { resetButton } from 'shared-components/styled';

export const StyledFooter = styled(Layout.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;

  & button {
    cursor: pointer;
    display: inline;
    ${resetButton}
  }

  & a {
    color: ${({ theme }) => theme.colors.secondary};
  }

  & a,
  & button {
    &:hover {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;
