import styled from 'styled-components';

import StatusBar from 'components/StatusBar';

export const Container = styled(StatusBar)`
  & a {
    font-weight: 500;
    text-decoration: underline;
    margin-left: 8px;
  }
`;
