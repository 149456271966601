import useSWRMutation from 'swr/mutation';

import { api } from 'api/config/baseApi';
import ISetting from 'interfaces/ISetting';

import useSWR from '../config/swr';

export const getCompanySettings = (
  companyId: string,
  shouldFetch = true,
): {
  configurations: ISetting[];
  isLoading: boolean;
  isMutating: boolean;
  isError: boolean;
  mutate: () => void;
  trigger: (arg: { q: string; id: string }) => Promise<ISetting[]>;
} => {
  const {
    data: configurations,
    isError,
    isLoading,
    mutate,
  } = useSWR<ISetting[]>(shouldFetch && companyId ? `/setting?parentIds=${companyId}` : null, {
    keepPreviousData: true,
  });

  const {
    trigger,
    data: foundConfigurations,
    isMutating,
  } = useSWRMutation('/setting/', async (url, { arg: { q, id } }: { arg: { q: string; id: string } }) =>
    q ? (await api.get(url, { params: { q: q, parentIds: id } })).data.body : null,
  );

  return {
    mutate,
    configurations: shouldFetch || !foundConfigurations ? configurations : foundConfigurations,
    isLoading: !shouldFetch ? false : isLoading,
    isError: !shouldFetch ? false : isError,
    isMutating,
    trigger,
  };
};
