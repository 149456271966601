import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <path
      d="M1.99013 20.29L11.0301 17.06C12.4101 16.57 12.8101 14.8 11.7701 13.76L7.24013 9.23C6.20013 8.19 4.44013 8.59 3.94013 9.97L0.71013 19.01C0.43013 19.81 1.19013 20.57 1.99013 20.29Z"
      fill="url(#paint0_linear_3636_1269)"
    />
    <path
      d="M13.0602 11L18.1202 5.94C18.6102 5.45 19.4002 5.45 19.8902 5.94L19.9502 6C20.2402 6.29 20.7202 6.29 21.0102 6C21.3002 5.71 21.3002 5.23 21.0102 4.94L20.9502 4.88C19.8802 3.81 18.1302 3.81 17.0602 4.88L12.0002 9.94C11.7102 10.23 11.7102 10.71 12.0002 11C12.2902 11.29 12.7702 11.29 13.0602 11Z"
      fill="url(#paint1_linear_3636_1269)"
    />
    <path
      d="M8.06022 5.88001L8.00021 5.94001C7.71021 6.23001 7.71021 6.71001 8.00021 7.00001C8.29021 7.29001 8.77022 7.29001 9.06022 7.00001L9.12021 6.94001C10.1902 5.87001 10.1902 4.12001 9.12021 3.05001L9.07021 3.00001C8.77021 2.70001 8.29021 2.70001 8.00021 3.00001C7.71021 3.29001 7.71021 3.77001 8.00021 4.06001L8.06022 4.12001C8.54022 4.60001 8.54022 5.40001 8.06022 5.88001Z"
      fill="url(#paint2_linear_3636_1269)"
    />
    <path
      d="M15.0602 10.88L14.0002 11.94C13.7102 12.23 13.7102 12.71 14.0002 13C14.2902 13.29 14.7702 13.29 15.0602 13L16.1202 11.94C16.6102 11.45 17.4002 11.45 17.8902 11.94L18.9702 13.02C19.2602 13.31 19.7402 13.31 20.0302 13.02C20.3202 12.73 20.3202 12.25 20.0302 11.96L18.9502 10.88C17.8702 9.81001 16.1302 9.81001 15.0602 10.88Z"
      fill="url(#paint3_linear_3636_1269)"
    />
    <path
      d="M13.0602 4.88001L10.0002 7.94001C9.71021 8.23001 9.71021 8.71001 10.0002 9.00001C10.2902 9.29001 10.7702 9.29001 11.0602 9.00001L14.1202 5.94001C15.1902 4.87001 15.1902 3.12001 14.1202 2.05001L13.0602 0.990007C12.7702 0.700007 12.2902 0.700007 12.0002 0.990007C11.7102 1.28001 11.7102 1.76001 12.0002 2.05001L13.0602 3.11001C13.5402 3.60001 13.5402 4.40001 13.0602 4.88001Z"
      fill="url(#paint4_linear_3636_1269)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3636_1269"
        x1="0.650879"
        y1="8.64288"
        x2="12.3702"
        y2="8.6559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3636_1269"
        x1="11.7827"
        y1="4.0775"
        x2="21.2382"
        y2="4.0914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3636_1269"
        x1="7.78271"
        y1="2.77501"
        x2="9.92509"
        y2="2.77616"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3636_1269"
        x1="13.7827"
        y1="10.0775"
        x2="20.2549"
        y2="10.0922"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3636_1269"
        x1="9.78271"
        y1="0.772507"
        x2="14.9284"
        y2="0.775987"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
