/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  FailResponseDto,
  PermissionBillingAccountControllerDelete200,
  PermissionBillingAccountControllerDeleteParams,
  PermissionBillingAccountControllerGrant200,
  PermissionBillingAccountControllerGrantParams,
  PermissionBillingAccountControllerList200,
  PermissionBillingAccountControllerListParams,
  PermissionBillingAccountInDto,
  RevokePermissionBillingAccountInDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

export const permissionBillingAccountControllerList = (
  billingAccountId: string,
  params?: PermissionBillingAccountControllerListParams,
) => {
  return customInstance<PermissionBillingAccountControllerList200>({
    url: `/billing-accounts/${billingAccountId}/access`,
    method: 'GET',
    params,
  });
};

export const getPermissionBillingAccountControllerListKey = (
  billingAccountId: string,
  params?: PermissionBillingAccountControllerListParams,
) => [`/billing-accounts/${billingAccountId}/access`, ...(params ? [params] : [])] as const;

export type PermissionBillingAccountControllerListQueryResult = NonNullable<
  Awaited<ReturnType<typeof permissionBillingAccountControllerList>>
>;
export type PermissionBillingAccountControllerListQueryError = FailResponseDto;

export const usePermissionBillingAccountControllerList = <TError = FailResponseDto>(
  billingAccountId: string,
  params?: PermissionBillingAccountControllerListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof permissionBillingAccountControllerList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!billingAccountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getPermissionBillingAccountControllerListKey(billingAccountId, params) : null));
  const swrFn = () => permissionBillingAccountControllerList(billingAccountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
export const permissionBillingAccountControllerGrant = (
  billingAccountId: string,
  permissionBillingAccountInDto: PermissionBillingAccountInDto,
  params?: PermissionBillingAccountControllerGrantParams,
) => {
  return customInstance<PermissionBillingAccountControllerGrant200>({
    url: `/billing-accounts/${billingAccountId}/access`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: permissionBillingAccountInDto,
    params,
  });
};

export const getPermissionBillingAccountControllerGrantMutationFetcher = (
  billingAccountId: string,
  params?: PermissionBillingAccountControllerGrantParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<PermissionBillingAccountControllerGrant200> => {
    return permissionBillingAccountControllerGrant(billingAccountId, arg as PermissionBillingAccountInDto, params);
  };
};
export const getPermissionBillingAccountControllerGrantMutationKey = (billingAccountId: string) =>
  `/billing-accounts/${billingAccountId}/access` as const;

export type PermissionBillingAccountControllerGrantMutationResult = NonNullable<
  Awaited<ReturnType<typeof permissionBillingAccountControllerGrant>>
>;
export type PermissionBillingAccountControllerGrantMutationError = FailResponseDto;

export const usePermissionBillingAccountControllerGrant = <TError = FailResponseDto>(
  billingAccountId: string,
  params?: PermissionBillingAccountControllerGrantParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof permissionBillingAccountControllerGrant>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof permissionBillingAccountControllerGrant>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getPermissionBillingAccountControllerGrantMutationKey(billingAccountId);
  const swrFn = getPermissionBillingAccountControllerGrantMutationFetcher(billingAccountId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const permissionBillingAccountControllerDelete = (
  billingAccountId: string,
  revokePermissionBillingAccountInDto: RevokePermissionBillingAccountInDto,
  params?: PermissionBillingAccountControllerDeleteParams,
) => {
  return customInstance<PermissionBillingAccountControllerDelete200>({
    url: `/billing-accounts/${billingAccountId}/access`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: revokePermissionBillingAccountInDto,
    params,
  });
};

export const getPermissionBillingAccountControllerDeleteMutationFetcher = (
  billingAccountId: string,
  params?: PermissionBillingAccountControllerDeleteParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<PermissionBillingAccountControllerDelete200> => {
    return permissionBillingAccountControllerDelete(
      billingAccountId,
      arg as RevokePermissionBillingAccountInDto,
      params,
    );
  };
};
export const getPermissionBillingAccountControllerDeleteMutationKey = (billingAccountId: string) =>
  `/billing-accounts/${billingAccountId}/access` as const;

export type PermissionBillingAccountControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof permissionBillingAccountControllerDelete>>
>;
export type PermissionBillingAccountControllerDeleteMutationError = FailResponseDto;

export const usePermissionBillingAccountControllerDelete = <TError = FailResponseDto>(
  billingAccountId: string,
  params?: PermissionBillingAccountControllerDeleteParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof permissionBillingAccountControllerDelete>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof permissionBillingAccountControllerDelete>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getPermissionBillingAccountControllerDeleteMutationKey(billingAccountId);
  const swrFn = getPermissionBillingAccountControllerDeleteMutationFetcher(billingAccountId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
