import { ButtonProps } from 'antd/lib';
import { useState } from 'react';

import Button from 'components/Button';
import TermsAndConditions from 'components/TermsAndConditions';

import { ButtonsWrapper, Footer, TermsWrapper } from './styled';

interface IFooterProps {
  buttons: Array<
    {
      isShown: boolean;
      key: string;
    } & ButtonProps
  >;
  showTerms: boolean;
}

export default ({ buttons, showTerms }: IFooterProps) => {
  const [disabledNext, setDisabledNext] = useState(showTerms);

  return (
    <Footer>
      {showTerms && (
        <TermsWrapper>
          <TermsAndConditions setIsCheckboxValid={(isValid) => setDisabledNext(!isValid)} />
        </TermsWrapper>
      )}
      <ButtonsWrapper>
        {buttons.map(({ isShown, title, key, ...props }) =>
          isShown ? (
            <Button size="large" key={key} {...props} disabled={(key === 'next' && disabledNext) || props.disabled}>
              {title}
            </Button>
          ) : null,
        )}
      </ButtonsWrapper>
    </Footer>
  );
};
