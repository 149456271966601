import { postcodeValidator as validatePostcode, postcodeValidatorExistsForCountry } from 'postcode-validator';

export const emailRegexp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const vatNumberRegexp = /^(?=([A-Za-z]{2,4}))\1(?![\W_]+$)(?=.{2,12}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/i;

export const domainRegexp =
  /^(?:[A-Za-z0-9 \-_äöëïÿüßÄÖËÏŸÜÍ](?:[A-Za-z0-9 \-_äöëïÿüßÄÖËÏŸÜÍ-]{0,61}[A-Za-z0-9 \-_äöëïÿüßÄÖËÏŸÜÍ])?\.)+[A-Za-z0-9 \-_äöëïÿüßÄÖËÏŸÜÍ][A-Za-z0-9 \-_äöëïÿüßÄÖËÏŸÜÍ-]{0,61}[A-Za-z0-9 \-_äöëïÿüßÄÖËÏŸÜÍ]$/;
export const alphanumWithNumbers = /^[a-zäüö0-9ß .,&:\-()]+$/i;

const SWISS_REGEX = new RegExp(/^\d{3}(\.?)\d{3}\1\d{3}(?:MWST|[TI]VA)?$/);
const NORWAY_REGEX = new RegExp(/^\d{9}MVA$/);
const AUSTRIA_REGEX = new RegExp(/^U\d{8}$/);

const isVatNumberMatchesRegex = (regex: RegExp) => (vatNumber: string) => {
  return regex.test(vatNumber);
};

export const isZipCodeValid = (zipCode: string, country: string) => {
  return !postcodeValidatorExistsForCountry(country) || validatePostcode(zipCode, country);
};

export const isSwissVatNumberValid = isVatNumberMatchesRegex(SWISS_REGEX);
export const isNorwayVatNumberValid = isVatNumberMatchesRegex(NORWAY_REGEX);
export const isAustriaVatNumberValid = isVatNumberMatchesRegex(AUSTRIA_REGEX);
