import styled, { keyframes } from 'styled-components';

import { pr } from 'shared-components/styled';

const wave = keyframes`
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
`;

export const DotsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.9;
  overflow-x: hidden;
  transition: 0.5s;
`;

export const Wave = styled.div`
  ${pr}
  margin-top: calc(50vh - 50px);
  text-align: center;
  height: 100px;
  margin-left: auto;
  margin-right: auto;

  & p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 26px;
  }

  & .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    background: ${({ theme }) => theme.colors.white};
    animation: ${wave} 1.4s linear infinite;

    &:nth-child(1) {
      animation-delay: 0.8s;
    }

    &:nth-child(2) {
      animation-delay: 1s;
    }

    &:nth-child(3) {
      animation-delay: 1.2s;
    }
  }
`;
