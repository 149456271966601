import { Radio, Flex, Form } from 'antd';

import { CreateSettingBodyFirstLayerVariant } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';

import { BannerWrapper, Banner, StyledRadio } from './styled';

const { Item } = Form;

const firstLayerOptions = [
  {
    title: 'Privacy Wall',
    note: 'Center Placement',
    value: CreateSettingBodyFirstLayerVariant.WALL,
    key: CreateSettingBodyFirstLayerVariant.WALL,
    banner: (
      <BannerWrapper position="center">
        <Banner width="24px" height="16px" />
      </BannerWrapper>
    ),
  },
  {
    title: 'Privacy Banner',
    value: CreateSettingBodyFirstLayerVariant.BANNER,
    key: CreateSettingBodyFirstLayerVariant.BANNER,
    note: 'Bottom Placement',
    banner: (
      <BannerWrapper position="bottom">
        <Banner width="90%" height="7px" />
      </BannerWrapper>
    ),
  },
];

const FirstLayer = () => {
  return (
    <Item name="firstLayerVariant" noStyle>
      <Radio.Group>
        <Flex vertical gap={8}>
          {firstLayerOptions.map(({ banner, key, note, title, value }) => (
            <StyledRadio value={value} key={key}>
              {banner}
              <Flex vertical>
                <Text weight={500}>{title}</Text>
                <Text weight={400} size="sm" type="secondary">
                  {note}
                </Text>
              </Flex>
            </StyledRadio>
          ))}
        </Flex>
      </Radio.Group>
    </Item>
  );
};

export default FirstLayer;
