const getDaysLeft = (expirationDate: string | null) => {
  if (!expirationDate) {
    return 0;
  }

  const now = new Date().getTime();
  const expirationTime = new Date(expirationDate).getTime();
  const diffTime = expirationTime - now;
  if (diffTime <= 0) {
    return 0;
  }
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export { getDaysLeft };
