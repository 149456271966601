interface IWebIcon {
  width?: number;
  color: string;
}

export default ({ width = 20, color }: IWebIcon) => (
  <svg width={width} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 3.33337H3.33341C2.41675 3.33337 1.67508 4.08337 1.67508 5.00004L1.66675 15C1.66675 15.9167 2.41675 16.6667 3.33341 16.6667H16.6667C17.5834 16.6667 18.3334 15.9167 18.3334 15V5.00004C18.3334 4.08337 17.5834 3.33337 16.6667 3.33337ZM3.33341 7.50004H12.0834V10.4167H3.33341V7.50004ZM3.33341 12.0834H12.0834V15H4.16675C3.70841 15 3.33341 14.625 3.33341 14.1667V12.0834ZM15.8334 15H13.7501V7.50004H16.6667V14.1667C16.6667 14.625 16.2917 15 15.8334 15Z"
      fill={color}
    />
  </svg>
);
