// import { Divider, Flex } from 'antd';

// import Button from 'components/Button';
// import { Title, Text } from 'components/Typography';

const Support = () => {
  return null;
  // return (
  //   <Flex vertical>
  //     <Divider />
  //     <Title weight={500} level={4}>
  //       Need help in anything?
  //     </Title>
  //     <Flex vertical gap={16}>
  //       <Text type="secondary">Contact one of our experts if you have any questions.</Text>
  //       <Flex>
  //         <Button>Contact Expert</Button>
  //       </Flex>
  //     </Flex>
  //   </Flex>
  // );
};

export default Support;
