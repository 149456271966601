import { Flex, Typography } from 'antd';
import styled from 'styled-components';

import { ManageSearch } from 'components/Icons';

const { Title } = Typography;

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  padding: 12px;
  border-radius: 8px;
  margin-top: 80px;
  background-color: ${({ theme }) => theme.colors.gray[8]};
`;

const NotFound = () => {
  return (
    <Flex vertical align="center">
      <IconWrapper>
        <ManageSearch />
      </IconWrapper>
      <br />
      <Title level={4}>No results were found that match your search term. </Title>
      <span>Please try again with a different term.</span>
    </Flex>
  );
};

export default NotFound;
