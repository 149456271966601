import { onboardingApi } from 'api/config/onboardingApi';
import { ICreateCustomerAndSubscriptionInDto } from 'interfaces/requests';
import { CheckCustomerExistenceResponse, ICreateCustomerAndSubscriptionOutDto } from 'interfaces/responses';
import { ONBOARDING_TYPE } from 'lib/consts';
import { HttpError } from 'lib/helpers/httpError';

export const checkExistence = async (trial?: ONBOARDING_TYPE): Promise<CheckCustomerExistenceResponse> => {
  try {
    return (await onboardingApi.get(`/customer/check?onboardingType=${trial}`)).data;
  } catch (error) {
    console.error(error);
    throw new HttpError(error);
  }
};

export const createCustomerAndSubscription = async (data: ICreateCustomerAndSubscriptionInDto) => {
  try {
    return (await onboardingApi.post<ICreateCustomerAndSubscriptionOutDto>('/customer/subscription', data)).data;
  } catch (error) {
    throw error.response.data.message;
  }
};
