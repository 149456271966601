import { Flex, Radio, Form, FormInstance } from 'antd';

import { CreateSettingBodyFirstLayerVariant } from 'api/requests/generated/generated.schemas';
import bannerBottom from 'assets/banner_bottom.svg';
import bannerCenter from 'assets/banner_center.svg';
import { Text } from 'components/Typography';

import { Banner, ImageWrapper, StyledRadio } from './styled';

const { Item } = Form;

const layoutOptions = [
  {
    title: 'Privacy Wall',
    note: 'Center Placement',
    value: 'WALL',
    key: 'WALL',
    banner: <Banner position="center" />,
  },
  {
    title: 'Privacy Banner',
    value: 'BANNER',
    key: 'BANNER',
    note: 'Bottom Placement',
    banner: <Banner position="bottom" />,
  },
];

interface ICustomizationProps {
  form: FormInstance;
}

const ConsentLayout = ({ form }: ICustomizationProps) => {
  const values = Form.useWatch([], form);

  return (
    <Flex vertical justify="center" gap={24}>
      <ImageWrapper>
        {values?.firstLayerVariant === CreateSettingBodyFirstLayerVariant.WALL ? (
          <img src={bannerCenter} alt="wall" />
        ) : (
          <img src={bannerBottom} alt="banner" />
        )}
      </ImageWrapper>
      <Item name="firstLayerVariant">
        <Radio.Group style={{ width: '100%' }}>
          <Flex vertical gap={8}>
            {layoutOptions.map(({ banner, key, note, title, value }) => (
              <StyledRadio value={value} key={key}>
                {banner}
                <Flex vertical>
                  <Text weight={500}>{title}</Text>
                  <Text weight={400} size="sm" type="secondary">
                    {note}
                  </Text>
                </Flex>
              </StyledRadio>
            ))}
          </Flex>
        </Radio.Group>
      </Item>
    </Flex>
  );
};

export default ConsentLayout;
