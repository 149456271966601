import styled from 'styled-components';

import { flexBetween } from 'shared-components/styled';

export const Company = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const CompanyInfo = styled.div`
  padding: 20px;
  ${flexBetween}
`;

export const Setting = styled.div`
  padding: 15px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[24]};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }
`;

export const CompanyName = styled.span`
  font-size: 16px;
  font-weight: 500;
  display: inlline-block;
  margin-right: 5px;
  cursor: pointer;
`;
