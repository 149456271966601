const KEY = 'uc_aci_feature_flags';

export const SHOW_UPGRADE_SCREEN = 'MON-135';
export const ONBOARDING_CUSTOMIZATION_V2 = 'ACP-216';

export const getFeatureFlags = () => {
  try {
    return localStorage.getItem(KEY)?.split(',') || [];
  } catch (e) {
    return [];
  }
};

export const isFeatureFlagEnabled = (featureFlag: string) => {
  try {
    const flags = localStorage.getItem(KEY)?.split(',') || [];
    return flags.includes(featureFlag);
  } catch (e) {
    return [];
  }
};
