import {
  SubscriptionType,
  SubscriptionPricePlan,
  SubscriptionOutDto,
  SubscriptionStatus,
} from 'api/requests/generated/generated.schemas';
import { OnePricingPlanNames, OnePricingPricePlans } from 'interfaces/enums';
import { DMA_COUPON } from 'lib/consts';

export const formatDate = (date?: string) =>
  date
    ? new Date(date).toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
    : '';

// date = 'MMM YYYY' (Jun 2024)
// Can't just use new Date('MMM YYYY') because firefox and safari don't support it
export const parseUsageDate = (date: string) => {
  const [month, year] = date.split(' ');
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return new Date(Number(year), months.indexOf(month));
};

export const queueRequests = async (
  requests: Array<() => void>,
  requestsPerTime: number,
  statusCallback: (done: number) => void,
): Promise<void[]> => {
  const numberOfChunks = Math.ceil(requests.length / requestsPerTime);
  // ([1, 2, 3, 4, 5], 2) => [[1, 2], [3, 4], [5]]
  const chunks = new Array(numberOfChunks)
    .fill(null)
    .map((_, i) => requests.slice(i * requestsPerTime, i * requestsPerTime + requestsPerTime));

  let results: void[] = [];
  for await (const chunk of chunks) {
    const result = await Promise.all(chunk.map((cb) => cb()));
    results = [...results, ...result];
    statusCallback(results.length);
  }

  return results;
};

export const getPlanName = (pricePlan: string | undefined, subscriptionType: SubscriptionType | undefined) => {
  if (!pricePlan || !subscriptionType) {
    return '';
  }

  const onePricingPlanName = OnePricingPlanNames[pricePlan as SubscriptionPricePlan];

  if (onePricingPlanName) {
    return onePricingPlanName;
  }

  // OneClick subscriptions have OneClick as a pricePlan, but we need to show Advanced
  // It will be changed after we have new packages like silver, gold, etc
  return `${
    ([SubscriptionPricePlan.OneClick, SubscriptionPricePlan.Summer2024] as string[]).includes(pricePlan)
      ? SubscriptionPricePlan.Advanced
      : pricePlan
  } ${subscriptionType.toUpperCaseFirst()}`;
};

export const getPlanNameFromPackage = (fullPackageName: string) => {
  if (!fullPackageName) return '';
  const pricePlan = fullPackageName.replace(/.*(Advanced|Premium).*/, '$1');
  const type = fullPackageName.replace(/.*(App|Web).*/, '$1');
  return `${pricePlan} ${type}`;
};

export const isBetween =
  (date: Date = new Date()) =>
  (startDate: string | null, finishDate: string | null) => {
    return (startDate === null || new Date(startDate) < date) && (finishDate === null || new Date(finishDate) > date);
  };

// The given regular expression looks for non-word boundaries (\B) where there are groups of three digits ((\d{3})+) but only if those groups are not followed by another digit ((?!\d)).
// It used to find and match patterns of three digits with spaces between them, used for formatting numbers such as thousands separators. (1000 -> 1,000)
export const numberWithCommas = (x: number) => (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isDmaCoupon = (couponId?: string) => DMA_COUPON === couponId;

//? NOTE: Checks for passed parameter to ot be undefined
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export const limitNumber = (number: number, min: number, max: number) => {
  if (number < min) return min;
  if (number > max) return max;
  return number;
};

export const isTrialExpired = (subscription: SubscriptionOutDto) =>
  OnePricingPricePlans.includes(subscription.pricePlan as SubscriptionPricePlan) &&
  subscription.status === SubscriptionStatus.cancelled &&
  subscription.initialTrialFinishedAt !== null &&
  subscription.trialFinishedAt === subscription.initialTrialFinishedAt;
