import { DeleteFilled } from '@ant-design/icons';
import { Form, Tooltip, Space, Badge, Flex, Row, Col, List, Alert } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  deleteCustomer,
  setPremiumAddon,
  setReviewMode,
  useGetAccountByCustomerId,
  useGetCustomer,
  useGetMyAccounts,
  setWhiteLabeling,
} from 'api/requests';
import { Permission } from 'api/requests/generated/generated.schemas';
import { getCompanySettings } from 'api/requests/settings';
import Button from 'components/Button';
import Card from 'components/Card';
import {
  Link as LinkIcon,
  Unlink as UnlinkIcon,
  Account as AccountIcon,
  Open as OpenIcon,
  Info as InfoIcon,
} from 'components/Icons';
import PageInfo from 'components/PageInfo';
import Spin from 'components/Spin';
import ToggleSection from 'components/ToggleSection';
import { Text, Title } from 'components/Typography';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';
import { Container } from 'shared-components';

import { CompanyDetails, ConnectToChargebee, DeleteCompany, EditCompanyDetails, UnlinkCompany } from './Modals';

const getDisabledTooltip = (isRulesetsExist: boolean, containsConfigurations: boolean, isCSA: boolean) => {
  if (isRulesetsExist && !isCSA) {
    return 'You have applied rulesets for this company’s configurations. Please remove them before deleting the company.';
  }
  if (containsConfigurations && !isCSA) {
    return 'Companies containing configurations cannot be deleted. Please move them to another company before.';
  }
  return 'Delete';
};

const CompanyDetailsPage = () => {
  const { id, mutate: mutateSelectedCompany, billingCustomerId } = useSelectedCompany();
  const [companyDetailsForm] = Form.useForm();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConnectToChargebeeOpen, setIsConnectToChargebeeOpen] = useState(false);
  const [isUnlinkCompanyOpen, setIsUnlinkCompanyOpen] = useState(false);
  const { isCSA, hasAccounts } = usePrivileges();
  const navigate = useNavigate();

  const { data: companyData, mutate, isLoading } = useGetCustomer(id);
  const { data: fetchedAccount, mutate: mutateAccount } = useGetAccountByCustomerId(
    id,
    {},
    { swr: { enabled: Boolean(isCSA && id) } },
  );
  const { data: fetchedAccounts } = useGetMyAccounts({ swr: { enabled: hasAccounts } });
  const { configurations } = getCompanySettings(id);

  const accounts = fetchedAccounts?.body || [];
  const account = fetchedAccount?.body;
  const company = companyData?.body;

  const canDeleteCompany =
    isCSA || (accounts?.some(({ id: accountId }) => accountId === billingCustomerId) && !configurations?.length);

  useEffect(() => {
    if (company) {
      companyDetailsForm.setFieldsValue({ ...company, chargebeeCustomerId: account?.chargebeeCustomerId });
    }
  }, [company, account]);

  if (isLoading) {
    return <Spin />;
  }

  const isRulesetsExist = Boolean(company?.ruleSets?.length);
  const containsConfigurations = Boolean(configurations?.length);
  const isHasAccess = company?.permission !== Permission.READ && company?.permission !== Permission.READWRITE;

  const isEditButtonShown = isHasAccess || isCSA;

  const onCloseEditModal = () => {
    mutateSelectedCompany();
    mutate();
    setIsEditModalOpen(false);
  };

  const onConnectToChargebeeClose = () => {
    mutate();
    mutateAccount();
    setIsConnectToChargebeeOpen(false);
  };

  const onUnlinkCompanyClose = () => {
    mutate();
    mutateAccount();
    setIsUnlinkCompanyOpen(false);
  };

  const onOpenAccountClick = () => {
    navigate(`/accounts/${account?.id}`);
  };

  const onPremiumAddOnChanged = async (status: boolean) => {
    await setPremiumAddon(id, { enable: !status });
    mutate();
  };

  const onReviewModeChanged = async (status: boolean) => {
    await setReviewMode(id, { enable: !status });
    mutate();
  };

  const onWhitelabelingChanged = async (status: boolean) => {
    await setWhiteLabeling(id, { enable: !status });
    mutate();
  };

  return (
    <Container>
      <PageInfo title="Company Details" description="Below you will find an overview of the company." />
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card
          title="General Information"
          styles={{
            body: { paddingTop: 0 },
          }}
          extra={
            <Space>
              {isEditButtonShown && (
                <Button key="edit" onClick={() => setIsEditModalOpen(true)} data-testid="button:edit-details-open">
                  Edit
                </Button>
              )}
              {canDeleteCompany && (
                <Tooltip
                  data-testid="tooltip:delete-company"
                  title={getDisabledTooltip(isRulesetsExist, containsConfigurations, isCSA)}
                >
                  <Button
                    key="delete"
                    disabled={isCSA ? false : isRulesetsExist || containsConfigurations}
                    onClick={() => setIsDeleteModalOpen(true)}
                    data-testid="button:delete-company-open"
                    icon={<DeleteFilled />}
                  >
                    Delete Company
                  </Button>
                </Tooltip>
              )}
            </Space>
          }
        >
          <CompanyDetails
            form={companyDetailsForm}
            initialValues={{
              ...company,
              chargebeeCustomerId: isCSA && company?.billingCustomerId ? account?.chargebeeCustomerId : '',
            }}
            readonly
          />
        </Card>
        {isCSA && (
          <>
            <Card
              title="Billing Account"
              extra={
                <>
                  {!company?.billingCustomerId ? (
                    <Button
                      key="connect-to-chargebee"
                      onClick={() => setIsConnectToChargebeeOpen(true)}
                      data-testid="button:connect-to-chargebee"
                    >
                      <Flex align="center" gap={6}>
                        <LinkIcon />
                        Connect to Chargebee
                      </Flex>
                    </Button>
                  ) : (
                    <Button
                      key="connect-to-chargebee"
                      onClick={() => setIsUnlinkCompanyOpen(true)}
                      icon={<UnlinkIcon />}
                      data-testid="button:unlink-from-account"
                    >
                      Unlink from account
                    </Button>
                  )}
                </>
              }
              noResults={{
                description: 'No account connected',
                icon: <AccountIcon />,
                condition: !company?.billingCustomerId,
              }}
            >
              {account && (
                <Card styles={{ body: { padding: 16 } }} data-testid="card:billing-account-info">
                  <Row justify="space-between" align="middle">
                    <Col span={12} data-testid="billing-account">
                      <Text strong>{account.name}</Text>
                      <Flex gap={8} align="center">
                        <Text size="sm" ellipsis>
                          Connected with Chargebee
                        </Text>
                        <Badge status="default" />
                        <Text size="sm" ellipsis={{ tooltip: `Customer ID: ${account.chargebeeCustomerId}` }}>
                          Customer ID: {account.chargebeeCustomerId}
                        </Text>
                      </Flex>
                    </Col>
                    <Col>
                      <Button onClick={onOpenAccountClick} icon={<OpenIcon />} data-testid="button:open-account">
                        Open Account
                      </Button>
                    </Col>
                  </Row>
                </Card>
              )}
            </Card>
            <List size="default" header={<Title level={4}>Support</Title>} bordered>
              <List.Item>
                <ToggleSection
                  defaultStatus={Boolean(company?.reviewMode)}
                  handler={onReviewModeChanged}
                  title="Review Mode"
                  description="If enabled, Review Mode will be activated for this company and can be enabled on a configuration level."
                  dataTestId="toggle:company-review-mode"
                />
              </List.Item>

              {!company?.billingCustomerId && (
                <List.Item>
                  <ToggleSection
                    defaultStatus={Boolean(company?.hasPremiumAddon)}
                    handler={onPremiumAddOnChanged}
                    title="Premium Add-on"
                    dataTestId="toggle:premium-add-on"
                    description="If enabled, Premium Add-on features will be activated for all configurations created within
                this company."
                    warning="Once enabled please do not turn off the Premium Add-on in case the account has been shared with a customer and configurations have been created."
                  />
                </List.Item>
              )}
              <List.Item>
                <Flex vertical style={{ width: '100%' }}>
                  <ToggleSection
                    defaultStatus={Boolean(company?.hasWhiteLabeling)}
                    handler={onWhitelabelingChanged}
                    title="Whitelabeling"
                    description="If enabled, the Whitelabeling feature will be visible for all configurations belonging to this company."
                    dataTestId="toggle:company-whitelabeling"
                  />
                  <Alert
                    message={
                      <Text>
                        This setting is persisted on company level, <Text italic>not</Text> on configuration level. The
                        Super-Admin Toggle for this feature will remain switched <Text italic>off</Text> on
                        configuration level even if the feature is enabled here.
                      </Text>
                    }
                    type="info"
                    showIcon
                    icon={<InfoIcon />}
                  />
                </Flex>
              </List.Item>
            </List>
          </>
        )}
      </Space>

      <EditCompanyDetails open={isEditModalOpen} companyId={id} onClose={onCloseEditModal} />
      <DeleteCompany
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => deleteCustomer(id)}
      />
      {company && (
        <>
          <ConnectToChargebee
            customerId={company.id}
            open={isConnectToChargebeeOpen}
            companyName={company.name}
            onClose={onConnectToChargebeeClose}
          />

          <UnlinkCompany
            customerId={company.id}
            open={isUnlinkCompanyOpen}
            companyName={company.name}
            onClose={onUnlinkCompanyClose}
          />
        </>
      )}
    </Container>
  );
};

export default CompanyDetailsPage;
