/* eslint-disable @typescript-eslint/no-empty-function */
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';
import { createContext, ReactNode, useState } from 'react';

type ContextType = {
  deviceId: string;
  initialiseAmplitude: Function;
};

export const AmplitudeContext = createContext<ContextType>({
  deviceId: '',
  initialiseAmplitude: () => {},
});

const AmplitudeProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const [registeredDeviceId, setRegisteredDeviceId] = useState<string>('');
  const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

  const initialiseAmplitude = async () => {
    const params: URLSearchParams = new URL(window.location.href).searchParams;
    const deviceId = params.get('deviceId');

    if (amplitudeApiKey) {
      await amplitude
        .init(amplitudeApiKey, {
          ...(deviceId ? { deviceId } : {}),
          serverZone: 'EU',
          defaultTracking: {
            attribution: false,
            pageViews: true,
            sessions: true,
            formInteractions: false,
            fileDownloads: false,
          },
          optOut: false,
          trackingOptions: {
            ipAddress: false,
          },
        })
        .promise.finally(() => {
          setRegisteredDeviceId(amplitude.getDeviceId() || '');
        });
    }
    await initializeAmplitudeExperiment();
  };

  const initializeAmplitudeExperiment = async () => {
    const deploymentKey = process.env.REACT_APP_AMPLITUDE_DEPLOYMENT_KEY;

    if (deploymentKey) {
      const experiment = Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
        serverZone: 'eu',
        fetchOnStart: false,
      });

      window.experiment = experiment;

      await experiment.start();
    }
  };

  return (
    <AmplitudeContext.Provider value={{ deviceId: registeredDeviceId, initialiseAmplitude }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;
