import { Row, Col, Flex, Typography, Skeleton } from 'antd';
import { useMemo } from 'react';

import { useSubscriptionTiers } from 'api/requests';
import { SubscriptionDto, SubscriptionType as ISubscriptionType } from 'api/requests/generated/generated.schemas';
import { Title, Text } from 'components/Typography';
import { Currency } from 'interfaces/enums';
import { getPlanName, numberWithCommas } from 'lib/helpers';
import { useUpgradePlan } from 'lib/hooks';

import { ExceededMessage, Orange, Tier } from './styled';

const { Paragraph } = Typography;

interface IUpgradePlan {
  subscription: SubscriptionDto;
  setSelectedTier: (tier: number) => void;
  selectedTier: number;
}

const getUnits = (type: ISubscriptionType, full?: boolean) => {
  if (type === ISubscriptionType.app) {
    return full ? 'DAU (Daily Active Users)' : 'DAU';
  }
  return 'Sessions';
};

const Tiers = ({ subscription, selectedTier, setSelectedTier }: IUpgradePlan) => {
  const { exceeded } = useUpgradePlan();
  const { data: response, isLoading } = useSubscriptionTiers(subscription.id);
  const units = getUnits(subscription.type);
  const data = response?.body;

  if (!subscription) return null;

  const limitedTiers = useMemo(() => {
    if (!data?.tiers) return [];
    const index = data?.tiers?.findIndex((tier) => (tier.maxValue || tier.minValue) === exceeded?.recommendedTier);
    return data?.tiers?.slice(index, index + 3);
  }, [exceeded?.recommendedTier, data]);

  return (
    <Flex justify="space-between">
      <Row gutter={[32, 0]}>
        <Col span={12}>
          <ExceededMessage>
            <Text size="sm">{getPlanName(subscription?.pricePlan, subscription?.type)}</Text>
            <Title level={3} style={{ margin: '4px 0 8px' }}>
              {getUnits(subscription.type, true)} limit exceeded
            </Title>
            <Paragraph>
              Your {units} limit of{' '}
              <Orange>
                {numberWithCommas(subscription.maxValue || subscription.quantity)} {units} per month
              </Orange>{' '}
              was exceeded for {exceeded?.amountOfMonths} consecutive months.
            </Paragraph>
            <Paragraph>
              Your average {units} usage was{' '}
              <Text strong>{numberWithCommas(exceeded?.averageUsage || 0)} sessions per month.</Text>
            </Paragraph>
          </ExceededMessage>
        </Col>
        <Col span={12}>
          <Title level={4} weight={500}>
            Select your upgrade option
          </Title>
          {isLoading ? (
            <Flex vertical gap={8}>
              <Skeleton.Button active style={{ width: '100%', height: '77px' }} />
              <Skeleton.Button active style={{ width: '100%', height: '77px' }} />
              <Skeleton.Button active style={{ width: '100%', height: '77px' }} />
            </Flex>
          ) : (
            limitedTiers.map((tier) => (
              <Tier
                key={tier.maxValue || tier.minValue}
                isSelected={selectedTier === (tier.maxValue || tier.minValue)}
                onClick={() => setSelectedTier(tier.maxValue)}
              >
                <Flex justify="space-between" align="center">
                  <Flex vertical justify="center">
                    <Text size="sm">{units} limit</Text>
                    <Title $noMargin level={5}>
                      {tier.maxValue
                        ? `Up to ${numberWithCommas(tier.maxValue)}`
                        : `${numberWithCommas(tier.minValue)} - & above`}
                    </Title>
                  </Flex>
                  <Flex vertical justify="center">
                    <Title $noMargin level={5}>
                      {Currency[subscription.currencyCode as keyof typeof Currency]}
                      {tier.monthlyPrice}
                    </Title>
                    <Text size="sm">per month</Text>
                  </Flex>
                </Flex>
              </Tier>
            ))
          )}
        </Col>
      </Row>
    </Flex>
  );
};

export default Tiers;
