/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  AccountControllerGetAccountByCustomerId200,
  AccountControllerGetAccountByCustomerIdParams,
  AccountControllerGetAccountDetails200,
  AccountControllerGetMyAccounts200,
  AccountControllerGetTrials200,
  AccountControllerGetTrialsParams,
  AccountControllerSearchAccounts200,
  AccountControllerSearchAccountsParams,
  AccountControllerUpdateAccountVat200,
  AccountControllerUpdateBillingAccountBillingInfo200,
  AccountControllerUpgradeSubscription200,
  AccountControllerUpgradeSubscriptionNew200,
  AccountControllerUpgradeSubscriptionNewParams,
  AccountControllerUpgradeSubscriptionParams,
  AccountInDto,
  FailResponseDto,
  SetBillingInfoInDto,
  UpgradeSubscriptionInDto,
  UpgradeSubscriptionNewInDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns billing account to which the company is linked
 */
export const accountControllerGetAccountByCustomerId = (
  customerId: string,
  params?: AccountControllerGetAccountByCustomerIdParams,
) => {
  return customInstance<AccountControllerGetAccountByCustomerId200>({
    url: `/customer/${customerId}/account`,
    method: 'GET',
    params,
  });
};

export const getAccountControllerGetAccountByCustomerIdKey = (
  customerId: string,
  params?: AccountControllerGetAccountByCustomerIdParams,
) => [`/customer/${customerId}/account`, ...(params ? [params] : [])] as const;

export type AccountControllerGetAccountByCustomerIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerGetAccountByCustomerId>>
>;
export type AccountControllerGetAccountByCustomerIdQueryError = FailResponseDto;

/**
 * @summary Returns billing account to which the company is linked
 */
export const useAccountControllerGetAccountByCustomerId = <TError = FailResponseDto>(
  customerId: string,
  params?: AccountControllerGetAccountByCustomerIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof accountControllerGetAccountByCustomerId>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!customerId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getAccountControllerGetAccountByCustomerIdKey(customerId, params) : null));
  const swrFn = () => accountControllerGetAccountByCustomerId(customerId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of billing accounts and related entities to which logged-in user has access
 */
export const accountControllerGetMyAccounts = () => {
  return customInstance<AccountControllerGetMyAccounts200>({ url: `/accounts/my`, method: 'GET' });
};

export const getAccountControllerGetMyAccountsKey = () => [`/accounts/my`] as const;

export type AccountControllerGetMyAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerGetMyAccounts>>
>;
export type AccountControllerGetMyAccountsQueryError = FailResponseDto;

/**
 * @summary Returns list of billing accounts and related entities to which logged-in user has access
 */
export const useAccountControllerGetMyAccounts = <TError = FailResponseDto>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof accountControllerGetMyAccounts>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
}) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getAccountControllerGetMyAccountsKey() : null));
  const swrFn = () => accountControllerGetMyAccounts();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns info about billing account and related entities by id
 */
export const accountControllerGetAccountDetails = (billingCustomerId: string) => {
  return customInstance<AccountControllerGetAccountDetails200>({
    url: `/accounts/${billingCustomerId}/details`,
    method: 'GET',
  });
};

export const getAccountControllerGetAccountDetailsKey = (billingCustomerId: string) =>
  [`/accounts/${billingCustomerId}/details`] as const;

export type AccountControllerGetAccountDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerGetAccountDetails>>
>;
export type AccountControllerGetAccountDetailsQueryError = FailResponseDto;

/**
 * @summary Returns info about billing account and related entities by id
 */
export const useAccountControllerGetAccountDetails = <TError = FailResponseDto>(
  billingCustomerId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof accountControllerGetAccountDetails>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!billingCustomerId;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getAccountControllerGetAccountDetailsKey(billingCustomerId) : null));
  const swrFn = () => accountControllerGetAccountDetails(billingCustomerId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @deprecated
 * @summary Sets VAT ID for billing account
 */
export const accountControllerUpdateAccountVat = (billingCustomerId: string, accountInDto: AccountInDto) => {
  return customInstance<AccountControllerUpdateAccountVat200>({
    url: `/account/${billingCustomerId}/vat`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: accountInDto,
  });
};

export const getAccountControllerUpdateAccountVatMutationFetcher = (billingCustomerId: string) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AccountControllerUpdateAccountVat200> => {
    return accountControllerUpdateAccountVat(billingCustomerId, arg as AccountInDto);
  };
};
export const getAccountControllerUpdateAccountVatMutationKey = (billingCustomerId: string) =>
  `/account/${billingCustomerId}/vat` as const;

export type AccountControllerUpdateAccountVatMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerUpdateAccountVat>>
>;
export type AccountControllerUpdateAccountVatMutationError = FailResponseDto;

/**
 * @deprecated
 * @summary Sets VAT ID for billing account
 */
export const useAccountControllerUpdateAccountVat = <TError = FailResponseDto>(
  billingCustomerId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof accountControllerUpdateAccountVat>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof accountControllerUpdateAccountVat>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAccountControllerUpdateAccountVatMutationKey(billingCustomerId);
  const swrFn = getAccountControllerUpdateAccountVatMutationFetcher(billingCustomerId);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Sets billing info for billing account
 */
export const accountControllerUpdateBillingAccountBillingInfo = (
  billingAccountId: string,
  setBillingInfoInDto: SetBillingInfoInDto,
) => {
  return customInstance<AccountControllerUpdateBillingAccountBillingInfo200>({
    url: `/account/${billingAccountId}/billing-info`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: setBillingInfoInDto,
  });
};

export const getAccountControllerUpdateBillingAccountBillingInfoMutationFetcher = (billingAccountId: string) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AccountControllerUpdateBillingAccountBillingInfo200> => {
    return accountControllerUpdateBillingAccountBillingInfo(billingAccountId, arg as SetBillingInfoInDto);
  };
};
export const getAccountControllerUpdateBillingAccountBillingInfoMutationKey = (billingAccountId: string) =>
  `/account/${billingAccountId}/billing-info` as const;

export type AccountControllerUpdateBillingAccountBillingInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerUpdateBillingAccountBillingInfo>>
>;
export type AccountControllerUpdateBillingAccountBillingInfoMutationError = FailResponseDto;

/**
 * @summary Sets billing info for billing account
 */
export const useAccountControllerUpdateBillingAccountBillingInfo = <TError = FailResponseDto>(
  billingAccountId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof accountControllerUpdateBillingAccountBillingInfo>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof accountControllerUpdateBillingAccountBillingInfo>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAccountControllerUpdateBillingAccountBillingInfoMutationKey(billingAccountId);
  const swrFn = getAccountControllerUpdateBillingAccountBillingInfoMutationFetcher(billingAccountId);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Search for billing accounts by query term (e-mail or billing customer name)
 */
export const accountControllerSearchAccounts = (params: AccountControllerSearchAccountsParams) => {
  return customInstance<AccountControllerSearchAccounts200>({ url: `/accounts`, method: 'GET', params });
};

export const getAccountControllerSearchAccountsKey = (params: AccountControllerSearchAccountsParams) =>
  [`/accounts`, ...(params ? [params] : [])] as const;

export type AccountControllerSearchAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerSearchAccounts>>
>;
export type AccountControllerSearchAccountsQueryError = FailResponseDto;

/**
 * @summary Search for billing accounts by query term (e-mail or billing customer name)
 */
export const useAccountControllerSearchAccounts = <TError = FailResponseDto>(
  params: AccountControllerSearchAccountsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof accountControllerSearchAccounts>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getAccountControllerSearchAccountsKey(params) : null));
  const swrFn = () => accountControllerSearchAccounts(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns info about trial period and grace period for company based on the linked billing account
 */
export const accountControllerGetTrials = (params: AccountControllerGetTrialsParams) => {
  return customInstance<AccountControllerGetTrials200>({ url: `/account/trials`, method: 'GET', params });
};

export const getAccountControllerGetTrialsKey = (params: AccountControllerGetTrialsParams) =>
  [`/account/trials`, ...(params ? [params] : [])] as const;

export type AccountControllerGetTrialsQueryResult = NonNullable<Awaited<ReturnType<typeof accountControllerGetTrials>>>;
export type AccountControllerGetTrialsQueryError = FailResponseDto;

/**
 * @summary Returns info about trial period and grace period for company based on the linked billing account
 */
export const useAccountControllerGetTrials = <TError = FailResponseDto>(
  params: AccountControllerGetTrialsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof accountControllerGetTrials>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getAccountControllerGetTrialsKey(params) : null));
  const swrFn = () => accountControllerGetTrials(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Upgrades subscription up to higher tier
 */
export const accountControllerUpgradeSubscription = (
  subscriptionId: string,
  upgradeSubscriptionInDto: UpgradeSubscriptionInDto,
  params?: AccountControllerUpgradeSubscriptionParams,
) => {
  return customInstance<AccountControllerUpgradeSubscription200>({
    url: `/subscriptions/${subscriptionId}/upgrade`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: upgradeSubscriptionInDto,
    params,
  });
};

export const getAccountControllerUpgradeSubscriptionMutationFetcher = (
  subscriptionId: string,
  params?: AccountControllerUpgradeSubscriptionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AccountControllerUpgradeSubscription200> => {
    return accountControllerUpgradeSubscription(subscriptionId, arg as UpgradeSubscriptionInDto, params);
  };
};
export const getAccountControllerUpgradeSubscriptionMutationKey = (subscriptionId: string) =>
  `/subscriptions/${subscriptionId}/upgrade` as const;

export type AccountControllerUpgradeSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerUpgradeSubscription>>
>;
export type AccountControllerUpgradeSubscriptionMutationError = FailResponseDto;

/**
 * @summary Upgrades subscription up to higher tier
 */
export const useAccountControllerUpgradeSubscription = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: AccountControllerUpgradeSubscriptionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof accountControllerUpgradeSubscription>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof accountControllerUpgradeSubscription>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAccountControllerUpgradeSubscriptionMutationKey(subscriptionId);
  const swrFn = getAccountControllerUpgradeSubscriptionMutationFetcher(subscriptionId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Upgrades subscription (new)
 */
export const accountControllerUpgradeSubscriptionNew = (
  subscriptionId: string,
  upgradeSubscriptionNewInDto: UpgradeSubscriptionNewInDto,
  params?: AccountControllerUpgradeSubscriptionNewParams,
) => {
  return customInstance<AccountControllerUpgradeSubscriptionNew200>({
    url: `/subscriptions/${subscriptionId}/upgrade-new`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: upgradeSubscriptionNewInDto,
    params,
  });
};

export const getAccountControllerUpgradeSubscriptionNewMutationFetcher = (
  subscriptionId: string,
  params?: AccountControllerUpgradeSubscriptionNewParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AccountControllerUpgradeSubscriptionNew200> => {
    return accountControllerUpgradeSubscriptionNew(subscriptionId, arg as UpgradeSubscriptionNewInDto, params);
  };
};
export const getAccountControllerUpgradeSubscriptionNewMutationKey = (subscriptionId: string) =>
  `/subscriptions/${subscriptionId}/upgrade-new` as const;

export type AccountControllerUpgradeSubscriptionNewMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountControllerUpgradeSubscriptionNew>>
>;
export type AccountControllerUpgradeSubscriptionNewMutationError = FailResponseDto;

/**
 * @summary Upgrades subscription (new)
 */
export const useAccountControllerUpgradeSubscriptionNew = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: AccountControllerUpgradeSubscriptionNewParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof accountControllerUpgradeSubscriptionNew>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof accountControllerUpgradeSubscriptionNew>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAccountControllerUpgradeSubscriptionNewMutationKey(subscriptionId);
  const swrFn = getAccountControllerUpgradeSubscriptionNewMutationFetcher(subscriptionId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
