/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  AddPermissionBody,
  FailResponseDto,
  RevokePermissionInDto,
  SettingAccessControllerAddPermission200,
  SettingAccessControllerAddPermissionParams,
  SettingAccessControllerGetAllPermissions200,
  SettingAccessControllerGetAllPermissionsParams,
  SettingAccessControllerGetListOfReviewers200,
  SettingAccessControllerGetListOfReviewersParams,
  SettingAccessControllerRevokePermission200,
  SettingAccessControllerRevokePermissionParams,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns list of permission for particular setting
 */
export const settingAccessControllerGetAllPermissions = (
  id: string,
  params?: SettingAccessControllerGetAllPermissionsParams,
) => {
  return customInstance<SettingAccessControllerGetAllPermissions200>({
    url: `/setting/${id}/access`,
    method: 'GET',
    params,
  });
};

export const getSettingAccessControllerGetAllPermissionsKey = (
  id: string,
  params?: SettingAccessControllerGetAllPermissionsParams,
) => [`/setting/${id}/access`, ...(params ? [params] : [])] as const;

export type SettingAccessControllerGetAllPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingAccessControllerGetAllPermissions>>
>;
export type SettingAccessControllerGetAllPermissionsQueryError = FailResponseDto;

/**
 * @summary Returns list of permission for particular setting
 */
export const useSettingAccessControllerGetAllPermissions = <TError = FailResponseDto>(
  id: string,
  params?: SettingAccessControllerGetAllPermissionsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingAccessControllerGetAllPermissions>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSettingAccessControllerGetAllPermissionsKey(id, params) : null));
  const swrFn = () => settingAccessControllerGetAllPermissions(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Grants setting-level permission to particular user
 */
export const settingAccessControllerAddPermission = (
  id: string,
  addPermissionBody: AddPermissionBody,
  params?: SettingAccessControllerAddPermissionParams,
) => {
  return customInstance<SettingAccessControllerAddPermission200>({
    url: `/setting/${id}/access`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: addPermissionBody,
    params,
  });
};

export const getSettingAccessControllerAddPermissionMutationFetcher = (
  id: string,
  params?: SettingAccessControllerAddPermissionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingAccessControllerAddPermission200> => {
    return settingAccessControllerAddPermission(id, arg as AddPermissionBody, params);
  };
};
export const getSettingAccessControllerAddPermissionMutationKey = (id: string) => `/setting/${id}/access` as const;

export type SettingAccessControllerAddPermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingAccessControllerAddPermission>>
>;
export type SettingAccessControllerAddPermissionMutationError = FailResponseDto;

/**
 * @summary Grants setting-level permission to particular user
 */
export const useSettingAccessControllerAddPermission = <TError = FailResponseDto>(
  id: string,
  params?: SettingAccessControllerAddPermissionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingAccessControllerAddPermission>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingAccessControllerAddPermission>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingAccessControllerAddPermissionMutationKey(id);
  const swrFn = getSettingAccessControllerAddPermissionMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Revokes setting-level permission for particular user
 */
export const settingAccessControllerRevokePermission = (
  id: string,
  revokePermissionInDto: RevokePermissionInDto,
  params?: SettingAccessControllerRevokePermissionParams,
) => {
  return customInstance<SettingAccessControllerRevokePermission200>({
    url: `/setting/${id}/access`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: revokePermissionInDto,
    params,
  });
};

export const getSettingAccessControllerRevokePermissionMutationFetcher = (
  id: string,
  params?: SettingAccessControllerRevokePermissionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingAccessControllerRevokePermission200> => {
    return settingAccessControllerRevokePermission(id, arg as RevokePermissionInDto, params);
  };
};
export const getSettingAccessControllerRevokePermissionMutationKey = (id: string) => `/setting/${id}/access` as const;

export type SettingAccessControllerRevokePermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingAccessControllerRevokePermission>>
>;
export type SettingAccessControllerRevokePermissionMutationError = FailResponseDto;

/**
 * @summary Revokes setting-level permission for particular user
 */
export const useSettingAccessControllerRevokePermission = <TError = FailResponseDto>(
  id: string,
  params?: SettingAccessControllerRevokePermissionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingAccessControllerRevokePermission>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingAccessControllerRevokePermission>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingAccessControllerRevokePermissionMutationKey(id);
  const swrFn = getSettingAccessControllerRevokePermissionMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of reviewers for particular setting
 */
export const settingAccessControllerGetListOfReviewers = (
  id: string,
  params?: SettingAccessControllerGetListOfReviewersParams,
) => {
  return customInstance<SettingAccessControllerGetListOfReviewers200>({
    url: `/setting/${id}/access/reviewers`,
    method: 'GET',
    params,
  });
};

export const getSettingAccessControllerGetListOfReviewersKey = (
  id: string,
  params?: SettingAccessControllerGetListOfReviewersParams,
) => [`/setting/${id}/access/reviewers`, ...(params ? [params] : [])] as const;

export type SettingAccessControllerGetListOfReviewersQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingAccessControllerGetListOfReviewers>>
>;
export type SettingAccessControllerGetListOfReviewersQueryError = FailResponseDto;

/**
 * @summary Returns list of reviewers for particular setting
 */
export const useSettingAccessControllerGetListOfReviewers = <TError = FailResponseDto>(
  id: string,
  params?: SettingAccessControllerGetListOfReviewersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingAccessControllerGetListOfReviewers>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSettingAccessControllerGetListOfReviewersKey(id, params) : null));
  const swrFn = () => settingAccessControllerGetListOfReviewers(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
