import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Sidebar = styled(Layout.Sider)`
  padding-left: 16px;

  & .ant-layout-sider-children {
    gap: 4px;
    display: flex;
    flex-direction: column;
  }
`;

export const Item = styled(NavLink)`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active,
  &:hover {
    background: ${({ theme }) => theme.colors.blue[8]};
    border-radius: 8px;
    font-weight: 600;

    & div svg,
    & div svg g path,
    & div svg #user-management,
    #account {
      fill: ${({ theme }) => theme.colors.primary};
    }

    .ant-typography {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
