import { Badge, Flex } from 'antd';

import { Link, Text } from 'components/Typography';
import useAmplitude from 'lib/hooks/useAmplitude';

import { StyledFooter } from './styled';

const ContentFooter = () => {
  const { deviceId } = useAmplitude();

  return (
    <StyledFooter>
      <Text size="sm">© Copyright {new Date().getFullYear()} Usercentrics GmbH</Text>
      <Flex align="center" gap={10}>
        <button type="button" onClick={() => window.UC_UI.showSecondLayer()}>
          Privacy Settings
        </button>
        <Badge status="default" />
        <Link
          size="sm"
          href={`https://usercentrics.com/privacy-policy-cmp/?deviceId=${deviceId}`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </Link>
        <Badge status="default" />
        <Link
          size="sm"
          href={`https://usercentrics.com/legal-notice/?deviceId=${deviceId}`}
          target="_blank"
          rel="noreferrer"
        >
          Legal Notice
        </Link>
        <Badge status="default" />
        <Link size="sm" href={'https://usercentricsgmbh.statuspage.io/'} target="_blank" rel="noreferrer">
          System Status
        </Link>
      </Flex>
    </StyledFooter>
  );
};

export default ContentFooter;
