import { Modal } from 'antd';
import styled from 'styled-components';

import { flexCenter } from 'shared-components/styled';

interface ModalProps {
  hideFooterBorder?: boolean;
  bodyHeight?: string | number;
}

export const Container = styled(Modal)<ModalProps>`
  & .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }

  & .ant-modal-header {
    border-radius: 8px 8px 0 0;
    padding: 15px 20px;
    margin: 0;
    background: ${({ theme }) => theme.colors.primary};
  }

  & .ant-modal-close-x {
    ${flexCenter}

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  & .ant-modal-footer {
    padding: 20px;
    margin: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[16]};
    display: flex;
    align-items: center;
    border: ${(props) => (props.hideFooterBorder ? 0 : 1)};

    & button:last-child {
      margin-left: 8px;
    }
  }

  & .ant-modal-body {
    padding: ${(props) => (props.hideFooterBorder ? '14px' : 0)};
    max-height: ${(props) => props.bodyHeight || '470px'};
    overflow-y: auto;
    line-height: 18px;
  }

  & button.ant-modal-close {
    display: 'block';

    & svg path {
      fill: white;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Label = styled.span`
  margin-left: 10px;
`;

export const Content = styled.div<{ isConfirmation?: boolean }>`
  padding: ${(props) => (props.isConfirmation ? '24px' : '32px')};
  line-height: 1.43;
`;
