import { Alert, Form, Collapse, Flex } from 'antd';
import { useState, useEffect } from 'react';

import { updateAccountAccess, removeAccountAccess } from 'api/requests';
import {
  AccountOutDto,
  ListPermissionBillingAccountsOutDto,
  PermissionBillingAccountOutDtoPermission as Permission,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Item, Input } from 'components/Form';
import { Person as PersonIcon, Card as CardIcon } from 'components/Icons';
import { Modal } from 'components/Modal';
import { StyledSelect as Select } from 'components/Select/Select';
import Tag from 'components/Tag';
import { Title, Text } from 'components/Typography';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { trackEvent } from 'lib/helpers/amplitude';
import { emailRegexp } from 'lib/helpers/validation';
import { usePrivileges } from 'lib/hooks';
import { Counter } from 'shared-components';

import Menu from './Menu';
import { FormContainer, Login, PermissionRow } from './styled';

import { getLabel } from './index';

interface IProps {
  onClose: () => void;
  onUpdateList: () => void;
  account: AccountOutDto;
  accounts: ListPermissionBillingAccountsOutDto[];
  open: boolean;
}

const ManageAccessModal = ({ open, onUpdateList, onClose, account, accounts }: IProps) => {
  const { isCSA } = usePrivileges();
  const [adding, setAdding] = useState(false);
  const [form] = Form.useForm();

  const onAdd = async () => {
    try {
      setAdding(true);
      const values = await form.validateFields();
      await updateAccountAccess(account.id, values);
      trackEvent(TrackingEvents.USER_GRANTED_ACCESS_TO_BILLING_ACCOUNT, {
        [TrackingVariables.BILLING_ACCOUNT_ID]: account.id,
      });
      form.resetFields();
      onUpdateList();
      setAdding(false);
    } catch (e) {
      setAdding(false);
    }
  };

  const onUpdate = async (login: string, permission: Permission) => {
    await updateAccountAccess(account.id, { login: login, permission });
    onUpdateList();
  };

  const onRemove = async (login: string) => {
    await removeAccountAccess(account.id, { login });
    onUpdateList();
  };

  useEffect(() => {
    trackEvent(TrackingEvents.MANAGE_BILLING_ACCOUNT_ACCESS_VIEWED, {
      [TrackingVariables.BILLING_ACCOUNT_ID]: account.id,
    });
  }, []);

  return (
    <Modal open={open} onClose={onClose} title={`Manage access ${account.name}`} footer={null} width={720}>
      <div style={{ margin: '-8px' }}>
        {isCSA && (
          <Alert
            message="You are viewing this as a support role user"
            style={{ margin: '-24px -16px 16px -16px' }}
            type="info"
            showIcon
          />
        )}
        <Title level={3}>Manage Account Access</Title>
        <Text type="secondary">Add and manage users for your account.</Text>
        <FormContainer>
          <Form form={form} initialValues={{ email: '', permission: Permission.ADMIN }}>
            <Flex align="flex-start" gap={12}>
              <Item
                label="Email"
                colon={false}
                name="login"
                rules={[
                  { message: 'Email is required', required: true },
                  { pattern: emailRegexp, message: 'This should be a valid email address' },
                  {
                    validator: (_, value) => {
                      const owner = accounts.find((a) => a.permission === Permission.ADMIN);
                      if (owner?.login === value?.trim()) {
                        return Promise.reject(new Error("You cannot change owner's permissions."));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="user@company.com" />
              </Item>
              <Item label="Permission" colon={false} name="permission" required>
                <Select
                  options={[
                    { value: Permission.ADMIN, label: 'Account Admin (Write)' },
                    { value: Permission.READ, label: 'Account User (Read)' },
                  ]}
                  placeholder="Select permission"
                  size="large"
                />
              </Item>
              <Button
                type="primary"
                size="large"
                onClick={onAdd}
                loading={adding}
                style={{ marginTop: '36px', height: '40px', padding: '8px 24px' }}
              >
                Add
              </Button>
            </Flex>
          </Form>
        </FormContainer>
        <Collapse
          expandIconPosition="end"
          defaultActiveKey="1"
          items={[
            {
              extra: <Counter count={accounts.length} showZero />,
              key: '1',
              label: <Title level={4}>Account Access</Title>,
              children: accounts.map((a) => (
                <PermissionRow key={a.login} justify="space-between" align="center">
                  <Login align="center" gap={12}>
                    <PersonIcon />
                    {a.login}
                  </Login>
                  <Flex align="center" gap={20}>
                    <Tag $bold size="normal" color="default">
                      <Flex gap={8} align="center">
                        <CardIcon />
                        {getLabel(a)}
                      </Flex>
                    </Tag>
                    <Menu
                      onRemove={() => onRemove(a.login)}
                      onUpdate={(permission) => onUpdate(a.login, permission)}
                      permission={a.permission}
                      disabled={a.isOwner}
                      reasonOfDisabled="You cannot revoke owner's access"
                    />
                  </Flex>
                </PermissionRow>
              )),
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default ManageAccessModal;
