import { Typography, Flex } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Image from 'assets/placeholder_unassigned.svg';
import { Link } from 'components/Typography';
import useAmplitude from 'lib/hooks/useAmplitude';

import { Placeholder } from './styled';

const { Title, Paragraph } = Typography;

const EmptyCompaniesList = () => {
  const { deviceId } = useAmplitude();
  const isBigScreen = useMediaQuery({
    query: '(min-width: 950px)',
  });

  return (
    <Placeholder>
      <Flex wrap={isBigScreen ? 'nowrap' : 'wrap'}>
        <div>
          <Title level={2} data-testid="empty-placeholder:title">
            No company found
          </Title>
          <Paragraph style={{ whiteSpace: 'pre-wrap' }} data-testid="empty-placeholder:description">
            Unfortunately, it appears that your account has not yet been assigned to a company.
            <br />
            <br />
            If you don&apos;t yet have a subscription to Usercentrics, you will find an overview of all available plans
            on our{' '}
            <Link
              strong
              href={`https://usercentrics.com/pricing/?deviceId=${deviceId}`}
              target="_blank"
              rel="noreferrer"
            >
              website
            </Link>
            .
            <br />
            <br />
            If you think you should have access, please contact our{' '}
            <Link
              href="https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/2456256639"
              target="_blank"
              rel="noreferrer"
              strong
            >
              support
            </Link>
            .
          </Paragraph>
        </div>
        <img src={Image} alt="Placeholder" />
      </Flex>
    </Placeholder>
  );
};

export default EmptyCompaniesList;
