import { CheckCircleFilled } from '@ant-design/icons';
import { Flex } from 'antd';

import { RecommendedPricePlanBySubscriptionEnrichedOutDto } from 'api/requests/generated/generated.schemas';
import { numberWithCommas } from 'lib/helpers';
import { colors } from 'lib/theme/colors';

export const FeaturesList = ({ plan }: { plan: RecommendedPricePlanBySubscriptionEnrichedOutDto }) => {
  const sessions = plan.tier.maxValue;

  const { domains, languages, numberOfConfigs } = plan.catalogFeatures.entitlements.reduce<{
    [key in 'domains' | 'languages' | 'numberOfConfigs']: string | null;
  }>(
    (acc, curr) => {
      if (curr.featureId === 'unique-domains') {
        return { ...acc, domains: curr.value };
      }

      if (curr.featureId === 'cmp-languages-per-config') {
        return { ...acc, languages: curr.value };
      }

      if (curr.featureId === 'number-of-configs') {
        return { ...acc, numberOfConfigs: curr.value };
      }

      return acc;
    },
    { domains: null, languages: null, numberOfConfigs: null },
  );

  const domainLabel = domains === null ? 'Unlimited domains' : `${domains} domain${Number(domains) > 1 ? 's' : ''}`;
  const sessionsLabel =
    sessions === null ? 'Unlimited sessions' : `${numberWithCommas(Number(sessions))} sessions / month`;
  const numberOfConfigsLabel =
    numberOfConfigs === null || Number(numberOfConfigs) >= 1000
      ? 'Unlimited privacy regulations'
      : `${numberOfConfigs} privacy regulation${Number(numberOfConfigs) > 1 ? 's' : ''}`;
  const languagesLabel =
    languages === null
      ? 'Unlimited banner languages'
      : `${languages} banner language${Number(languages) > 1 ? 's' : ''}`;

  return (
    <Flex vertical gap={10}>
      {[domainLabel, sessionsLabel, numberOfConfigsLabel, 'Unlimited seats', 'Unlimited subpages', languagesLabel].map(
        (feature) => (
          <Flex gap={12} key={feature}>
            <CheckCircleFilled style={{ color: colors.primary, fontSize: 20 }} />
            {feature}
          </Flex>
        ),
      )}
    </Flex>
  );
};
