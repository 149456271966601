import styled from 'styled-components';

export const RowWrapper = styled.div<{ disableRow?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: ${(props) => (props.disableRow ? '.4' : '1')};
  padding: 16px 16px 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[16]};
  }

  &:last-child {
    border-radius: 8px;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
`;
