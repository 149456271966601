import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router-dom';

import { loginUser, logoutUser } from 'api/requests';
import { ONBOARDING_TYPE, TRIAL_COOKIES, USER_AUTH0_PARAMETERS } from 'lib/consts';
import { updateCSA } from 'lib/contexts/Privileges';
import { clearOrganization, getConnection, getOrganization, saveOrganization } from 'lib/helpers/auth0';

import { useLogout } from './useLogout';
import { useQueryParameters } from './useQueryParameters';
import { TrackingEvents, TrackingVariables } from '../../interfaces/enums';
import { trackEvent } from '../helpers/amplitude';

export const LOCALSTORAGE_FAILED_CNT = 'auth0_failed_silent_auth';

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { organization: queryOrganization, dma_signup: dmaSignup, trial, dma, message, code } = useQueryParameters();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setAuthorized] = useState<boolean>(false);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const logoutFromAuth0 = useLogout();
  const connection = getConnection(location.search);
  const isLogoutPage = location.pathname.includes('/logout');

  //? NOTE: Skipping auth0 part to prevent error in e2e tests (we don't need auth0 flow there)
  if (process.env.REACT_APP_E2E_TESTS === '1') {
    return { isLoading: false, isAuthorized: true };
  }

  //? NOTE: Fetch new organization parameter or take stored from localstorage
  //? We don't use useEffect() here as this parameter can only change when the App is loaded
  const organization = queryOrganization || getOrganization();
  let authOpts = {};
  if (organization) {
    authOpts = {
      organization,
      connection,
    };
    saveOrganization(organization);
  }

  const logout = useCallback(async () => {
    try {
      await logoutUser();
      updateCSA(false);
      await logoutFromAuth0();
    } finally {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.LOGOUT,
        },
      });
      trackEvent(TrackingEvents.LOGOUT);
      clearOrganization();
      localStorage.removeItem(LOCALSTORAGE_FAILED_CNT);
    }
  }, [logoutFromAuth0]);

  /**
   * Tracking events
   */
  useEffect(() => {
    const successStatus = 'success';
    const emailVerifiedMessage = 'Your email was verified. You can continue using the application.';
    if (code === successStatus) {
      if (decodeURIComponent(message) === emailVerifiedMessage) {
        TagManager.dataLayer({
          dataLayer: {
            event: TrackingEvents.EMAIL_VERIFICATION_COMPLETED,
            [TrackingVariables.TRIAL_TYPE]: Cookies.get(TRIAL_COOKIES.TRIAL_USED) || ONBOARDING_TYPE.STANDARD,
            [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
              Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
            ),
          },
        });
        trackEvent(TrackingEvents.EMAIL_VERIFICATION_COMPLETED, {
          [TrackingVariables.TRIAL_TYPE]: Cookies.get(TRIAL_COOKIES.TRIAL_USED) || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
        });
      }

      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.SIGNUP,
          [TrackingVariables.TRIAL_TYPE]: Cookies.get(TRIAL_COOKIES.TRIAL_USED) || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
        },
      });
    }
  }, [code, message]);
  /**
   * =======
   */

  useEffect(() => {
    if (
      ([dma, dmaSignup].includes('true') || trial === ONBOARDING_TYPE.DMA || trial === ONBOARDING_TYPE.STANDARD) &&
      !isLogoutPage
    ) {
      //? NOTE: Parameters are already known from the provider
      loginWithRedirect();
    }
  }, [dmaSignup]);

  const handleAuthorization = () => {
    setAuthorized(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getAccessTokenSilently({
      authorizationParams: { ...authOpts, connection },
    })
      .then((token) => loginUser({ authToken: token }))
      .then(handleAuthorization)
      .then(() => localStorage.setItem(LOCALSTORAGE_FAILED_CNT, '0'))
      .catch(async (error) => {
        updateCSA(false);
        //? NOTE: We need to ignore "Login required" error,
        //? otherwise user will always hit the error page
        // A: Fix -> https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
        const errorDescriptionParam = searchParams.get('error_description');
        if (error.error === 'login_required' && errorDescriptionParam === null) {
          return location.pathname === '/signup'
            ? loginWithRedirect({ authorizationParams: { ...authOpts, connection, screen_hint: 'signup' } })
            : loginWithRedirect({ authorizationParams: { ...authOpts, connection } });
        }
        if (error.message && error.message !== 'Login required') {
          setIsLoading(false);
          return navigate('/', { state: { error: error.message } });
        }

        if (searchParams.get('error')) {
          setIsLoading(false);
          return navigate('/', { state: { error: searchParams.get('error_description') } });
        }
        //! Important: Catch endless redirects caused by blocked third party cookies
        const failedSilentAuthCnt = parseInt(localStorage.getItem(LOCALSTORAGE_FAILED_CNT) || '0', 10);
        localStorage.setItem(LOCALSTORAGE_FAILED_CNT, `${failedSilentAuthCnt + 1}`);
        if (failedSilentAuthCnt > 3) {
          // eslint-disable-next-line no-console
          console.error('Aborting retrying silent auth (third party cookies blocked?)');
          await logout();
          return navigate('/');
        }

        if (error.code === 401) {
          await logout();
        }

        if (isLogoutPage) {
          setIsLoading(false);
          return;
        }

        return location.pathname === '/signup'
          ? loginWithRedirect({ authorizationParams: { ...authOpts, connection, screen_hint: 'signup' } })
          : loginWithRedirect({ authorizationParams: { ...authOpts, connection } });
      });
  }, [getAccessTokenSilently]);

  return { isLoading, isAuthorized };
};

export default useAuth;
