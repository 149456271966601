import { Flex, Typography } from 'antd';
import styled from 'styled-components';

export const OnboardingBlock = styled(Flex)`
  padding: 48px;
  background-color: ${({ theme }) => theme.colors.blue[8]};
  height: 100%;
`;

export const OnboardingParagraph = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.25rem;
  line-height: 1.75rem;
`;
