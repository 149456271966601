import { AxiosError } from 'axios';

export interface ApiErrorResponse {
  success: boolean;
  error?: IError;
  body: {};
}

export interface IError {
  http_code: number;
  error: string;
  msg?: string;
}

export class HttpError extends Error {
  status?: number;

  constructor(error: AxiosError<ApiErrorResponse>) {
    super(error.response?.data.error?.msg);

    this.status = error.response?.status;
  }
}
