import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/google/sidebar/billing</title>
    <defs>
      <path
        d="M20,4 L4,4 C2.89,4 2.01,4.89 2.01,6 L2,18 C2,19.11 2.89,20 4,20 L20,20 C21.11,20 22,19.11 22,18 L22,6 C22,4.89 21.11,4 20,4 Z M19,18 L5,18 C4.45,18 4,17.55 4,17 L4,12 L20,12 L20,17 C20,17.55 19.55,18 19,18 Z M20,8 L4,8 L4,6 L20,6 L20,8 Z"
        id="path-134"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon/google/sidebar/billing">
        <mask id="mask-234" fill="white">
          <use xlinkHref="#path-134"></use>
        </mask>
        <g id="icon/device/devices_24px" fillRule="nonzero"></g>
        <g id="account" mask="url(#mask-234)" fill="#303030">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
