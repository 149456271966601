import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getPrivileges } from 'api/requests';
import { OnboardingStatusEnum, USER_ORIGIN, checkExistence, getOnboardingStatus } from 'api/requests/onboarding';
import { OnboardingType } from 'interfaces/enums';
import { updateAmplitudeUserProperty } from 'lib/helpers/amplitude';
import { clearTrialCookies } from 'lib/helpers/clearTrialCookies';

import { USER_AUTH0_PARAMETERS, ONBOARDING_TYPE, TRIAL_COOKIES } from '../consts';

export const useOnboardingAllowed = (isAuthorized: boolean) => {
  const navigate = useNavigate();
  const { user = {} } = useAuth0();
  let { isAuthenticated } = useAuth0();
  const [isOnboardingLoading, setIsOnboardingLoading] = useState(true);
  const [onboardingType, setOnboardingType] = useState<OnboardingType | null>(null);

  if (process.env.REACT_APP_E2E_TESTS === '1') {
    isAuthenticated = true;
  }

  const handleRegularFlow = async () => {
    try {
      setOnboardingType(OnboardingType.REGULAR);

      const isTrialFlow =
        user[USER_AUTH0_PARAMETERS.uc_onboarding] === ONBOARDING_TYPE.DMA ||
        user[USER_AUTH0_PARAMETERS.uc_onboarding] === ONBOARDING_TYPE.STANDARD;

      const trialUsed = Cookies.get(TRIAL_COOKIES.TRIAL_USED);
      let canBeCreated = false;

      if (trialUsed || isTrialFlow) {
        canBeCreated = (await checkExistence(user[USER_AUTH0_PARAMETERS.uc_onboarding] || trialUsed)).canBeCreated;
      }

      const {
        body: { hasSettings, hasAccounts, hasCompanies },
      } = await getPrivileges();

      const regex = /^\/details(\/[^\/]+)*$/;
      const hasCompanyAccess = hasCompanies.direct > 0 || hasCompanies.indirect > 0;
      const hasAnySettings =
        hasSettings?.modern.assigned > 0 || hasSettings?.modern.unassigned > 0 || hasSettings?.legacy > 0;

      const userWantsToAccessTrial = canBeCreated && trialUsed !== undefined;
      const userNeedsToAccessTrial = !hasAnySettings && !hasAccounts && !hasCompanyAccess;
      const navigateToDetails = userWantsToAccessTrial || userNeedsToAccessTrial;

      if (navigateToDetails) {
        navigate('/details', { state: { hasAnySettings: hasAnySettings || hasCompanyAccess, canBeCreated: true } });
      } else if (regex.test(location.pathname)) {
        clearTrialCookies();
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };

  const handleOneClickFlow = async (status: OnboardingStatusEnum) => {
    setOnboardingType(OnboardingType.ONE_CLICK);

    if (status === OnboardingStatusEnum.NOT_STARTED) {
      navigate('/onboarding/google-integration');
    }
  };

  const decideOnboardingFlow = async () => {
    try {
      const onboardingStatus = await getOnboardingStatus(user.sub || '');
      updateAmplitudeUserProperty('origin', onboardingStatus?.user?.origin);

      if (onboardingStatus?.user?.origin === USER_ORIGIN.google_dma) {
        await handleOneClickFlow(onboardingStatus.status);
      } else {
        await handleRegularFlow();
      }
    } catch (error) {
      if (error.status === 404) {
        await handleRegularFlow();
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        setIsOnboardingLoading(false);
      }

      if (isAuthorized) {
        setIsOnboardingLoading(true);

        await decideOnboardingFlow();

        setIsOnboardingLoading(false);
      }

      if (isAuthenticated) {
        setIsOnboardingLoading(false);
      }
    })();
  }, [isAuthorized]);

  return { isOnboardingLoading, onboardingType };
};
