import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path
      d="M10,16 L10,8 C10,6.9 10.89,6 12,6 L21,6 L21,5 C21,3.9 20.1,3 19,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,18 L12,18 C10.89,18 10,17.1 10,16 Z M13,8 C12.45,8 12,8.45 12,9 L12,15 C12,15.55 12.45,16 13,16 L22,16 L22,8 L13,8 Z M16,13.5 C15.17,13.5 14.5,12.83 14.5,12 C14.5,11.17 15.17,10.5 16,10.5 C16.83,10.5 17.5,11.17 17.5,12 C17.5,12.83 16.83,13.5 16,13.5 Z"
      id="Shape"
      fill="#303030"
      fillRule="nonzero"
    />
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
