import { useContext } from 'react';

import { AppContext } from 'pages/App';

const useMessage = () => {
  const ctx = useContext(AppContext);

  return ctx.message;
};

export default useMessage;
