import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 34 26" fill="none">
    <g filter="url(#filter0_d_6048_7233)">
      <rect x="5" y="5.20001" width="24" height="15.6" rx="1" fill="#0045A5" />
    </g>
    <rect x="8" y="13.9969" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="9.11646" y="14.2333" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="13.0232" y="13.997" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="14.1398" y="14.2335" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="18.0468" y="13.997" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="19.1628" y="14.2335" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="23.0698" y="13.997" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="24.186" y="14.2335" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="6.95337" y="7.09149" width="5.86046" height="0.945455" fill="#D6D6D6" />
    <rect x="6.95337" y="8.98248" width="19.5349" height="0.472727" fill="#D6D6D6" />
    <rect x="6.95337" y="9.92786" width="19.5349" height="0.472727" fill="#D6D6D6" />
    <rect x="6.95337" y="10.8732" width="19.5349" height="0.472727" fill="#D6D6D6" />
    <rect x="6.95337" y="11.8188" width="19.5349" height="0.472727" fill="#D6D6D6" />
    <rect x="20.9534" y="17.9969" width="5.5814" height="1.41818" rx="0.6" fill="#ECEFFE" />
    <rect x="14.0002" y="17.9969" width="5.5814" height="1.41818" rx="0.6" fill="#ECEFFE" />
    <rect x="7.00024" y="17.9969" width="5.5814" height="1.41818" rx="0.6" fill="#ECEFFE" />
    <defs>
      <filter
        id="filter0_d_6048_7233"
        x="0.2"
        y="0.700012"
        width="33.6"
        height="25.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.3" />
        <feGaussianBlur stdDeviation="2.4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6048_7233" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6048_7233" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
