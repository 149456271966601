import styled from 'styled-components';

import { Modal } from 'components/Modal';

export const StyledModal = styled(Modal)`
  & .ant-modal-header {
    background: ${(props) => props.theme.colors.white};
    padding: 40px 40px 0 40px;
  }

  &.ant-modal-title {
    color: ${(props) => props.theme.colors.primaryText};
  }

  & .ant-modal-body {
    padding: 0;
  }

  & .ant-typography {
    margin: 0;
  }

  & .ant-modal-footer {
    padding: 0px 30px 40px 30px;

    & button {
      width: 100%;
      padding: 12px 0;
      margin: 0 auto;
    }
  }
`;

export const IconWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary}1a;
  padding: 8px;
  background: ${({ theme }) => theme.colors.gray[4]}};
`;
