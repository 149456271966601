import { Result } from 'antd';
import styled from 'styled-components';

import { pa } from 'shared-components/styled';

export const StyledResult = styled(Result)`
  margin: 0;
  ${pa}
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
`;
