import styled, { css } from 'styled-components';

import { BankAccount, Card } from 'components/Icons';

const icon = css`
  width: 16px;
`;

export const CCIcon = styled(Card)`
  ${icon}
`;
export const BAIcon = styled(BankAccount)`
  ${icon}
`;
