import { useContext } from 'react';

import { EntitlementContext } from 'lib/contexts/Entitlement';

const useEntitlement = () => {
  const {
    plan,
    entitlement,
    applyEntitlementsPerSubscription,
    getQuotaPerSubscription,
    subscription,
    storeSubscription,
    getEntitlement,
    clearSubscription,
  } = useContext(EntitlementContext);

  return {
    plan,
    entitlement,
    applyEntitlementsPerSubscription,
    getQuotaPerSubscription,
    subscription,
    storeSubscription,
    getEntitlement,
    clearSubscription,
  };
};

export default useEntitlement;
