import { useAuth0 } from '@auth0/auth0-react';
import { Button, Col, Divider, Flex, Row, Space, Switch } from 'antd';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import Dropdown from 'components/Dropdown';
import { User as UserIcon, ArrowRight } from 'components/Icons';
import Logo from 'components/Logo';
import { PermissionTag } from 'components/Tag';
import { Text } from 'components/Typography';
import { ManageSubscriptionSteps } from 'lib/contexts/ManageSubscription';
import { useLogout, usePrivileges, useSelectedCompany, useSelectedAccount, useManageSubscription } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import KnowledgeBase from './KnowledgeBase';
import {
  Header,
  UserWrapper,
  DateLabel,
  UserInfoWrapper,
  UserName,
  HeaderItem,
  Delimiter,
  DropdownItem,
} from './styled';

interface IHeaderComponent {
  isBillingHeader?: boolean;
  isMainHeader?: boolean;
  bottomChildren?: { content: React.ReactNode }[];
  topChildren?: { content: React.ReactNode }[];
  isManageSubscriptionHeader?: boolean;
}

const HeaderComponent = ({
  isBillingHeader,
  isMainHeader,
  bottomChildren,
  topChildren,
  isManageSubscriptionHeader,
}: IHeaderComponent) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const logOut = useLogout(true);
  const { isSupport, isCSA, hasAccounts, toggleCSA, isLoading } = usePrivileges();
  const selectedCompany = useSelectedCompany();
  const selectedAccount = useSelectedAccount();
  const { step, back } = useManageSubscription();
  const displaySupportAccess = isSupport && isMainHeader;
  const isCompanyHeader = (selectedCompany.id || selectedAccount.id) && isMainHeader;

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'local') {
      if (!isLoading) {
        if (user) {
          window.beamer_config = {
            ...(window.beamer_config || {}),
            user_firstname: user.given_name || '',
            user_lastname: user.family_name || '',
            user_email: user.email || '',
          };
        }
      }
    }
  }, [user, isLoading]);

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1000px)',
  });

  const userMenuItems = [
    {
      key: '1',
      label: (
        <UserInfoWrapper>
          <UserIcon />
          <Col>
            <UserName>{user?.name}</UserName>
            <p>{user?.email}</p>
            <Space direction="vertical">
              {user?.updated_at && (
                <DateLabel>Last login: {new Date(user?.updated_at).toLocaleString('en-GB')}</DateLabel>
              )}
              {isSupport && <PermissionTag>SUPPORT</PermissionTag>}
            </Space>
          </Col>
        </UserInfoWrapper>
      ),
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      label: <DropdownItem>Account & Billing</DropdownItem>,
      'data-testid': 'header:account-billing',
      onClick: () => navigate('/billing/account'),
      hidden: !hasAccounts,
    },
    {
      key: '4',
      label: <DropdownItem>Logout</DropdownItem>,
      onClick: logOut,
    },
  ];

  return (
    <Header>
      {topChildren && topChildren.map(({ content }) => content)}
      <Row
        justify="space-between"
        style={{
          padding: '0 20px',
        }}
      >
        <Flex align="center">
          <Logo width={151} />
          <Divider type="vertical" style={{ height: 30, margin: '0 16px' }} />

          <Flex align="center" gap={4}>
            {isManageSubscriptionHeader && (
              <>
                <Button ghost type="text" onClick={back}>
                  <Flex gap={8} align="center">
                    <ArrowRight
                      color={colors.primary}
                      style={{
                        transform: 'rotate(180deg)',
                      }}
                    />
                    {step === ManageSubscriptionSteps.SELECT && (
                      <Text size="sm" weight={700} style={{ color: colors.primary }}>
                        BACK
                      </Text>
                    )}
                    {step === ManageSubscriptionSteps.PAYMENT && (
                      <Text size="sm" weight={700} style={{ color: colors.primary }}>
                        BACK TO PLAN OVERVIEW
                      </Text>
                    )}
                  </Flex>
                </Button>
              </>
            )}

            {isBillingHeader && (
              <HeaderItem data-testid="link:overview" to="/companies">
                <Flex gap={8} align="center">
                  <ArrowRight
                    color={colors.primary}
                    style={{
                      transform: 'rotate(180deg)',
                    }}
                  />
                  <Text size="sm" weight={700} style={{ color: colors.primary }}>
                    BACK TO OVERVIEW
                  </Text>
                </Flex>
              </HeaderItem>
            )}

            {isMainHeader && (
              <HeaderItem data-testid="link:overview" to="/companies">
                {isCSA ? 'CUSTOMER SUPPORT ACCESS' : 'OVERVIEW'}
              </HeaderItem>
            )}

            {isCompanyHeader && (
              <>
                <Delimiter color={colors.gray[80]} />

                <Flex gap={8} align="center">
                  {selectedCompany.id && (
                    <>
                      <Text
                        size="sm"
                        weight={700}
                        style={{ color: colors.primary, maxWidth: 300 }}
                        data-testid="header:company-name"
                        ellipsis={{
                          tooltip: selectedCompany.name.toUpperCase(),
                        }}
                      >
                        {selectedCompany.name.toUpperCase()}
                      </Text>

                      {!isCSA && <PermissionTag>{selectedCompany.permission}</PermissionTag>}
                    </>
                  )}
                  {selectedAccount.id && (
                    <Text size="sm" weight={700} style={{ color: colors.primary }} data-testid="header:company-name">
                      {selectedAccount.name.toUpperCase()}
                    </Text>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex>
          {displaySupportAccess && (
            <Flex align="center" gap={14}>
              <Text size="sm">{isBigScreen ? 'Customer Support Access' : 'CSA'}</Text>
              <Switch onChange={() => toggleCSA()} defaultChecked={isCSA} data-testid="toggle:csa" />
            </Flex>
          )}

          <UserWrapper align="center" style={!displaySupportAccess ? { marginLeft: 'auto' } : {}}>
            <Divider type="vertical" style={{ height: 30, margin: '0 16px' }} />
            <KnowledgeBase />
            <Dropdown items={userMenuItems} align={{ offset: [-10, 0] }}>
              <span className="ant-dropdown-link" data-testid="header:user">
                <UserIcon />
              </span>
            </Dropdown>
          </UserWrapper>
        </Flex>
      </Row>
      {bottomChildren && bottomChildren.map(({ content }) => content)}
    </Header>
  );
};

export default HeaderComponent;
