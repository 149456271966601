import { Badge } from 'antd';
import styled from 'styled-components';

export const StyledBadge = styled(Badge)`
  background: ${(props) => props.theme.colors.primaryText};
  color: ${(props) => props.theme.colors.white};
  padding: 4px 9px;
  border-radius: 999px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.2px;
`;
