import { useContext } from 'react';

import { PrivilegesContext } from 'lib/contexts/Privileges';

const usePrivileges = () => {
  const { isCSA, isSupport, hasAccounts, hasSettings, toggleCSA, isLoading, hasCompanies } =
    useContext(PrivilegesContext);

  return { isCSA, isSupport, hasAccounts, hasSettings, toggleCSA, isLoading, hasCompanies };
};

export default usePrivileges;
