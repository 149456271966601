import { CompanySettingDto } from 'api/requests/generated/generated.schemas';

interface ISort<T> {
  (a: T, b: T): number;
}

export const sort: ISort<string> = (a, b) => {
  return a?.localeCompare(b) || 0;
};

export const sortByNameAndId: ISort<CompanySettingDto> = (a, b) => {
  const aName = a?.aliasName || '';
  const bName = b?.aliasName || '';

  if (aName === '' && bName !== '') {
    return 1;
  }

  if (bName === '' && aName !== '') {
    return -1;
  }

  const nameCmp = aName.localeCompare(bName);

  if (nameCmp !== 0) {
    return nameCmp;
  }

  const aSettingId = a.id || '';
  const bSettingId = b.id || '';

  return aSettingId.localeCompare(bSettingId);
};

export const sortByDate: ISort<string | 0> = (a, b) => new Date(a).getTime() - new Date(b).getTime();
