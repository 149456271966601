import { useState } from 'react';

import Modal from 'components/Modal/Modal';

interface IDeleteRulesetProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
}

const DeleteRuleset = ({ open, onClose, onDelete }: IDeleteRulesetProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteClick = async () => {
    setLoading(true);
    await onDelete();

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      title="Delete Ruleset?"
      primaryButton={{
        label: 'Delete',
        'data-testid': 'button:delete-ruleset',
        loading,
        onClick: onDeleteClick,
        danger: true,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
        danger: false,
      }}
      isConfirmation
      isDefaultFooter={false}
      width={470}
      onClose={onClose}
    >
      <span>
        Please confirm that you want to delete this Ruleset. Deleting the Ruleset will delete all associated rules. This
        action might break your CMP implementation, if the script is on the live website already. We recommend adapting
        the scripts accordingly.
      </span>
    </Modal>
  );
};

export default DeleteRuleset;
