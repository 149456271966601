import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { TitleProps } from 'antd/es/typography/Title';
import { LinkProps } from 'antd/lib/typography/Link';
import styled from 'styled-components';

export interface ITitleProps extends TitleProps {
  align?: 'right' | 'center' | 'left';
  $noMargin?: boolean;
  colorText?: 'white' | 'black';
  weight?: number;
  fontSize?: number;
}

export interface ITextProps extends TextProps {
  size?: 'sm' | 'normal' | 'md';
  align?: 'right' | 'center' | 'left';
  weight?: number;
}

export interface ILinkProps extends LinkProps {
  size?: 'sm' | 'lg';
  weight?: number;
  color?: 'link' | 'text';
}

export const Title = styled(Typography.Title)<ITitleProps>`
  text-align: ${(props) => props.align || 'left'};
  &&.ant-typography {
    ${(props) => `font-weight: ${props.weight ?? 500};`}
    ${(props) => props.fontSize && `font-size: ${props.fontSize}px;`}
    ${(props) => props.$noMargin && 'margin: 0'};
    ${(props) => {
      switch (props.color) {
        case 'white':
          return `color: ${props.theme.colors.white}`;
        case 'orange':
          return `color: ${props.theme.colors.orange[100]}`;
        default:
          return '';
      }
    }};
  }
`;

export const Text = styled(Typography.Text)<ITextProps>`
  text-align: ${(props) => props.align || 'left'};
  ${(props) => props.weight && `font-weight: ${props.weight};`}

  font-size: ${(props) => {
    switch (props.size) {
      case 'sm':
        return '0.75rem';
      case 'normal':
        return '0.875rem';
      case 'md':
        return '1rem';
      default:
        return '0.875rem';
    }
  }};

  &&.ant-typography {
    ${(props) => {
      switch (props.color) {
        case 'white':
          return `color: ${props.theme.colors.white}`;

        default:
          return `color: ${props.color}`;
      }
    }};
  }
`;

export const Link = styled(Typography.Link)<ILinkProps>`
  ${(props) => props.weight && `font-weight: ${props.weight};`}

  font-size: ${(props) => {
    switch (props.size) {
      case 'sm':
        return '12px';

      case 'lg':
        return '18px';

      default:
        return 'inherited';
    }
  }};

  &.ant-typography {
    color: ${(props) => (props.color === 'text' ? props.theme.colors.primaryText : 'inherited')};
  }
`;
