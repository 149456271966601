/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Key, SWRConfiguration } from 'swr';
import type { FailResponseDto, PrivilegeControllerGetPrivileges200 } from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Checks if logged-in user has ability to work in CSA mode ("SuperAdmin"). Also returns info about settings/companies/billing accounts available for current user.
 */
export const privilegeControllerGetPrivileges = () => {
  return customInstance<PrivilegeControllerGetPrivileges200>({ url: `/privileges`, method: 'GET' });
};

export const getPrivilegeControllerGetPrivilegesKey = () => [`/privileges`] as const;

export type PrivilegeControllerGetPrivilegesQueryResult = NonNullable<
  Awaited<ReturnType<typeof privilegeControllerGetPrivileges>>
>;
export type PrivilegeControllerGetPrivilegesQueryError = FailResponseDto;

/**
 * @summary Checks if logged-in user has ability to work in CSA mode ("SuperAdmin"). Also returns info about settings/companies/billing accounts available for current user.
 */
export const usePrivilegeControllerGetPrivileges = <TError = FailResponseDto>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof privilegeControllerGetPrivileges>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
}) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getPrivilegeControllerGetPrivilegesKey() : null));
  const swrFn = () => privilegeControllerGetPrivileges();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
