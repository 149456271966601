import { ResultStatusType } from 'antd/lib/result';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import LightHeaderComponent from 'components/Header/LightHeader';
import { Wrapper } from 'shared-components';

import { StyledResult } from './styled';

interface IProps {
  status: number;
  title?: string;
  extra?: React.ReactNode;
}

const GenericNotFound = ({ status, title, extra }: IProps) => {
  let cStatus: string | number = status;
  let cSubTitle = <></>;
  const cTitle = title || cStatus || 'unknown error';

  const navigate = useNavigate();

  switch (cStatus) {
    // Antd Result has only these 3 templates defined
    case 403:
      cSubTitle = <p>Sorry, you are not authorized to access this page.</p>;
      break;
    case 404:
      cSubTitle = <p>Sorry, the page you visited does not exist.</p>;
      break;
    case 500:
      cSubTitle = <p>Sorry, something went wrong.</p>;
      break;
    default:
      // for others used generic 'error' template
      cSubTitle = <p>Unknown error</p>;
      cStatus = 'error';
  }

  return (
    <Wrapper>
      <LightHeaderComponent />
      <StyledResult
        status={cStatus as ResultStatusType}
        title={title || cTitle}
        subTitle={cSubTitle}
        extra={
          extra || (
            <Button type="primary" onClick={() => navigate('/')}>
              Back Home
            </Button>
          )
        }
      />
    </Wrapper>
  );
};

export default GenericNotFound;
