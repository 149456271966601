import { Form, Typography } from 'antd';
import { useState, useEffect } from 'react';

import { useGetCustomer, updateCustomer } from 'api/requests';
import Modal from 'components/Modal/Modal';
import { useMessage } from 'lib/hooks';

import CompanyDetails from './CompanyDetails';

const { Title } = Typography;
const { useForm, useWatch } = Form;

interface IEditCompanyDetailsProps {
  onClose: () => void;
  companyId: string;
  open: boolean;
}

const EditCompanyDetails = ({ open, companyId, onClose }: IEditCompanyDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [companyDetailsForm] = useForm();
  const companyValues = useWatch([], { form: companyDetailsForm, preserve: true });

  const message = useMessage();

  const { data: companyData, mutate } = useGetCustomer(companyId);

  const company = companyData?.body;

  useEffect(() => {
    companyDetailsForm.validateFields().then(
      () => {
        setIsButtonDisabled(false);
      },
      () => {
        setIsButtonDisabled(true);
      },
    );
  }, [companyValues]);

  const onSave = async () => {
    try {
      setLoading(true);
      const data = await companyDetailsForm.validateFields();
      await updateCustomer(companyId, { customer: data });
      mutate();
      onClose();
    } catch (error) {
      if (error?.message) {
        message.error(error.response?.data.error?.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!company) {
    return null;
  }

  useEffect(() => {
    if (open) {
      companyDetailsForm.setFieldsValue(company);
    }
  }, [open, company]);

  return (
    <Modal
      open={open}
      title={`Edit Company Details [${company.name}]`}
      data-testid="modal:edit-details"
      primaryButton={{
        label: 'Save Changes',
        onClick: onSave,
        loading,
        disabled: isButtonDisabled,
        'data-testid': 'button:save-details-changes',
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
        'data-testid': 'button:cancel-details-changes',
      }}
      width={740}
      onClose={onClose}
    >
      <Title level={3}>Company Details</Title>
      <CompanyDetails form={companyDetailsForm} initialValues={company} />
    </Modal>
  );
};

export default EditCompanyDetails;
