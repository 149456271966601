import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.1998 4.99995C7.1998 4.11995 7.9198 3.39995 8.7998 3.39995H34.3998C35.2798 3.39995 35.9998 2.67995 35.9998 1.79995C35.9998 0.919951 35.2798 0.199951 34.3998 0.199951H7.1998C5.4398 0.199951 3.9998 1.63995 3.9998 3.39995V21H3.1998C1.8718 21 0.799805 22.072 0.799805 23.4C0.799805 24.728 1.8718 25.8 3.1998 25.8H23.1998V21H7.1998V4.99995ZM37.5998 6.59995H27.9998C27.1198 6.59995 26.3998 7.31995 26.3998 8.19995V24.2C26.3998 25.08 27.1198 25.8 27.9998 25.8H37.5998C38.4798 25.8 39.1998 25.08 39.1998 24.2V8.19995C39.1998 7.31995 38.4798 6.59995 37.5998 6.59995ZM35.9998 21H29.5998V9.79995H35.9998V21Z"
      fill="url(#paint0_linear_288_2130)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_288_2130"
        x1="0.799805"
        y1="0.199951"
        x2="39.2424"
        y2="0.264022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
