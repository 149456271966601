import { Col } from 'antd';

import { SubscriptionDto, SubscriptionType } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import { Currency } from 'interfaces/enums';
import { numberWithCommas } from 'lib/helpers';

import { formatDate, getSubscriptionDescription } from '../utils';

export const Sessions = ({ subscription }: { subscription: SubscriptionDto }) => (
  <>
    <Col span={11}>
      <Text data-testid={`${subscription.id}:subscription-sessions`} weight={500}>
        {subscription.type === SubscriptionType.web ? 'Sessions' : 'Daily Active Users (DAU)'}
      </Text>
    </Col>
    <Col span={13}>
      <Text>{numberWithCommas(subscription.maxValue || subscription.quantity)} / month</Text>
    </Col>
  </>
);

export const Configurations = ({ count }: { count: number }) => (
  <>
    <Col span={11}>
      <Text weight={500}>Configurations</Text>
    </Col>
    <Col span={13}>{count}</Col>
  </>
);

export const Domains = ({ domains, subscriptionId }: { domains: number | string; subscriptionId: string }) => (
  <>
    <Col span={11}>
      <Text data-testid={`${subscriptionId}:subscription-domains`} weight={500}>
        Domains
      </Text>
    </Col>
    <Col span={13}>{domains === 'unlimited' ? domains : `Incl. ${domains} domain${domains === 1 ? '' : 's'}`}</Col>
  </>
);

export const Price = ({ subscription }: { subscription: SubscriptionDto }) => (
  <>
    <Col span={11}>
      <Text weight={500}>Price</Text>
    </Col>
    <Col span={13}>
      {Currency[subscription.currencyCode as keyof typeof Currency]}
      {subscription.monthlyPrice} / month
    </Col>
  </>
);

export const SubscriptionId = ({ subscriptionId }: { subscriptionId: string }) => (
  <>
    <Col span={11}>
      <Text data-testid={`${subscriptionId}:subscription-info`} weight={500}>
        Subscription-ID
      </Text>
    </Col>
    <Col span={13}>
      <Text>{subscriptionId}</Text>
    </Col>
  </>
);

export const Status = ({ subscription }: { subscription: SubscriptionDto }) => (
  <>
    <Col span={11}>
      <Text data-testid={`${subscription.id}:subscription-status`} weight={500}>
        Status
      </Text>
    </Col>
    <Col span={13}>{getSubscriptionDescription(subscription)} </Col>
  </>
);

export const Billing = ({ subscription }: { subscription: SubscriptionDto }) =>
  subscription.nextBillingAt ? (
    <>
      <Col span={11}>
        <Text data-testid={`${subscription.id}:subscription-status`} weight={500}>
          Billing
        </Text>
      </Col>
      <Col span={13}>
        <Text>Next Billing on {formatDate(subscription.nextBillingAt)}</Text>
      </Col>
    </>
  ) : null;
