import { Flex, Modal, Tabs } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 4px;
    padding: 0;
  }
`;

export const HeaderContainer = styled(Flex)`
  padding: 24px 32px 0 32px;
`;

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-content {
    background-color: ${(props) => props.theme.colors.blue[4]};
    border-radius: 0 0 4px 4px;
    padding: 32px;
  }
`;
