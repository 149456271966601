/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  CustomerAccessControllerDeletePermission200,
  CustomerAccessControllerDeletePermissionParams,
  CustomerAccessControllerGetPermissions200,
  CustomerAccessControllerGetPermissionsParams,
  CustomerAccessControllerListCustomerPermissionsByBillingAccount200,
  CustomerAccessControllerListCustomerPermissionsByBillingAccountParams,
  CustomerAccessControllerSetPermission200,
  CustomerAccessControllerSetPermissionParams,
  DeletePermissionInDto,
  FailResponseDto,
  SetPermissionBodyDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns list of permission for particular company
 */
export const customerAccessControllerGetPermissions = (
  id: string,
  params?: CustomerAccessControllerGetPermissionsParams,
) => {
  return customInstance<CustomerAccessControllerGetPermissions200>({
    url: `/customer/${id}/access`,
    method: 'GET',
    params,
  });
};

export const getCustomerAccessControllerGetPermissionsKey = (
  id: string,
  params?: CustomerAccessControllerGetPermissionsParams,
) => [`/customer/${id}/access`, ...(params ? [params] : [])] as const;

export type CustomerAccessControllerGetPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerAccessControllerGetPermissions>>
>;
export type CustomerAccessControllerGetPermissionsQueryError = FailResponseDto;

/**
 * @summary Returns list of permission for particular company
 */
export const useCustomerAccessControllerGetPermissions = <TError = FailResponseDto>(
  id: string,
  params?: CustomerAccessControllerGetPermissionsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerAccessControllerGetPermissions>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getCustomerAccessControllerGetPermissionsKey(id, params) : null));
  const swrFn = () => customerAccessControllerGetPermissions(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Grants (or updates existing) company-level permission to particular user
 */
export const customerAccessControllerSetPermission = (
  id: string,
  setPermissionBodyDto: SetPermissionBodyDto,
  params?: CustomerAccessControllerSetPermissionParams,
) => {
  return customInstance<CustomerAccessControllerSetPermission200>({
    url: `/customer/${id}/access`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: setPermissionBodyDto,
    params,
  });
};

export const getCustomerAccessControllerSetPermissionMutationFetcher = (
  id: string,
  params?: CustomerAccessControllerSetPermissionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerAccessControllerSetPermission200> => {
    return customerAccessControllerSetPermission(id, arg as SetPermissionBodyDto, params);
  };
};
export const getCustomerAccessControllerSetPermissionMutationKey = (id: string) => `/customer/${id}/access` as const;

export type CustomerAccessControllerSetPermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerAccessControllerSetPermission>>
>;
export type CustomerAccessControllerSetPermissionMutationError = FailResponseDto;

/**
 * @summary Grants (or updates existing) company-level permission to particular user
 */
export const useCustomerAccessControllerSetPermission = <TError = FailResponseDto>(
  id: string,
  params?: CustomerAccessControllerSetPermissionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerAccessControllerSetPermission>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerAccessControllerSetPermission>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerAccessControllerSetPermissionMutationKey(id);
  const swrFn = getCustomerAccessControllerSetPermissionMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Revokes company-level permission for particular user
 */
export const customerAccessControllerDeletePermission = (
  id: string,
  deletePermissionInDto: DeletePermissionInDto,
  params?: CustomerAccessControllerDeletePermissionParams,
) => {
  return customInstance<CustomerAccessControllerDeletePermission200>({
    url: `/customer/${id}/access`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: deletePermissionInDto,
    params,
  });
};

export const getCustomerAccessControllerDeletePermissionMutationFetcher = (
  id: string,
  params?: CustomerAccessControllerDeletePermissionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerAccessControllerDeletePermission200> => {
    return customerAccessControllerDeletePermission(id, arg as DeletePermissionInDto, params);
  };
};
export const getCustomerAccessControllerDeletePermissionMutationKey = (id: string) => `/customer/${id}/access` as const;

export type CustomerAccessControllerDeletePermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerAccessControllerDeletePermission>>
>;
export type CustomerAccessControllerDeletePermissionMutationError = FailResponseDto;

/**
 * @summary Revokes company-level permission for particular user
 */
export const useCustomerAccessControllerDeletePermission = <TError = FailResponseDto>(
  id: string,
  params?: CustomerAccessControllerDeletePermissionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerAccessControllerDeletePermission>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerAccessControllerDeletePermission>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerAccessControllerDeletePermissionMutationKey(id);
  const swrFn = getCustomerAccessControllerDeletePermissionMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Lists current user permission for all companies in billing account
 */
export const customerAccessControllerListCustomerPermissionsByBillingAccount = (
  billingAccountId: string,
  params?: CustomerAccessControllerListCustomerPermissionsByBillingAccountParams,
) => {
  return customInstance<CustomerAccessControllerListCustomerPermissionsByBillingAccount200>({
    url: `/billing-accounts/${billingAccountId}/access/customers`,
    method: 'GET',
    params,
  });
};

export const getCustomerAccessControllerListCustomerPermissionsByBillingAccountKey = (
  billingAccountId: string,
  params?: CustomerAccessControllerListCustomerPermissionsByBillingAccountParams,
) => [`/billing-accounts/${billingAccountId}/access/customers`, ...(params ? [params] : [])] as const;

export type CustomerAccessControllerListCustomerPermissionsByBillingAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerAccessControllerListCustomerPermissionsByBillingAccount>>
>;
export type CustomerAccessControllerListCustomerPermissionsByBillingAccountQueryError = FailResponseDto;

/**
 * @summary Lists current user permission for all companies in billing account
 */
export const useCustomerAccessControllerListCustomerPermissionsByBillingAccount = <TError = FailResponseDto>(
  billingAccountId: string,
  params?: CustomerAccessControllerListCustomerPermissionsByBillingAccountParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof customerAccessControllerListCustomerPermissionsByBillingAccount>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!billingAccountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getCustomerAccessControllerListCustomerPermissionsByBillingAccountKey(billingAccountId, params)
        : null);
  const swrFn = () => customerAccessControllerListCustomerPermissionsByBillingAccount(billingAccountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
