import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col, Typography, Radio } from 'antd';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import Item from 'components/Form/Item';
import { WebIcon, AppIcon } from 'components/Icons';
import StyledRadioRound from 'components/RadioRound';
import { USER_AUTH0_PARAMETERS } from 'lib/consts';
import useAmplitude from 'lib/hooks/useAmplitude';
import { colors } from 'lib/theme/colors';

import { Box } from '../../styled';

const { Title, Paragraph } = Typography;

interface IDetailsProps {
  value: SubscriptionType; // Current value
  onChange: (value: SubscriptionType) => void; // Handler to update the value
}

const DetailsPlatform = ({ value, onChange }: IDetailsProps) => {
  const { user } = useAuth0();
  const { deviceId } = useAmplitude();
  const isOnePricing = user?.one_pricing_plan;
  const getColor = (isActive: boolean) => (isActive ? colors.primary : colors.gray[80]);

  if (user?.[USER_AUTH0_PARAMETERS.uc_subscription_type] || isOnePricing) {
    return null;
  }

  return (
    <Row gutter={40}>
      <Col flex="260px">
        <Title level={3} data-testid="empty-placeholder:title">
          Select Trial
        </Title>
        <Paragraph>
          Choose the platform for your 30-day free trial. More information is available{' '}
          <a href={`https://usercentrics.com/pricing?deviceId=${deviceId}`} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </Paragraph>
      </Col>
      <Col flex="auto">
        <Box>
          <Item label="Platform" name="platform" colon={false} weight="medium" requiredMark={false}>
            <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
              {Object.values(SubscriptionType).map((val, index) => (
                <StyledRadioRound key={index} value={val}>
                  {index === 0 ? (
                    <WebIcon color={getColor(value === 'web')} />
                  ) : (
                    <AppIcon color={getColor(value === 'app')} />
                  )}
                  {`${val.toUpperCase()} CMP`}
                </StyledRadioRound>
              ))}
            </Radio.Group>
          </Item>
        </Box>
      </Col>
    </Row>
  );
};

export default DetailsPlatform;
