import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
    <g filter="url(#filter0_d_6048_7265)">
      <rect x="4" y="4" width="15" height="20" rx="0.876712" fill="#0045A5" />
    </g>
    <rect x="6" y="7" width="3.3" height="0.945455" fill="#D6D6D6" />
    <rect x="6" y="8.89062" width="11" height="0.472727" fill="#D6D6D6" />
    <rect x="6" y="9.83594" width="11" height="0.472727" fill="#D6D6D6" />
    <rect x="6" y="10.7812" width="11" height="0.472727" fill="#D6D6D6" />
    <rect x="6" y="11.7266" width="11" height="0.472727" fill="#D6D6D6" />
    <rect x="6" y="14" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="7.11646" y="14.2365" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="10" y="14" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="11.1166" y="14.2365" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="14" y="14" width="2.23256" height="1.18182" rx="0.590909" fill="#131313" />
    <rect x="15.1166" y="14.2365" width="0.837209" height="0.709091" rx="0.354545" fill="white" />
    <rect x="12" y="20" width="4" height="1" rx="0.5" fill="#ECEFFE" />
    <rect x="7" y="20" width="4" height="1" rx="0.5" fill="#ECEFFE" />
    <defs>
      <filter
        id="filter0_d_6048_7265"
        x="0.493151"
        y="0.712329"
        width="22.0137"
        height="27.0137"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.219178" />
        <feGaussianBlur stdDeviation="1.75342" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6048_7265" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6048_7265" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
