import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="16px" viewBox="0 0 24 16">
    <rect id="Rectangle" fill="#EFEFEF" fillRule="nonzero" x="0" y="0" width="24" height="16" />
    <path
      d="M7.96684,10.37216 L6.59368,10.37216 L5.56396,6.32824 C5.51508,6.14224 5.41132,5.97776 5.25868,5.90028 C4.87772,5.70548 4.457944,5.55048 4,5.47228 L4,5.3166 L6.21204,5.3166 C6.51736,5.3166 6.74632,5.55048 6.78448,5.82208 L7.31876,8.73908 L8.69124,5.3166 L10.02624,5.3166 L7.96684,10.37216 Z M10.78948,10.37216 L9.49264,10.37216 L10.56052,5.3166 L11.85736,5.3166 L10.78948,10.37216 Z M13.53512,6.71716 C13.57328,6.44484 13.80228,6.28916 14.0694,6.28916 C14.4892,6.25008 14.94644,6.32824 15.32808,6.52236 L15.55704,5.43388 C15.17544,5.2782 14.75564,5.2 14.37468,5.2 C13.11604,5.2 12.20012,5.90028 12.20012,6.87216 C12.20012,7.61152 12.84888,7.99972 13.30684,8.2336 C13.80228,8.4668 13.99308,8.62252 13.95492,8.85572 C13.95492,9.20548 13.57328,9.3612 13.19236,9.3612 C12.7344,9.3612 12.27644,9.2446 11.85736,9.0498 L11.62836,10.13896 C12.08632,10.33308 12.58176,10.41124 13.03968,10.41124 C14.45104,10.44968 15.32808,9.75008 15.32808,8.7 C15.32808,7.37764 13.53512,7.30016 13.53512,6.71716 Z M19.86668,10.37216 L18.83696,5.3166 L17.73092,5.3166 C17.50196,5.3166 17.273,5.47228 17.19668,5.70548 L15.28992,10.37216 L16.62492,10.37216 L16.89136,9.63348 L18.53168,9.63348 L18.68432,10.37216 L19.86668,10.37216 Z M17.92176,6.67804 L18.30268,8.5834 L17.23484,8.5834 L17.92176,6.67804 Z"
      id="Shape"
      fill="#172B85"
    />
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
