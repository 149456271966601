import { CheckOutlined } from '@ant-design/icons';
import { Col, Flex } from 'antd';
import { JSX, useRef, useState } from 'react';

import { useMessage } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { Code, CodeWrapper, CopyText } from './styled';
import { Copy as CopyIcon } from '../Icons';

interface ICodeProps {
  copyText: string;
  successMessage?: string;
  prefix?: JSX.Element;
  dataTestId?: string;
  background?: 'white' | 'gray';
}

export const CopyCode = ({ copyText, successMessage, prefix, dataTestId, background }: ICodeProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const codeRef = useRef<HTMLParagraphElement>(null);
  const message = useMessage();

  return (
    <CodeWrapper
      align="middle"
      justify="space-between"
      background={background}
      gutter={6}
      $isActive={isCopied}
      onClick={() => codeRef.current?.click()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Col span={23}>
        <Flex align="center" gap={20}>
          {prefix}
          <Code data-testid={dataTestId}>{copyText}</Code>
        </Flex>
      </Col>
      <Col span={1} flex="none">
        <CopyText
          hidden={!isHovered}
          copyable={{
            text: copyText,
            tooltips: false,
            icon: [
              <Flex gap={4} align="center" ref={codeRef}>
                <CopyIcon color={colors.primary} key="copy-icon" />
              </Flex>,
              <CheckOutlined />,
            ],
            onCopy: () => {
              setIsCopied(true);
              message.success(successMessage || 'Copied to clipboard.');
              setTimeout(() => setIsCopied(false), 3000);
            },
          }}
        />
      </Col>
    </CodeWrapper>
  );
};
