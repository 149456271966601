import styled from 'styled-components';

import { PermissionTag } from 'components/Tag';
import { flexBetween } from 'shared-components/styled';

const Container = styled.div`
  width: 100%;
  ${flexBetween}
  margin-top: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.gray[4]};
`;
interface IColumn {
  isSettingPermissions: boolean;
}
const Column = styled.div<IColumn>`
  width: ${(props) => (props.isSettingPermissions ? 'calc((100% - 24px) / 4)' : 'calc((100% - 24px) / 3)')};

  & ul {
    margin: 0;
    margin-top: 8px;

    & li {
      color: ${({ theme }) => theme.colors.primaryText};
      font-size: 14px;
      list-style: none;

      &:before {
        content: '• ';
      }
    }
  }
`;

interface IPermissionDetailsProps {
  adminRights: string[];
  writeRights: string[];
  readRights: string[];
  reviewRights?: string[];
}

const PermissionDetails = ({ adminRights, writeRights, readRights, reviewRights }: IPermissionDetailsProps) => (
  <Container>
    <Column isSettingPermissions={Boolean(reviewRights)}>
      <PermissionTag>Admin</PermissionTag>
      <ul>
        {adminRights.map((right) => (
          <li key={right}>{right}</li>
        ))}
      </ul>
    </Column>
    {reviewRights && (
      <Column isSettingPermissions={Boolean(reviewRights)}>
        <PermissionTag>Review</PermissionTag>
        <ul>
          {reviewRights.map((right) => (
            <li key={right}>{right}</li>
          ))}
        </ul>
      </Column>
    )}
    <Column isSettingPermissions={Boolean(reviewRights)}>
      <PermissionTag>Write</PermissionTag>
      <ul>
        {writeRights.map((right) => (
          <li key={right}>{right}</li>
        ))}
      </ul>
    </Column>
    <Column isSettingPermissions={Boolean(reviewRights)}>
      <PermissionTag>Read</PermissionTag>
      <ul>
        {readRights.map((right) => (
          <li key={right}>{right}</li>
        ))}
      </ul>
    </Column>
  </Container>
);

export default PermissionDetails;
