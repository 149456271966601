import { Typography } from 'antd';
import { Key, useState } from 'react';

import { attachSettingsToSubscription } from 'api/requests';
import { TrialSubscriptionOutDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import Table from 'components/Table';
import { ISettingInfo } from 'interfaces/ISetting';
import { getPlanName } from 'lib/helpers';
import useMessage from 'lib/hooks/useMessage';

const { Title, Paragraph } = Typography;

interface IConnectToChargebeeProps {
  open: boolean;
  subscription: TrialSubscriptionOutDto;
  configurations: ISettingInfo[];
  onClose: () => void;
}

const generateColumns = () => [
  {
    title: 'Configuration Name [ID]',
    render: ({ aliasName, id }: ISettingInfo) => (
      <>
        {aliasName} [{id}]
      </>
    ),
  },
  {
    title: 'Company',
    key: 'company',
    dataIndex: 'company',
  },
  {
    title: 'Data Controller',
    key: 'dataController',
    dataIndex: 'dataController',
  },
];

const AssignConfigurations = ({ open, onClose, subscription, configurations }: IConnectToChargebeeProps) => {
  const [selectedConfigurations, setSelectedConfigurations] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const message = useMessage();

  const onChange = async (selectedRowsKeys: Key[]) => {
    setSelectedConfigurations(selectedRowsKeys as string[]);
  };

  const onAssignClick = async () => {
    try {
      setLoading(true);
      await attachSettingsToSubscription(subscription.id, { settingIds: selectedConfigurations });
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      title="Assign Configurations"
      primaryButton={{
        label: 'Assign to Subscription',
        disabled: !selectedConfigurations.length,
        'data-testid': 'button:assign-to-subscription',
        loading,
        onClick: onAssignClick,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      width={740}
      onClose={onClose}
    >
      <Title level={3}>Select Configurations</Title>
      <Paragraph>
        Select all configurations that should be assigned to {getPlanName(subscription?.pricePlan, subscription?.type)}{' '}
        [{subscription.id}]{' '}
      </Paragraph>
      <Table
        dataSource={configurations}
        columns={generateColumns()}
        rowSelection={{ onChange, selectedRowKeys: selectedConfigurations }}
      />
    </Modal>
  );
};

export default AssignConfigurations;
