import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';

export const Layout = styled(AntLayout)`
  padding-top: 30px;
  color: ${({ theme }) => theme.colors.primaryText};
  min-height: 100vh;

  .ant-layout-content.scroll {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 16px;

    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.gray[50]};
      border-radius: 10px;
      width: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.gray[24]};
    }
  }
`;

export const NotAuthorizedContent = styled(AntLayout.Content)`
  background: ${({ theme }) => theme.colors.blue[4]};
  height: calc(100vh - 4rem - 3.938rem);
`;

export const Content = styled(AntLayout.Content)`
  padding: 0 40px 30px 40px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-between;
  height: inherit;
`;
