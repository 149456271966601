import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,9 C18,7.9 17.1,7 16,7 L8,7 C6.9,7 6,7.9 6,9 L6,19 Z M18,4 L15.5,4 L14.79,3.29 C14.61,3.11 14.35,3 14.09,3 L9.91,3 C9.65,3 9.39,3.11 9.21,3.29 L8.5,4 L6,4 C5.45,4 5,4.45 5,5 C5,5.55 5.45,6 6,6 L18,6 C18.55,6 19,5.55 19,5 C19,4.45 18.55,4 18,4 Z"
        id="path-3"
      />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/delete">
        <mask id="mask-3" fill="white">
          <use xlinkHref="#path-3" />
        </mask>
        <use id="Shape" fill="#979797" fillRule="nonzero" xlinkHref="#path-3" />
        <g id="↳-Color" mask="url(#mask-3)" fill="#979797">
          <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
