import { PlusOutlined } from '@ant-design/icons';
import { Card, Col, Flex, Form, Row, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Item, Input } from 'components/Form';
import { TrashCan as TrashCanIcon } from 'components/Icons';
import { ISOCountriesSelect } from 'components/Select/ISOCountriesCodesSelect';
import { StyledSelect } from 'components/Select/Select';
import { IRule } from 'interfaces/IRuleset';
import ISetting from 'interfaces/ISetting';

const { Title, Paragraph } = Typography;

interface IRegionalRuleProps {
  configurationsList: ISetting[];
  isRuleDisabled: boolean;
  rulesetSetting: string;
  setIsRuleDisabled: (value: boolean) => void;
}

const RegionalRule = ({
  isRuleDisabled,
  setIsRuleDisabled,
  rulesetSetting,
  configurationsList,
}: IRegionalRuleProps) => {
  const [currentRule, setCurrentRule] = useState(0);

  const form = Form.useFormInstance<{ rules: IRule[] }>();
  const rules = Form.useWatch(['rules'], form) || [];

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsRuleDisabled(false);
      },
      () => {
        setIsRuleDisabled(true);
      },
    );
  }, [rules]);

  const configurations = useMemo(() => {
    const tcf2Enabled = configurationsList.find((configuration) => configuration.id === rulesetSetting)?.tcf2Enabled;

    const configurationsToExclude = [
      rulesetSetting,
      ...rules?.map((rule) => rule && rule.settingsId),
      ...configurationsList
        .filter((configuration) => {
          // Always show APP configurations in the list
          if (configuration.subscription?.type === SubscriptionType.app) {
            return false;
          }

          // When default ruleset configuration is TCF -> show only TCF settings in the list
          // Otherwise exclude all TCF configurations
          if (tcf2Enabled) {
            return !configuration.tcf2Enabled;
          } else {
            return configuration.tcf2Enabled;
          }
        })
        .map(({ id }) => id),
    ];

    return configurationsList.filter((configuration) => !configurationsToExclude.includes(configuration.id));
  }, [configurationsList, rules, rulesetSetting]);

  return (
    <>
      <Title level={3}>Regional Settings</Title>
      <Paragraph>
        Each ruleset can contain one or multiple Regional Rules. They enable you to display specific configurations to
        users in different regions.
      </Paragraph>

      <Form.List name="rules">
        {(fields, { add, remove }) => (
          <Row justify="space-between">
            <Col span={8}>
              <Row gutter={[0, 15]}>
                {fields.map(({ key, name }) => (
                  <Col span={20}>
                    <Button
                      key={key}
                      size="large"
                      block
                      style={{ maxWidth: 200 }}
                      onClick={() => setCurrentRule(name)}
                      data-testid={`button:regional-rule[${name}]`}
                      {...(currentRule === name && { type: 'default', ghost: true })}
                    >
                      <Flex justify="space-between" align="center">
                        <Paragraph ellipsis style={{ margin: 0 }}>
                          {rules[name]?.name || 'Regional Rule'}
                        </Paragraph>
                        <TrashCanIcon
                          data-testid={`button:delete-rule[${name}]`}
                          onClick={(e) => {
                            e.stopPropagation();
                            remove(name);
                            // Set last rule as active
                            setCurrentRule(fields.length - 2);
                          }}
                        />
                      </Flex>
                    </Button>
                  </Col>
                ))}
                <Col span={20}>
                  <Button
                    icon={<PlusOutlined />}
                    disabled={isRuleDisabled && rules?.length > 0}
                    data-testid="button:add-regional-rule"
                    block
                    onClick={() => {
                      add();
                      // Set just added rule as active
                      setCurrentRule(fields.length);
                    }}
                  >
                    Add Regional Rule
                  </Button>
                </Col>
              </Row>
            </Col>
            {fields.map(({ key, name, ...restField }) => (
              <Col key={key} span={currentRule === name ? 16 : 0}>
                <Card>
                  <Item
                    required
                    label="Rule Name"
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'Input required' }]}
                    {...restField}
                  >
                    <Input data-testid="input:rule-name" placeholder="Rule Name for Regional Rule" />
                  </Item>
                  <Item
                    required
                    label="Region"
                    name={[name, 'locations']}
                    rules={[{ required: true, message: 'Input required' }]}
                    tooltip="Select the regions that would display the respective configuration."
                    {...restField}
                  >
                    <ISOCountriesSelect
                      codesToExclude={[
                        ...rules
                          ?.map((r) => r?.locations || [])
                          .reduce((pL, cL) => pL.concat(cL), [])
                          .filter((l) => !rules[name]?.locations?.includes(l)),
                      ]}
                    />
                  </Item>
                  <Item
                    required
                    name={[name, 'settingsId']}
                    rules={[{ required: true, message: 'Input required' }]}
                    label="Configuration"
                    tooltip={
                      <>
                        Select the configuration that should be shown for this region.
                        <br />
                        <br />
                        Please note that only configurations are available that are not assigned to another rule.
                        Additionally, configurations supporting TCF should belong to a single ruleset and need to be
                        implemented with the respective Script Tag.
                      </>
                    }
                    {...restField}
                  >
                    <StyledSelect
                      options={configurations.map((configuration) => ({
                        value: configuration.id,
                        label: `${configuration?.aliasName || ''} [${configuration.id}]`,
                      }))}
                      filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
                      showSearch
                      data-testid="select:configurations"
                      placeholder="Select Configuration"
                    />
                  </Item>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    </>
  );
};

export default RegionalRule;
