import { Flex, Radio, Form } from 'antd';

import { CreateSettingBodyButtonPrivacyVariant } from 'api/requests/generated/generated.schemas';
import { Fingerprint, Gear, Verified } from 'components/Icons';
import { Text } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { IconWrapper, StyledRadio } from './styled';

const { Item } = Form;

const options = [
  {
    title: 'Fingerprint',
    key: 'fingerprint',
    value: CreateSettingBodyButtonPrivacyVariant.fingerprint,
    icon: <Fingerprint />,
  },
  {
    title: 'Settings',
    key: 'settings',
    value: CreateSettingBodyButtonPrivacyVariant.settings,
    icon: <Verified />,
  },
  {
    title: 'Security',
    key: 'security',
    value: CreateSettingBodyButtonPrivacyVariant.security,
    icon: <Gear color={colors.white} />,
  },
];

const PrivacyButton = () => {
  return (
    <Item name="buttonPrivacyVariant" style={{ margin: 0 }}>
      <Radio.Group style={{ width: '100%' }}>
        <Flex gap={8} justify="space-between">
          {options.map(({ title, icon, key, value }) => (
            <StyledRadio value={value} key={key}>
              <IconWrapper>{icon}</IconWrapper>
              <Text weight={500}>{title}</Text>
            </StyledRadio>
          ))}
        </Flex>
      </Radio.Group>
    </Item>
  );
};

export default PrivacyButton;
