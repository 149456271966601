import { Layout } from 'antd';
import styled from 'styled-components';

export const Sider = styled(Layout.Sider)`
  &.ant-layout-sider {
    padding: 0;
    max-height: 85vh;
    position: sticky;
    overflow: auto;
    top: 100px;

    & .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

export const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
`;

export const ContentLayout = styled(Layout)`
  margin-left: 40px;
`;

export const Footer = styled(Layout.Footer)`
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 15px;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px ${(props) => props.theme.colors.black}1a;
  padding: 24px 32px;
`;
