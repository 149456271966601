import { Col, Flex } from 'antd';

import { PaymentSourceDto, PaymentSourceType, SubscriptionDto } from 'api/requests/generated/generated.schemas';
import { CreditCardOff } from 'components/Icons';
import { Text } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { BAIcon, CCIcon } from './styled';

interface IPaymentMethodProps {
  subscription: SubscriptionDto;
  paymentSources?: PaymentSourceDto[];
  primaryPaymentSourceId?: string;
}

export const PaymentMethod = ({ subscription, paymentSources, primaryPaymentSourceId }: IPaymentMethodProps) => {
  const primaryPaymentMethod = paymentSources?.find((ps) => ps.id === primaryPaymentSourceId);

  return (
    <>
      <Col span={11}>
        <Text data-testid={`${subscription.id}:subscription-payment-method`} weight={500}>
          Payment Method
        </Text>
      </Col>
      <Col span={13}>
        <Text>{renderPaymentSource(primaryPaymentMethod)}</Text>
      </Col>
    </>
  );
};

const renderPaymentSource = (ps?: PaymentSourceDto) => {
  if (!ps)
    return (
      <Flex align="center" gap={6}>
        <CreditCardOff />
        <Text style={{ color: colors.gray[80] }}>not added</Text>
      </Flex>
    );

  if (ps.type === PaymentSourceType.card) {
    return (
      <Flex align="center" gap={4}>
        <CCIcon />
        Credit Card
      </Flex>
    );
  }
  if (ps.type === PaymentSourceType.direct_debit) {
    return (
      <Flex align="center" gap={4}>
        <BAIcon />
        Bank Account
      </Flex>
    );
  }
};
