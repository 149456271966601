import { LoadingOutlined } from '@ant-design/icons';
import { Row, Space, Spin, Typography } from 'antd';

import { Text } from 'components/Typography';
import { colors } from 'lib/theme/colors';

const { Title } = Typography;

export const LoadingState = () => {
  return (
    <Row justify="center" align="middle" style={{ height: '80vh' }}>
      <Space align="center" direction="vertical">
        <Spin size="large" indicator={<LoadingOutlined style={{ color: colors.gray[80] }} />} />
        <Title level={2}>Preparing your account...</Title>
        <Text>Usually your account will be ready in less than 1 minute.</Text>
        <Text>Please don't close this window.</Text>
      </Space>
    </Row>
  );
};
