/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  BulkAttachSettingsToCustomer,
  CustomerSettingControllerAttachExistingSettingToCustomer200,
  CustomerSettingControllerAttachExistingSettingToCustomerParams,
  CustomerSettingControllerBulkAttachSettingsToCustomer200,
  CustomerSettingControllerBulkAttachSettingsToCustomerParams,
  CustomerSettingControllerDetachSettingFromCustomer200,
  CustomerSettingControllerDetachSettingFromCustomerParams,
  CustomerSettingControllerGetAvailableTypes200,
  CustomerSettingControllerGetAvailableTypesParams,
  CustomerSettingControllerMoveSettingToAnotherCompany200,
  CustomerSettingControllerMoveSettingToAnotherCompanyParams,
  FailResponseDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns which setting types can be created for particular company (based on existing subscriptions)
 */
export const customerSettingControllerGetAvailableTypes = (
  customerId: string,
  params?: CustomerSettingControllerGetAvailableTypesParams,
) => {
  return customInstance<CustomerSettingControllerGetAvailableTypes200>({
    url: `/customer/${customerId}/setting/availableTypes`,
    method: 'GET',
    params,
  });
};

export const getCustomerSettingControllerGetAvailableTypesKey = (
  customerId: string,
  params?: CustomerSettingControllerGetAvailableTypesParams,
) => [`/customer/${customerId}/setting/availableTypes`, ...(params ? [params] : [])] as const;

export type CustomerSettingControllerGetAvailableTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerSettingControllerGetAvailableTypes>>
>;
export type CustomerSettingControllerGetAvailableTypesQueryError = FailResponseDto;

/**
 * @summary Returns which setting types can be created for particular company (based on existing subscriptions)
 */
export const useCustomerSettingControllerGetAvailableTypes = <TError = FailResponseDto>(
  customerId: string,
  params?: CustomerSettingControllerGetAvailableTypesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof customerSettingControllerGetAvailableTypes>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!customerId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getCustomerSettingControllerGetAvailableTypesKey(customerId, params) : null));
  const swrFn = () => customerSettingControllerGetAvailableTypes(customerId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Attaches unassigned settings to company by bulk
 */
export const customerSettingControllerBulkAttachSettingsToCustomer = (
  customerId: string,
  bulkAttachSettingsToCustomer: BulkAttachSettingsToCustomer,
  params?: CustomerSettingControllerBulkAttachSettingsToCustomerParams,
) => {
  return customInstance<CustomerSettingControllerBulkAttachSettingsToCustomer200>({
    url: `/customer/${customerId}/setting/bulk`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bulkAttachSettingsToCustomer,
    params,
  });
};

export const getCustomerSettingControllerBulkAttachSettingsToCustomerMutationFetcher = (
  customerId: string,
  params?: CustomerSettingControllerBulkAttachSettingsToCustomerParams,
) => {
  return (
    _: string,
    { arg }: { arg: Arguments },
  ): Promise<CustomerSettingControllerBulkAttachSettingsToCustomer200> => {
    return customerSettingControllerBulkAttachSettingsToCustomer(
      customerId,
      arg as BulkAttachSettingsToCustomer,
      params,
    );
  };
};
export const getCustomerSettingControllerBulkAttachSettingsToCustomerMutationKey = (customerId: string) =>
  `/customer/${customerId}/setting/bulk` as const;

export type CustomerSettingControllerBulkAttachSettingsToCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerSettingControllerBulkAttachSettingsToCustomer>>
>;
export type CustomerSettingControllerBulkAttachSettingsToCustomerMutationError = FailResponseDto;

/**
 * @summary Attaches unassigned settings to company by bulk
 */
export const useCustomerSettingControllerBulkAttachSettingsToCustomer = <TError = FailResponseDto>(
  customerId: string,
  params?: CustomerSettingControllerBulkAttachSettingsToCustomerParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerSettingControllerBulkAttachSettingsToCustomer>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerSettingControllerBulkAttachSettingsToCustomer>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCustomerSettingControllerBulkAttachSettingsToCustomerMutationKey(customerId);
  const swrFn = getCustomerSettingControllerBulkAttachSettingsToCustomerMutationFetcher(customerId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Assign unassigned setting to company
 */
export const customerSettingControllerAttachExistingSettingToCustomer = (
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerAttachExistingSettingToCustomerParams,
) => {
  return customInstance<CustomerSettingControllerAttachExistingSettingToCustomer200>({
    url: `/customer/${customerId}/setting/${settingId}`,
    method: 'POST',
    params,
  });
};

export const getCustomerSettingControllerAttachExistingSettingToCustomerMutationFetcher = (
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerAttachExistingSettingToCustomerParams,
) => {
  return (
    _: string,
    { arg }: { arg: Arguments },
  ): Promise<CustomerSettingControllerAttachExistingSettingToCustomer200> => {
    return customerSettingControllerAttachExistingSettingToCustomer(customerId, settingId, params);
  };
};
export const getCustomerSettingControllerAttachExistingSettingToCustomerMutationKey = (
  customerId: string,
  settingId: string,
) => `/customer/${customerId}/setting/${settingId}` as const;

export type CustomerSettingControllerAttachExistingSettingToCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerSettingControllerAttachExistingSettingToCustomer>>
>;
export type CustomerSettingControllerAttachExistingSettingToCustomerMutationError = FailResponseDto;

/**
 * @summary Assign unassigned setting to company
 */
export const useCustomerSettingControllerAttachExistingSettingToCustomer = <TError = FailResponseDto>(
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerAttachExistingSettingToCustomerParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerSettingControllerAttachExistingSettingToCustomer>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerSettingControllerAttachExistingSettingToCustomer>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getCustomerSettingControllerAttachExistingSettingToCustomerMutationKey(customerId, settingId);
  const swrFn = getCustomerSettingControllerAttachExistingSettingToCustomerMutationFetcher(
    customerId,
    settingId,
    params,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Unassign setting from company
 */
export const customerSettingControllerDetachSettingFromCustomer = (
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerDetachSettingFromCustomerParams,
) => {
  return customInstance<CustomerSettingControllerDetachSettingFromCustomer200>({
    url: `/customer/${customerId}/setting/${settingId}`,
    method: 'DELETE',
    params,
  });
};

export const getCustomerSettingControllerDetachSettingFromCustomerMutationFetcher = (
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerDetachSettingFromCustomerParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<CustomerSettingControllerDetachSettingFromCustomer200> => {
    return customerSettingControllerDetachSettingFromCustomer(customerId, settingId, params);
  };
};
export const getCustomerSettingControllerDetachSettingFromCustomerMutationKey = (
  customerId: string,
  settingId: string,
) => `/customer/${customerId}/setting/${settingId}` as const;

export type CustomerSettingControllerDetachSettingFromCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerSettingControllerDetachSettingFromCustomer>>
>;
export type CustomerSettingControllerDetachSettingFromCustomerMutationError = FailResponseDto;

/**
 * @summary Unassign setting from company
 */
export const useCustomerSettingControllerDetachSettingFromCustomer = <TError = FailResponseDto>(
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerDetachSettingFromCustomerParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerSettingControllerDetachSettingFromCustomer>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerSettingControllerDetachSettingFromCustomer>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getCustomerSettingControllerDetachSettingFromCustomerMutationKey(customerId, settingId);
  const swrFn = getCustomerSettingControllerDetachSettingFromCustomerMutationFetcher(customerId, settingId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Move setting from one company to another
 */
export const customerSettingControllerMoveSettingToAnotherCompany = (
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerMoveSettingToAnotherCompanyParams,
) => {
  return customInstance<CustomerSettingControllerMoveSettingToAnotherCompany200 | void>({
    url: `/customer/${customerId}/setting/${settingId}/moveTo`,
    method: 'POST',
    params,
  });
};

export const getCustomerSettingControllerMoveSettingToAnotherCompanyMutationFetcher = (
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerMoveSettingToAnotherCompanyParams,
) => {
  return (
    _: string,
    { arg }: { arg: Arguments },
  ): Promise<CustomerSettingControllerMoveSettingToAnotherCompany200 | void> => {
    return customerSettingControllerMoveSettingToAnotherCompany(customerId, settingId, params);
  };
};
export const getCustomerSettingControllerMoveSettingToAnotherCompanyMutationKey = (
  customerId: string,
  settingId: string,
) => `/customer/${customerId}/setting/${settingId}/moveTo` as const;

export type CustomerSettingControllerMoveSettingToAnotherCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof customerSettingControllerMoveSettingToAnotherCompany>>
>;
export type CustomerSettingControllerMoveSettingToAnotherCompanyMutationError = FailResponseDto;

/**
 * @summary Move setting from one company to another
 */
export const useCustomerSettingControllerMoveSettingToAnotherCompany = <TError = FailResponseDto>(
  customerId: string,
  settingId: string,
  params: CustomerSettingControllerMoveSettingToAnotherCompanyParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof customerSettingControllerMoveSettingToAnotherCompany>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof customerSettingControllerMoveSettingToAnotherCompany>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getCustomerSettingControllerMoveSettingToAnotherCompanyMutationKey(customerId, settingId);
  const swrFn = getCustomerSettingControllerMoveSettingToAnotherCompanyMutationFetcher(customerId, settingId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
