import { Flex } from 'antd';
import styled from 'styled-components';

import PremiumFeatureBar from 'components/PremiumFeatureBar';
import { Text } from 'components/Typography';

export const Container = styled.div`
  padding: 20px 16px;
`;

export const UpgradeMessage = styled(PremiumFeatureBar)`
  margin-bottom: 16px;
  padding: 16px;

  & > div > span {
    align-self: flex-start;
  }
`;

export const SessionsContainer = styled(Flex)<{ $completed: boolean }>`
  background: ${(props) => (props.$completed ? props.theme.colors.yellow[8] : props.theme.colors.blue[4])};
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 32px;
  border-radius: 4px;
`;

export const RowContainer = styled(Flex)<{ $exceeded: boolean }>`
  padding: 12px;
  margin-bottom: 2px;
  border-radius: 4px;
  background: ${(props) => (props.$exceeded ? props.theme.colors.yellow[8] : props.theme.colors.gray[8])};
`;

export const RowContent = styled(Text)<{ $gray?: boolean; $exceeded?: boolean }>`
  color: ${(props) => {
    if (props.$gray) return props.theme.colors.gray[80];
    if (props.$exceeded) return props.theme.colors.orange[100];
    return props.theme.colors.primaryText;
  }};
  font-weight: ${(props) => (props.$exceeded ? 600 : 400)};
`;

export const Disclaimer = styled(Text)`
  color: ${(props) => props.theme.colors.secondary};
`;

export const SuccessMessage = styled(Flex)`
  background: ${(props) => props.theme.colors.green[8]};
  padding: 12px 16px;
  margin-top: 8px;
  border-radius: 4px;

  & svg {
    width: 20px;
    height: 20px;
  }
`;
