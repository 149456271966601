import styled from 'styled-components';

import StatusBar from 'components/StatusBar';
import { pr, flexBetween } from 'shared-components/styled';

export const Trial = styled(StatusBar)`
  ${pr}
  ${flexBetween}
  align-items: center;
`;
