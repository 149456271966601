import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    className="svg-icon"
    style={{ width: '1em', verticalAlign: 'middle' }}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M725.333333 298.666667h-170.666666v81.066666h170.666666c72.96 0 132.266667 59.306667 132.266667 132.266667 0 61.013333-41.813333 112.213333-98.56 127.146667l62.293333 62.293333C890.88 666.026667 938.666667 595.2 938.666667 512c0-117.76-95.573333-213.333333-213.333334-213.333333z m-42.666666 170.666666h-93.44l85.333333 85.333334H682.666667v-85.333334zM85.333333 182.186667l132.693334 132.693333A212.949333 212.949333 0 0 0 85.333333 512c0 117.76 95.573333 213.333333 213.333334 213.333333h170.666666v-81.066666H298.666667c-72.96 0-132.266667-59.306667-132.266667-132.266667 0-67.84 51.626667-123.733333 117.76-130.986667L372.48 469.333333H341.333333v85.333334h116.48L554.666667 651.52V725.333333h73.813333l171.093333 170.666667L853.333333 842.24 139.52 128 85.333333 182.186667z"
      fill="#000000"
    />
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
