import { Row } from 'antd';

import { AccountOutDto, SubscriptionDto } from 'api/requests/generated/generated.schemas';

import { Status, Billing, Sessions, Price, SubscriptionId, Domains, Configurations } from './Items';
import { PaymentMethod } from './Items/PaymentMethod';

export type Items =
  | 'status'
  | 'billing'
  | 'subscriptionId'
  | 'price'
  | 'domains'
  | 'configurations'
  | 'sessions'
  | 'payment';

interface ISubscriptionCardContentProps {
  subscription: SubscriptionDto;
  account: Pick<AccountOutDto, 'paymentSources' | 'primaryPaymentSourceId'>;
  itemsToShow: Partial<(Items | undefined)[]>;
  domains?: number | string;
}

const SubscriptionCardContent = ({ subscription, itemsToShow, account, domains }: ISubscriptionCardContentProps) => {
  const items: { [key in Items]: React.ReactNode } = {
    billing: <Billing key="billing" subscription={subscription} />,
    status: <Status key="status" subscription={subscription} />,
    subscriptionId: <SubscriptionId key="subscriptionId" subscriptionId={subscription.id} />,
    price: <Price key="price" subscription={subscription} />,
    domains: domains ? <Domains key="domains" subscriptionId={subscription.id} domains={domains} /> : null,
    configurations: <Configurations key="configurations" count={subscription.actualSettingsCount} />,
    sessions: <Sessions key="sessions" subscription={subscription} />,
    payment: (
      <PaymentMethod
        key="payment"
        subscription={subscription}
        primaryPaymentSourceId={account?.primaryPaymentSourceId}
        paymentSources={account?.paymentSources}
      />
    ),
  };

  return (
    <Row justify="space-between" gutter={[20, 2]} style={{ padding: 8 }}>
      {itemsToShow.map((item) => item && items[item])}
    </Row>
  );
};

export default SubscriptionCardContent;
