import { Flex } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { searchAccounts } from 'api/requests';
import { AccountOutDto } from 'api/requests/generated/generated.schemas';
import Skeleton from 'components/AccountDetails/Skeleton';
import { ArrowRight } from 'components/Icons';
import NotFound from 'components/NotFound';
import PageInfo from 'components/PageInfo';
import Search from 'components/Search';
import { Title } from 'components/Typography';
import { useMessage, usePrivileges } from 'lib/hooks';
import { Container } from 'shared-components';

import { AccountBlock } from './styled';

const Accounts = () => {
  const message = useMessage();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isCSA } = usePrivileges();
  const [accounts, setAccounts] = useState<AccountOutDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const query = searchParams.get('q') || '';

  useEffect(() => {
    (async () => {
      if (query) {
        await onSearch(query);
      }
    })();
  }, []);

  useEffect(() => {
    if (!isCSA) {
      navigate('/');
    }
  }, [isCSA]);

  const onSearch = async (q: string) => {
    try {
      if (!q) {
        return setAccounts([]);
      }

      setIsLoading(true);
      setSearchParams({ q });
      const fetchedAccounts = (await searchAccounts({ q })).body;

      if (!fetchedAccounts.length) {
        setShowNoResults(true);
      }

      setAccounts(fetchedAccounts);
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <PageInfo title="Accounts" description="Below you can search for any account." />
      <Search
        placeholder="Search for Account Name, Chargebee Customer ID or Billing Email"
        data-testid="input:account-search"
        onSearch={onSearch}
        isLoading={isLoading}
        defaultValue={query}
        minLength={3}
      />
      {!isLoading ? (
        accounts.length ? (
          <Flex vertical gap={20}>
            {accounts.map((account) => (
              <AccountBlock
                key={account.id}
                align="center"
                justify="space-between"
                onClick={() => navigate(`/accounts/${account.id}/`)}
                data-testid={`block:account-${account.chargebeeCustomerId}`}
              >
                <Title level={4} weight={500} $noMargin>
                  {account.name}
                </Title>
                <ArrowRight />
              </AccountBlock>
            ))}
          </Flex>
        ) : (
          showNoResults && <NotFound />
        )
      ) : (
        <Skeleton />
      )}
    </Container>
  );
};

export default Accounts;
