import { Col, Flex, Form, Row, Switch } from 'antd';

import ColorPicker from 'components/ColorPicker';
import { Input } from 'components/Form';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { ToggleContainer } from './styled';

const { Item } = Form;

const BackgroundOverlay = () => {
  const form = Form.useFormInstance();
  const { customizationDetails, setCustomizationDetails } = useOnboarding();
  const { setFieldValue } = form;

  const onSwitchChange = (checked: boolean) => {
    setCustomizationDetails((prev) => ({ ...prev, styling: { ...prev.styling, isOverlayEnabled: checked } }));
  };

  const onSetColorValue = (name: string, value: string) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      styling: { ...prev.styling, [name]: value },
    }));
    setFieldValue(name, value);
  };

  const onOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;

    form.setFieldValue('overlayOpacity', value);
    setCustomizationDetails((prev) => ({
      ...prev,
      styling: { ...prev.styling, overlayOpacity: value / 100 },
    }));
  };

  const onOpacityBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;

    if (!value || value < 1) {
      form.setFieldValue('overlayOpacity', 1);
      setCustomizationDetails((prev) => ({
        ...prev,
        styling: { ...prev.styling, overlayOpacity: 0.01 },
      }));

      return;
    }

    if (value > 100) {
      form.setFieldValue('overlayOpacity', 100);
      setCustomizationDetails((prev) => ({
        ...prev,
        styling: { ...prev.styling, overlayOpacity: 1 },
      }));
    }
  };

  return (
    <>
      <ToggleContainer gap={16} align="center">
        <Item name="isOverlayEnabled" noStyle>
          <Switch onChange={onSwitchChange} />
        </Item>
        <Text weight={500}>Enable Overlay</Text>
      </ToggleContainer>
      <Row justify="space-between" gutter={[20, 20]}>
        <Col span={12}>
          <Flex vertical gap={8}>
            <Text weight={500}>Background</Text>
            <ColorPicker
              name="overlay"
              colorValue={customizationDetails.styling.overlay}
              setColorValue={(value) => onSetColorValue('overlay', value)}
            />
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical gap={8}>
            <Text weight={500}>Opacity</Text>
            <Item name="overlayOpacity">
              <Input onChange={onOpacityChange} onBlur={onOpacityBlur} addonAfter="%" type="number" max={100} min={1} />
            </Item>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default BackgroundOverlay;
