import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg height="16" viewBox="0 0 24 16" width="24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <rect fill="#efefef" fill-rule="nonzero" height="16" rx="4" width="24" />
      <g transform="translate(4.4 3.6)">
        <path
          d="m7.45264 7.86632c-.78936.66536-1.81328 1.067-2.9322 1.067-2.49656 0-4.52044-1.9998-4.52044-4.46664 0-2.46688 2.02388-4.46668 4.52044-4.46668 1.11892 0 2.14284.401664 2.9322 1.06704.78936-.665376 1.81332-1.06704 2.9322-1.06704 2.4966 0 4.52044 1.9998 4.52044 4.46668 0 2.46684-2.02384 4.46664-4.52044 4.46664-1.11888 0-2.14284-.40164-2.9322-1.067z"
          fill="#ed0006"
        />
        <path
          d="m7.45272 7.86632c.97196-.81928 1.58828-2.03836 1.58828-3.39964 0-1.36132-.61632-2.5804-1.58828-3.39964.7894-.665376 1.81332-1.06704 2.9322-1.06704 2.4966 0 4.52048 1.9998 4.52048 4.46668 0 2.46684-2.02388 4.46664-4.52048 4.46664-1.11888 0-2.1428-.40164-2.9322-1.067z"
          fill="#f9a000"
        />
        <path
          d="m7.45272 7.86628c.97192-.81924 1.58824-2.03832 1.58824-3.3996 0-1.36132-.61632-2.58036-1.58824-3.39964-.97196.81928-1.58828 2.03832-1.58828 3.39964 0 1.36128.61632 2.58036 1.58828 3.3996z"
          fill="#ff5e00"
        />
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
