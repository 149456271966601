import { Row, Flex, Col, Form } from 'antd';

import ColorPicker from 'components/ColorPicker';
import { Text } from 'components/Typography';
import { ICustomizationStyling } from 'lib/contexts/Onboarding';
import useOnboarding from 'lib/hooks/useOnboarding';

const Colors = <T extends Partial<keyof ICustomizationStyling>>({
  colors,
}: {
  colors: { name: T; title: string }[];
}) => {
  const form = Form.useFormInstance();
  const { customizationDetails, setCustomizationDetails } = useOnboarding();
  const { setFieldValue } = form;

  const onSetColorValue = (name: T, value: string) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      styling: { ...prev.styling, [name]: value },
    }));
    setFieldValue(name, value);
  };

  return (
    <Row justify="space-between" gutter={[20, 20]} align="middle">
      {colors.map((color) => (
        <Col span={12} key={`${color.title}[${color.name}]`}>
          <Flex vertical gap={8}>
            <Text ellipsis={{ tooltip: true }} weight={500}>
              {color.title}
            </Text>
            <ColorPicker
              name={color.name}
              colorValue={customizationDetails.styling[color.name] as string}
              setColorValue={(value) => onSetColorValue(color.name, value)}
            />
          </Flex>
        </Col>
      ))}
    </Row>
  );
};

export default Colors;
