import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from 'antd';

import { createOrUpdateCompanyPermissions, deleteCompanyPermissions, useGetPermission } from 'api/requests';
import { Permission, SetPermissionInDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import Permissions from 'components/Permissions';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';
import useMessage from 'lib/hooks/useMessage';

interface IEditPermissionsProps {
  onClose: () => void;
  company: { name: string; id: string };
  open: boolean;
}

const ManageAccess = ({ open, company, onClose }: IEditPermissionsProps) => {
  const { isCSA } = usePrivileges();
  const { user } = useAuth0();
  const { mutate: mutateSelectedCompany } = useSelectedCompany();
  const { data: fetchedPermissions, mutate } = useGetPermission(company.id);
  const message = useMessage();

  const permissions = fetchedPermissions?.body || [];

  const clearAndClose = async () => {
    onClose();
    mutateSelectedCompany();
  };

  const onRemove = async (login: string) => {
    try {
      await deleteCompanyPermissions(company.id, { login });
      mutate();
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    }
  };

  const onUpdate = async (login: string, newPermission: Permission) => {
    await createOrUpdateCompanyPermissions(company.id, {
      permission: { login, permission: newPermission, notify: false },
    });
    mutate();
  };

  const onAdd = async (data: SetPermissionInDto) => {
    if (data.login === user?.email && !isCSA) {
      message.error('You can not revoke your own administrator status');
    }
    await createOrUpdateCompanyPermissions(company.id, { permission: data });
    mutate();
  };

  return (
    <Modal
      open={open}
      title={
        <>
          Manage Access <span>[{company.name}]</span>
        </>
      }
      width={760}
      bodyHeight="600px"
      onClose={clearAndClose}
      data-testid="modal:manage-permissions"
      hideFooterBorder
    >
      {isCSA && (
        <Alert
          message="You are viewing this as a support role user"
          style={{ margin: '-40px -40px 40px -40px' }}
          type="info"
          showIcon
          data-testid="info-block:csa-only"
        />
      )}
      <Permissions isCSA={isCSA} onAdd={onAdd} onRemove={onRemove} onUpdate={onUpdate} permissions={permissions} />
    </Modal>
  );
};

export default ManageAccess;
