import { Col, Flex } from 'antd';

import { Open } from 'components/Icons';
import { Title } from 'components/Typography';

import { Box, IconWrapper } from './styled';

interface ILinkBoxProps {
  title: string;
  icon: React.ReactNode;
  href: string;
}

const LinkBox = ({ title, icon, href }: ILinkBoxProps) => (
  <Box
    justify="space-between"
    align="middle"
    onClick={() => {
      window.open(href, '_blank');
    }}
  >
    <Col span={20}>
      <Flex gap={16} align="center">
        <IconWrapper>{icon}</IconWrapper>
        <Title level={4} weight={500} $noMargin>
          {title}
        </Title>
      </Flex>
    </Col>
    <Col>
      <Open size={24} />
    </Col>
  </Box>
);

export default LinkBox;
