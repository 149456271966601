import { Form } from 'antd';
import { useState } from 'react';

import { RuleSetInDto, RuleSetOutDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import useMessage from 'lib/hooks/useMessage';

import RulesetDetails from './Content/Details';

const { useForm, useWatch } = Form;

interface IEditRegionalRulesProps {
  onClose: () => Promise<void>;
  onUpdate: (rulesetId: string, rulesetList: RuleSetInDto) => Promise<void>;
  ruleset: RuleSetOutDto;
}

const EditRulesetDetails = ({ onClose, ruleset, onUpdate }: IEditRegionalRulesProps) => {
  const message = useMessage();
  const [detailsForm] = useForm();
  const rulesetName = useWatch(['name'], detailsForm);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    name: ruleset.name,
    description: ruleset.description,
  };

  const onButtonClick = async () => {
    try {
      setIsLoading(true);

      const details = detailsForm.getFieldsValue();
      await onUpdate(ruleset.ruleSetId, { ...ruleset, ...details });
      await onClose();
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open
      title="Edit Ruleset"
      primaryButton={{
        label: 'Save changes',
        disabled: !rulesetName,
        onClick: onButtonClick,
        loading: isLoading,
        'data-testid': 'button:save-ruleset-details',
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      bodyHeight={600}
      width={760}
      onClose={onClose}
    >
      <RulesetDetails form={detailsForm} initialValues={initialValues} />
    </Modal>
  );
};

export default EditRulesetDetails;
