import Icon from '@ant-design/icons';

import { IconProps } from 'types';

type Color = 'blue' | string;

const CustomIcon = ({ color }: { color?: Color }) => (
  <svg
    width="12px"
    height="20px"
    viewBox="0 0 12 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Platform Indicator</title>
    <defs>
      <path
        d="M9.69230769,0 L2.30769231,0 C1.03384615,0 0,1.01818182 0,2.27272727 L0,17.7272727 C0,18.9818182 1.03384615,20 2.30769231,20 L9.69230769,20 C10.9661538,20 12,18.9818182 12,17.7272727 L12,2.27272727 C12,1.01818182 10.9661538,0 9.69230769,0 Z M6,19.0909091 C5.23384615,19.0909091 4.61538462,18.4818182 4.61538462,17.7272727 C4.61538462,16.9727273 5.23384615,16.3636364 6,16.3636364 C6.76615385,16.3636364 7.38461538,16.9727273 7.38461538,17.7272727 C7.38461538,18.4818182 6.76615385,19.0909091 6,19.0909091 Z M10.1538462,15.4545455 L1.84615385,15.4545455 L1.84615385,2.72727273 L10.1538462,2.72727273 L10.1538462,15.4545455 Z"
        id="path-13"
      />
    </defs>
    <g id="✅-[DPSD-XXXX]-Subscription-Info" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="✅-[CSA]-Company-Details" transform="translate(-314.000000, -868.000000)">
        <g id="Subscription-Copy" transform="translate(272.000000, 782.000000)">
          <g id="Subscription-Copy-4" transform="translate(20.000000, 60.000000)">
            <g id="Platform-Indicator" transform="translate(22.000000, 26.000000)">
              <mask id="mask-12" fill="white">
                <use xlinkHref="#path-13" />
              </mask>
              <use id="Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-13" />
              <g id="↳-Color" mask="url(#mask-12)" fill={color === 'blue' ? '#0045A5' : '#303030'}>
                <g transform="translate(-6.000000, -2.000000)" id="Rectangle">
                  <rect x="0" y="0" width="24" height="24" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
