import { Flex } from 'antd';
import styled from 'styled-components';

export const TrialInfoBar = styled(Flex)<{ type: 'active' | 'expired' }>`
  ${(props) => {
    switch (props.type) {
      case 'active': {
        return 'background: linear-gradient(90deg, #fff6cc 0%, #ffdfcc 100%);';
      }

      case 'expired': {
        return `background: ${props.theme.colors.yellow[8]};`;
      }

      default: {
        return '';
      }
    }
  }}

  padding: 16px;
  margin: -16px -16px 16px -16px;
`;
