import { Tooltip } from 'antd';

import { Permission } from 'api/requests/generated/generated.schemas';
import Dropdown from 'components/Dropdown';
import { Dots as DotsIcon } from 'components/Icons';

interface IPermissionMenuProps {
  permission: Permission;
  onUpdate: (permission: Permission) => void;
  onRemove: () => void;
  disabled?: boolean;
  isReviewMode: boolean;
  reasonOfDisabled?: string;
}

const PermissionMenu = ({
  onRemove,
  onUpdate,
  permission,
  disabled,
  reasonOfDisabled,
  isReviewMode,
}: IPermissionMenuProps) => {
  const items = [
    {
      label: 'Make Admin',
      key: 'admin',
      hidden: permission === Permission.ADMIN,
      disabled: disabled,
      'data-testid': 'menu-item:make-admin',
      onClick: () => onUpdate(Permission.ADMIN),
    },
    {
      label: 'Change to Read',
      key: 'read',
      hidden: permission === Permission.READ,
      disabled: disabled,
      'data-testid': 'menu-item:change-read',
      onClick: () => onUpdate(Permission.READ),
    },
    {
      label: 'Change to Review',
      key: 'review',
      hidden: permission === Permission.REVIEW && !isReviewMode,
      disabled: disabled,
      'data-testid': 'menu-item:change-review',
      onClick: () => onUpdate(Permission.REVIEW),
    },
    {
      label: 'Change to Write',
      key: 'write',
      hidden: permission === Permission.READWRITE,
      disabled: disabled,
      'data-testid': 'menu-item:change-write',
      onClick: () => onUpdate(Permission.READWRITE),
    },
    {
      hidden: permission === Permission.LEGACY_ADMIN,
      type: 'divider',
    },
    {
      label: 'Remove access',
      danger: true,
      disabled: disabled,
      key: 'delete',
      hidden: permission === Permission.LEGACY_ADMIN,
      'data-testid': 'menu-item:remove-access',
      onClick: onRemove,
    },
  ];

  return (
    <Dropdown items={items} disabled={disabled}>
      <Tooltip title={disabled ? reasonOfDisabled : null} key="leftButton">
        <a
          onClick={(e) => {
            e.stopPropagation();
          }}
          data-testid="div:permission-menu"
        >
          <DotsIcon />
        </a>
      </Tooltip>
    </Dropdown>
  );
};

export default PermissionMenu;
