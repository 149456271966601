import styled from 'styled-components';

import { Info, Close } from 'components/Icons';

export const InfoIcon = styled(Info)`
  & svg {
    & path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const CloseIcon = styled(Close)`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
