import {
  useAccountControllerGetAccountByCustomerId,
  useAccountControllerGetAccountDetails,
  useAccountControllerGetMyAccounts,
  useAccountControllerGetTrials,
  useAccountControllerSearchAccounts,
} from './generated/account/account';
import { usePermissionBillingAccountControllerList } from './generated/billing-account-access/billing-account-access';
import {
  useConsumptionControllerGetAccountConsumption,
  useConsumptionControllerGetSubscriptionConsumption,
} from './generated/consumption/consumption';
import {
  useCustomerControllerGet,
  useCustomerControllerGetBaseInfo,
  useCustomerControllerGetCustomerRuleSets,
  useCustomerControllerGetSettingsToExport,
} from './generated/customer/customer';
import { useCustomerAccessControllerGetPermissions } from './generated/customer-access/customer-access';
import { useCustomerSettingControllerGetAvailableTypes } from './generated/customer-setting/customer-setting';
import { usePrivilegeControllerGetPrivileges } from './generated/privilege/privilege';
import { useRuleSetControllerGetById } from './generated/rule-set/rule-set';
import {
  useSettingControllerSearchSettings,
  useSettingControllerSearchUnassignedSettings,
  useSettingControllerSearchUnassignedSettingsAll,
} from './generated/setting/setting';
import { useSettingAccessControllerGetAllPermissions } from './generated/setting-access/setting-access';
import {
  useSubscriptionTierControllerGetSubscriptionTiers,
  useRecommendedPricePlanControllerGetRecommendedPlan,
  useEntitlementControllerGetQuotaBySubscriptionId,
} from './generated/subscription/subscription';
import { commonSwrConfig } from '../config/swr';

export {
  permissionBillingAccountControllerGrant as updateAccountAccess,
  permissionBillingAccountControllerDelete as removeAccountAccess,
} from './generated/billing-account-access/billing-account-access';

export { privilegeControllerGetPrivileges as getPrivileges } from './generated/privilege/privilege';
export {
  paymentControllerCreatePaymentIntent as createPaymentIntent,
  paymentControllerCreatePaymentSource as createPaymentSource,
} from './generated/payment/payment';

export {
  accountControllerGetAccountByCustomerId as getAccountByCustomerId,
  accountControllerGetMyAccounts as getMyAccounts,
  accountControllerUpdateAccountVat as updateAccountVat,
  accountControllerSearchAccounts as searchAccounts,
  accountControllerGetTrials as getTrials,
  accountControllerUpdateBillingAccountBillingInfo as updateBillingAccountBillingInfo,
  accountControllerGetAccountDetails as getAccountDetails,
  accountControllerUpgradeSubscriptionNew as upgradeSubscriptionNew,
} from './generated/account/account';

export {
  ruleSetControllerCreate as createRuleset,
  ruleSetControllerGetById as getRulesetById,
  ruleSetControllerUpdate as updateRuleset,
  ruleSetControllerDelete as deleteRuleset,
} from './generated/rule-set/rule-set';

export {
  customerControllerUpdate as updateCustomer,
  customerControllerCreate as createCustomer,
  customerControllerCreateForAccount as createCustomerByBillingUser,
  customerControllerDelete as deleteCustomer,
  customerControllerSetReviewMode as setReviewMode,
  customerControllerSetPremiumAddon as setPremiumAddon,
  customerControllerSetWhiteLabeling as setWhiteLabeling,
} from './generated/customer/customer';

export {
  authControllerAuthenticate as loginUser,
  authControllerVerify as verifyUser,
  authControllerLogout as logoutUser,
} from './generated/auth/auth';

export {
  subscriptionControllerCreateSubscriptionBasedOnPrevious as createSubscriptionInChargebee,
  subscriptionControllerAttachSettingsToSubscription as attachSettingsToSubscription,
  entitlementControllerGetEntitlementBySubscriptionId as getEntitlementBySubscriptionId,
  entitlementControllerGetQuotaBySubscriptionId as getQuotaBySubscriptionId,
  estimationControllerEstimateUpgrade as estimateUpgradeNew,
} from './generated/subscription/subscription';

export {
  customerAccessControllerDeletePermission as deleteCompanyPermissions,
  customerAccessControllerSetPermission as createOrUpdateCompanyPermissions,
  customerAccessControllerListCustomerPermissionsByBillingAccount as getCompanyPermissionsByBillingAccount,
} from './generated/customer-access/customer-access';

export {
  settingAccessControllerAddPermission as createOrUpdateSettingPermissions,
  settingAccessControllerRevokePermission as deleteSettingPermissions,
} from './generated/setting-access/setting-access';

export {
  customerLinkageControllerUnlink as unlinkCustomer,
  customerLinkageControllerLink as linkCustomer,
  customerLinkageControllerValidateLink as validateCustomerLink,
  customerLinkageControllerValidateUnlink as validateCustomerUnlink,
} from './generated/customer-linkage/customer-linkage';

export { customerSettingControllerBulkAttachSettingsToCustomer as importUnassignedSettings } from './generated/customer-setting/customer-setting';

export {
  settingControllerEnableReviewMode as enableReviewMode,
  settingControllerDisableReviewMode as disableReviewMode,
  settingControllerCreateSetting as createSetting,
  settingControllerDuplicate as duplicateSetting,
  settingControllerDeleteSetting as deleteSetting,
} from './generated/setting/setting';

export {
  customerSettingControllerMoveSettingToAnotherCompany as moveSettingToCompany,
  customerSettingControllerAttachExistingSettingToCustomer as assignToCompany,
  customerSettingControllerDetachSettingFromCustomer as unassignFromCompany,
} from './generated/customer-setting/customer-setting';

// ------------- Privileges -----------
export const useGetPrivileges: typeof usePrivilegeControllerGetPrivileges = (options) => {
  const swrOptions = options?.swr || {};
  return usePrivilegeControllerGetPrivileges({ swr: { ...swrOptions, ...commonSwrConfig } });
};

// ------------- Account --------------

export const useGetMyAccounts: typeof useAccountControllerGetMyAccounts = (options) => {
  const swrOptions = options?.swr || {};
  return useAccountControllerGetMyAccounts({ swr: { ...swrOptions, ...commonSwrConfig } });
};

export const useGetAccountByCustomerId: typeof useAccountControllerGetAccountByCustomerId = (
  customerId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useAccountControllerGetAccountByCustomerId(customerId, params, { swr: { ...swrOptions, ...commonSwrConfig } });
};

export const useGetTrials: typeof useAccountControllerGetTrials = (params, options) => {
  const swrOptions = options?.swr || {};
  return useAccountControllerGetTrials(params, { swr: { ...swrOptions, ...commonSwrConfig } });
};

export const useGetAccount: typeof useAccountControllerGetAccountDetails = (billingCustomerId, options) => {
  const swrOptions = options?.swr || {};
  return useAccountControllerGetAccountDetails(billingCustomerId, { swr: { ...swrOptions, ...commonSwrConfig } });
};

export const useSearchAccounts: typeof useAccountControllerSearchAccounts = (params, options) => {
  const swrOptions = options?.swr || {};
  return useAccountControllerSearchAccounts(params, { swr: { ...swrOptions, ...commonSwrConfig } });
};

// ---------- Rule Set -----------

export const useGetRulesetById: typeof useRuleSetControllerGetById = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useRuleSetControllerGetById(id, params, { swr: { ...swrOptions, ...commonSwrConfig } });
};

// ---------- Customer -------------

export const useGetCustomerRuleSets: typeof useCustomerControllerGetCustomerRuleSets = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useCustomerControllerGetCustomerRuleSets(id, params, { swr: { ...swrOptions, ...commonSwrConfig } });
};

export const useGetCustomerBaseInfo: typeof useCustomerControllerGetBaseInfo = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useCustomerControllerGetBaseInfo(id, params, { swr: { ...swrOptions, ...commonSwrConfig } });
};

export const useGetCustomer: typeof useCustomerControllerGet = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useCustomerControllerGet(id, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

export const useGetConfigurationsToExport: typeof useCustomerControllerGetSettingsToExport = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useCustomerControllerGetSettingsToExport(id, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// --------- Subscription -----------
export const useSubscriptionTiers: typeof useSubscriptionTierControllerGetSubscriptionTiers = (
  subscriptionId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useSubscriptionTierControllerGetSubscriptionTiers(subscriptionId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

export const useSubscriptionQuota: typeof useEntitlementControllerGetQuotaBySubscriptionId = (
  subscriptionId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useEntitlementControllerGetQuotaBySubscriptionId(subscriptionId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

export const useRecommendedPricePlan: typeof useRecommendedPricePlanControllerGetRecommendedPlan = (
  subscriptionId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useRecommendedPricePlanControllerGetRecommendedPlan(subscriptionId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// ------- Customer Access ----------
export const useGetPermission: typeof useCustomerAccessControllerGetPermissions = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useCustomerAccessControllerGetPermissions(id, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// -------- Setting Access  -----------
export const useGetSettingPermissions: typeof useSettingAccessControllerGetAllPermissions = (id, params, options) => {
  const swrOptions = options?.swr || {};
  return useSettingAccessControllerGetAllPermissions(id, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// -------- Setting --------------
export const useSearchUnassignedSettings: typeof useSettingControllerSearchUnassignedSettings = (params, options) => {
  const swrOptions = options?.swr || {};
  return useSettingControllerSearchUnassignedSettings(params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

export const useSearchUnassignedSettingsAll: typeof useSettingControllerSearchUnassignedSettingsAll = (
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useSettingControllerSearchUnassignedSettingsAll(params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

export const useSearchSettingsBy: typeof useSettingControllerSearchSettings = (params, options) => {
  const swrOptions = options?.swr || {};
  return useSettingControllerSearchSettings(params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// ----------- Customer Setting -----------
export const useGetAvailableConfigurationsTypes: typeof useCustomerSettingControllerGetAvailableTypes = (
  customerId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useCustomerSettingControllerGetAvailableTypes(customerId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// ------------ Consumption ----------
export const useSubscriptionConsumption: typeof useConsumptionControllerGetSubscriptionConsumption = (
  subscriptionId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useConsumptionControllerGetSubscriptionConsumption(subscriptionId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

export const useAccountConsumption: typeof useConsumptionControllerGetAccountConsumption = (
  accountId,
  params,
  options,
) => {
  const swrOptions = options?.swr || {};
  return useConsumptionControllerGetAccountConsumption(accountId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};

// --------------- Account Access ---------------
export const useAccountAccess: typeof usePermissionBillingAccountControllerList = (accountId, params, options) => {
  const swrOptions = options?.swr || {};
  return usePermissionBillingAccountControllerList(accountId, params, {
    swr: { ...swrOptions, ...commonSwrConfig },
  });
};
