import { ExclamationCircleFilled } from '@ant-design/icons';
import { Row, Space, Typography } from 'antd';

import { Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

const { Paragraph } = Typography;

export const ErrorState = () => {
  return (
    <Row justify="center" align="middle" style={{ height: '80vh' }}>
      <Space align="center" direction="vertical">
        <ExclamationCircleFilled color={colors.yellow[100]} style={{ fontSize: '24px' }} />
        <Title align="center" level={2}>
          Sorry, this is taking
          <br /> longer than expected!
        </Title>
        <Paragraph>Please come back later</Paragraph>
      </Space>
    </Row>
  );
};
