import { Flex } from 'antd';
import styled from 'styled-components';

import { StepModal } from 'components/Modal';

export const Modal = styled(StepModal)`
  & .ant-modal-header {
    margin-bottom: 16px;
    background: transparent;
    padding: 0;
  }

  & .ant-modal-content {
    padding: 32px;

    & .ant-modal-close {
      top: 32px;
      inset-inline-end: 32px;

      & svg path {
        fill: ${({ theme }) => theme.colors.primaryText};
      }
    }

    & .ant-modal-body {
      & > div {
        padding: 0;
      }

      padding: 0;
    }
  }

  & .ant-modal-footer {
    margin-top: 32px;
    padding: 0;
  }
`;

export const HeaderWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.blue[8]};
  padding: 40px;
  border-radius: 8px;
`;

export const IconWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.blue[4]};
  padding: 8px;
  border-radius: 4px;
`;
