import {
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
} from 'api/requests/generated/generated.schemas';

export interface ITrialDetailsForm {
  companyName: string;
  domain: string;
  framework: string;
}

export interface ITrialCustomisationForm {
  firstLayerVariant: CreateSettingBodyFirstLayerVariant;
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant;
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

export enum Breadcrumbs {
  WELCOME = 'Welcome',
  CONSENT_CUSTOMIZATION = 'Consent Customization',
  IMPLEMENTATION = 'Implementation',
}
