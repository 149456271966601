import { Card, Flex, Segmented, Space } from 'antd';
import { useState } from 'react';

import { RuleSetOutDto } from 'api/requests/generated/generated.schemas';
import { CopyCode } from 'components/CopyCode';
import { Open } from 'components/Icons';
import { Link, Text } from 'components/Typography';
import ISetting from 'interfaces/ISetting';
import {
  getBrowserScriptTagV2,
  getBrowserScriptTagV3,
  SCRIPT_TAG_V2_DOCUMENTATION_LINK,
  SCRIPT_TAG_V3_HOW_TO_MIGRATE_LINK,
} from 'lib/consts';
import { colors } from 'lib/theme/colors';

import { CodeIconWrapper } from './styled';

interface IScriptTagProps {
  settings: ISetting[];
  ruleset: RuleSetOutDto;
}

type ScriptVersion = 'v2' | 'v3';

export const ScriptTag = ({ ruleset, settings }: IScriptTagProps) => {
  const [scriptVersion, setScriptVersion] = useState<ScriptVersion>('v2');

  const generateImplementationScript = (version: ScriptVersion) => {
    const { ruleSetId } = ruleset;
    const tcf2Enabled = settings.find((setting) => setting.id === ruleset.defaultRule.settingsId)?.tcf2Enabled || false;

    return version === 'v2'
      ? getBrowserScriptTagV2(ruleSetId, tcf2Enabled)
      : getBrowserScriptTagV3(ruleSetId, tcf2Enabled);
  };

  const script = generateImplementationScript(scriptVersion);

  return (
    <Card>
      <Flex vertical gap={16}>
        <Segmented<ScriptVersion>
          style={{ backgroundColor: colors.blue['8'], alignSelf: 'flex-start' }}
          size="large"
          options={[
            {
              label: (
                <Text
                  size="normal"
                  data-testid="script-tag-v2:tab"
                  style={{ color: scriptVersion === 'v2' ? colors.primary : 'inherit' }}
                >
                  V2 Script Tag
                </Text>
              ),
              value: 'v2',
            },
            {
              label: (
                <Text
                  size="normal"
                  data-testid="script-tag-v3:tab"
                  style={{ color: scriptVersion === 'v3' ? colors.primary : 'inherit' }}
                >
                  V3 Script Tag
                </Text>
              ),
              value: 'v3',
            },
          ]}
          onChange={(value) => setScriptVersion(value)}
        />
        <CopyCode
          dataTestId="copy-tag:link[scriptTag]"
          prefix={<CodeIconWrapper />}
          copyText={script}
          successMessage="Script Tag copied to clipboard."
        />
        <Space>
          <Link data-testid="script-tag-v2:documentation-link" target="_blank" href={SCRIPT_TAG_V2_DOCUMENTATION_LINK}>
            <Open color={colors.primary} /> Documentation
          </Link>
          {scriptVersion === 'v3' && (
            <Link data-testid="script-tag-v3:migration-link" target="_blank" href={SCRIPT_TAG_V3_HOW_TO_MIGRATE_LINK}>
              <Open color={colors.primary} /> How to migrate to V3
            </Link>
          )}
        </Space>
      </Flex>
    </Card>
  );
};
