import { Radio, Flex, Form } from 'antd';

import { BannerCenter, BannerSide } from 'components/Icons';
import { Text } from 'components/Typography';
import { SecondLayerVariant } from 'lib/contexts/Onboarding';

import { BannerWrapper, StyledRadio } from './styled';

const { Item } = Form;

const secondLayerOptions = [
  {
    title: 'Privacy Settings',
    note: 'Center Placement',
    value: SecondLayerVariant.CENTER,
    key: SecondLayerVariant.CENTER,
    banner: (
      <BannerWrapper position="center">
        <BannerCenter />
      </BannerWrapper>
    ),
  },
  {
    title: 'Privacy Settings',
    value: SecondLayerVariant.SIDE,
    key: SecondLayerVariant.SIDE,
    note: 'Side Placement',
    banner: (
      <BannerWrapper position="left">
        <BannerSide />
      </BannerWrapper>
    ),
  },
];

const SecondLayer = () => {
  return (
    <Item name="secondLayerVariant" noStyle>
      <Radio.Group>
        <Flex vertical gap={8}>
          {secondLayerOptions.map(({ banner, key, note, title, value }) => (
            <StyledRadio value={value} key={key}>
              {banner}
              <Flex vertical>
                <Text weight={500}>{title}</Text>
                <Text weight={400} size="sm" type="secondary">
                  {note}
                </Text>
              </Flex>
            </StyledRadio>
          ))}
        </Flex>
      </Radio.Group>
    </Item>
  );
};

export default SecondLayer;
