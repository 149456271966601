import React, { createContext, useState } from 'react';

const EMPTY_ACCOUNT = {
  name: '',
  id: '',
};

export const SelectedAccountContext = createContext<{
  id: string;
  name: string;
  setSelectedAccount: (account: { name: string; id: string }) => void;
}>({
  ...EMPTY_ACCOUNT,
  setSelectedAccount: () => undefined,
});

const SelectedAccountProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [selectedAccount, setSelectedAccount] = useState(EMPTY_ACCOUNT);
  return (
    <SelectedAccountContext.Provider value={{ ...selectedAccount, setSelectedAccount }}>
      {children}
    </SelectedAccountContext.Provider>
  );
};

export default SelectedAccountProvider;
