import { Alert } from 'antd';

import { createOrUpdateSettingPermissions, deleteSettingPermissions, useGetSettingPermissions } from 'api/requests';
import { CustomerBaseInfoOutDto, Permission, SetPermissionInDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import Permissions from 'components/Permissions';
import { ISettingDetailsWithPermissions } from 'interfaces/ISetting';
import { useMessage, usePrivileges } from 'lib/hooks';

interface IEditSettingPermissionsProps {
  onClose: () => void;
  company?: CustomerBaseInfoOutDto;
  open: boolean;
  setting: ISettingDetailsWithPermissions | null;
}

const EditSettingPermissions = ({ setting, open, company, onClose }: IEditSettingPermissionsProps) => {
  const { isCSA } = usePrivileges();
  const { data: fetchedPermissions, mutate } = useGetSettingPermissions(setting?.id || '');
  const message = useMessage();

  const permissions = fetchedPermissions?.body || [];

  const onRemove = async (login: string) => {
    if (setting) {
      await deleteSettingPermissions(setting.id, { login });
      mutate();
    }
  };

  const onUpdate = async (login: string, newPermission: Permission) => {
    if (setting) {
      await createOrUpdateSettingPermissions(setting.id, {
        permission: { login, permission: newPermission, notify: false },
      });
      mutate();
    }
  };

  const onAdd = async (data: SetPermissionInDto) => {
    const isAdminPermissionExist = permissions.some(
      ({ inherited, login, permission }) =>
        inherited && permission === 'ADMIN' && login.toLowerCase() === data.login.toLowerCase(),
    );

    if (isAdminPermissionExist) {
      message.error('You can not over-write Configuration Access for a Company Admin');
      return;
    }

    if (setting) {
      await createOrUpdateSettingPermissions(setting.id, { permission: data });
      mutate();
    }

    const user = permissions.find((p) => p.login === data?.login);

    if (user) {
      message.success(`Changed to Configuration Access for [${user.login}]`);
    } else {
      message.success('User successfully added');
    }
  };

  return (
    <Modal
      open={open}
      title={`Manage Access [${setting?.aliasName ? `${setting?.aliasName} | ` : ''}${setting?.id}]`}
      width={760}
      onClose={onClose}
      bodyHeight="600px"
      data-testid="modal:manage-permissions"
      hideFooterBorder
    >
      {isCSA && (
        <Alert
          message="You are viewing this as a support role user"
          style={{ margin: '-40px -40px 40px -40px' }}
          type="info"
          showIcon
        />
      )}
      <Permissions
        onAdd={onAdd}
        hasCompanyReviewModeEnabled={company?.reviewMode}
        onRemove={onRemove}
        onUpdate={onUpdate}
        isSettingPermissions
        isCSA={isCSA}
        permissions={permissions}
        setting={setting}
      />
    </Modal>
  );
};

export default EditSettingPermissions;
