import { Col, Flex, Typography } from 'antd';

import Button from 'components/Button';

import { Placeholder } from './styled';

const { Title, Paragraph } = Typography;

interface IEmptyListPlaceholder {
  title: string;
  description: string | React.ReactElement;
  image: string;
  button?: {
    text: string;
    onClick: () => void;
  } | null;
}

const EmptyListPlaceholder = ({ title, description, image, button }: IEmptyListPlaceholder) => {
  return (
    <Placeholder gutter={[32, 0]}>
      <Col span={12}>
        <Flex vertical gap={24}>
          <div>
            <Title level={2} data-testid="empty-placeholder:title">
              {title}
            </Title>
            <Paragraph type="secondary" style={{ whiteSpace: 'pre-wrap' }} data-testid="empty-placeholder:description">
              {description}
            </Paragraph>
          </div>
          {button && (
            <Flex>
              <Button onClick={button.onClick} type="primary" size="large" data-testid="empty-placeholder:button">
                {button.text}
              </Button>
            </Flex>
          )}
        </Flex>
      </Col>
      <Col span={12}>
        <img src={image} alt="Placeholder" />
      </Col>
    </Placeholder>
  );
};

export default EmptyListPlaceholder;
