import { CountryIsoCodeDictionary, EUCountries, StateIsoCodeDictionary } from '@usercentrics/i18n-iso3166-2';
import { BaseOptionType, DefaultOptionType, SelectProps, SelectValue } from 'antd/lib/select';

import { StyledSelect } from './Select';
import Tag from '../Tag';

type ISOLocation = [code: string, name: string];

enum RegionGroups {
  Regions = 'Regions',
  Countries = 'Countries',
  States = 'States',
}

/**
 * Make sure the value is the same as label if we use JSX code for label
 * It uses the value instead
 */
enum Regions {
  EUROPEAN_UNION = 'European Union',
}

const euCountries = Object.values(EUCountries);

const regionOptions = [
  {
    value: Regions.EUROPEAN_UNION,
    label: (
      <span>
        European Union <Tag color="blue">{euCountries.length} countries</Tag>
      </span>
    ),
    countries: euCountries,
  },
];

const countryOptions = Object.entries(CountryIsoCodeDictionary).map(([code, name]: ISOLocation) => ({
  value: code,
  label: name,
}));

const stateOptions = Object.entries(StateIsoCodeDictionary).map(([code, name]: ISOLocation) => ({
  value: code,
  label: name,
}));

/* The component accept custom property 'codesToExclude' : (['DE', 'FR']) */
interface ISOCountriesSelectProps extends SelectProps<SelectValue | unknown, DefaultOptionType | BaseOptionType> {
  codesToExclude?: string[];
  value?: string[];
}

const isCountry = (code: string) => code.length === 2;
const stateToCountry = (code: string) => code.slice(0, 2);
const unique = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

export const ISOCountriesSelect = ({ codesToExclude = [], onChange, value, ...props }: ISOCountriesSelectProps) => {
  const { countries: excludedCountryCodes, states: excludedStateCodes } = codesToExclude.reduce(
    (acc, cur: string) => {
      return isCountry(cur)
        ? { countries: [...acc.countries, cur], states: acc.states }
        : { countries: acc.countries, states: [...acc.states, cur] };
    },
    { countries: [] as string[], states: [] as string[] },
  );
  const countriesExcludedByStates = excludedStateCodes.map(stateToCountry).filter(unique);
  const statesExcludedByCountries = stateOptions
    .map((state) => state.value)
    .filter((state) => excludedCountryCodes.includes(stateToCountry(state)));

  const excludedCountries = [...excludedCountryCodes, ...countriesExcludedByStates];
  const excludedStates = [...excludedStateCodes, ...statesExcludedByCountries];

  const isCountriesChosenFrom = (values: string[], list: string[]): boolean => {
    return list.every((code) => values.includes(code) || excludedCountries.includes(code));
  };

  const options: BaseOptionType[] = [
    {
      label: RegionGroups.Regions,
      options: regionOptions.map((regionOption) => ({
        ...regionOption,
        disabled: isCountriesChosenFrom(value || [], euCountries),
      })),
    },
    {
      label: RegionGroups.Countries,
      options: countryOptions.map((countryOption) => ({
        ...countryOption,
        disabled: excludedCountries.includes(countryOption.value),
      })),
    },
    {
      label: RegionGroups.States,
      options: stateOptions.map((stateOption) => {
        return { ...stateOption, disabled: excludedStates.includes(stateOption.value) };
      }),
    },
  ];

  const getSelectedOptions = (selectedValues: string[]) => {
    return options
      .flatMap((currentOption) => currentOption.options)
      .filter((option) => selectedValues.includes(option.code));
  };

  return (
    <StyledSelect
      allowClear
      showSearch
      data-testid="select:regions"
      mode="multiple"
      filterOption={(input, option) => {
        if (typeof option?.label === 'string') {
          return option?.label?.toLowerCase().includes(input.toLowerCase());
        } else {
          return option?.value?.toLowerCase().includes(input.toLowerCase());
        }
      }}
      placeholder="Select Region(s)"
      options={options}
      onChange={(selectedValues, selectedOptions) => {
        if ((selectedValues as string[]).includes(Regions.EUROPEAN_UNION) && onChange) {
          const europeanUnionOption = selectedOptions.find(
            (option: DefaultOptionType) => option.value === Regions.EUROPEAN_UNION,
          );
          const newValue = Array.from(new Set([...(value || []), ...europeanUnionOption.countries]).values()).filter(
            (code) => !excludedCountries.includes(code),
          );
          onChange(newValue, getSelectedOptions(newValue));
        } else if (onChange) {
          onChange(selectedValues, selectedOptions);
        }
      }}
      value={value}
      maxTagCount={20}
      {...props}
    />
  );
};
