import React, { createContext, useState } from 'react';

import { DiscountDto, TrialSubscriptionOutDto } from 'api/requests/generated/generated.schemas';
import { OnboardingType } from 'interfaces/enums';

export interface IUpgradeSubscription extends TrialSubscriptionOutDto {
  accountName: string;
  billingCustomerId: string;
  vatNumber: string;
  country: string;
  coupon?: DiscountDto;
}

type Exceeded = {
  recommendedTier: number;
  amountOfMonths: number;
  averageUsage: number;
  forcedUpgrade?: boolean; // Indicates if user willingly opened upgrade popup or we show it to them because of the overuse
};

type ContextType = {
  subscription: IUpgradeSubscription | null;
  setPlanToUpgrade: (subscription: IUpgradeSubscription | null, exceeded?: Exceeded) => void;
  exceeded: Exceeded | null;
  onboardingType: OnboardingType;
};

export const UpgradePlanContext = createContext<ContextType>({
  subscription: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPlanToUpgrade: () => {},
  exceeded: null,
  onboardingType: OnboardingType.REGULAR,
});

const UpgradePlanProvider = ({
  children,
  onboardingType,
}: {
  children: React.ReactNode | React.ReactNode[];
  onboardingType: OnboardingType;
}) => {
  const [subscription, setSubscription] = useState<IUpgradeSubscription | null>(null);
  const [exceeded, setExceeded] = useState<Exceeded | null>(null);

  const setPlanToUpgrade = (s: IUpgradeSubscription | null, exc?: Exceeded) => {
    setSubscription(s);
    setExceeded(exc || null);
  };

  return (
    <UpgradePlanContext.Provider value={{ subscription, exceeded, setPlanToUpgrade, onboardingType }}>
      {children}
    </UpgradePlanContext.Provider>
  );
};

export default UpgradePlanProvider;
