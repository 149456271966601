import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Flex, List, Space } from 'antd';

import Tag from 'components/Tag';
import { Link, Text } from 'components/Typography';

interface Props {
  domain: string;
  onAdd: (domain: string) => void;
  isAdded: boolean;
}

export const AddDomainListItem = ({ domain, isAdded, onAdd }: Props) => {
  return (
    <List.Item>
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Text>{domain}</Text>
        {isAdded && (
          <Tag bordered size="normal" color="green">
            <Space size="small">
              <CheckCircleFilled />
              Added
            </Space>
          </Tag>
        )}
        {!isAdded && (
          <Link onClick={() => onAdd(domain)}>
            <PlusOutlined></PlusOutlined> Add to this configuration
          </Link>
        )}
      </Flex>
    </List.Item>
  );
};
