import { reset } from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';
import TagManager from 'react-gtm-module';

import { TrackingEvents } from 'interfaces/enums';
import { trackEvent } from 'lib/helpers/amplitude';

export const useLogout = (redirectToLogout = false) => {
  const { logout } = useAuth0();

  return async () => {
    reset();

    TagManager.dataLayer({
      dataLayer: {
        event: TrackingEvents.LOGOUT,
      },
    });
    trackEvent(TrackingEvents.LOGOUT);

    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}${redirectToLogout ? '/logout' : '/login'}`,
      },
    });
  };
};
