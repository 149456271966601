/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib';
import { useEffect } from 'react';

interface IMoveConfigurationConfirm {
  onCheckboxChange?: (isChecked: boolean) => void;
  action?: 'move' | 'unassign';
  form: FormInstance;
}

const initialValues = {
  copyWithPermissions: false,
};

const MoveOrUnassignConfigurationConfirm = ({ onCheckboxChange, action = 'move', form }: IMoveConfigurationConfirm) => {
  return (
    <Form form={form} name="move-confirm" initialValues={initialValues}>
      <Row gutter={[0, 15]}>
        <Col>
          Please confirm that you want to {action === 'move' ? 'move' : 'unassign'} the configuration. If you want to
          copy all existing users with their current permissions, please activate the checkbox below.
        </Col>
        <Col>
          <Form.Item name="copyWithPermissions" valuePropName="checked" noStyle>
            <Checkbox>Copy user permissions</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MoveOrUnassignConfigurationConfirm;
