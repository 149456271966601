import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { PaymentIntent } from '@chargebee/chargebee-js-types';
import { FormInstance, Typography, Flex } from 'antd';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
  createPaymentIntent,
  createPaymentSource,
  createSubscriptionInChargebee,
  estimateUpgradeNew,
} from 'api/requests';
import { EstimationOutDto, SubscriptionPricePlan } from 'api/requests/generated/generated.schemas';
import CardPayment from 'components/CardPayment';
import { Card } from 'components/Icons';
import { Text, Title } from 'components/Typography';
import VatInput from 'components/VatInput';
import { Currency } from 'interfaces/enums';
import { COUNTRIES_WITH_MANDATORY_VAT_ID } from 'lib/consts';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { getPlanNameFromPackage } from 'lib/helpers';
import useMessage from 'lib/hooks/useMessage';

import { Container, PayMethod } from './styled';

const { Paragraph } = Typography;

interface IPaymentDetails {
  subscription: IUpgradeSubscription;
  initialEstimation?: EstimationOutDto;
  scriptStatus: { isInited: boolean } | null;
  nameForm: FormInstance;
  expiredTrial: boolean;
  isAvailable: boolean;
  setIsPaymentDisabled: (value: boolean) => void;
}

export interface RefType {
  onConfirmPayment: () => Promise<unknown | null | undefined>;
}

const PaymentDetailsForm = (
  {
    subscription,
    initialEstimation,
    setIsPaymentDisabled,
    nameForm,
    expiredTrial,
    isAvailable,
    scriptStatus,
  }: IPaymentDetails,
  ref: Ref<RefType>,
) => {
  const message = useMessage();
  const cardRef = useRef<ChargebeeComponents>(null);
  const [vatError, setVatError] = useState<string | null>(null);
  const [isCardValid, setIsCardValid] = useState(false);
  const [estimation, setEstimation] = useState<EstimationOutDto>();
  const [isVatExcluded, setVatExcluded] = useState(true);

  const currencyCode = (estimation?.currencyCode || 'EUR') as keyof typeof Currency;

  const total = estimation?.perBillingCycle?.total || 0;

  const isVatNumberMandatory = COUNTRIES_WITH_MANDATORY_VAT_ID.includes(subscription.country);

  useImperativeHandle(ref, () => ({
    async onConfirmPayment() {
      try {
        if (cardRef.current && estimation) {
          const { body: paymentIntent } = await createPaymentIntent(subscription.billingCustomerId, {
            amount: total,
            currencyCode,
          });

          const { id: paymentIntentId } = await cardRef.current.authorizeWith3ds(
            paymentIntent as unknown as PaymentIntent,
            { cardBillingAddress: { firstName: nameForm.getFieldValue('name') } },
            { error: () => message.error('Unfortunately your request could not be processed. Please try again.') },
          );

          if (expiredTrial) {
            return createSubscriptionInChargebee(subscription.billingCustomerId, subscription.id, { paymentIntentId });
          }

          return createPaymentSource(subscription.billingCustomerId, { paymentIntentId });
        }
        return null;
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    },
  }));

  useEffect(() => {
    if (isVatNumberMandatory) {
      setVatExcluded(true);
    } else {
      setVatExcluded(estimation?.perMonth?.total === estimation?.perMonth?.taxableAmount);
    }
  }, [estimation]);

  useEffect(() => {
    (async () => {
      if (isVatNumberMandatory && !subscription.vatNumber) {
        setVatError('VAT ID required');
      }

      if (!initialEstimation && isAvailable) {
        await reestimate();
      } else {
        setEstimation(initialEstimation);
      }
    })();
  }, [isAvailable]);

  useEffect(() => {
    setIsPaymentDisabled(Boolean(vatError) || !isCardValid);
  }, [vatError, isCardValid]);

  const reestimate = async () => {
    const updatedEstimation = (
      await estimateUpgradeNew(subscription.id, {
        pricePlan: subscription.pricePlan as SubscriptionPricePlan,
        quantity: (subscription.maxValue || subscription.quantity) as number,
      })
    ).body;

    setEstimation(updatedEstimation);
  };

  if (!scriptStatus?.isInited) {
    return null;
  }

  const onVatNumberInputChange = () => {
    setVatError('VAT ID requires validation');
  };

  const onVatNumberValidationChanged = async (isValid: boolean) => {
    if (isValid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setVatError(null);
      await reestimate();
    } else {
      setVatError('VAT ID is not valid');
    }
  };

  const getTotalPostfix = () => {
    return isVatExcluded ? '' : ' (incl. VAT)';
  };

  return (
    <Container isAvailable={isAvailable}>
      <Flex justify="space-between">
        <Text size="sm">Payment Overview</Text>
        <Text size="sm">Total{getTotalPostfix()}</Text>
      </Flex>
      <Flex justify="space-between" align="center">
        <Title level={3}>{getPlanNameFromPackage(estimation?.package as string)}</Title>
        <Title level={3} align="right" data-testid="div:total-amount" $noMargin>
          {Currency[currencyCode]}
          {total}
        </Title>
      </Flex>
      <PayMethod>
        <Card /> Pay by Credit Card
      </PayMethod>
      <CardPayment nameForm={nameForm} cardRef={cardRef} onError={(isError) => setIsCardValid(!isError)} />
      <Flex style={{ paddingTop: 20 }}>
        <VatInput
          country={subscription.country}
          accountId={subscription.billingCustomerId}
          initialVatNumber={subscription.vatNumber}
          onChange={onVatNumberInputChange}
          onIsValidChanged={onVatNumberValidationChanged}
        />
      </Flex>
      <br />
      <Paragraph>
        By confirming your payment, you agree that Usercentrics GmbH may charge your card for this and future payments
        in accordance with its terms.
      </Paragraph>
    </Container>
  );
};

export default forwardRef(PaymentDetailsForm);
