import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="16px" viewBox="0 0 24 16">
    <rect id="Rectangle" fill="#1F72CD" fillRule="nonzero" x="0" y="0" width="24" height="16" rx="4" />
    <path
      d="M4.920752,5.6 L2.8,10.43116 L5.338832,10.43116 L5.653552,9.66088 L6.372992,9.66088 L6.687712,10.43116 L9.482272,10.43116 L9.482272,9.84328 L9.731272,10.43116 L11.176832,10.43116 L11.425832,9.83084 L11.425832,10.43116 L17.237712,10.43116 L17.944392,9.68088 L18.606112,10.43116 L21.591232,10.4374 L19.463792,8.02908 L21.591232,5.6 L18.652432,5.6 L17.964472,6.33644 L17.323592,5.6 L11.001072,5.6 L10.458152,6.84696 L9.902472,5.6 L7.368952,5.6 L7.368952,6.16792 L7.087112,5.6 L4.920752,5.6 Z M5.411992,6.28604 L6.649512,6.28604 L8.056232,9.56208 L8.056232,6.28604 L9.411912,6.28604 L10.498392,8.63496 L11.499752,6.28604 L12.848632,6.28604 L12.848632,9.75272 L12.027872,9.75272 L12.021152,7.03624 L10.824552,9.75272 L10.090312,9.75272 L8.886992,7.03624 L8.886992,9.75272 L7.198472,9.75272 L6.878352,8.97552 L5.148912,8.97552 L4.829472,9.75204 L3.924776,9.75204 L5.411992,6.28604 Z M16.937032,6.28604 L13.599552,6.28604 L13.599552,9.75068 L16.885352,9.75068 L17.944392,8.60244 L18.965152,9.75068 L20.032232,9.75068 L18.481272,8.0284 L20.032232,6.28604 L19.011472,6.28604 L17.957792,7.42108 L16.937032,6.28604 Z M6.013992,6.8726 L5.444232,8.25708 L6.583152,8.25708 L6.013992,6.8726 Z M14.423752,7.63664 L14.423752,7.0038 L14.423752,7.0032 L16.506192,7.0032 L17.414872,8.01528 L16.465952,9.03288 L14.423752,9.03288 L14.423752,8.342 L16.244472,8.342 L16.244472,7.63664 L14.423752,7.63664 Z"
      id="Shape"
      fill="#FFFFFF"
    />
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
