import styled from 'styled-components';

import Card from 'components/Card';

export const StyledCard = styled(Card)<{ $isSelected: boolean }>`
  width: 280px;
  min-width: 280px;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.gray[50]};

  ${(props) =>
    props.$isSelected && `border: 2px solid ${props.theme.colors.primary}; background: ${props.theme.colors.blue[4]}`}
`;
