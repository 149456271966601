import { Select } from 'antd';
import styled from 'styled-components';

type TItemSize = 'sm' | 'md';

export const StyledSelect = styled(Select)<{ $itemSize?: TItemSize }>`
  & .ant-select-selection-item {
    font-size: ${({ $itemSize }) => ($itemSize === 'sm' ? '0.75rem' : '0.875rem')};
  }

  & .ant-select-selection-placeholder {
    font-size: 14px;
    font-weight: 300;
  }

  & .ant-select-selection-overflow-item {
    width: auto;
  }
`;

export const Option = styled(Select.Option)`
  & .ant-select-item-option-selected {
    font-weight: 600;
  }
`;
