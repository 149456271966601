import { App, ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';

import { theme } from 'lib/theme';
import { colors } from 'lib/theme/colors';

export default ({ children }: React.PropsWithChildren) => {
  return (
    <ConfigProvider theme={theme}>
      {/* Let's keep old theme for now. We'll get rid of oldTheme stuff as we introduce Antd components. */}
      <ThemeProvider theme={{ ...theme, colors }}>
        <App>{children}</App>
      </ThemeProvider>
    </ConfigProvider>
  );
};
