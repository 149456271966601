import { Form } from 'antd';
import { useMemo, useState } from 'react';

import { RuleSetInDto, RuleSetOutDto, SubscriptionType } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import ISetting from 'interfaces/ISetting';

import GlobalSetting from './Content/GlobalSetting';

const { useForm } = Form;

interface IGlobalSettingsProps {
  onClose: () => Promise<void>;
  onUpdate: (rulesetId: string, rulesetList: RuleSetInDto) => Promise<void>;
  configurations: ISetting[];
  ruleset: RuleSetOutDto;
}

type FormData = {
  settingsId: string;
  noShow: boolean;
};

const EditGlobalSettings = ({ onClose, onUpdate, configurations, ruleset }: IGlobalSettingsProps) => {
  const [globalSettingForm] = useForm<FormData>();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    settingsId: ruleset.defaultRule.settingsId,
    noShow: ruleset.defaultRule.noShow,
  };

  const onPrimaryClick = async () => {
    try {
      setIsLoading(true);

      const values = globalSettingForm.getFieldsValue();
      //
      await onUpdate(ruleset.ruleSetId, {
        ...ruleset,
        defaultRule: values,
      });
      await onClose();
    } catch (e) {
      console.log('Error accrued', e);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredConfigurations = useMemo(() => {
    const hasRegionalRules = Boolean(ruleset.rules.length);
    if (!hasRegionalRules) {
      return configurations;
    }
    const tcf2Enabled = configurations.find(
      (configuration) => configuration.id === ruleset.defaultRule.settingsId,
    )?.tcf2Enabled;
    const configurationsToExclude = [
      ...ruleset.rules.map(({ settingsId }) => settingsId),
      ...configurations
        .filter((configuration) => {
          if (configuration.subscription?.type === SubscriptionType.app) {
            return false;
          }

          return tcf2Enabled ? !configuration.tcf2Enabled : configuration.tcf2Enabled;
        })
        .map(({ id }) => id),
    ];
    return configurations.filter((configuration) => !configurationsToExclude.includes(configuration.id));
  }, [configurations, ruleset]);

  return (
    <Modal
      open
      title="Edit Ruleset"
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      primaryButton={{
        label: 'Save Changes',
        onClick: onPrimaryClick,
        loading: isLoading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
    >
      <GlobalSetting configurations={filteredConfigurations} form={globalSettingForm} initialValues={initialValues} />
    </Modal>
  );
};

export default EditGlobalSettings;
