import { SubscriptionPricePlan } from 'api/requests/generated/generated.schemas';

export const getPlanDetailsById = (planId: string) => {
  switch (planId) {
    case SubscriptionPricePlan.EssentialExtended:
    case SubscriptionPricePlan.EssentialSimplified:
      return {
        description: 'Simplify your privacy compliance with essential tools',
      };

    case SubscriptionPricePlan.PlusExtended:
      return {
        description: 'Easily manage privacy compliance while growing your online presence',
      };

    case SubscriptionPricePlan.ProExtended:
    case SubscriptionPricePlan.ProSimplified:
      return {
        description: 'Robust consent management solutions designed for small teams',
      };

    case SubscriptionPricePlan.BusinessExtended:
    case SubscriptionPricePlan.BusinessSimplified:
      return {
        description: 'Optimize for scaling operations, and data-driven decisions',
      };

    default:
      return {
        description: '',
      };
  }
};
