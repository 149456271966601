import Icon from '@ant-design/icons';

import { IconProps } from 'types';

type Color = 'blue' | string;

const CustomIcon = ({ color }: { color?: Color }) => (
  <svg
    width="20px"
    height="16px"
    viewBox="0 0 20 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Platform Indicator</title>
    <defs>
      <path
        d="M18,0 L2,0 C0.9,0 0.01,0.9 0.01,2 L0,14 C0,15.1 0.9,16 2,16 L18,16 C19.1,16 20,15.1 20,14 L20,2 C20,0.9 19.1,0 18,0 Z M2,5 L12.5,5 L12.5,8.5 L2,8.5 L2,5 Z M2,10.5 L12.5,10.5 L12.5,14 L3,14 C2.45,14 2,13.55 2,13 L2,10.5 Z M17,14 L14.5,14 L14.5,5 L18,5 L18,13 C18,13.55 17.55,14 17,14 Z"
        id="path-16"
      />
    </defs>
    <g id="✅-[DPSD-XXXX]-Subscription-Info" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="✅-Examples-Subscription-Status" transform="translate(-310.000000, -626.000000)">
        <g id="Subscription-Copy-4" transform="translate(272.000000, 538.000000)">
          <g id="Subscription" transform="translate(20.000000, 60.000000)">
            <g id="Platform-Indicator" transform="translate(18.000000, 28.000000)">
              <mask id="mask-24" fill="white">
                <use xlinkHref="#path-16" />
              </mask>
              <use id="Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-16" />
              <g id="↳-Color" mask="url(#mask-24)" fill={color === 'blue' ? '#0045A5' : '#303030'}>
                <g transform="translate(-2.000000, -4.000000)" id="Rectangle">
                  <rect x="0" y="0" width="24" height="24" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
