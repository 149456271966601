import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>manage_search_black_24dp</title>
    <g id="✅-[DPSD-1671]-No-results" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="✅-[DPSD-1671]-No-results-CSA-D" transform="translate(-700.000000, -327.000000)">
        <g id="Group-4" transform="translate(540.000000, 315.000000)">
          <g id="Group-3" transform="translate(148.000000, 0.000000)">
            <g id="manage_search_black_24dp" transform="translate(12.000000, 12.000000)">
              <polygon id="Path" points="0 0 40 0 40 40 0 40" />
              <path
                d="M9.73727192,15.0814295 L4.68431798,15.0814295 C3.75794309,15.0814295 3,14.3200939 3,13.3895725 L3,13.3895725 C3,12.4590511 3.75794309,11.6977154 4.68431798,11.6977154 L9.73727192,11.6977154 C10.6636468,11.6977154 11.4215899,12.4590511 11.4215899,13.3895725 L11.4215899,13.3895725 C11.4215899,14.3200939 10.6636468,15.0814295 9.73727192,15.0814295 Z M9.73727192,20.1570007 L4.68431798,20.1570007 C3.75794309,20.1570007 3,20.9183363 3,21.8488577 L3,21.8488577 C3,22.7793791 3.75794309,23.5407148 4.68431798,23.5407148 L9.73727192,23.5407148 C10.6636468,23.5407148 11.4215899,22.7793791 11.4215899,21.8488577 L11.4215899,21.8488577 C11.4215899,20.9183363 10.6636468,20.1570007 9.73727192,20.1570007 Z M33.1156055,30.7987815 L27.8605334,25.5201875 C26.4120199,26.4676275 24.6771724,27.0090217 22.8075794,26.9075103 C18.8157458,26.721406 15.3965803,23.5914705 14.8575986,19.6156064 C14.1164987,13.9478853 18.9841776,9.15992987 24.6771724,10.1242884 C27.9615924,10.6826012 30.6901876,13.2542239 31.4144443,16.519508 C31.9702692,18.9896193 31.4312875,21.2905449 30.2354217,23.1177505 L35.507337,28.4132631 C36.164221,29.0730873 36.164221,30.1389572 35.507337,30.7987815 L35.507337,30.7987815 C34.850453,31.4586057 33.7724895,31.4586057 33.1156055,30.7987815 Z M28.2647697,18.4651436 C28.2647697,15.6735795 25.9909404,13.3895725 23.2118157,13.3895725 C20.4326911,13.3895725 18.1588618,15.6735795 18.1588618,18.4651436 C18.1588618,21.2567078 20.4326911,23.5407148 23.2118157,23.5407148 C25.9909404,23.5407148 28.2647697,21.2567078 28.2647697,18.4651436 Z M4.68431798,32 L18.1588618,32 C19.0852367,32 19.8431798,31.2386643 19.8431798,30.308143 L19.8431798,30.308143 C19.8431798,29.3776216 19.0852367,28.6162859 18.1588618,28.6162859 L4.68431798,28.6162859 C3.75794309,28.6162859 3,29.3776216 3,30.308143 L3,30.308143 C3,31.2386643 3.75794309,32 4.68431798,32 Z"
                id="Shape"
                fill="#979797"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
