import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = ({ color }: { color?: 'gradient-blue' | string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4138_1255)">
      <path
        d="M7.35998 13.12L4.23998 10L7.35998 6.88C7.67198 6.568 7.67198 6.072 7.35998 5.76C7.04798 5.448 6.55198 5.448 6.23998 5.76L2.56798 9.432C2.25598 9.744 2.25598 10.248 2.56798 10.56L6.23998 14.24C6.55198 14.552 7.04798 14.552 7.35998 14.24C7.67198 13.928 7.67198 13.432 7.35998 13.12ZM12.64 13.12L15.76 10L12.64 6.88C12.328 6.568 12.328 6.072 12.64 5.76C12.952 5.448 13.448 5.448 13.76 5.76L17.432 9.432C17.744 9.744 17.744 10.248 17.432 10.56L13.76 14.24C13.448 14.552 12.952 14.552 12.64 14.24C12.328 13.928 12.328 13.432 12.64 13.12Z"
        fill={color === 'gradient-blue' ? 'url(#paint0_linear_4138_1255)' : color || '#303030'}
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4138_1255"
        x1="2.33398"
        y1="5.526"
        x2="17.683"
        y2="5.55522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <clipPath id="clip0_4138_1255">
        <rect width="19.2" height="19.2" fill="white" transform="translate(0.399902 0.400024)" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
