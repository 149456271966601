interface IAppIcon {
  width?: number;
  color: string;
}

export default ({ width = 20, color }: IAppIcon) => (
  <svg width={width} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9167 0.833374H6.25008C5.10008 0.833374 4.16675 1.76671 4.16675 2.91671V17.0834C4.16675 18.2334 5.10008 19.1667 6.25008 19.1667H12.9167C14.0667 19.1667 15.0001 18.2334 15.0001 17.0834V2.91671C15.0001 1.76671 14.0667 0.833374 12.9167 0.833374ZM9.58341 18.3334C8.89175 18.3334 8.33341 17.775 8.33341 17.0834C8.33341 16.3917 8.89175 15.8334 9.58341 15.8334C10.2751 15.8334 10.8334 16.3917 10.8334 17.0834C10.8334 17.775 10.2751 18.3334 9.58341 18.3334ZM13.3334 15H5.83341V3.33337H13.3334V15Z"
      fill={color}
    />
  </svg>
);
