/* eslint-disable prefer-promise-reject-errors */
import { Col, Form, Row, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { AccountOutDto } from 'api/requests/generated/generated.schemas';
import { Item, Input } from 'components/Form';
import CountriesSelect from 'components/Select/CountriesSelect';
import { Option, StyledSelect } from 'components/Select/Select';
import { alphanumWithNumbers, isZipCodeValid } from 'lib/helpers/validation';

export interface ICompanyDetailsFields {
  name?: string;
  address?: string;
  postcode?: string;
  city?: string;
  country?: string;
  chargebeeCustomerId?: string;
  account?: string;
}

interface IAccount extends AccountOutDto {
  disabled?: string | null;
}

interface ICompanyDetailsProps {
  form: FormInstance;
  initialValues?: ICompanyDetailsFields;
  readonly?: boolean;
  accounts?: IAccount[];
}

const INIT_DATA = {
  name: '',
  address: '',
  postcode: '',
  city: '',
  country: undefined,
};

const CompanyDetails = ({ initialValues, form, readonly, accounts }: ICompanyDetailsProps) => (
  <Form name="company-details" form={form} initialValues={{ ...INIT_DATA, ...initialValues }}>
    <Row gutter={[10, 5]}>
      <Col span={24}>
        {accounts && accounts.length > 0 && (
          <Item
            colon={!readonly}
            label="Billing Account"
            name="account"
            rules={[{ required: true, message: 'Input required' }]}
          >
            <StyledSelect placeholder="Select Billing Account">
              {accounts.map((account) => (
                <Option key={account.id} value={account.id} disabled={account.disabled}>
                  <Tooltip title={account.disabled}>{account.name}</Tooltip>
                </Option>
              ))}
            </StyledSelect>
          </Item>
        )}
      </Col>
      <Col span={24} pull={1} offset={1}>
        <Item
          colon={!readonly}
          required={!readonly}
          label="Company Name"
          name="name"
          rules={[{ required: true, message: 'Input required' }]}
        >
          <Input placeholder="Company Name" disabled={readonly} data-testid="input:company-name" />
        </Item>
      </Col>
      <Col span={initialValues?.chargebeeCustomerId ? 24 : 0}>
        <Item colon={!readonly} required={!readonly} label="Chargebee Customer ID" name="chargebeeCustomerId">
          <Input disabled={true} />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          colon={!readonly}
          required={!readonly}
          label="Street"
          name="address"
          rules={[
            { required: true, message: 'Input required' },
            { pattern: alphanumWithNumbers, message: 'Invalid address' },
          ]}
        >
          <Input placeholder="Street" disabled={readonly} data-testid="input:company-street" />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          colon={!readonly}
          required={!readonly}
          label="ZIP Code"
          name="postcode"
          dependencies={['country']}
          rules={[
            {
              validator: (_, zipCode) => {
                const country = form.getFieldValue('country');

                if (isZipCodeValid(zipCode, country)) {
                  return Promise.resolve();
                }

                return Promise.reject('Please enter a valid ZIP Code value');
              },
            },
          ]}
        >
          <Input placeholder="ZIP Code" disabled={readonly} data-testid="input:company-code" />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          colon={!readonly}
          required={!readonly}
          label="City"
          name="city"
          rules={[{ required: true, message: 'Input required' }]}
        >
          <Input placeholder="City" disabled={readonly} data-testid="input:company-city" />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          colon={!readonly}
          required={!readonly}
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Input required' }]}
        >
          <CountriesSelect disabled={readonly} />
        </Item>
      </Col>
    </Row>
  </Form>
);

export default CompanyDetails;
