import { Radio } from 'antd';
import styled from 'styled-components';

import { flexBetween } from 'shared-components/styled';

export const StyledRadio = styled(Radio)`
  flex-flow: row-reverse;
  display: flex;

  & > span:not(:first-child) {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    gap: 12px;
    padding-inline-start: 0;
    padding-inline-end: 12px;
  }
`;

export const TypeWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  border-radius: 8px;
  width: 320px;
  ${flexBetween}
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  & .ant-radio-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;

    & .ant-radio {
      margin-left: 95px;
    }
  }

  &:has(> .ant-radio-wrapper > .ant-radio > .ant-radio-input:checked) {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & img {
    width: 200px;
    height: 128px;
  }
`;
