import { Input } from 'antd';
import styled from 'styled-components';

export default styled(Input)`
  height: 40px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};

  & .ant-input-group {
    height: 40px;

    & .ant-input {
      height: 100%;
    }
  }
`;
