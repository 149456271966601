/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Key, SWRConfiguration } from 'swr';
import type {
  ConsumptionControllerGetAccountConsumption200,
  ConsumptionControllerGetAccountConsumptionParams,
  ConsumptionControllerGetSubscriptionConsumption200,
  ConsumptionControllerGetSubscriptionConsumptionParams,
  FailResponseDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns consumption info for all account subscriptions
 */
export const consumptionControllerGetAccountConsumption = (
  billingAccountId: string,
  params: ConsumptionControllerGetAccountConsumptionParams,
) => {
  return customInstance<ConsumptionControllerGetAccountConsumption200>({
    url: `/accounts/${billingAccountId}/consumption`,
    method: 'GET',
    params,
  });
};

export const getConsumptionControllerGetAccountConsumptionKey = (
  billingAccountId: string,
  params: ConsumptionControllerGetAccountConsumptionParams,
) => [`/accounts/${billingAccountId}/consumption`, ...(params ? [params] : [])] as const;

export type ConsumptionControllerGetAccountConsumptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof consumptionControllerGetAccountConsumption>>
>;
export type ConsumptionControllerGetAccountConsumptionQueryError = FailResponseDto;

/**
 * @summary Returns consumption info for all account subscriptions
 */
export const useConsumptionControllerGetAccountConsumption = <TError = FailResponseDto>(
  billingAccountId: string,
  params: ConsumptionControllerGetAccountConsumptionParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof consumptionControllerGetAccountConsumption>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!billingAccountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getConsumptionControllerGetAccountConsumptionKey(billingAccountId, params) : null));
  const swrFn = () => consumptionControllerGetAccountConsumption(billingAccountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns consumption info for particular subscription
 */
export const consumptionControllerGetSubscriptionConsumption = (
  subscriptionId: string,
  params: ConsumptionControllerGetSubscriptionConsumptionParams,
) => {
  return customInstance<ConsumptionControllerGetSubscriptionConsumption200>({
    url: `/subscriptions/${subscriptionId}/consumption`,
    method: 'GET',
    params,
  });
};

export const getConsumptionControllerGetSubscriptionConsumptionKey = (
  subscriptionId: string,
  params: ConsumptionControllerGetSubscriptionConsumptionParams,
) => [`/subscriptions/${subscriptionId}/consumption`, ...(params ? [params] : [])] as const;

export type ConsumptionControllerGetSubscriptionConsumptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof consumptionControllerGetSubscriptionConsumption>>
>;
export type ConsumptionControllerGetSubscriptionConsumptionQueryError = FailResponseDto;

/**
 * @summary Returns consumption info for particular subscription
 */
export const useConsumptionControllerGetSubscriptionConsumption = <TError = FailResponseDto>(
  subscriptionId: string,
  params: ConsumptionControllerGetSubscriptionConsumptionParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof consumptionControllerGetSubscriptionConsumption>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getConsumptionControllerGetSubscriptionConsumptionKey(subscriptionId, params) : null));
  const swrFn = () => consumptionControllerGetSubscriptionConsumption(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
