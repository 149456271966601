import { Skeleton, Flex } from 'antd';

const AccountDetailsSkeleton = () => (
  <Flex vertical gap={20}>
    {Array.from({ length: 4 }).map((_, index) => (
      <Skeleton.Button key={index} active size="large" block style={{ height: '65px' }} />
    ))}
  </Flex>
);

export default AccountDetailsSkeleton;
