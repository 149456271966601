import { Flex, Button } from 'antd';

import { Clock } from 'components/Icons';
import { Text } from 'components/Typography';
import { getDaysLeft } from 'lib/helpers/date';
import { useManageSubscription } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import StatusBar from '.';

interface IGetFullAccessBarProps {
  trialFinishedAt: string | null;
  subscriptionId: string;
}

const GetFullAccessBar = ({ trialFinishedAt, subscriptionId }: IGetFullAccessBarProps) => {
  const daysLeft = getDaysLeft(trialFinishedAt);
  const { open } = useManageSubscription();

  return (
    <StatusBar type="black">
      <Flex gap={8}>
        <Clock />
        <Text color={colors.white}>Enjoy all the premium features:</Text>
        <Text color={colors.white}>
          Premium trial ends in{' '}
          <strong>
            {daysLeft} day{daysLeft > 1 ? 's' : ''} left
          </strong>
        </Text>
      </Flex>
      <Button ghost onClick={() => open(subscriptionId)}>
        Get Full Access
      </Button>
    </StatusBar>
  );
};

export default GetFullAccessBar;
