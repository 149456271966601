import { Collapse, Flex } from 'antd';
import styled from 'styled-components';

export const TabsContentContainer = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.blue[16]};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const CustomizationItem = styled(Flex)`
  padding: 0 24px;
`;

export const ItemsContainer = styled.div`
  padding: 24px 0;
  border-right: 1px solid ${(props) => props.theme.colors.blue[16]};
  width: 30%;
  height: 530px;
  overflow: scroll;
`;

export const Footer = styled(Flex)`
  padding: 24px 32px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.blue[16]};
`;

export const PreviewContainer = styled(Flex)`
  width: 70%;
`;

export const StyledCollapse = styled(Collapse)`
  padding: 0 24px;

  &&&& .ant-collapse-expand-icon {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding: 12px 6px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.gray[50]};
  }

  && .ant-collapse-header {
    padding: 0;
    margin-bottom: 16px;
    font-size: 16px;
  }
`;
