import { Col, Flex, Row, Skeleton } from 'antd';

import { Box } from '../Regular/styled';

export const SkeletonSurvey = () => {
  const mockCategories = [{ questions: [1, 2, 3] }, { questions: [1, 2] }];
  return (
    <>
      {mockCategories.map(({ questions }, categoryId) => (
        <Row key={categoryId} style={{ paddingBottom: '24px' }} justify="space-between" gutter={[0, 24]}>
          <Col span={11}>
            <Skeleton paragraph={{ rows: 1 }} active></Skeleton>
          </Col>
          <Col span={13}>
            <Box>
              {questions.map((_, questionId) => (
                <Flex key={questionId} vertical style={{ marginBottom: '20px' }}>
                  <Skeleton.Input active size="small" style={{ marginBottom: '8px', width: '80%' }} />
                  <Skeleton.Input active size="large" style={{ marginBottom: '12px', width: '100%' }} />
                </Flex>
              ))}
            </Box>
          </Col>
        </Row>
      ))}
    </>
  );
};
