import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/google/editor/link</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <rect id="Rectangle" x="0" y="0" width="16" height="16" />
        <path
          d="M17,7 L14,7 C13.45,7 13,7.45 13,8 C13,8.55 13.45,9 14,9 L17,9 C18.65,9 20,10.35 20,12 C20,13.65 18.65,15 17,15 L14,15 C13.45,15 13,15.45 13,16 C13,16.55 13.45,17 14,17 L17,17 C19.76,17 22,14.76 22,12 C22,9.24 19.76,7 17,7 Z M8,12 C8,12.55 8.45,13 9,13 L15,13 C15.55,13 16,12.55 16,12 C16,11.45 15.55,11 15,11 L9,11 C8.45,11 8,11.45 8,12 Z M10,15 L7,15 C5.35,15 4,13.65 4,12 C4,10.35 5.35,9 7,9 L10,9 C10.55,9 11,8.55 11,8 C11,7.45 10.55,7 10,7 L7,7 C4.24,7 2,9.24 2,12 C2,14.76 4.24,17 7,17 L10,17 C10.55,17 11,16.55 11,16 C11,15.45 10.55,15 10,15 Z"
          id="Shape"
          fill="#303030"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} style={{ verticalAlign: 'sub' }} {...props} />;
