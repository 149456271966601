import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { updateCSA } from 'lib/contexts/Privileges';

import { apiErrorInterceptor, authInterceptor, paramsInterceptor } from './interceptors';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API || 'https://permission.service.usercentrics-sandbox.eu',
});

export const fetcher = (url: string, options = {}) => api.get(url, options).then((res) => res.data && res.data.body);

const notAuthorizedInterceptor = (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.status === 401) {
    updateCSA(false);
    window.location.replace(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${window.location.origin}/login`,
    );
  }

  return Promise.reject(error);
};

api.interceptors.request.use(authInterceptor);
api.interceptors.request.use(paramsInterceptor);
api.interceptors.response.use((r) => r, notAuthorizedInterceptor);
api.interceptors.response.use((r) => r, apiErrorInterceptor);

export const customInstance = async <T>(config: AxiosRequestConfig): Promise<T> => {
  return api(config).then(({ data }) => data);
};
