import Cookies from 'js-cookie';

import { TRIAL_COOKIES, USER_AUTH0_PARAMETERS } from '../consts';

export const clearTrialCookies = () => {
  parseEnumAndRemoveCookies(USER_AUTH0_PARAMETERS);
  parseEnumAndRemoveCookies(TRIAL_COOKIES);
};

const parseEnumAndRemoveCookies = (value: object) => {
  Object.values(value).forEach((parameterName) => {
    Cookies.remove(parameterName);
  });
};
