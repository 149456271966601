import { Form } from 'antd';
import { Key, useState } from 'react';

import { assignToCompany, moveSettingToCompany } from 'api/requests';
import DotsLoader from 'components/DotsLoader';
import { StepModal } from 'components/Modal';
import SelectCompany from 'components/SelectCompany/SelectCompany';
import SelectConfigurations from 'components/SelectConfigurations';
import { ISettingDetails } from 'interfaces/ISetting';
import { queueRequests } from 'lib/helpers';
import { useMessage } from 'lib/hooks';

import MoveOrUnassignConfigurationConfirm from './MoveOrUnassignConfigurationConfirm';

const { useForm, useWatch } = Form;

interface IManageConfigurationsProps {
  onClose: () => void;
  // No company props means it's unassigned page
  company?: { id: string; name: string; billingCustomerId: string };
  configurations: ISettingDetails[];
  open: boolean;
  withoutPermissionsStep?: boolean;
  onSaveCb?: () => void;
}

const ManageConfigurations = ({
  open,
  company,
  configurations,
  onClose,
  withoutPermissionsStep,
  onSaveCb,
}: IManageConfigurationsProps) => {
  const message = useMessage();
  const [progress, setProgress] = useState({ total: 0, done: 0 });
  const [selectedConfigurations, setSelectedConfigurations] = useState<string[]>([]);

  const [selectCompanyForm] = useForm();
  const [moveConfirmForm] = useForm();
  const selectedCompany = useWatch(['company'], { form: selectCompanyForm, preserve: true });

  const rowSelection = {
    selectedRowKeys: selectedConfigurations,
    onChange: (_: Key[], selectedRows: ISettingDetails[]) => {
      const selectedRowsFlat = selectedRows.map((setting) => setting.id);
      setSelectedConfigurations(selectedRowsFlat);
    },
  };

  const moveConfigurations = async () => {
    if (selectedCompany) {
      try {
        const { copyWithPermissions: isCopyWithPermissions } = moveConfirmForm.getFieldsValue(true);
        const { id, name } = JSON.parse(selectedCompany);

        const total = selectedConfigurations.length;
        setProgress({ total, done: 0 });

        await queueRequests(
          selectedConfigurations.map(
            (settingId) => () =>
              company
                ? moveSettingToCompany(company.id, settingId, {
                    targetCustomerId: id,
                    withPermissions: isCopyWithPermissions,
                  })
                : assignToCompany(id, settingId, {
                    copyWithPermissions: isCopyWithPermissions,
                    skipAttachToSubscription: false,
                  }),
          ),
          5,
          (done) => {
            setProgress({ total, done });
          },
        );

        message.success(`${selectedConfigurations.length} configurations moved to ${name}`);

        if (onSaveCb) {
          onSaveCb();
        }
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    }
  };

  return (
    <StepModal
      open={open}
      title="Manage Configurations"
      okText="Move To Company"
      onClose={onClose}
      steps={[
        {
          content: <SelectConfigurations configurations={configurations} rowSelection={rowSelection} />,
          primaryButton: {
            disabled: !selectedConfigurations.length,
          },
        },
        {
          content: <SelectCompany form={selectCompanyForm} />,
          handleNext: withoutPermissionsStep ? moveConfigurations : undefined,
          primaryButton: {
            disabled: !selectedCompany,
          },
        },
        !withoutPermissionsStep
          ? {
              content: <MoveOrUnassignConfigurationConfirm form={moveConfirmForm} />,
              title: 'Move Configurations?',
              handleNext: moveConfigurations,
            }
          : undefined,
      ]}
    >
      <DotsLoader
        open={Boolean(progress.total)}
        text={`Moving ${progress.done} out of ${progress.total} configurations.`}
      />
    </StepModal>
  );
};

export default ManageConfigurations;
