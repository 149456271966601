import { Form, Typography, Col, Row } from 'antd';
import { useState, useEffect } from 'react';

import { updateBillingAccountBillingInfo, useGetMyAccounts } from 'api/requests';
import { AccountOutDto } from 'api/requests/generated/generated.schemas';
import { Item, Input } from 'components/Form';
import Modal from 'components/Modal/Modal';
import CountriesSelect from 'components/Select/CountriesSelect';
import { getPrefix, getVatPlaceholder, shouldShowInput } from 'components/VatInput';
import { isAustriaVatNumberValid, isNorwayVatNumberValid, isSwissVatNumberValid } from 'lib/helpers/validation';
import { useMessage } from 'lib/hooks';

const { Title } = Typography;
const { useWatch } = Form;

interface IEditAccountDetailsProps {
  onClose: () => void;
  open: boolean;
  initialValues: Omit<
    AccountOutDto,
    'chargebeeCustomerId' | 'companies' | 'paymentSources' | 'primaryPaymentSourceId' | 'subscriptions'
  > & { email: string };
}

const EditAccountDetails = ({ initialValues, open, onClose }: IEditAccountDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [accountForm] = Form.useForm();
  const { mutate } = useGetMyAccounts();
  const accountValues = useWatch([], { form: accountForm, preserve: true });

  const message = useMessage();

  useEffect(() => {
    accountForm.validateFields().then(
      () => {
        setIsButtonDisabled(false);
      },
      () => {
        setIsButtonDisabled(true);
      },
    );
  }, [accountValues]);

  const onSave = async () => {
    try {
      setLoading(true);
      const data = await accountForm.validateFields();
      if (data.vatNumber) {
        switch (data.country) {
          case 'CH':
            if (!isSwissVatNumberValid(data.vatNumber)) {
              throw new Error('CH VAT number invalid!');
            }
            break;
          case 'NO':
            if (!isNorwayVatNumberValid(data.vatNumber)) {
              throw new Error('NO VAT number invalid!');
            }
            break;
        }
      }

      const { body: result } = await updateBillingAccountBillingInfo(initialValues.id, {
        country: data.country,
        street: data.street,
        zip: data.postcode,
        city: data.city,
        name: data.name,
        vatNumber: shouldShowInput(data.country) ? data.vatNumber : null,
      });

      if (result.vatNumberStatus === 'undetermined') {
        switch (data.country) {
          case 'AT':
            if (!isAustriaVatNumberValid(data.vatNumber)) {
              throw new Error('AT VAT number invalid!');
            }
        }
      }

      await mutate();
      onClose();
    } catch (error) {
      if (error?.message) {
        message.error(error.response?.data.error?.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      title={`Edit Billing Account Details [${initialValues.name}]`}
      data-testid="modal:edit-account-details"
      primaryButton={{
        label: loading ? 'Saving...' : 'Save changes',
        onClick: onSave,
        loading,
        disabled: isButtonDisabled,
        'data-testid': 'button:save-details-changes',
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
        'data-testid': 'button:cancel-details-changes',
      }}
      width={740}
      onClose={onClose}
    >
      <Title level={3}>Billing Account Details</Title>
      <Form form={accountForm} initialValues={initialValues} name={`billing-account-details-${initialValues.id}`}>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Item label="Account Name" name="name" colon={false}>
              <Input placeholder="Account Name" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Billing Email" name="email" colon={false}>
              <Input placeholder="Billing Email" disabled />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Street" name="street" colon={false}>
              <Input placeholder="Street" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="ZIP Code" name="postcode" colon={false}>
              <Input placeholder="ZIP Code" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="City" name="city" colon={false}>
              <Input placeholder="City" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Country" name="country" colon={false}>
              <CountriesSelect />
            </Item>
          </Col>
          {shouldShowInput(accountValues?.country) && (
            <Col span={12}>
              <Item
                colon={false}
                label="VAT ID"
                name="vatNumber"
                tooltip="The value-added tax identification number (VAT ID) is required to be able to apply the zero VAT rate for intra-community supplies of goods in the EU."
              >
                <Input
                  addonBefore={getPrefix(accountValues?.country)}
                  size="large"
                  placeholder={getVatPlaceholder(accountValues?.country)}
                  data-testid="input:account-vat"
                />
              </Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default EditAccountDetails;
