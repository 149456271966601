import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  height: max-content;
  width: 100%;
`;

export const Content = styled(Flex)`
  padding: 40px;
`;

export const SwiperContainer = styled(Flex)`
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;
