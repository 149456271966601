import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export default styled(TextArea)`
  height: 40px;
  border-radius: 4px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
`;
