import { Flex } from 'antd';
import styled from 'styled-components';

export const SelectAccountContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray[50]};
  min-width: 250px;
  margin-bottom: 24px;

  & > span {
    padding: 8px 12px;
    border-radius: 4px 0 0 4px;
    background: ${(props) => props.theme.colors.gray[8]};
  }

  & > div {
    width: 100%;
  }
`;

export const IconBackground = styled(Flex)`
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 5px;
`;
