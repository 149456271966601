import { Flex } from 'antd';

import Card from 'components/Card';
import { WebSubscription } from 'components/Icons';
import Tag from 'components/Tag';
import { Text, Title } from 'components/Typography';
import { Currency } from 'interfaces/enums';
import { getPlanName } from 'lib/helpers';
import { useManageSubscription } from 'lib/hooks';
import { colors } from 'lib/theme/colors';
import { FeaturesList } from 'pages/ManageSubscription/components/FeaturesList';

import { StyledBadge } from './styled';

interface IPlanOverviewProps {
  estimation: number;
}

const PlanOverview = ({ estimation }: IPlanOverviewProps) => {
  const { selectedPricePlan, subscription } = useManageSubscription();

  return (
    <Card
      styles={{
        header: {
          padding: 16,
        },
        body: {
          padding: 0,
        },
      }}
      title={
        <Flex gap={8} align="center">
          <Tag bordered $bolder color="blue-inverse" size="middle">
            <WebSubscription color="blue" />
          </Tag>
          <Text weight={500} ellipsis={{ tooltip: '' }} style={{ maxWidth: 80 }}>
            {getPlanName(selectedPricePlan?.pricePlan, subscription?.type)}
          </Text>
        </Flex>
      }
      extra={
        <Flex align="center" gap={4}>
          <Title $noMargin level={3}>
            {Currency[subscription?.currencyCode as keyof typeof Currency]}
            {estimation}
          </Title>
          <Text size="sm" type="secondary">
            per month
          </Text>
        </Flex>
      }
    >
      <Flex vertical gap={8} style={{ padding: 24, borderBottom: `1px solid ${colors.gray[24]}` }}>
        {selectedPricePlan && <FeaturesList plan={selectedPricePlan} />}
      </Flex>
      <Flex style={{ padding: '16px 24px' }} gap={8} align="center">
        <StyledBadge>TAX</StyledBadge>
        <Text size="sm" type="secondary">
          Shown price is in Euro (EUR) excl. VAT.
        </Text>
      </Flex>
    </Card>
  );
};

export default PlanOverview;
