import { Space, Typography } from 'antd';

import { CopyCode } from 'components/CopyCode';
import { Tooltip } from 'components/Form';

import { ImplementationContainer } from './styled';
const { Title } = Typography;

interface ICopyTagProps {
  link: string;
  name: string;
  tooltip: string;
}

export const CopyTag = ({ link, name, tooltip }: ICopyTagProps) => {
  return (
    <ImplementationContainer>
      <Space direction="horizontal" align="start">
        <Title level={5} data-testid={`copy-tag:name[${name}]`}>
          {name}
        </Title>
        <Tooltip title={tooltip} placement="topLeft" />
      </Space>

      <CopyCode
        dataTestId={'copy-tag:link[Ruleset ID]'}
        copyText={link}
        successMessage={`${name} copied to clipboard.`}
      />
    </ImplementationContainer>
  );
};
