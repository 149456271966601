/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  CreateSettingBody,
  FailResponseDto,
  FixInconsistentInDto,
  GetSettingsPermissionsBatchPublicInDto,
  SettingControllerCreateSetting200,
  SettingControllerCreateSettingParams,
  SettingControllerDeleteSetting200,
  SettingControllerDeleteSettingParams,
  SettingControllerDisableReviewMode200,
  SettingControllerDisableReviewModeParams,
  SettingControllerDuplicate200,
  SettingControllerDuplicateParams,
  SettingControllerEnableReviewMode200,
  SettingControllerEnableReviewModeParams,
  SettingControllerFixInconsistent200,
  SettingControllerFixInconsistentParams,
  SettingControllerGetById200,
  SettingControllerGetByIdParams,
  SettingControllerGetByIdWithPermissions200,
  SettingControllerGetByIdWithPermissionsParams,
  SettingControllerGetCustomerBySettingId200,
  SettingControllerGetCustomerBySettingIdParams,
  SettingControllerGetInconsistent200,
  SettingControllerGetInconsistentParams,
  SettingControllerGetLost200,
  SettingControllerGetLostParams,
  SettingControllerGetSettingsPermissionsBatch200,
  SettingControllerGetSettingsPermissionsBatchParams,
  SettingControllerSearchSettings200,
  SettingControllerSearchSettingsParams,
  SettingControllerSearchUnassignedSettings200,
  SettingControllerSearchUnassignedSettingsAll200,
  SettingControllerSearchUnassignedSettingsAllParams,
  SettingControllerSearchUnassignedSettingsParams,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns list of settings by search term
 */
export const settingControllerSearchSettings = (params?: SettingControllerSearchSettingsParams) => {
  return customInstance<SettingControllerSearchSettings200>({ url: `/setting`, method: 'GET', params });
};

export const getSettingControllerSearchSettingsKey = (params?: SettingControllerSearchSettingsParams) =>
  [`/setting`, ...(params ? [params] : [])] as const;

export type SettingControllerSearchSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerSearchSettings>>
>;
export type SettingControllerSearchSettingsQueryError = FailResponseDto;

/**
 * @summary Returns list of settings by search term
 */
export const useSettingControllerSearchSettings = <TError = FailResponseDto>(
  params?: SettingControllerSearchSettingsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerSearchSettings>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerSearchSettingsKey(params) : null));
  const swrFn = () => settingControllerSearchSettings(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Creates setting
 */
export const settingControllerCreateSetting = (
  createSettingBody: CreateSettingBody,
  params?: SettingControllerCreateSettingParams,
) => {
  return customInstance<SettingControllerCreateSetting200>({
    url: `/setting`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSettingBody,
    params,
  });
};

export const getSettingControllerCreateSettingMutationFetcher = (params?: SettingControllerCreateSettingParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerCreateSetting200> => {
    return settingControllerCreateSetting(arg as CreateSettingBody, params);
  };
};
export const getSettingControllerCreateSettingMutationKey = () => `/setting` as const;

export type SettingControllerCreateSettingMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerCreateSetting>>
>;
export type SettingControllerCreateSettingMutationError = FailResponseDto;

/**
 * @summary Creates setting
 */
export const useSettingControllerCreateSetting = <TError = FailResponseDto>(
  params?: SettingControllerCreateSettingParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerCreateSetting>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerCreateSetting>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerCreateSettingMutationKey();
  const swrFn = getSettingControllerCreateSettingMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of unassigned settings where current user has access (filtered by search term)
 */
export const settingControllerSearchUnassignedSettings = (params?: SettingControllerSearchUnassignedSettingsParams) => {
  return customInstance<SettingControllerSearchUnassignedSettings200>({
    url: `/setting/unassigned`,
    method: 'GET',
    params,
  });
};

export const getSettingControllerSearchUnassignedSettingsKey = (
  params?: SettingControllerSearchUnassignedSettingsParams,
) => [`/setting/unassigned`, ...(params ? [params] : [])] as const;

export type SettingControllerSearchUnassignedSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerSearchUnassignedSettings>>
>;
export type SettingControllerSearchUnassignedSettingsQueryError = FailResponseDto;

/**
 * @summary Returns list of unassigned settings where current user has access (filtered by search term)
 */
export const useSettingControllerSearchUnassignedSettings = <TError = FailResponseDto>(
  params?: SettingControllerSearchUnassignedSettingsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerSearchUnassignedSettings>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerSearchUnassignedSettingsKey(params) : null));
  const swrFn = () => settingControllerSearchUnassignedSettings(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of unassigned settings (filtered by search term)
 */
export const settingControllerSearchUnassignedSettingsAll = (
  params?: SettingControllerSearchUnassignedSettingsAllParams,
) => {
  return customInstance<SettingControllerSearchUnassignedSettingsAll200>({
    url: `/setting/unassigned/all`,
    method: 'GET',
    params,
  });
};

export const getSettingControllerSearchUnassignedSettingsAllKey = (
  params?: SettingControllerSearchUnassignedSettingsAllParams,
) => [`/setting/unassigned/all`, ...(params ? [params] : [])] as const;

export type SettingControllerSearchUnassignedSettingsAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerSearchUnassignedSettingsAll>>
>;
export type SettingControllerSearchUnassignedSettingsAllQueryError = FailResponseDto;

/**
 * @summary Returns list of unassigned settings (filtered by search term)
 */
export const useSettingControllerSearchUnassignedSettingsAll = <TError = FailResponseDto>(
  params?: SettingControllerSearchUnassignedSettingsAllParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerSearchUnassignedSettingsAll>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerSearchUnassignedSettingsAllKey(params) : null));
  const swrFn = () => settingControllerSearchUnassignedSettingsAll(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary [Debug purpose only] Returns list of settings which exist in DB but not exist in Mongo
 */
export const settingControllerGetLost = (params?: SettingControllerGetLostParams) => {
  return customInstance<SettingControllerGetLost200>({ url: `/setting/lost`, method: 'GET', params });
};

export const getSettingControllerGetLostKey = (params?: SettingControllerGetLostParams) =>
  [`/setting/lost`, ...(params ? [params] : [])] as const;

export type SettingControllerGetLostQueryResult = NonNullable<Awaited<ReturnType<typeof settingControllerGetLost>>>;
export type SettingControllerGetLostQueryError = FailResponseDto;

/**
 * @summary [Debug purpose only] Returns list of settings which exist in DB but not exist in Mongo
 */
export const useSettingControllerGetLost = <TError = FailResponseDto>(
  params?: SettingControllerGetLostParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerGetLost>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerGetLostKey(params) : null));
  const swrFn = () => settingControllerGetLost(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary [Debug purpose only] Returns list of settings which treats as legacy settings, but exists in DB
 */
export const settingControllerGetInconsistent = (params: SettingControllerGetInconsistentParams) => {
  return customInstance<SettingControllerGetInconsistent200>({ url: `/setting/inconsistent`, method: 'GET', params });
};

export const getSettingControllerGetInconsistentKey = (params: SettingControllerGetInconsistentParams) =>
  [`/setting/inconsistent`, ...(params ? [params] : [])] as const;

export type SettingControllerGetInconsistentQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerGetInconsistent>>
>;
export type SettingControllerGetInconsistentQueryError = FailResponseDto;

/**
 * @summary [Debug purpose only] Returns list of settings which treats as legacy settings, but exists in DB
 */
export const useSettingControllerGetInconsistent = <TError = FailResponseDto>(
  params: SettingControllerGetInconsistentParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerGetInconsistent>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerGetInconsistentKey(params) : null));
  const swrFn = () => settingControllerGetInconsistent(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary [Debug purpose only] Removes from legacy user list settings, which actually are modern
 */
export const settingControllerFixInconsistent = (
  fixInconsistentInDto: FixInconsistentInDto,
  params?: SettingControllerFixInconsistentParams,
) => {
  return customInstance<SettingControllerFixInconsistent200 | void>({
    url: `/setting/inconsistent/fix`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fixInconsistentInDto,
    params,
  });
};

export const getSettingControllerFixInconsistentMutationFetcher = (params?: SettingControllerFixInconsistentParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerFixInconsistent200 | void> => {
    return settingControllerFixInconsistent(arg as FixInconsistentInDto, params);
  };
};
export const getSettingControllerFixInconsistentMutationKey = () => `/setting/inconsistent/fix` as const;

export type SettingControllerFixInconsistentMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerFixInconsistent>>
>;
export type SettingControllerFixInconsistentMutationError = FailResponseDto;

/**
 * @summary [Debug purpose only] Removes from legacy user list settings, which actually are modern
 */
export const useSettingControllerFixInconsistent = <TError = FailResponseDto>(
  params?: SettingControllerFixInconsistentParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerFixInconsistent>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerFixInconsistent>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerFixInconsistentMutationKey();
  const swrFn = getSettingControllerFixInconsistentMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of all setting permissions for all users
 */
export const settingControllerGetByIdWithPermissions = (
  id: string,
  params?: SettingControllerGetByIdWithPermissionsParams,
) => {
  return customInstance<SettingControllerGetByIdWithPermissions200>({
    url: `/setting/${id}/permissions`,
    method: 'GET',
    params,
  });
};

export const getSettingControllerGetByIdWithPermissionsKey = (
  id: string,
  params?: SettingControllerGetByIdWithPermissionsParams,
) => [`/setting/${id}/permissions`, ...(params ? [params] : [])] as const;

export type SettingControllerGetByIdWithPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerGetByIdWithPermissions>>
>;
export type SettingControllerGetByIdWithPermissionsQueryError = FailResponseDto;

/**
 * @summary Returns list of all setting permissions for all users
 */
export const useSettingControllerGetByIdWithPermissions = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerGetByIdWithPermissionsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerGetByIdWithPermissions>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerGetByIdWithPermissionsKey(id, params) : null));
  const swrFn = () => settingControllerGetByIdWithPermissions(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns (inherited) permission and basic info for setting by id (used by service-api-v2)
 */
export const settingControllerGetById = (id: string, params?: SettingControllerGetByIdParams) => {
  return customInstance<SettingControllerGetById200>({ url: `/setting/${id}`, method: 'GET', params });
};

export const getSettingControllerGetByIdKey = (id: string, params?: SettingControllerGetByIdParams) =>
  [`/setting/${id}`, ...(params ? [params] : [])] as const;

export type SettingControllerGetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof settingControllerGetById>>>;
export type SettingControllerGetByIdQueryError = FailResponseDto;

/**
 * @summary Returns (inherited) permission and basic info for setting by id (used by service-api-v2)
 */
export const useSettingControllerGetById = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerGetByIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerGetById>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerGetByIdKey(id, params) : null));
  const swrFn = () => settingControllerGetById(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Removes setting by id
 */
export const settingControllerDeleteSetting = (id: string, params?: SettingControllerDeleteSettingParams) => {
  return customInstance<SettingControllerDeleteSetting200>({ url: `/setting/${id}`, method: 'DELETE', params });
};

export const getSettingControllerDeleteSettingMutationFetcher = (
  id: string,
  params?: SettingControllerDeleteSettingParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerDeleteSetting200> => {
    return settingControllerDeleteSetting(id, params);
  };
};
export const getSettingControllerDeleteSettingMutationKey = (id: string) => `/setting/${id}` as const;

export type SettingControllerDeleteSettingMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerDeleteSetting>>
>;
export type SettingControllerDeleteSettingMutationError = FailResponseDto;

/**
 * @summary Removes setting by id
 */
export const useSettingControllerDeleteSetting = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerDeleteSettingParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerDeleteSetting>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerDeleteSetting>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerDeleteSettingMutationKey(id);
  const swrFn = getSettingControllerDeleteSettingMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns (inherited) permission and overview of a company of the setting
 */
export const settingControllerGetCustomerBySettingId = (
  id: string,
  params?: SettingControllerGetCustomerBySettingIdParams,
) => {
  return customInstance<SettingControllerGetCustomerBySettingId200>({
    url: `/setting/${id}/customer`,
    method: 'GET',
    params,
  });
};

export const getSettingControllerGetCustomerBySettingIdKey = (
  id: string,
  params?: SettingControllerGetCustomerBySettingIdParams,
) => [`/setting/${id}/customer`, ...(params ? [params] : [])] as const;

export type SettingControllerGetCustomerBySettingIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerGetCustomerBySettingId>>
>;
export type SettingControllerGetCustomerBySettingIdQueryError = FailResponseDto;

/**
 * @summary Returns (inherited) permission and overview of a company of the setting
 */
export const useSettingControllerGetCustomerBySettingId = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerGetCustomerBySettingIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof settingControllerGetCustomerBySettingId>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSettingControllerGetCustomerBySettingIdKey(id, params) : null));
  const swrFn = () => settingControllerGetCustomerBySettingId(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * 
    Returns the list of the settings with user's permissions.
    The user is retrieved from the jwt token provided in the authorization header.

    The only difference between this endpoint and the `internal/setting/getPermissionsBatch` is the authentication mechanism:
      * here end user's jwt token is used.
      Therefore, a single request is required to validate the token and obtain user's permissions.
      * in `internal/setting/getPermissionsBatch` google auth is used for service authorization and the user is provided as an parameter.
      Therefore, an a supper admin user can check whether a certain user has access to certain settings.
    
 * @summary Returns the list of the settings with user's permissions.
 */
export const settingControllerGetSettingsPermissionsBatch = (
  getSettingsPermissionsBatchPublicInDto: GetSettingsPermissionsBatchPublicInDto,
  params?: SettingControllerGetSettingsPermissionsBatchParams,
) => {
  return customInstance<SettingControllerGetSettingsPermissionsBatch200>({
    url: `/setting/getPermissionsBatch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: getSettingsPermissionsBatchPublicInDto,
    params,
  });
};

export const getSettingControllerGetSettingsPermissionsBatchMutationFetcher = (
  params?: SettingControllerGetSettingsPermissionsBatchParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerGetSettingsPermissionsBatch200> => {
    return settingControllerGetSettingsPermissionsBatch(arg as GetSettingsPermissionsBatchPublicInDto, params);
  };
};
export const getSettingControllerGetSettingsPermissionsBatchMutationKey = () => `/setting/getPermissionsBatch` as const;

export type SettingControllerGetSettingsPermissionsBatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerGetSettingsPermissionsBatch>>
>;
export type SettingControllerGetSettingsPermissionsBatchMutationError = FailResponseDto;

/**
 * @summary Returns the list of the settings with user's permissions.
 */
export const useSettingControllerGetSettingsPermissionsBatch = <TError = FailResponseDto>(
  params?: SettingControllerGetSettingsPermissionsBatchParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerGetSettingsPermissionsBatch>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerGetSettingsPermissionsBatch>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerGetSettingsPermissionsBatchMutationKey();
  const swrFn = getSettingControllerGetSettingsPermissionsBatchMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Enables review mode for particular setting
 */
export const settingControllerEnableReviewMode = (id: string, params?: SettingControllerEnableReviewModeParams) => {
  return customInstance<SettingControllerEnableReviewMode200>({
    url: `/setting/${id}/reviewMode/enable`,
    method: 'PUT',
    params,
  });
};

export const getSettingControllerEnableReviewModeMutationFetcher = (
  id: string,
  params?: SettingControllerEnableReviewModeParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerEnableReviewMode200> => {
    return settingControllerEnableReviewMode(id, params);
  };
};
export const getSettingControllerEnableReviewModeMutationKey = (id: string) =>
  `/setting/${id}/reviewMode/enable` as const;

export type SettingControllerEnableReviewModeMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerEnableReviewMode>>
>;
export type SettingControllerEnableReviewModeMutationError = FailResponseDto;

/**
 * @summary Enables review mode for particular setting
 */
export const useSettingControllerEnableReviewMode = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerEnableReviewModeParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerEnableReviewMode>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerEnableReviewMode>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerEnableReviewModeMutationKey(id);
  const swrFn = getSettingControllerEnableReviewModeMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Disables review mode for particular setting
 */
export const settingControllerDisableReviewMode = (id: string, params?: SettingControllerDisableReviewModeParams) => {
  return customInstance<SettingControllerDisableReviewMode200>({
    url: `/setting/${id}/reviewMode/disable`,
    method: 'PUT',
    params,
  });
};

export const getSettingControllerDisableReviewModeMutationFetcher = (
  id: string,
  params?: SettingControllerDisableReviewModeParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerDisableReviewMode200> => {
    return settingControllerDisableReviewMode(id, params);
  };
};
export const getSettingControllerDisableReviewModeMutationKey = (id: string) =>
  `/setting/${id}/reviewMode/disable` as const;

export type SettingControllerDisableReviewModeMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerDisableReviewMode>>
>;
export type SettingControllerDisableReviewModeMutationError = FailResponseDto;

/**
 * @summary Disables review mode for particular setting
 */
export const useSettingControllerDisableReviewMode = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerDisableReviewModeParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerDisableReviewMode>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerDisableReviewMode>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerDisableReviewModeMutationKey(id);
  const swrFn = getSettingControllerDisableReviewModeMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Duplicates existing setting (copy of setting with different id will be created)
 */
export const settingControllerDuplicate = (id: string, params?: SettingControllerDuplicateParams) => {
  return customInstance<SettingControllerDuplicate200>({ url: `/setting/${id}/duplicate`, method: 'POST', params });
};

export const getSettingControllerDuplicateMutationFetcher = (id: string, params?: SettingControllerDuplicateParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SettingControllerDuplicate200> => {
    return settingControllerDuplicate(id, params);
  };
};
export const getSettingControllerDuplicateMutationKey = (id: string) => `/setting/${id}/duplicate` as const;

export type SettingControllerDuplicateMutationResult = NonNullable<
  Awaited<ReturnType<typeof settingControllerDuplicate>>
>;
export type SettingControllerDuplicateMutationError = FailResponseDto;

/**
 * @summary Duplicates existing setting (copy of setting with different id will be created)
 */
export const useSettingControllerDuplicate = <TError = FailResponseDto>(
  id: string,
  params?: SettingControllerDuplicateParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof settingControllerDuplicate>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof settingControllerDuplicate>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSettingControllerDuplicateMutationKey(id);
  const swrFn = getSettingControllerDuplicateMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
