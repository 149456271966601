import { Flex, Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)`
  display: flex;
  flex-flow: row-reverse;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #f0f0f0;
  background: ${(props) => props.theme.colors.white};
  padding: 12px 16px;

  &:has(> .ant-radio-checked) {
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background: ${(props) => props.theme.colors.blue[4]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue[4]};
  }

  & > span:not(:first-child) {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    gap: 12px;
    padding-inline-start: 0;
  }
`;

export const ImageWrapper = styled(Flex)`
  margin: 0 auto;
`;

export const Banner = styled.div<{ position: 'center' | 'bottom' }>`
  display: flex;
  justify-content: center;
  width: 45px;
  height: 35px;
  border-radius: 3px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray[24]};

  &:after {
    content: '';
    display: block;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 1px;
    margin: 2px;
    ${(props) => (props.position === 'center' ? 'width: 24px; height: 16px;' : 'width: 90%; height: 7px')}
  }

  ${(props) => (props.position === 'center' ? 'align-items: center' : 'align-items: end')}
`;
