import Icon from '@ant-design/icons';

import { IconProps } from 'types';

import { colors } from '../../lib/theme/colors';

const CustomIcon = ({ fill }: { fill?: string }) => (
  <svg
    width="18px"
    height="18px"
    version="1.1"
    viewBox="0 0 700 500"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill || colors.primaryText}
  >
    <g>
      <path d="m472.5 105h-332.5c-4.6406 0-9.0938 1.8438-12.375 5.125s-5.125 7.7344-5.125 12.375v385c0 4.6406 1.8438 9.0938 5.125 12.375s7.7344 5.125 12.375 5.125h332.5c4.6406 0 9.0938-1.8438 12.375-5.125s5.125-7.7344 5.125-12.375v-385c0-4.6406-1.8438-9.0938-5.125-12.375s-7.7344-5.125-12.375-5.125zm-17.5 385h-297.5v-350h297.5z" />
      <path d="m560 35h-367.5c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75h350v367.5h-17.5c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75h35c4.6406 0 9.0938-1.8438 12.375-5.125s5.125-7.7344 5.125-12.375v-402.5c0-4.6406-1.8438-9.0938-5.125-12.375s-7.7344-5.125-12.375-5.125z" />
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ fill: props.color })} {...props} />;
