import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Flex, Row, Col, Space, Spin } from 'antd';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useMediaQuery } from 'react-responsive';

import { verifyUser } from 'api/requests';
import Button from 'components/Button';
import Email from 'components/Email';
import { Input } from 'components/Form';
import LightHeaderComponent from 'components/Header/LightHeader';
import { VerifyEmail as MobileView } from 'components/MobileView';
import { Text, Title } from 'components/Typography';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { USER_AUTH0_PARAMETERS, ONBOARDING_TYPE, TRIAL_COOKIES } from 'lib/consts';
import { trackEvent } from 'lib/helpers/amplitude';
import { clearOrganization } from 'lib/helpers/auth0';
import { emailRegexp } from 'lib/helpers/validation';
import { useLogout } from 'lib/hooks';
import { colors } from 'lib/theme/colors';
import { Wrapper } from 'shared-components';

import { Container, LogButton, Top } from './styled';

const { Paragraph } = Typography;

interface IProps {
  error: string;
}

const AuthErrorPage = ({ error }: IProps) => {
  const [email, changeEmail] = useState('');
  const [isEmailVerifying, setIsEmailVerifying] = useState(false);
  const [messageSent, switchMessageStatus] = useState(false);
  const logOut = useLogout();
  const [isLoading, setIsLoading] = useState(true);

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  /**
   * Tracking events
   */
  useEffect(() => {
    if (error && error === 'ERROR_NOT_VERIFIED') {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.EMAIL_VERIFICATION_VISITED,
          [TrackingVariables.TRIAL_TYPE]: Cookies.get(TRIAL_COOKIES.TRIAL_USED) || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
        },
      });
      trackEvent(
        TrackingEvents.EMAIL_VERIFICATION_VISITED,
        {
          [TrackingVariables.TRIAL_TYPE]: Cookies.get(TRIAL_COOKIES.TRIAL_USED) || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  }, []);
  /**
   * =====
   */

  const handleResendClick = async () => {
    try {
      setIsEmailVerifying(true);
      await verifyUser({ email });
      switchMessageStatus(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsEmailVerifying(false);
    }
  };

  const LoginButton = ({ type }: { type: 'link' | 'button' }) => {
    const onLoginClick = () => {
      clearOrganization();
      logOut();
    };

    if (type == 'button') {
      return (
        <Button type="primary" onClick={onLoginClick} size="large">
          Login
        </Button>
      );
    }

    if (type == 'link') {
      return (
        <LogButton>
          <Text underline onClick={onLoginClick}>
            login
          </Text>
        </LogButton>
      );
    }

    return null;
  };

  if (!isBigScreen) {
    return (
      <MobileView>
        <LoginButton type="button" />
      </MobileView>
    );
  }

  const renderEmailSent = () => (
    <>
      <Title>Verify your email</Title>
      <Paragraph>
        Verification mail was sent, it may take up to 5 minutes, please check your inbox. Once verification is done,
        please try to <LoginButton type="link" /> again.
      </Paragraph>
    </>
  );

  const renderEmailNotSent = () => (
    <>
      <Title>Verify your email</Title>
      <Paragraph>
        Your email address needs to be verified, kindly check your inbox. Once verification is done, please try to{' '}
        <LoginButton type="link" /> again.
      </Paragraph>
      <Paragraph>
        If you didn&apos;t receive verification mail, please fill input with your email and press resend button.
      </Paragraph>
      <Flex>
        <Input style={{ marginRight: '0.75rem' }} onChange={(e) => changeEmail(e.target.value)} value={email} />
        <Button disabled={isEmailVerifying || !emailRegexp.test(email)} onClick={handleResendClick} type="primary">
          Resend
        </Button>
      </Flex>
    </>
  );

  const renderEmailNotSentDma = () => {
    return (
      <>
        <Title>Verify your email</Title>
        <Paragraph>
          Verifying your email is crucial to the security of your account and ensuring you receive important updates and
          notifications from us.
        </Paragraph>
        <Row gutter={[0, 32]}>
          <Col>
            <Text>
              An email will be sent to the email address you've provided. Please check your inbox and click on the
              'Verify email address' button provided in the email.
            </Text>
          </Col>
          <Col>
            <LoginButton type="button" />
          </Col>
          <Col>
            <Text size="sm">
              Note: If you did not receive the email, please check your spam folder. If it's not there, you can request
              a new verification email from the system. Please make sure to enter your email address correctly.
            </Text>
          </Col>
        </Row>
      </>
    );
  };

  const renderDefault = () => (
    <Wrapper>
      <LightHeaderComponent />
      <Top>
        <Container>
          {error === 'ERROR_NOT_VERIFIED' ? (
            messageSent ? (
              renderEmailSent()
            ) : (
              renderEmailNotSent()
            )
          ) : (
            <>
              <Title>Something&apos;s wrong here.</Title>
              <Paragraph>
                {error.endsWith('.') ? error : `${error}.`} Please try to <LoginButton type="link" /> again later.
              </Paragraph>
            </>
          )}
        </Container>
      </Top>
    </Wrapper>
  );

  const renderTemplateContent = () => {
    if (isLoading) {
      return (
        <Row justify="center" align="middle" style={{ height: '80vh' }}>
          <Space align="center" direction="vertical">
            <Spin size="large" indicator={<LoadingOutlined style={{ color: colors.gray[80] }} />} />
          </Space>
        </Row>
      );
    } else {
      return messageSent ? renderEmailSent() : renderEmailNotSentDma();
    }
  };

  const renderNewTemplate = () => (
    <Wrapper>
      <Flex align="center" justify="center" style={{ height: '100%', gap: '3.75rem' }}>
        <Email width={550} />
        <Flex style={{ width: '28rem' }} vertical align="start">
          {renderTemplateContent()}
        </Flex>
      </Flex>
    </Wrapper>
  );

  return error === 'ERROR_NOT_VERIFIED' ? renderNewTemplate() : renderDefault();
};

export default AuthErrorPage;
