import { Col } from 'antd/lib';

import { Text } from 'components/Typography';
import { numberWithCommas } from 'lib/helpers';

import { RowContainer, RowContent } from './styled';

interface IData {
  date: Date;
  isExceeded: boolean;
  max: number;
  amount: number;
  notAvailable: boolean;
}

const formatExceeded = (exceeded: number, na?: boolean) => {
  if (na || exceeded <= 0) return '-';
  return '+' + numberWithCommas(exceeded);
};

const Row = ({ date, max, isExceeded, amount, notAvailable }: IData) => {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return (
    <RowContainer $exceeded={isExceeded} gap={8}>
      <Col span={8}>
        <Text>{notAvailable ? '-' : `${month}/${year}`}</Text>
      </Col>
      <Col span={8}>
        <RowContent $gray={notAvailable}>{notAvailable ? '-' : numberWithCommas(amount)}</RowContent>
      </Col>
      <Col span={8}>
        <RowContent $gray={!isExceeded} $exceeded={isExceeded}>
          {formatExceeded(amount - max, notAvailable)}
        </RowContent>
      </Col>
    </RowContainer>
  );
};

export default Row;
