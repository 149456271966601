import useSWRMutation from 'swr/mutation';

import { api } from 'api/config/baseApi';
import ICompany, { ICompanyWithSettings } from 'interfaces/ICompany';

import useSWR from '../config/swr';

export const getCompanies = (
  shouldFetch = true,
): {
  companies: ICompany[];
  isLoading: boolean;
  isMutating: boolean;
  isError: boolean;
  mutate: () => void;
  trigger: (value: string) => Promise<ICompany[]>;
} => {
  const {
    data: companies = [],
    isLoading,
    isError,
    mutate,
  } = useSWR<ICompany[]>(shouldFetch ? '/customer' : null, { keepPreviousData: true });

  const { trigger, data, isMutating } = useSWRMutation(
    '/customer/',
    async (url, { arg }: { arg: string }) => (await api.get(url, { params: { q: arg } })).data.body,
    {
      populateCache: true,
    },
  );

  if (!shouldFetch) {
    return {
      companies: data,
      isLoading: false,
      isMutating,
      isError: false,
      mutate,
      trigger,
    };
  }

  return { companies, isLoading, isError, isMutating, trigger, mutate };
};

export const getCompaniesWithSettings = (
  shouldFetch = true,
): {
  companies: ICompanyWithSettings[];
  isLoading: boolean;
  isMutating: boolean;
  isError: boolean;
  mutate: () => void;
  trigger: (value: string) => Promise<ICompanyWithSettings[]>;
} => {
  const {
    data: companies = [],
    isLoading,
    isError,
    mutate,
  } = useSWR<ICompanyWithSettings[]>(() => (shouldFetch ? '/customer/v2' : null));

  const { trigger, data, isMutating } = useSWRMutation(
    '/customer/v2',
    async (url, { arg }: { arg: string }) => (await api.get(url, { params: { q: arg || '' } })).data.body,
    {
      populateCache: true,
    },
  );

  if (!shouldFetch) {
    return {
      companies: data || [],
      isLoading: false,
      isMutating,
      isError: false,
      mutate,
      trigger,
    };
  }

  return {
    mutate,
    companies,
    isLoading,
    isMutating,
    isError,
    trigger,
  };
};
