import { IStylingButtonsColors, IStylingLayoutColors, IStylingToggleColors } from 'lib/contexts/Onboarding';

export const layoutColors: { name: Partial<keyof IStylingLayoutColors>; title: string }[] = [
  {
    name: 'layerBackground',
    title: 'Background',
  },
  {
    name: 'text',
    title: 'Text',
  },
  {
    name: 'linkFont',
    title: 'Links',
  },
  {
    name: 'secondLayerTab',
    title: 'Tabs',
  },
  {
    name: 'tabsBorderColor',
    title: 'Accent',
  },
];

export const toggleColors: { name: Partial<keyof IStylingToggleColors>; title: string }[] = [
  {
    name: 'toggleActiveBackground',
    title: 'Active (Background)',
  },
  {
    name: 'toggleActiveIcon',
    title: 'Active (Icon)',
  },
  {
    name: 'toggleInactiveBackground',
    title: 'Inactive (Background)',
  },
  {
    name: 'toggleInactiveIcon',
    title: 'Inactive (Icon)',
  },
  {
    name: 'toggleDisabledBackground',
    title: 'Disabled (Background)',
  },
  {
    name: 'toggleDisabledIcon',
    title: 'Disabled (Icon)',
  },
];

export const buttonColors: { name: Partial<keyof IStylingButtonsColors>; title: string }[] = [
  {
    name: 'buttonBackgroundColor',
    title: 'Background',
  },
  {
    name: 'buttonTextColor',
    title: 'Text',
  },
];
