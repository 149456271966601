import { Card as AntdCard, Flex } from 'antd';
import styled, { css } from 'styled-components';

export const Card = styled(AntdCard)`
  border-color: ${(props) => props.theme.colors.gray[24]};

  &.focused {
    border: 1px solid ${({ theme }) => theme.colors.blue[50]};
    transition: border 0.2s;
  }

  &.framework {
    .ant-card-body {
      padding: 12px 16px;
    }

    &.focused {
      background-color: ${({ theme }) => theme.colors.blue[4]};
      border: 2px solid ${({ theme }) => theme.colors.primary};
      transition: border 0.2s;
    }

    &.disabled {
      background: ${({ theme }) => theme.colors.gray[8]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[8]};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.blue[4]};
    }
  }

  &.ant-card .ant-card-head {
    border-color: ${(props) => props.theme.colors.gray[24]};
  }
`;

const borderedFooter = css`
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid ${(props) => props.theme.colors.gray[24]};
  margin: 16px -16px -16px -16px;
  padding: 18px 16px;
`;

export const CardFooter = styled.div<{ borderedFooter?: boolean }>`
  font-size: 12px;
  padding-top: 1.25rem;
  ${(props) => props.borderedFooter && borderedFooter};
`;

export const NoResults = styled(Flex)`
  padding: 20px 20px 20px 20px;
  background-color: ${({ theme }) => theme.colors.gray[8]};
  color: ${({ theme }) => theme.colors.gray[80]};
  border-radius: 4px;

  & svg g {
    fill: ${({ theme }) => theme.colors.gray[80]};
  }
`;
