import { Row, Col, Flex, Form } from 'antd';
import { FormInstance } from 'antd/lib';

import { Input, Item } from 'components/Form';
import CountriesSelect from 'components/Select/CountriesSelect';
import { isZipCodeValid } from 'lib/helpers/validation';

export type Address = {
  address: {
    country: string;
    city: string;
    street: string;
    zip: string;
  };
};

interface IAddressItemProps<T> {
  form: FormInstance<T & Address>;
  label: string;
}

const AddressItem = <T extends Address>({ form, label }: IAddressItemProps<T>) => {
  const values = Form.useWatch([], form);

  return (
    <Item label={label} required weight="medium" colon={false} requiredMark={false}>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Item
            name={['address', 'street']}
            colon={false}
            noStyle
            rules={[{ required: true, message: 'Street is required' }]}
          >
            <Input placeholder="Street" />
          </Item>
        </Col>
        <Col span={24}>
          <Flex gap={8}>
            <Item
              name={['address', 'zip']}
              dependencies={['address', 'country']}
              noStyle
              colon={false}
              rules={[
                { required: true, message: 'ZIP code is required' },
                ({ getFieldValue }) => ({
                  validator: (_, zipCode) => {
                    const country = getFieldValue(['address', 'country']);

                    if (isZipCodeValid(zipCode, country)) {
                      return Promise.resolve();
                    }

                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Please enter a valid ZIP Code value');
                  },
                }),
              ]}
            >
              <Input placeholder="ZIP Code" />
            </Item>
            <Item
              name={['address', 'city']}
              noStyle
              colon={false}
              rules={[{ required: true, message: 'City is required' }]}
            >
              <Input placeholder="City" />
            </Item>
            <Item
              name={['address', 'country']}
              colon={false}
              noStyle
              rules={[{ required: true, message: 'Country is required' }]}
            >
              <CountriesSelect
                onChange={(country) => {
                  form.setFields([
                    {
                      name: ['address', 'zip'],
                      errors: !isZipCodeValid(values.address.zip, country as string)
                        ? ['Please enter a valid ZIP Code value']
                        : [],
                    },
                  ]);
                }}
              />
            </Item>
          </Flex>
        </Col>
      </Row>
    </Item>
  );
};

export default AddressItem;
