import { Alert, Row, Typography, Switch, Flex } from 'antd';
import { useState } from 'react';

import { Info as InfoIcon } from 'components/Icons';

const { Title, Paragraph } = Typography;

interface IToggleSectionProps {
  defaultStatus: boolean;
  description: string;
  title: string;
  warning?: string;
  dataTestId?: string;
  handler: (status: boolean) => Promise<void>;
}

const ToggleSection = ({ defaultStatus, handler, description, warning, title, dataTestId }: IToggleSectionProps) => {
  const [status, setStatus] = useState(() => defaultStatus);

  const onToggle = async () => {
    setStatus(!status);
    await handler(status);
  };

  return (
    <Flex vertical style={{ width: '100%' }}>
      <Row justify="space-between">
        <Title level={4}>{title}</Title>
        <Switch data-testid={dataTestId} onChange={onToggle} defaultChecked={status} />
      </Row>
      <Paragraph>{description}</Paragraph>
      {warning && <Alert message={warning} type="warning" showIcon icon={<InfoIcon />} />}
    </Flex>
  );
};

export default ToggleSection;
