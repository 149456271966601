import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="40" height="40" rx="4" fill="#F5F8FC" />
    <g clipPath="url(#clip0_3636_398)">
      <path
        d="M10 16V8C10 6.9 10.89 6 12 6H21V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V18H12C10.89 18 10 17.1 10 16ZM13 8C12.45 8 12 8.45 12 9V15C12 15.55 12.45 16 13 16H22V8H13ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z"
        fill="url(#paint0_linear_670_3690)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_670_3690"
        x1="11"
        y1="11"
        x2="30.0211"
        y2="11.0223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <clipPath id="clip0_3636_398">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
