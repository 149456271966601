import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <g clipPath="url(#clip0_780_53685)">
      <path
        d="M14.6266 14.1267L2.37326 1.87334C2.11326 1.61334 1.69326 1.61334 1.43326 1.87334C1.1666 2.13334 1.1666 2.55334 1.4266 2.81334L1.9866 3.37334C1.89326 3.56001 1.83993 3.77334 1.83993 4.00001L1.83326 12C1.83326 12.74 2.4266 13.3333 3.1666 13.3333H11.9466L13.6866 15.0733C13.9466 15.3333 14.3666 15.3333 14.6266 15.0733C14.8866 14.8133 14.8866 14.3867 14.6266 14.1267ZM3.1666 8.00001V5.33334H3.9466L6.61326 8.00001H3.1666ZM5.05326 2.66668H13.8333C14.5733 2.66668 15.1666 3.26001 15.1666 4.00001V12C15.1666 12.2267 15.1133 12.44 15.0133 12.6267L10.3866 8.00001H13.8333V5.33334H7.71993L5.05326 2.66668Z"
        fill="#979797"
      />
    </g>
    <defs>
      <clipPath id="clip0_780_53685">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
