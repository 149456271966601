import { SubscriptionType as ISubscriptionType } from 'api/requests/generated/generated.schemas';
import { AppSubscription, WebSubscription } from 'components/Icons';

const SubscriptionType = ({ type }: { type: ISubscriptionType }) => {
  switch (type) {
    case 'web':
      return <WebSubscription />;
    case 'app':
      return <AppSubscription />;
    default:
      return null;
  }
};

export default SubscriptionType;
