import { Col, Row, Tooltip } from 'antd';

import { UnassignedSettingsOutDto } from 'api/requests/generated/generated.schemas';
import Tag from 'components/Tag';
import { Text } from 'components/Typography';
import { sort, sortByNameAndId } from 'lib/helpers/sort';

export const generateConfigurationColumns = (newSettings: string[]) => [
  {
    title: 'Configuration Name [ID]',
    key: 'settingId',
    ellipsis: true,
    sorter: (a: UnassignedSettingsOutDto, b: UnassignedSettingsOutDto) => sortByNameAndId(a, b),
    render: (record: UnassignedSettingsOutDto) => {
      const text = [record.aliasName, `[${record.id}]`].filter((x) => x).join(' ');

      return (
        <Row>
          <Col flex="auto">
            <Text ellipsis={{ tooltip: true }} data-testid={`setting-id:${record.id}`}>
              {text}
            </Text>
          </Col>
          <Col>
            {newSettings.includes(record.id) && (
              <Tag data-testid={`new-label:${record.id}`} color="blue" bordered>
                New
              </Tag>
            )}
          </Col>
        </Row>
      );
    },
  },
  {
    title: 'Domain / App-ID',
    sorter: (a: UnassignedSettingsOutDto, b: UnassignedSettingsOutDto) =>
      sort(a.domains?.join() || '', b.domains?.join() || ''),
    showSorterTooltip: false,
    key: 'domainOrAppIds',
    ellipsis: true,
    render: (record: UnassignedSettingsOutDto) => {
      const domains = record.domains?.join(', ');
      const appIds = record.appIds?.join(', ');

      const text = domains || appIds;

      return (
        <Tooltip title={text} placement="topLeft">
          <span data-testid={`domain:${record.id}`}>{text}</span>
        </Tooltip>
      );
    },
  },
  {
    title: 'Data Controller',
    dataIndex: 'dataController',
    showSorterTooltip: false,
    sorter: (a: UnassignedSettingsOutDto, b: UnassignedSettingsOutDto) => sort(a.dataController, b.dataController),
    key: 'dataController',
    ellipsis: true,
    render: (dataController: string, record: UnassignedSettingsOutDto) => (
      <Tooltip placement="topLeft" title={dataController}>
        <span data-testid={`data-controller:${record.id}`}>{dataController}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Framework',
    showSorterTooltip: false,
    dataIndex: 'legalFramework',
    sorter: (a: UnassignedSettingsOutDto, b: UnassignedSettingsOutDto) => sort(a.legalFramework, b.legalFramework),
    key: 'legalFramework',
    ellipsis: true,
    render: (legalFramework: string, record: UnassignedSettingsOutDto) => (
      <Tooltip placement="topLeft" title={legalFramework}>
        <span data-testid={`framework:${record.id}`}>{legalFramework}</span>
      </Tooltip>
    ),
  },
];
