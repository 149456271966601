import { useAuth0 } from '@auth0/auth0-react';
import { Row, Space } from 'antd';

import Button from 'components/Button';
import { Success } from 'components/Icons';
import { Title } from 'components/Typography';

const Logout = () => {
  const { loginWithRedirect } = useAuth0();

  const onClick = async () => {
    await loginWithRedirect();
  };

  return (
    <Row align="middle" justify="center" style={{ height: '100%' }}>
      <Space direction="vertical">
        <Row justify="center" align="middle">
          <Success />
        </Row>
        <Space direction="vertical" size={40}>
          <Title>Logout successful</Title>
          <Row justify="center">
            <Button size="large" type="primary" onClick={onClick}>
              Login
            </Button>
          </Row>
        </Space>
      </Space>
    </Row>
  );
};

export default Logout;
