import { CountryIsoCodeDictionary, StateIsoCodeDictionary } from '@usercentrics/i18n-iso3166-2';
import { Tooltip } from 'antd';

import { IRule } from 'interfaces/IRuleset';
import ISetting from 'interfaces/ISetting';

const countries: { [key: string]: string } = {
  ...CountryIsoCodeDictionary,
  ...StateIsoCodeDictionary,
};

export const generateColumns = (configurations: ISetting[]) => [
  {
    title: 'Rule Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (name: string) => {
      return (
        <Tooltip title={name} placement="topLeft">
          <span data-testid={`table-column:rule-name[${name}]`}>{name}</span>
        </Tooltip>
      );
    },
  },
  {
    title: 'Regions',
    key: 'regions',
    ellipsis: true,
    render: (record: IRule) => {
      const locations = record.locations.map((location) => countries[location] || location).join(', ');

      return (
        <Tooltip title={locations} placement="topLeft">
          <span data-testid={`table-column:regions[${locations}]`}>{locations}</span>
        </Tooltip>
      );
    },
  },
  {
    title: 'Configuration',
    key: 'settingsId',
    dataIndex: 'settingsId',
    ellipsis: true,
    render: (settingId: string, record: IRule, index: number) => {
      const setting = configurations.find((configuration) => configuration.id === settingId);

      return (
        <span data-testid={`table-column:configurations[${record.settingsId}]`}>
          {index === 0 ? (
            record.settingsId
          ) : (
            <>
              {setting?.aliasName} [{record.settingsId}]
            </>
          )}
        </span>
      );
    },
  },
];
