import { Flex } from 'antd';
import styled from 'styled-components';

export const Dot = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
`;

export const DotsContainer = styled(Flex)`
  border-radius: 34px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.gray[8]};
  width: fit-content;
`;
