import { Form } from 'antd';
import styled from 'styled-components';

export const CheckboxItem = styled(Form.Item)`
  &.ant-form-item {
    margin: 0;
  }

  & .ant-form-item-explain {
    margin-left: 40px;
  }

  & .ant-checkbox-wrapper {
    font-size: 0.75rem;
  }

  & .ant-form-item-control-input {
    position: relative;
    padding-left: 2rem;
    & .ant-checkbox {
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
    }
  }
`;
