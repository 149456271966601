import { ColorPicker } from 'antd';
import styled from 'styled-components';

export const StyledColorPicker = styled(ColorPicker)`
  width: 42px;
  height: 40px;
  padding: 0;

  & .ant-color-picker-color-block {
    width: 100%;
    height: 100%;
  }

  &.ant-color-picker-trigger {
    &.ant-color-picker-trigger-active {
      border-color: transparent;
    }
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.gray[24]};
  }
`;
