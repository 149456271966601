import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'components/Modal/Modal';
import { useMessage, usePrivileges } from 'lib/hooks';

interface IDeleteCompanyProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => Promise<unknown>;
}

const DeleteCompany = ({ open, onClose, onDelete }: IDeleteCompanyProps) => {
  const [loading, setLoading] = useState(false);
  const message = useMessage();
  const navigate = useNavigate();
  const { isCSA } = usePrivileges();

  const onCompanyDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
      onClose();
      setLoading(false);
      navigate('/companies');
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    }
  };

  return (
    <Modal
      open={open}
      title="Delete Company?"
      primaryButton={{
        label: 'Delete',
        loading,
        'data-testid': 'button:delete-company',
        onClick: onCompanyDelete,
      }}
      secondaryButton={{
        label: 'Cancel',
        'data-testid': 'button:cancel-delete-company',
        onClick: onClose,
      }}
      width={480}
      onClose={onClose}
      isConfirmation
      isDefaultFooter={false}
    >
      {isCSA ? (
        <span>
          Please confirm that you want to delete this company. Deleting the company will delete all configurations and
          other users might not be able to access those configurations anymore. This action cannot be undone.
        </span>
      ) : (
        <span>Please confirm that you want to delete this company. This action cannot be undone.</span>
      )}
    </Modal>
  );
};

export default DeleteCompany;
