import { DefaultOptionType, BaseOptionType, SelectProps } from 'antd/lib/select';
import { countries as fullCountriesList } from 'countries-list';

import { StyledSelect } from './Select';
import { CountryCode } from '../../types';

const options: BaseOptionType[] = Object.entries(fullCountriesList)
  .map(([code, { name }]) => ({
    value: code as CountryCode,
    label: name,
  }))
  .sort((a, b) => (a.value === 'DE' || b.value === 'DE' ? -1 : 0));

type IProps = SelectProps<string | unknown, DefaultOptionType | BaseOptionType>;

const CountriesSelect = ({ ...props }: IProps) => (
  <StyledSelect
    {...props}
    showSearch
    size="large"
    filterOption={(input, option) => option?.label?.toLowerCase().includes(input?.toLowerCase())}
    placeholder="Country"
    data-testid="select:country"
    options={options}
  />
);

export default CountriesSelect;
