import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 22" width="24" fill="#a2a2a2">
    <path
      d="M20.0912619,18.6725 L1.71126187,0.2925 C1.32126187,-0.0975 0.691261867,-0.0975 0.301261867,0.2925 L0.301261867,0.2925 C-0.0987381326,0.6825 -0.0987381326,1.3125 0.291261867,1.7025 L1.13126187,2.5425 C0.991261867,2.8225 0.911261867,3.1425 0.911261867,3.4825 L0.901261867,15.4825 C0.901261867,16.5925 1.79126187,17.4825 2.90126187,17.4825 L16.0712619,17.4825 L18.6812619,20.0925 C19.0712619,20.4825 19.7012619,20.4825 20.0912619,20.0925 L20.0912619,20.0925 C20.4812619,19.7025 20.4812619,19.0625 20.0912619,18.6725 Z M2.90126187,9.4825 L2.90126187,5.4825 L4.07126187,5.4825 L8.07126187,9.4825 L2.90126187,9.4825 Z M5.73126187,1.4825 L18.9012619,1.4825 C20.0112619,1.4825 20.9012619,2.3725 20.9012619,3.4825 L20.9012619,15.4825 C20.9012619,15.8225 20.8212619,16.1425 20.6712619,16.4225 L13.7312619,9.4825 L18.9012619,9.4825 L18.9012619,5.4825 L9.73126187,5.4825 L5.73126187,1.4825 Z"
      id="path-1"
    />
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
