import { Flex } from 'antd';
import { useState } from 'react';

import { upgradeSubscriptionNew } from 'api/requests';
import {
  SubscriptionPricePlan,
  RecommendedPricePlanBySubscriptionEnrichedOutDto,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import SubscriptionCard from 'components/SubscriptionCard';
import TermsAndConditions from 'components/TermsAndConditions';
import { Text } from 'components/Typography';
import { ManageSubscriptionSteps } from 'lib/contexts/ManageSubscription';
import { useManageSubscription, useMessage } from 'lib/hooks';
import Support from 'pages/ManageSubscription/components/Support';

import PricePlanSelection from './PricePlanSelection';
import { Sider, Content, Footer, ContentLayout } from '../../styled';

const ManageSubscription = () => {
  const { forward, account, subscription, selectedPricePlan } = useManageSubscription();
  const [isUpgradeButtonDisabled, setIsUpgradeButtonDisabled] = useState(false);
  const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
  const message = useMessage();

  const onUpgradeClick = async (plan: RecommendedPricePlanBySubscriptionEnrichedOutDto | null) => {
    try {
      if (subscription && plan) {
        setIsUpgradeLoading(true);
        await upgradeSubscriptionNew(subscription.id, {
          pricePlan: plan?.pricePlan as SubscriptionPricePlan,
          quantity: plan.pricePlan === SubscriptionPricePlan.FreeExtended ? 1 : subscription.quantity,
        });
        setIsUpgradeLoading(false);
        forward(ManageSubscriptionSteps.SUCCESS);
      }
    } catch (error) {
      setIsUpgradeLoading(false);
      message.error(error.response?.data.error?.msg);
    }
  };

  return (
    <>
      <Sider width={350}>
        <Flex vertical gap={16}>
          <Text size="md" strong>
            Your current plan
          </Text>
          <SubscriptionCard subscription={subscription} account={account} />
        </Flex>
        <Support />
      </Sider>
      <ContentLayout>
        {subscription && (
          <>
            <Content>
              <PricePlanSelection onUpgrade={onUpgradeClick} />
            </Content>
            {account?.primaryPaymentSourceId ? (
              <Footer>
                <TermsAndConditions
                  termsType="upgrade"
                  setIsCheckboxValid={(isValid) => setIsUpgradeButtonDisabled(!isValid)}
                />
                <Button
                  loading={isUpgradeLoading}
                  onClick={() => onUpgradeClick(selectedPricePlan)}
                  disabled={isUpgradeButtonDisabled || !selectedPricePlan}
                  size="large"
                  type="primary"
                >
                  Upgrade now
                </Button>
              </Footer>
            ) : (
              <Footer>
                <Text type="secondary">Shown prices are in Euro (EUR) excl. VAT.</Text>
                <Button
                  loading={isUpgradeLoading}
                  size="large"
                  type="primary"
                  onClick={() => forward()}
                  disabled={!selectedPricePlan}
                >
                  Continue to Payment
                </Button>
              </Footer>
            )}
          </>
        )}
      </ContentLayout>
    </>
  );
};

export default ManageSubscription;
