import { useState } from 'react';

import { duplicateSetting } from 'api/requests';
import { useMessage, useNewConfigurations } from 'lib/hooks';

export const useDuplicate = () => {
  const [isDuplicating, setIsDuplicating] = useState('');

  const message = useMessage();
  const { markConfigurationsAsNew } = useNewConfigurations();

  const duplicate = async (settingId: string, isCopyWithPermission?: boolean) => {
    setIsDuplicating('Duplicating Settings-ID');
    try {
      const { settingsId } = (await duplicateSetting(settingId, { withPermissions: isCopyWithPermission })).body;
      markConfigurationsAsNew([settingsId]);
      message.success(`Duplicate settingID ${settingsId} created.`);
    } catch (error) {
      message.error(error?.message);
    } finally {
      setIsDuplicating('');
    }
  };

  return { duplicate, isDuplicating };
};
