/* eslint-disable prefer-promise-reject-errors */
import { Checkbox, Form } from 'antd';
import { useEffect } from 'react';

import { Link } from 'components/Typography';
import { Links } from 'interfaces/enums';
import useAmplitude from 'lib/hooks/useAmplitude';

import { CheckboxItem } from './styled';

interface ITermsAndConditionsProps {
  setIsCheckboxValid: (value: boolean) => void;
  termsType?: 'default' | 'upgrade';
}

interface ITermForm {
  confirmation: boolean;
}

const TermsAndConditions = ({ setIsCheckboxValid, termsType = 'default' }: ITermsAndConditionsProps) => {
  const { deviceId } = useAmplitude();
  const [form] = Form.useForm<ITermForm>();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsCheckboxValid(true);
      },
      () => {
        setIsCheckboxValid(false);
      },
    );
  }, [values]);

  return (
    <Form name="terms" form={form}>
      <CheckboxItem
        name="confirmation"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Required field')),
          },
        ]}
      >
        {termsType === 'upgrade' ? (
          <Checkbox>
            By clicking on the "Upgrade now" button, you are accepting the selected payment terms. The new price will be
            charged to your selected payment option starting from the next billing cycle as per our{' '}
            <Link underline size="sm" href={`${Links.TERMS_AND_CONDITIONS}?deviceId=${deviceId}`} target="_blank">
              Terms and Conditions
            </Link>
            . Please note that clicking on "Upgrade now" results in a binding contract amendment that cannot be
            cancelled.
          </Checkbox>
        ) : (
          <Checkbox>
            I hereby confirm that I have read and accept the{' '}
            <Link underline size="sm" href={`${Links.TERMS_AND_CONDITIONS}?deviceId=${deviceId}`} target="_blank">
              Terms and Conditions
            </Link>
            {' / '}
            <Link underline size="sm" href={`${Links.TERMS_AND_CONDITIONS_USA}?deviceId=${deviceId}`} target="_blank">
              Terms and Conditions (USA)
            </Link>{' '}
            as well as the{' '}
            <Link underline size="sm" href={`${Links.DPA_US}?deviceId=${deviceId}`} target="_blank">
              Data Processing Agreement
            </Link>{' '}
            of the Usercentrics Consent Management Platform.
          </Checkbox>
        )}
      </CheckboxItem>
    </Form>
  );
};

export default TermsAndConditions;
