import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="ic_tag_manager (1) 1" clipPath="url(#clip0_869_17053)">
      <g id="Group">
        <path
          id="Vector"
          d="M11.5949 18.4158L8.41266 15.3127L15.247 8.3335L18.5418 11.5627L11.5949 18.4158Z"
          fill="#8AB4F8"
        />
        <path
          id="Vector_2"
          d="M11.6083 4.69551L8.43751 1.45801L1.50417 8.32572C0.610425 9.21947 0.610425 10.6684 1.50417 11.5632L8.33334 18.4372L11.5625 15.4163L6.35938 9.94447L11.6083 4.69551Z"
          fill="#4285F4"
        />
        <path
          id="Vector_3"
          d="M18.4958 8.37861L11.6208 1.50361C10.726 0.608822 9.27499 0.608822 8.3802 1.50361C7.48541 2.3984 7.48541 3.84945 8.3802 4.74424L15.2552 11.6192C16.15 12.514 17.601 12.514 18.4958 11.6192C19.3906 10.7244 19.3906 9.27341 18.4958 8.37861Z"
          fill="#8AB4F8"
        />
        <path
          id="Vector_4"
          d="M9.94808 19.1667C11.185 19.1667 12.1877 18.164 12.1877 16.9271C12.1877 15.6902 11.185 14.6875 9.94808 14.6875C8.71119 14.6875 7.7085 15.6902 7.7085 16.9271C7.7085 18.164 8.71119 19.1667 9.94808 19.1667Z"
          fill="#246FDB"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_869_17053">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
