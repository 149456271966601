import { Col, Flex, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useEffect, useState } from 'react';

import { getSurveyById, isSurveyAnswered } from 'api/requests/survey';
import { Light } from 'components/Icons';
import Tag from 'components/Tag';
import { Text, Title } from 'components/Typography';
import { ISurveyCategory, ISurveyQuestion } from 'interfaces/responses';

import { SkeletonSurvey } from './SkeletonSurvey';
import { HeaderWrapper, IconWrapper, Modal } from './styled';

const { Item } = Form;

interface ISurveyModalProps {
  onSubmit: (surveyId: string) => void | Promise<void>;
  surveyId: string;
  form: FormInstance;
}

const SurveyModal = ({ onSubmit, surveyId, form }: ISurveyModalProps) => {
  const [categories, setCategories] = useState<(ISurveyCategory & { questions: ISurveyQuestion[] })[] | null>(null);
  const [isLoading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isSurveyAnswered(surveyId).then(({ isAnswered }) => {
      if (!isAnswered) {
        setIsOpen(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isOpen && !categories && !isLoading) {
      (async () => {
        try {
          setLoading(true);
          const result = await getSurveyById(surveyId);

          const questions = result.questions.reduce((acc: { [key: string]: ISurveyQuestion[] }, curr) => {
            acc[curr.categoryId] = acc[curr.categoryId] ? [...acc[curr.categoryId], curr] : [curr];
            return acc;
          }, {});

          const mappedCategories = result.categories.map((category) => ({
            ...category,
            questions: questions[category.categoryId],
          }));

          setCategories(mappedCategories);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [isOpen]);

  const onModalCloseAndSubmit = async () => {
    setIsOpen(false);
    await onSubmit(surveyId);
  };

  if (!categories) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      title={
        <Flex gap={12} align="center">
          <IconWrapper>
            <Light />
          </IconWrapper>
          <Title $noMargin level={2}>
            Tell us about yourself
          </Title>
        </Flex>
      }
      destroyOnClose
      width={740}
      bodyHeight={600}
      maskClosable={false}
      hideFooterBorder
      okText="Get started"
      onSubmit={() => onModalCloseAndSubmit()}
      cancelText="Skip"
      onClose={onModalCloseAndSubmit}
      steps={categories.map((category) => ({
        content: !isLoading ? (
          <Flex vertical gap={20}>
            <HeaderWrapper vertical gap={8} align="center">
              <Flex align="center" gap={8}>
                <Title $noMargin>Tailor your experience</Title>
                <Tag size="large" color="geekblue-inverse" $bolder style={{ borderRadius: '1rem', margin: 0 }}>
                  Optional
                </Tag>
              </Flex>
              <Text type="secondary">
                Help us understand your needs and context better by providing additional information.
              </Text>
            </HeaderWrapper>

            <Form name={category.title} form={form} layout="vertical">
              <Row key={category.categoryId} gutter={[0, 20]}>
                <Col>
                  <Title level={3} $noMargin>
                    {category.title}
                  </Title>
                </Col>
                {category.questions.map(({ id, label, answers }) => (
                  <Col key={id} span={24}>
                    <Flex vertical gap={4}>
                      <Flex justify="space-between" align="center">
                        {label}
                        <Text size="sm">Optional</Text>
                      </Flex>
                      <Item name={id} colon={false} style={{ marginBottom: 0 }}>
                        <Select
                          optionRender={(option) => (
                            <div style={{ height: 'auto', textWrap: 'wrap' }}>{option.label}</div>
                          )}
                          allowClear
                          options={answers}
                          placeholder="Select your choice"
                          size="large"
                        />
                      </Item>
                    </Flex>
                  </Col>
                ))}
              </Row>
            </Form>
          </Flex>
        ) : (
          <SkeletonSurvey />
        ),
        secondaryButton: {
          size: 'large',
        },
        primaryButton: {
          size: 'large',
        },
      }))}
    />
  );
};
export default SurveyModal;
