import { useGetMyAccounts, useGetTrials } from 'api/requests';
import { AccountOutDto, Permission, TrialSubscriptionOutDto } from 'api/requests/generated/generated.schemas';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';

const mergeAccountContext = (
  subscription: TrialSubscriptionOutDto,
  account: AccountOutDto | undefined,
): IUpgradeSubscription => {
  return {
    ...subscription,
    accountName: account?.name || '',
    billingCustomerId: account?.id || '',
    vatNumber: account?.vatNumber || '',
    country: account?.country || '',
  };
};

interface TrialStatus {
  readonly?: boolean;
  shouldShowLockoutScreen?: boolean;
  shouldShowTrialBar?: boolean;
  subscriptionsInTrial?: IUpgradeSubscription[];
  userHasMoreCompaniesInAccounts: boolean;
  expiredTrial?: IUpgradeSubscription | null;
}

const useTrial = (): TrialStatus => {
  const { id, billingCustomerId, isLoading } = useSelectedCompany();
  const { hasAccounts } = usePrivileges();
  const { data: fetchedAccounts, isLoading: isAccountLoading } = useGetMyAccounts({ swr: { enabled: hasAccounts } });
  const { data, isLoading: isTrialsLoading } = useGetTrials(
    { customerId: id },
    { swr: { enabled: Boolean(!isLoading && id && billingCustomerId) } },
  );
  const accounts = fetchedAccounts?.body || [];
  const trialsData = data?.body;

  const currentAccount = accounts?.find((account) => account.id === billingCustomerId);

  const userHasMoreCompaniesInAccounts = Boolean(accounts?.filter((account) => account.companies.length).length);
  const trials = trialsData?.subscriptionsInTrial?.map((subscription) =>
    mergeAccountContext(subscription, currentAccount),
  );

  return {
    readonly: !currentAccount || currentAccount.permission === Permission.READ,
    shouldShowTrialBar: Boolean(id && trialsData?.shouldShowTrialBar),
    shouldShowLockoutScreen: trialsData?.shouldShowLockoutScreen || false,
    userHasMoreCompaniesInAccounts,
    subscriptionsInTrial: isAccountLoading || isTrialsLoading ? undefined : trials,
    expiredTrial: trialsData?.subscriptionsInGracePeriod?.length
      ? mergeAccountContext(trialsData?.subscriptionsInGracePeriod[0], currentAccount)
      : null,
  };
};

export default useTrial;
