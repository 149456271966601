import { Radio, Form, Row, Col, RadioChangeEvent } from 'antd';

import { CreateSettingBodyButtonPrivacyVariant } from 'api/requests/generated/generated.schemas';
import { Fingerprint, Gear, Verified } from 'components/Icons';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { IconWrapper, StyledRadio } from './styled';

const { Item } = Form;

const options = [
  {
    title: 'Fingerprint',
    key: 'fingerprint',
    value: CreateSettingBodyButtonPrivacyVariant.fingerprint,
    icon: (color: string) => <Fingerprint color={color} />,
  },
  {
    title: 'Security',
    key: 'security',
    value: CreateSettingBodyButtonPrivacyVariant.security,
    icon: (color: string) => <Verified color={color} />,
  },
  {
    title: 'Settings',
    key: 'settings',
    value: CreateSettingBodyButtonPrivacyVariant.settings,
    icon: (color: string) => <Gear color={color} />,
  },
];

const PrivacyButton = () => {
  const { setCustomizationDetails, customizationDetails } = useOnboarding();

  const onPrivacyButtonVariantChange = (event: RadioChangeEvent) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      privacyTrigger: { ...prev?.privacyTrigger, buttonPrivacyVariant: event.target.value },
    }));
  };

  return (
    <Item name="buttonPrivacyVariant" style={{ margin: 0 }}>
      <Radio.Group style={{ width: '100%' }} onChange={onPrivacyButtonVariantChange}>
        <Row gutter={[8, 16]}>
          {options.map(({ title, icon, key, value }) => (
            <Col key={key} span={12}>
              <StyledRadio value={value}>
                <IconWrapper background={customizationDetails.privacyTrigger.privacyButtonBackground}>
                  {icon(customizationDetails.privacyTrigger.privacyButtonIcon)}
                </IconWrapper>
                <Text weight={500}>{title}</Text>
              </StyledRadio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </Item>
  );
};

export default PrivacyButton;
