import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)`
  padding: 12px;
  flex-flow: row-reverse;
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.colors.white};

  &:has(> .ant-radio-checked) {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }

  & > span:first-child {
    align-self: end;
    margin-bottom: 3px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
  padding-left: 12px;

  & .anticon {
    display: flex;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
  }
`;
