import { Radio, Typography } from 'antd';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import banner from 'assets/banner.svg';
import phone from 'assets/phone.svg';

import { TypeWrapper } from './styled';
const { Title, Paragraph } = Typography;

interface IType {
  setType: (value: SubscriptionType) => void;
  type: SubscriptionType;
}

const Type = ({ setType, type }: IType) => {
  return (
    <>
      <Title level={3}>Configuration Type</Title>
      <Paragraph> Select the type for your configuration.</Paragraph>
      <Radio.Group style={{ display: 'flex', justifyContent: 'space-between' }} value={type}>
        <TypeWrapper onClick={() => setType(SubscriptionType.web)}>
          <img src={banner} alt="Configuration for Web" />
          <Radio value="web">
            <span className="radio-name" data-testid="configuration-type:web">
              Configuration for Web
            </span>
          </Radio>
        </TypeWrapper>
        <TypeWrapper onClick={() => setType(SubscriptionType.app)}>
          <img src={phone} alt="Configuration for App" />
          <Radio value="app">
            <span className="radio-name" data-testid="configuration-type:app">
              Configuration for App
            </span>
          </Radio>
        </TypeWrapper>
      </Radio.Group>
    </>
  );
};

export default Type;
