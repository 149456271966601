import { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { getCSA } from 'lib/contexts/Privileges';

export const authInterceptor = (config: InternalAxiosRequestConfig) => ({
  ...config,
  withCredentials: true,
  credentials: 'include',
});

export const apiErrorInterceptor = (e: AxiosError): Promise<AxiosError> => {
  const status = e?.response?.status;
  if (status && (status === 403 || status === 404 || status === 500)) {
    window.location.replace(`/${status}`);
  }
  return Promise.reject(e);
};

export const paramsInterceptor = (request: InternalAxiosRequestConfig) => {
  request.params = request.params || {};
  request.params.customerSupportMode = getCSA();

  return request;
};
