import { CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { Row, Col, Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { Ref, useEffect, useState } from 'react';

import { Input, Item } from 'components/Form';
import { Text } from 'components/Typography';

import { StyledCardComponent } from './styled';

const styles = {
  base: {
    '::placeholder': {
      color: '#cecece',
    },
  },
  empty: {},
  invalid: {},
};

const initialErrorsFields = {
  name: '',
  number: '',
  expiry: '',
  cvv: '',
};

interface ICardComponentProps {
  nameForm: FormInstance<{ name: string }>;
  cardRef: Ref<ChargebeeComponents>;
  onError: (isError: boolean) => void;
}

export default ({ cardRef, nameForm, onError }: ICardComponentProps) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>(initialErrorsFields);

  useEffect(() => {
    onError(Boolean(Object.keys(errors).length > 0));
  }, [errors]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Input Required' }));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setErrors(({ name: fieldToExclude, ...fields }) => fields);
    }
  };

  const onChargebeeInputChange = (status: React.ChangeEvent & { field: string; error: Error }) => {
    const { field, error } = status;

    if (error?.message) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setErrors(({ [field]: fieldToExclude, ...fields }) => fields);
    }
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24} style={{ paddingRight: '10px' }}>
        <Form form={nameForm}>
          <Item
            label="Name"
            name="name"
            colon={false}
            requiredMark={false}
            rules={[{ required: true, message: 'Input required' }]}
          >
            <Input placeholder="Name on card" onChange={onNameChange} />
          </Item>
        </Form>
      </Col>

      <StyledCardComponent
        ref={cardRef}
        styles={styles}
        placeholder={{ number: '1234 1234 1234 1234', expiry: 'MM/YY', cvv: 'CVC' }}
        onChange={onChargebeeInputChange}
      >
        <Row gutter={[10, 16]} style={{ width: '100%' }} wrap>
          <Col span={24}>
            <Text strong>Card number</Text>
            <CardNumber className="chargebee-input" />
            <Text type="danger" size="sm">
              {errors?.['number']}
            </Text>
          </Col>
          <Col xs={24} lg={12}>
            <Text strong>Expiration</Text>
            <CardExpiry className="chargebee-input" />
            <Text type="danger" size="sm">
              {errors?.['expiry']}
            </Text>
          </Col>
          <Col xs={24} lg={12}>
            <Text strong>Security Code</Text>
            <CardCVV className="chargebee-input" />
            <Text type="danger" size="sm">
              {errors?.['cvv']}
            </Text>
          </Col>
        </Row>
      </StyledCardComponent>
    </Row>
  );
};
