import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 90%;
  min-width: 768px;
`;

export const Header = styled(Flex)`
  & button {
    white-space: nowrap;
  }

  & svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;
