import { Tooltip } from 'antd';

import { Permission, SearchSettingOutDto, UnassignedSettingsOutDto } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Dots as DotsIcon } from 'components/Icons';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';

interface ISettingMenuProps {
  onEditPermissions: () => void;
  onDuplicate: () => void;
  onUnassign: () => void;
  onDelete: () => void;
  onMoveTo: (() => void) | null;
  setting: SearchSettingOutDto | UnassignedSettingsOutDto;
  disableDuplicate?: boolean;
}

const SettingMenu = ({
  setting,
  onEditPermissions,
  onDuplicate,
  onUnassign,
  onMoveTo,
  onDelete,
  disableDuplicate = false,
}: ISettingMenuProps) => {
  const { isCSA } = usePrivileges();
  const company = useSelectedCompany();

  const shouldShowOptions =
    isCSA ||
    company?.permission === Permission.ADMIN ||
    setting.permission === Permission.ADMIN ||
    setting.permission === Permission.LEGACY_ADMIN;

  const hasRuleSet = Boolean(setting && 'ruleSets' in setting && setting.ruleSets?.length);
  const canUnassign = 'parentId' in setting && setting?.parentId && isCSA;
  const canDuplicate =
    isCSA ||
    (setting?.legacyFeatures?.duplicateSetting &&
      (('parentPermission' in setting && setting?.parentPermission === Permission.ADMIN) ||
        ('parentId' in setting && !setting.parentId)));

  if (!shouldShowOptions) {
    return null;
  }

  const items = [
    {
      label: 'Manage Access',
      key: 'edit',
      hidden: setting?.permission === Permission.READ && !isCSA,
      'data-testid': `menu-item[${setting?.id}]:edit-permissions`,
      onClick: onEditPermissions,
    },
    {
      label: (
        <Tooltip
          title={disableDuplicate ? 'To duplicate this configuration please go to the company level.' : null}
          placement="right"
        >
          Duplicate
        </Tooltip>
      ),
      key: 'duplicate',
      hidden: !canDuplicate,
      'data-testid': `menu-item[${setting?.id}]:duplicate`,
      onClick: onDuplicate,
      disabled: disableDuplicate,
    },
    {
      label: (
        <Tooltip
          title={
            hasRuleSet
              ? 'This configuration cannot be moved to a different company as it is part of a Geolocation Ruleset.'
              : null
          }
          placement="right"
        >
          Move to...
        </Tooltip>
      ),
      key: 'moveTo',
      hidden: !onMoveTo,
      disabled: hasRuleSet,
      'data-testid': `menu-item[${setting?.id}]:move-to`,
      onClick: onMoveTo,
    },
    {
      label: (
        <Tooltip
          title={hasRuleSet ? 'This configuration cannot be unassigned as it is part of a Geolocation Ruleset.' : null}
          placement="right"
        >
          Unassign
        </Tooltip>
      ),
      key: 'unassign',
      hidden: !canUnassign,
      disabled: hasRuleSet,
      'data-testid': `menu-item[${setting?.id}]:unassign`,
      onClick: onUnassign,
    },
    {
      type: 'divider',
      hidden: !isCSA,
    },
    {
      label: (
        <Tooltip
          title={hasRuleSet ? 'This configuration cannot be deleted as it is part of a Geolocation Ruleset.' : null}
          placement="right"
        >
          Delete
        </Tooltip>
      ),
      danger: true,
      key: 'delete',
      hidden: !isCSA,
      disabled: hasRuleSet,
      'data-testid': `menu-item[${setting?.id}]:delete`,
      onClick: onDelete,
    },
  ];

  return (
    <Dropdown items={items}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
        }}
        size="small"
        data-testid={`menu[${setting?.id}]:configurations`}
      >
        <DotsIcon />
      </Button>
    </Dropdown>
  );
};

export default SettingMenu;
