import { Form } from 'antd';
import { useState } from 'react';

import { unassignFromCompany } from 'api/requests';
import { SearchSettingOutDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import MoveOrUnassignConfigurationConfirm from 'components/Modal/MoveOrUnassignConfigurationConfirm';
import { useMessage } from 'lib/hooks';

interface IProps {
  open: boolean;
  onClose: () => void;
  setting: SearchSettingOutDto;
  toggleLoader: (text: string) => void;
}

const UnassignSetting = ({ open, onClose, setting, toggleLoader }: IProps) => {
  const [isCopyWithPermissions, setIsCopyWithPermissions] = useState(false);
  const [moveConfirmForm] = Form.useForm();
  const message = useMessage();

  const onUnassign = async () => {
    try {
      toggleLoader('Unassigning Settings-ID');
      await unassignFromCompany(setting.parentId, setting.id, { withPermissions: isCopyWithPermissions });
      message.success(`[${setting.id}] was successfully unassigned from ${setting?.parentName}`);
    } catch (error) {
      message.error(error?.message);
    } finally {
      toggleLoader('');
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      title="Unassign Configuration?"
      primaryButton={{
        label: 'Unassign',
        onClick: onUnassign,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      isDefaultFooter={false}
      isConfirmation
      onClose={onClose}
    >
      <MoveOrUnassignConfigurationConfirm
        form={moveConfirmForm}
        action="unassign"
        onCheckboxChange={(isChecked: boolean) => setIsCopyWithPermissions(isChecked)}
      />
    </Modal>
  );
};

export default UnassignSetting;
