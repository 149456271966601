import * as amplitude from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, message, Spin } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { createContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMediaQuery } from 'react-responsive';
import { Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { fetcher } from 'api/config/baseApi';
import GenericNotFound from 'components/Errors/GenericErrors';
import { MainLayout, BillingLayout, ManageSubscriptionLayout } from 'components/Layout';
import NotAuthorizedLayout from 'components/Layout/NotAuthorizedLayout';
import { Main as MobileView } from 'components/MobileView';
import { OnboardingType, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import EntitlementProvider from 'lib/contexts/Entitlement';
import ManageSubscriptionProvider from 'lib/contexts/ManageSubscription';
import NewConfigurationsProvider from 'lib/contexts/NewConfigurations';
import { OnboardingContextProvider } from 'lib/contexts/Onboarding';
import PrivilegesProvider, { updateCSA } from 'lib/contexts/Privileges';
import SelectedAccountProvider from 'lib/contexts/SelectedAccount';
import SelectedCompanyProvider from 'lib/contexts/SelectedCompany';
import UpgradePlanProvider from 'lib/contexts/UpgradePlan';
import { trackEvent } from 'lib/helpers/amplitude';
import { getConnection } from 'lib/helpers/auth0';
import { useAuthenticated, useOnboardingAllowed } from 'lib/hooks';
import useAmplitude from 'lib/hooks/useAmplitude';
import useScript from 'lib/hooks/useScript';

// Pages
import Accounts from './Accounts';
import Account from './Accounts/Account';
import BillingAccount from './BillingAccount';
import CompanyConfigurations from './Companies/Configurations';
import CompanyDetailsPage from './Companies/Details';
import GeoRulesets from './Companies/GeoRulesets';
import Companies from './Companies/Overview';
import UserManagement from './Companies/UserManagement';
import ConfigurationsPage from './Configurations';
import AuthErrorPage from './Login/AuthErrorPage';
import Logout from './Logout';
import ManageSubscription from './ManageSubscription';
import GoogleOneClickOnboarding from './Onboarding/GoogleOneClick';
import RegularOnboarding from './Onboarding/Regular';
import Unassigned from './Unassigned';
import Upgrade from './Upgrade';

interface IAppContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: MessageInstance | any;
}

export const AppContext = createContext<IAppContext>({ message: null });

const App = () => {
  const { initialiseAmplitude } = useAmplitude();
  const { isLoading, isAuthorized } = useAuthenticated();
  const { loginWithRedirect, user } = useAuth0();
  const location = useLocation();
  const { isOnboardingLoading, onboardingType } = useOnboardingAllowed(isAuthorized);
  let { state } = useLocation();
  let { isAuthenticated } = useAuth0();
  const [notify, contextHolder] = message.useMessage();

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  useScript('../../beamer.js', { shouldPreventLoad: process.env.REACT_APP_ENV === 'local' });
  useScript('https://3ty1sgzsdzfp.statuspage.io/embed/script.js', {
    shouldPreventLoad: process.env.REACT_APP_ENV === 'local',
  });

  if (process.env.REACT_APP_E2E_TESTS === '1') {
    isAuthenticated = true;
    state = { error: '' };
  }

  message.config({
    duration: 5,
  });

  useEffect(() => {
    initialiseAmplitude();
  }, []);

  useEffect(() => {
    if (user) {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.SET_USER_ID,
          [TrackingVariables.USER_ID]: user.sub,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.LOGIN,
        },
      });
      trackEvent(TrackingEvents.LOGIN);
      amplitude.setUserId(user.sub);
      window.experiment?.fetch({ user_id: amplitude.getUserId() });
    }
  }, [JSON.stringify(user)]);

  useEffect(() => {
    const connection = getConnection(location.search);

    async function checkRoute() {
      if (location.pathname.includes('/signup')) {
        updateCSA(false);
        await loginWithRedirect({
          authorizationParams: { screen_hint: 'signup', connection },
        });
      } else if (location.pathname.includes('/login')) {
        updateCSA(false);
        await loginWithRedirect({ authorizationParams: { connection } });
      }
    }
    checkRoute();
  }, [location, loginWithRedirect]);

  if (state?.error) {
    return (
      <AppContext.Provider value={{ message: notify }}>
        <AuthErrorPage error={state.error} />
      </AppContext.Provider>
    );
  }

  const shouldShowApp = !isLoading && !isOnboardingLoading && onboardingType;

  if (!shouldShowApp) {
    return (
      <Flex align="center" justify="center" style={{ height: '100vh' }}>
        <Spin />
      </Flex>
    );
  }

  if (!isBigScreen) {
    return <MobileView />;
  }

  return (
    <SWRConfig value={{ fetcher }}>
      <Routes>
        {!isAuthenticated && (
          <Route element={<NotAuthorizedLayout />}>
            <Route path="/logout" element={<Logout />} />
          </Route>
        )}
        <Route
          element={
            <AppContext.Provider value={{ message: notify }}>
              <EntitlementProvider>
                <ManageSubscriptionProvider>
                  <NewConfigurationsProvider>
                    <SelectedCompanyProvider>
                      <SelectedAccountProvider>
                        <UpgradePlanProvider onboardingType={onboardingType}>
                          {contextHolder}
                          <Outlet />
                        </UpgradePlanProvider>
                      </SelectedAccountProvider>
                    </SelectedCompanyProvider>
                  </NewConfigurationsProvider>
                </ManageSubscriptionProvider>
              </EntitlementProvider>
            </AppContext.Provider>
          }
        >
          {onboardingType === OnboardingType.ONE_CLICK && (
            <Route path="/onboarding/google-integration" element={<GoogleOneClickOnboarding />} />
          )}
          {onboardingType === OnboardingType.REGULAR && (
            <Route
              path="/details"
              element={
                <OnboardingContextProvider>
                  <RegularOnboarding />
                </OnboardingContextProvider>
              }
            />
          )}

          <Route element={<PrivilegesProvider />}>
            <Route path="/403" element={<GenericNotFound status={403} />} />
            <Route path="/404" element={<GenericNotFound status={404} />} />
            <Route path="/500" element={<GenericNotFound status={500} />} />

            <Route path="/billing" element={<BillingLayout />}>
              <Route path="account/:accountId?" element={<BillingAccount />} />
            </Route>

            <Route element={<MainLayout />}>
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/accounts/:accountId" element={<Account />} />
              <Route path="/unassigned" element={<Unassigned />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/company/:companyId/configurations" element={<CompanyConfigurations />} />
              <Route path="/company/:companyId/user-management" element={<UserManagement />} />
              <Route path="/company/:companyId/details" element={<CompanyDetailsPage />} />
              <Route path="/company/:companyId/geo-rulesets" element={<GeoRulesets />} />
              <Route path="/configurations" element={<ConfigurationsPage />} />
              <Route path="/" element={<Navigate to="companies" replace />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>

            <Route path="/subscription" element={<ManageSubscriptionLayout />}>
              <Route path=":id/manage" element={<ManageSubscription />} />
            </Route>
            <Route path="/company/:companyId/upgrade" element={<Upgrade />} />
          </Route>
          <Route path="/" element={<Navigate to="companies" replace />} />
        </Route>
      </Routes>
    </SWRConfig>
  );
};

export default App;
