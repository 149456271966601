import { SearchProps } from 'antd/es/input';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import React, { useState } from 'react';

import SearchFilters from './SearchFilters';
import { StyledSearch } from './styled';

interface ISearchProps extends SearchProps {
  onSearch: (value: string) => void;
  isLoading?: boolean;
  minLength?: number;
  filterItems?: MenuItemType[];
  defaultFilterOption?: MenuItemType;
  onFilterChange?: (item: MenuItemType) => void;
}

const Search = ({
  onSearch,
  isLoading = false,
  minLength = 0,
  filterItems,
  defaultFilterOption,
  onFilterChange,
  ...props
}: ISearchProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!value || value.length < minLength) {
      onSearch('');
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  return (
    <StyledSearch
      onChange={onInputChange}
      onSearch={(data) => {
        if (data && data.length >= minLength) {
          onSearch(data);
        }
      }}
      allowClear
      loading={isLoading}
      addonBefore={
        Boolean(filterItems?.length) ? (
          <SearchFilters onChange={onFilterChange} items={filterItems} defaultFilterOption={defaultFilterOption} />
        ) : null
      }
      $isButtonDisabled={isButtonDisabled}
      {...props}
    />
  );
};

export default Search;
