/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import type { Arguments } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  AuthControllerAuthenticate200,
  AuthControllerAuthenticatePartner200,
  AuthControllerLogout200,
  AuthControllerVerify200,
  AuthControllerVerifyToken200,
  AuthenticateInDto,
  AuthenticatePartnerInDto,
  FailResponseDto,
  VerifyInDto,
  VerifyTokenInDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Authenticates user via Auth0 token and sets access token cookie
 */
export const authControllerAuthenticate = (authenticateInDto: AuthenticateInDto) => {
  return customInstance<AuthControllerAuthenticate200>({
    url: `/auth/authenticate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: authenticateInDto,
  });
};

export const getAuthControllerAuthenticateMutationFetcher = () => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AuthControllerAuthenticate200> => {
    return authControllerAuthenticate(arg as AuthenticateInDto);
  };
};
export const getAuthControllerAuthenticateMutationKey = () => `/auth/authenticate` as const;

export type AuthControllerAuthenticateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerAuthenticate>>
>;
export type AuthControllerAuthenticateMutationError = FailResponseDto;

/**
 * @summary Authenticates user via Auth0 token and sets access token cookie
 */
export const useAuthControllerAuthenticate = <TError = FailResponseDto>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authControllerAuthenticate>>,
    TError,
    string,
    Arguments,
    Awaited<ReturnType<typeof authControllerAuthenticate>>
  > & { swrKey?: string };
}) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthControllerAuthenticateMutationKey();
  const swrFn = getAuthControllerAuthenticateMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Authenticates Partner API user and produces access token
 */
export const authControllerAuthenticatePartner = (authenticatePartnerInDto: AuthenticatePartnerInDto) => {
  return customInstance<AuthControllerAuthenticatePartner200>({
    url: `/auth/authenticate/partner`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: authenticatePartnerInDto,
  });
};

export const getAuthControllerAuthenticatePartnerMutationFetcher = () => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AuthControllerAuthenticatePartner200> => {
    return authControllerAuthenticatePartner(arg as AuthenticatePartnerInDto);
  };
};
export const getAuthControllerAuthenticatePartnerMutationKey = () => `/auth/authenticate/partner` as const;

export type AuthControllerAuthenticatePartnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerAuthenticatePartner>>
>;
export type AuthControllerAuthenticatePartnerMutationError = FailResponseDto;

/**
 * @summary Authenticates Partner API user and produces access token
 */
export const useAuthControllerAuthenticatePartner = <TError = FailResponseDto>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authControllerAuthenticatePartner>>,
    TError,
    string,
    Arguments,
    Awaited<ReturnType<typeof authControllerAuthenticatePartner>>
  > & { swrKey?: string };
}) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthControllerAuthenticatePartnerMutationKey();
  const swrFn = getAuthControllerAuthenticatePartnerMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Triggers verification email via Auth0
 */
export const authControllerVerify = (verifyInDto: VerifyInDto) => {
  return customInstance<AuthControllerVerify200>({
    url: `/auth/verify`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyInDto,
  });
};

export const getAuthControllerVerifyMutationFetcher = () => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AuthControllerVerify200> => {
    return authControllerVerify(arg as VerifyInDto);
  };
};
export const getAuthControllerVerifyMutationKey = () => `/auth/verify` as const;

export type AuthControllerVerifyMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerVerify>>>;
export type AuthControllerVerifyMutationError = FailResponseDto;

/**
 * @summary Triggers verification email via Auth0
 */
export const useAuthControllerVerify = <TError = FailResponseDto>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authControllerVerify>>,
    TError,
    string,
    Arguments,
    Awaited<ReturnType<typeof authControllerVerify>>
  > & { swrKey?: string };
}) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthControllerVerifyMutationKey();
  const swrFn = getAuthControllerVerifyMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Verifies access token
 */
export const authControllerVerifyToken = (verifyTokenInDto: VerifyTokenInDto) => {
  return customInstance<AuthControllerVerifyToken200>({
    url: `/auth/token/verify`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyTokenInDto,
  });
};

export const getAuthControllerVerifyTokenMutationFetcher = () => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AuthControllerVerifyToken200> => {
    return authControllerVerifyToken(arg as VerifyTokenInDto);
  };
};
export const getAuthControllerVerifyTokenMutationKey = () => `/auth/token/verify` as const;

export type AuthControllerVerifyTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerVerifyToken>>
>;
export type AuthControllerVerifyTokenMutationError = FailResponseDto;

/**
 * @summary Verifies access token
 */
export const useAuthControllerVerifyToken = <TError = FailResponseDto>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authControllerVerifyToken>>,
    TError,
    string,
    Arguments,
    Awaited<ReturnType<typeof authControllerVerifyToken>>
  > & { swrKey?: string };
}) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthControllerVerifyTokenMutationKey();
  const swrFn = getAuthControllerVerifyTokenMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Logs out current logged-in user
 */
export const authControllerLogout = () => {
  return customInstance<AuthControllerLogout200>({ url: `/auth/logout`, method: 'POST' });
};

export const getAuthControllerLogoutMutationFetcher = () => {
  return (_: string, { arg }: { arg: Arguments }): Promise<AuthControllerLogout200> => {
    return authControllerLogout();
  };
};
export const getAuthControllerLogoutMutationKey = () => `/auth/logout` as const;

export type AuthControllerLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerLogout>>>;
export type AuthControllerLogoutMutationError = FailResponseDto;

/**
 * @summary Logs out current logged-in user
 */
export const useAuthControllerLogout = <TError = FailResponseDto>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authControllerLogout>>,
    TError,
    string,
    Arguments,
    Awaited<ReturnType<typeof authControllerLogout>>
  > & { swrKey?: string };
}) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthControllerLogoutMutationKey();
  const swrFn = getAuthControllerLogoutMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
