/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Key, SWRConfiguration } from 'swr';
import type {
  ChannelPartnerControllerGetPartnerName200,
  ChannelPartnerControllerGetPartnerNameParams,
  FailResponseDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * This endpoint is called during a referred customer's sign up to show the partner name the customer will be linked to
 * @summary Returns channel partner name (company name) by referral code
 */
export const channelPartnerControllerGetPartnerName = (params: ChannelPartnerControllerGetPartnerNameParams) => {
  return customInstance<ChannelPartnerControllerGetPartnerName200>({
    url: `/channel-partner/name`,
    method: 'GET',
    params,
  });
};

export const getChannelPartnerControllerGetPartnerNameKey = (params: ChannelPartnerControllerGetPartnerNameParams) =>
  [`/channel-partner/name`, ...(params ? [params] : [])] as const;

export type ChannelPartnerControllerGetPartnerNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof channelPartnerControllerGetPartnerName>>
>;
export type ChannelPartnerControllerGetPartnerNameQueryError = FailResponseDto;

/**
 * @summary Returns channel partner name (company name) by referral code
 */
export const useChannelPartnerControllerGetPartnerName = <TError = FailResponseDto>(
  params: ChannelPartnerControllerGetPartnerNameParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof channelPartnerControllerGetPartnerName>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getChannelPartnerControllerGetPartnerNameKey(params) : null));
  const swrFn = () => channelPartnerControllerGetPartnerName(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
