import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="path-111"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.55 15.753l-2.906-2.996a.844.844 0 01-.236-.588c0-.221.085-.433.236-.589a.79.79 0 011.142 0l2.34 2.404 5.57-5.742a.79.79 0 011.141 0 .85.85 0 010 1.176l-6.145 6.335a.79.79 0 01-1.141 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <mask id="mask-211" fill="#fff">
        <use xlinkHref="#path-111" />
      </mask>
      <g fill="#17855E" mask="url(#mask-211)">
        <path d="M0 0H24V24H0z" />
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
