import styled from 'styled-components';

export const ProgressContainer = styled.div`
  width: 250px;
  height: 12px;
  background: white;
  border: 1px solid ${(props) => props.theme.colors.gray[24]};
  border-radius: 6px;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;
`;

export const Status = styled.div<{ $completed: boolean }>`
  border-radius: 6px;
  height: calc(100% - 2px);
  margin: 1px;
  position: absolute;
  background: ${(props) =>
    props.$completed
      ? props.theme.colors.orange[100]
      : `linear-gradient(to left, ${props.theme.colors.primary} 0.15%, ${props.theme.colors.blue[100]} 49.6%)`};
`;
