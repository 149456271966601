import { Row, Col, Typography, Flex, Divider, List, Space } from 'antd';

import Button from 'components/Button';
import { CopyCode } from 'components/CopyCode';
import { Code } from 'components/CopyCode/styled';
import { CodeIcon, Open, TagManager as TagManagerIcon } from 'components/Icons';
import LinkBox from 'components/LinkBox';
import StyledSegmented from 'components/Segmented';
import { PremiumTagIfOnePricing } from 'components/Tag/PremiumTagIfOnePricing';
import { Text, Title } from 'components/Typography';
import {
  GOOGLE_CONSENT_MODE,
  INTEGRATION_WITH_THIRD_PARTY,
  UC_DOCS,
  getBrowserScriptTagV3WithSettingId,
} from 'lib/consts';
import { colors } from 'lib/theme/colors';
import { CodeIconWrapper } from 'pages/Companies/GeoRulesets/ScriptTag/styled';

import { ListItem, CodeWrapper, Collapse } from './styled';
import { BLOCKING_TYPE } from '../../index';
import { Box } from '../../styled';

const { Paragraph } = Typography;

const Implementation = ({
  settingId,
  isTcf,
  blockingType,
  setBlockingType,
}: {
  settingId: string;
  isTcf: boolean;
  blockingType: BLOCKING_TYPE;
  setBlockingType: (type: BLOCKING_TYPE) => void;
}) => {
  const script = getBrowserScriptTagV3WithSettingId(settingId, isTcf, blockingType === 'auto');

  return (
    <Space direction="vertical" size="middle">
      <Row gutter={40}>
        <Col flex="30%">
          <Flex vertical gap={8} style={{ paddingTop: 16 }}>
            <Title $noMargin level={3} data-testid="empty-placeholder:title">
              Direct implementation
            </Title>
            <Text type="secondary">Integrate the Usercentrics script directly into your website.</Text>
            <Button href={UC_DOCS} type="link" target="_blank">
              <strong>Documentation</strong>
              <Open color={colors.primary} size={20} />
            </Button>
          </Flex>
        </Col>
        <Col flex="70%">
          <Box>
            <Flex vertical gap={16}>
              <Flex vertical gap={8}>
                <Title $noMargin level={4} weight={500} data-testid="empty-placeholder:title">
                  Script Tag
                </Title>
                <Paragraph type="secondary">
                  Copy the script tag below and paste it into the <strong>{'<head>'} section </strong>of your website.
                  Make sure it is placed before any third-party script that requires user consent.
                </Paragraph>
              </Flex>
              <div>
                <StyledSegmented<BLOCKING_TYPE>
                  options={[
                    {
                      label: (
                        <Flex gap={8}>
                          Auto Blocking{' '}
                          <span>
                            <PremiumTagIfOnePricing $noMargin />
                          </span>
                        </Flex>
                      ),
                      value: 'auto',
                    },
                    {
                      label: 'Manual Blocking',
                      value: 'manual',
                    },
                  ]}
                  value={blockingType}
                  onChange={(value) => {
                    setBlockingType(value);
                  }}
                />
              </div>
              <CopyCode
                dataTestId="copy-tag:link[scriptTag]"
                prefix={<CodeIconWrapper color="gradient-blue" />}
                copyText={script}
                background="white"
                successMessage="Script Tag copied to clipboard."
              />
            </Flex>
            <Divider />
            <Collapse
              bordered={false}
              ghost
              expandIconPosition="end"
              items={[
                {
                  key: '1',
                  label: <Text weight={500}>How to place the script correctly?</Text>,
                  children: (
                    <List
                      dataSource={['<html>', '<head>', script, '</head>', '<body>', '...', '</body>', '</html>']}
                      renderItem={(item, index) => (
                        <ListItem>
                          {index === 2 ? (
                            <CodeWrapper>
                              <Code>{item}</Code>
                            </CodeWrapper>
                          ) : (
                            <Text size="sm" type="secondary">
                              {item}
                            </Text>
                          )}
                        </ListItem>
                      )}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col flex="30%">
          <Title level={3} data-testid="empty-placeholder:title">
            Implementation guides
          </Title>
          <Text type="secondary">Let us walk you through other supported implementation methods.</Text>
        </Col>
        <Col flex="70%">
          <Box>
            <Flex vertical gap={8}>
              <LinkBox href={GOOGLE_CONSENT_MODE} title="Google Tag Manager (GTM)" icon={<TagManagerIcon />} />
              <LinkBox href={INTEGRATION_WITH_THIRD_PARTY} title="Other implementation methods" icon={<CodeIcon />} />
            </Flex>
          </Box>
        </Col>
      </Row>
    </Space>
  );
};

export default Implementation;
