import { Radio } from 'antd';
import styled from 'styled-components';

const StyledRadioRound = styled(Radio)`
  .ant-radio + * {
      display: flex;
      gap: 7px;
      align-items: center;
      border: 0.125rem solid ${({ theme }) => theme.colors.gray[50]};
      background: ${({ theme }) => theme.colors.white};
      border-radius: 3.25rem;
      padding: 0.5rem 0.75rem;
    }
  }

  .ant-radio-checked {
    &.ant-radio + * {
      border: 0.125rem solid ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};
      background: none;
 
    }
  }

  .ant-radio {
    display: none;
  }
`;

export default StyledRadioRound;
