import Icon from '@ant-design/icons';

export type CustomIconViewSize = 'default' | 'large';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_503_2334)">
      <path
        d="M5.00065 8.33337C4.08398 8.33337 3.33398 9.08337 3.33398 10C3.33398 10.9167 4.08398 11.6667 5.00065 11.6667C5.91732 11.6667 6.66732 10.9167 6.66732 10C6.66732 9.08337 5.91732 8.33337 5.00065 8.33337ZM15.0007 8.33337C14.084 8.33337 13.334 9.08337 13.334 10C13.334 10.9167 14.084 11.6667 15.0007 11.6667C15.9173 11.6667 16.6673 10.9167 16.6673 10C16.6673 9.08337 15.9173 8.33337 15.0007 8.33337ZM10.0007 8.33337C9.08398 8.33337 8.33398 9.08337 8.33398 10C8.33398 10.9167 9.08398 11.6667 10.0007 11.6667C10.9173 11.6667 11.6673 10.9167 11.6673 10C11.6673 9.08337 10.9173 8.33337 10.0007 8.33337Z"
        fill="#303030"
      />
    </g>
    <defs>
      <clipPath id="clip0_503_2334">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default () => <Icon component={CustomIcon} />;
