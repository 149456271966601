export const colors = {
  primary: '#0045A5',
  primaryText: '#303030',
  secondary: '#595959',
  white: '#FFFFFF',
  black: '#000000',
  orange: {
    100: '#EB6435',
  },
  blue: {
    4: '#f5f8fc',
    8: '#ebf1f8',
    16: '#d6e1f0',
    24: '#7fa2d2',
    50: '#4074bc',
    100: '#0045a5',
    300: '#021836',
  },
  gray: {
    4: '#fbfbfb',
    8: '#f7f7f7',
    10: '#efefef',
    12: '#EBEDEF',
    16: '#eaeaea',
    24: '#dedede',
    50: '#cecece',
    80: '#979797',
  },
  red: {
    8: '#fdeff0',
    50: '#e76374',
    100: '#dd2e44',
  },
  yellow: {
    8: '#fef2eb',
    50: '#ffdd75',
    60: '#FFD401',
    100: '#f25800',
  },
  green: {
    8: '#edf6f3',
    24: '#d4f7eb',
    100: '#17855e',
    200: '#32a852',
  },
  purple: {
    100: '#5800A5',
  },
};
