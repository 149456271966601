import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = ({ color }: { color?: string }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M19.4322087,12.98 C19.4722087,12.66 19.5022087,12.34 19.5022087,12 C19.5022087,11.66 19.4722087,11.34 19.4322087,11.02 L21.5422087,9.37 C21.7322087,9.22 21.7822087,8.95 21.6622087,8.73 L19.6622087,5.27 C19.5422087,5.05 19.2722087,4.97 19.0522087,5.05 L16.5622087,6.05 C16.0422087,5.65 15.4822087,5.32 14.8722087,5.07 L14.4922087,2.42 C14.4622087,2.18 14.2522087,2 14.0022087,2 L10.0022087,2 C9.75220874,2 9.54220874,2.18 9.51220874,2.42 L9.13220874,5.07 C8.52220874,5.32 7.96220874,5.66 7.44220874,6.05 L4.95220874,5.05 C4.72220874,4.96 4.46220874,5.05 4.34220874,5.27 L2.34220874,8.73 C2.21220874,8.95 2.27220874,9.22 2.46220874,9.37 L4.57220874,11.02 C4.53220874,11.34 4.50220874,11.67 4.50220874,12 C4.50220874,12.33 4.53220874,12.66 4.57220874,12.98 L2.46220874,14.63 C2.27220874,14.78 2.22220874,15.05 2.34220874,15.27 L4.34220874,18.73 C4.46220874,18.95 4.73220874,19.03 4.95220874,18.95 L7.44220874,17.95 C7.96220874,18.35 8.52220874,18.68 9.13220874,18.93 L9.51220874,21.58 C9.54220874,21.82 9.75220874,22 10.0022087,22 L14.0022087,22 C14.2522087,22 14.4622087,21.82 14.4922087,21.58 L14.8722087,18.93 C15.4822087,18.68 16.0422087,18.34 16.5622087,17.95 L19.0522087,18.95 C19.2822087,19.04 19.5422087,18.95 19.6622087,18.73 L21.6622087,15.27 C21.7822087,15.05 21.7322087,14.78 21.5422087,14.63 L19.4322087,12.98 Z M12.0022087,15.5 C10.0722087,15.5 8.50220874,13.93 8.50220874,12 C8.50220874,10.07 10.0722087,8.5 12.0022087,8.5 C13.9322087,8.5 15.5022087,10.07 15.5022087,12 C15.5022087,13.93 13.9322087,15.5 12.0022087,15.5 Z"
        id="config"
      />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/configuration">
        <mask id="config2" fill="white">
          <use xlinkHref="#config" />
        </mask>
        <g id="icon/action/settings_24px" fillRule="nonzero" />
        <g id="↳-Color" mask="url(#config2)" fill={color || '#303030'}>
          <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
