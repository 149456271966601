import { Col } from 'antd';
import styled from 'styled-components';

export const PermissionItem = styled(Col)`
  padding: 16px 20px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.gray[8]};

  & svg {
    width: 24px;
    height: 24px;
  }
`;
