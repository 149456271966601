import { Flex } from 'antd';

import Image from 'assets/confetti.svg';
import Button from 'components/Button';
import { Tada as TadaIcon } from 'components/Icons';
import { Title } from 'components/Typography';
import { useManageSubscription } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { StyledModal, IconWrapper } from './styled';

const Success = () => {
  const { forward } = useManageSubscription();

  return (
    <StyledModal
      styles={{ mask: { backgroundColor: colors.white, opacity: 0.95 } }}
      width={730}
      title={
        <Flex gap={12} align="center">
          <IconWrapper>
            <TadaIcon />
          </IconWrapper>
          <Title level={2}>Thank you for your purchase</Title>
        </Flex>
      }
      open
      closable
      maskClosable={false}
      hideFooterBorder
      onClose={forward}
      footer={
        <Button type="primary" onClick={() => forward()}>
          Get Started
        </Button>
      }
    >
      <img src={Image} alt="Confetti" />
    </StyledModal>
  );
};

export default Success;
