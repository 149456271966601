import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useState } from 'react';

import { useSearchSettingsBy } from 'api/requests';

import { searchFilters } from '../consts';

export const useSettingsSearch = (companyId?: string) => {
  const [query, setQuery] = useState('');
  const [currentSearchFilter, setCurrentSearchFilter] = useState<MenuItemType>(searchFilters[0]);
  const [swrFilter, setSWRFilter] = useState<MenuItemType>(searchFilters[0]);

  const { mutate, isLoading, data } = useSearchSettingsBy(
    //@ts-ignore
    { [swrFilter.key.toString()]: query, parentIds: companyId },
    { swr: { enabled: Boolean(companyId) || Boolean(query) } },
  );

  const settings = data?.body || [];

  const onSearch = (searchString: string) => {
    setSWRFilter(currentSearchFilter);
    if (companyId && !searchString) {
      setSWRFilter(searchFilters[0]);
    }
    setQuery(searchString);
  };

  const onFiltersChange = (option: MenuItemType) => {
    setCurrentSearchFilter(option);
  };

  return { settings, isLoading, onSearch, onFiltersChange, mutate, currentSearchFilter, query };
};
