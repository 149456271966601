import styled from 'styled-components';

import { CodeIcon } from 'components/Icons';

export const CodeIconWrapper = styled(CodeIcon)`
  height: 32px;
  width: 32px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary}1a;
  border-radius: 3px;
  box-shadow: 1.667px 1.667px 6.667px 0px rgba(0, 0, 0, 0.05);
`;
