import Dropdown from 'components/Dropdown';
import {
  Help as HelpIcon,
  KnowledgeBase as KnowledgeBaseIcon,
  Documentation as DocumentationIcon,
  Headphones as HeadphonesIcon,
  Feedback as FeedbackIcon,
} from 'components/Icons';
import { Text, Link } from 'components/Typography';
import useAmplitude from 'lib/hooks/useAmplitude';

const getLinkByLanguage = () => {
  const { language } = navigator;

  switch (language) {
    case 'pt-PT':
      return 'https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/1831469272';
    case 'de-DE':
      return 'https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/141557846';
    default:
      return 'https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/176979988';
  }
};

const KnowledgeBase = () => {
  const { deviceId } = useAmplitude();

  const knowledgeBaseItems = [
    {
      key: '1',
      icon: <KnowledgeBaseIcon />,
      label: (
        <Link color="text" href={getLinkByLanguage()} target="_blank">
          Knowledge Base
        </Link>
      ),
    },
    {
      key: '2',
      icon: <DocumentationIcon />,
      label: (
        <Link color="text" href={`https://docs.usercentrics.com/?deviceId=${deviceId}`} target="_blank">
          Documentation
        </Link>
      ),
    },
    {
      key: '3',
      icon: <HeadphonesIcon />,
      label: (
        <Link color="text" href="https://usercentricssupport.zendesk.com/hc/en-us/requests/new" target="_blank">
          Support
        </Link>
      ),
    },
    {
      key: '4',
      icon: <FeedbackIcon />,
      label: <Text>Send Feedback</Text>,
      onClick: () => window.Beamer.forceShowNPS(),
    },
  ];

  return (
    <Dropdown items={knowledgeBaseItems} align={{ offset: [-10, 0] }}>
      <span className="ant-dropdown-link">
        <HelpIcon />
      </span>
    </Dropdown>
  );
};

export default KnowledgeBase;
