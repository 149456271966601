import React, { createContext, useState } from 'react';

import { LS_KEYS } from 'interfaces/enums';

const getNewConfigurations = (): string[] => {
  try {
    return JSON.parse(localStorage.getItem(LS_KEYS.UC_NEW_SETTINGS) || '[]');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't get configurations from local storage", error);
  }

  return [];
};

const updateConfigurations = (configurations: string[]) => {
  try {
    localStorage.setItem(LS_KEYS.UC_NEW_SETTINGS, JSON.stringify(configurations));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't save configurations to local storage", error);
  }
};

type ContextType = {
  newConfigurations: string[];
  markConfigurationsAsNew: (configurationIds: string[]) => void;
  markConfigurationAsOld: (configurationId: string) => void;
};

export const NewConfigurationsContext = createContext<ContextType>({
  newConfigurations: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markConfigurationsAsNew: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markConfigurationAsOld: () => {},
});

const NewConfigurationsProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [configurations, setConfigurations] = useState(() => getNewConfigurations());

  const markConfigurationsAsNew = (configurationIds: string[]) => {
    const newConfigurations = [...configurations, ...configurationIds];
    setConfigurations(newConfigurations);
    updateConfigurations(newConfigurations);
  };

  const markConfigurationAsOld = (configurationId: string) => {
    const newConfigurations = configurations.filter((cid) => cid !== configurationId);
    setConfigurations(newConfigurations);
    updateConfigurations(newConfigurations);
  };

  return (
    <NewConfigurationsContext.Provider
      value={{ newConfigurations: configurations, markConfigurationAsOld, markConfigurationsAsNew }}
    >
      {children}
    </NewConfigurationsContext.Provider>
  );
};

export default NewConfigurationsProvider;
