import { TableProps } from 'antd';

import { ITableStyleProps, StyledTable } from './styled';

export interface ITableProps<T> extends ITableStyleProps, Omit<TableProps<T>, 'pagination'> {
  pagination?: boolean;
}

const Table = <T extends {}>({ pagination = false, ...props }: ITableProps<T>) => (
  <StyledTable
    pagination={pagination ? { position: ['bottomRight'], pageSize: 50, showSizeChanger: false } : false}
    {...props}
  />
);

export default Table;
