import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg width="12px" height="12px" viewBox="0 0 12 12">
    <title>Icon</title>
    <g id="✅-[DPSD-1692]-CSA:-Move-bulk-of-unassigned" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="✅-Configuration---Import" transform="translate(-1011.000000, -107.000000)">
        <g id="Button/Secondary/small-Copy-3" transform="translate(1001.000000, 97.000000)">
          <g id="Icon" transform="translate(8.000000, 8.000000)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M12,10 L12,12 L4,12 L4,10 L2.66666667,10 L2.66666667,12 C2.66666667,12.7333333 3.26666667,13.3333333 4,13.3333333 L12,13.3333333 C12.7333333,13.3333333 13.3333333,12.7333333 13.3333333,12 L13.3333333,10 L12,10 Z M4.66666667,6 L5.60666667,6.94 L7.33333333,5.22 L7.33333333,10.6666667 L8.66666667,10.6666667 L8.66666667,5.22 L10.3933333,6.94 L11.3333333,6 L8,2.66666667 L4.66666667,6 Z"
              id="Shape"
              fill="#303030"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
