import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="test"
  >
    <title>Review</title>
    <defs>
      <path
        d="M6,15 L8.09177489,15 L8.09177489,11.3756064 L9.76796537,11.3756064 L11.6311688,15 L14,15 L11.8943723,11.0637561 C13.0025974,10.6271656 13.7021645,9.53222453 13.7021645,8.21552322 L13.7021645,8.2016632 C13.7021645,6.19196119 12.3722944,5 10.1281385,5 L6,5 L6,15 Z M8.09177489,9.78863479 L8.09177489,6.63548164 L9.87186147,6.63548164 C10.9038961,6.63548164 11.5619048,7.24532225 11.5619048,8.2016632 L11.5619048,8.21552322 C11.5619048,9.1995842 10.9385281,9.78863479 9.8995671,9.78863479 L8.09177489,9.78863479 Z M2,0 L18,0 C19.1045695,-2.02906125e-16 20,0.8954305 20,2 L20,18 C20,19.1045695 19.1045695,20 18,20 L2,20 C0.8954305,20 -8.67738547e-17,19.1045695 0,18 L0,2 C-1.3527075e-16,0.8954305 0.8954305,-1.91384796e-17 2,0 Z"
        id="path-2"
      />
    </defs>
    <g id="[Prototype]" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="[AM]-[Admin]-Activate-Review-Mode-01" transform="translate(-902.000000, -303.000000)">
        <g id="card-company-copy-3" transform="translate(273.000000, 281.000000)">
          <g id="Icon/review-Copy" transform="translate(629.000000, 22.000000)">
            <mask id="mask-22" fill="white">
              <use xlinkHref="#path-2" />
            </mask>
            <g id="icon/action/highlight_off_24px" fillRule="nonzero" />
            <g id="Color" mask="url(#mask-22)" fill="#5800A5">
              <g transform="translate(-2.000000, -2.000000)" id="Rectangle">
                <rect x="0" y="0" width="24" height="24" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
