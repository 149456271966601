import { CheckCircleFilled } from '@ant-design/icons';
import { Flex } from 'antd';

import { Text } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { DotsContainer, Dot } from './styled';

interface IStepper {
  steps: string[];
  activeStep: number;
  name: string;
}

const DotStepper = ({ activeStep, steps, name }: IStepper) => {
  const renderStep = (step: number, text: string) => {
    if (step < activeStep) {
      return (
        <>
          <CheckCircleFilled style={{ color: colors.green[100] }} />
          <Text>{text}</Text>
        </>
      );
    }

    if (step === activeStep) {
      return (
        <>
          <Dot color={colors.primary} />
          <Text color={colors.primary}>{text}</Text>
        </>
      );
    }

    if (step > activeStep) {
      return (
        <>
          <Dot color={colors.gray[24]} />
          <Text>{text}</Text>
        </>
      );
    }

    return (
      <>
        <Dot color={colors.primary} />
        <Text>{text}</Text>
      </>
    );
  };

  return (
    <DotsContainer gap={8}>
      {steps.map((step, index) => (
        <Flex gap={4} align="center" key={`${name}[${step}]`}>
          {renderStep(index, step)}
        </Flex>
      ))}
    </DotsContainer>
  );
};

export default DotStepper;
