import { Alert, Typography } from 'antd';
import { Key, useState } from 'react';

import { useSearchUnassignedSettingsAll, importUnassignedSettings } from 'api/requests';
import { BaseCustomerOutDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import Search from 'components/Search';
import Table from 'components/Table';
import ISetting from 'interfaces/ISetting';
import { useMessage } from 'lib/hooks';

const { Paragraph } = Typography;

interface IImportUnassigned {
  onClose: () => void;
  company: BaseCustomerOutDto;
  open: boolean;
}

const generateColumns = () => [
  {
    title: 'Name/Setting-ID',
    key: 'settingId',
    render: (setting: ISetting) => (
      <div data-testid={`import-unassigned-row[${setting.id}]:setting-id`}>
        {setting.aliasName} [{setting.id}]
      </div>
    ),
  },
  {
    title: 'Domain/App-IDs',
    key: 'domainOrAppId',
    render: ({ appIds, domains }: ISetting) => <> {domains?.join(', ') || '' || appIds?.join(', ')} </>,
  },
  {
    title: 'Data Controller',
    dataIndex: 'dataController',
    key: 'dataController',
  },
];

const ImportUnassigned = ({ open, company, onClose }: IImportUnassigned) => {
  const [selectedConfigurations, setSelectedConfigurations] = useState<string[]>([]);
  const [importing, setImporting] = useState(false);
  const [queryString, setQueryString] = useState('');
  const { isLoading, data } = useSearchUnassignedSettingsAll(
    { q: queryString },
    { swr: { enabled: Boolean(queryString) } },
  );
  const message = useMessage();
  const settings = data?.body || [];

  const onChange = (_: Key[], selectedRows: { key: string }[]) => {
    const selectedRowsFlat = selectedRows.map((setting: { key: string }) => setting.key);
    setSelectedConfigurations(selectedRowsFlat);
  };

  const onImport = async () => {
    try {
      setImporting(true);

      await importUnassignedSettings(company.id, { settingIds: selectedConfigurations });
      message.success(`${selectedConfigurations.length} configurations imported to ${company.name}`);
      setImporting(false);
      onClose();
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    }
  };

  const tableConfigurations = settings.map((setting) => ({ ...setting, key: setting.id }));

  const onSearch = (query: string) => {
    setQueryString(query);
    setSelectedConfigurations([]);
  };

  return (
    <Modal
      open={open}
      title="Import Unassigned Configurations"
      width={760}
      onClose={onClose}
      data-testid="modal:import-unassigned"
      primaryButton={{
        label: 'Import',
        disabled: !selectedConfigurations.length || importing,
        onClick: onImport,
        loading: importing,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
    >
      <Alert
        message="You are viewing this as a support role user"
        style={{ margin: '-40px -40px 40px -40px' }}
        type="info"
        showIcon
      />
      <Paragraph>Search for unassigned configurations you want to move to this company.</Paragraph>
      <Search
        onSearch={onSearch}
        isLoading={isLoading}
        placeholder="Search for Configuration ID, Subscription ID or Email"
        data-testid="input:import-unassigned"
      />
      {Boolean(tableConfigurations?.length) && (
        <Table
          dataSource={tableConfigurations}
          columns={generateColumns()}
          rowSelection={{ onChange, selectedRowKeys: selectedConfigurations }}
        />
      )}
    </Modal>
  );
};

export default ImportUnassigned;
