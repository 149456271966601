import { Radio, Space } from 'antd';
import styled from 'styled-components';

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
`;

export const SpaceWrap = styled(Space)`
  width: 100%;
`;

export const CompanyItem = styled(Radio)`
  border: 1px solid ${({ theme }) => theme.colors.gray[16]};
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  width: 100%;
`;
