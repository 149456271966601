import { Form, Alert, Space, Collapse, Typography, ConfigProvider } from 'antd';
import { useState } from 'react';

import { unlinkCustomer, validateCustomerUnlink } from 'api/requests';
import { InvalidUnlinkOutDto, ValidUnlinkOutDto } from 'api/requests/generated/generated.schemas';
import { Item, Input } from 'components/Form';
import Modal from 'components/Modal/Modal';
import Tag from 'components/Tag';
import { UnlinkCompanyStep } from 'interfaces/steps';
import useMessage from 'lib/hooks/useMessage';

const { Title, Paragraph } = Typography;

interface IUnlinkCompanyProps {
  open: boolean;
  customerId: string;
  companyName: string;
  onClose: () => void;
}

const UnlinkCompany = ({ open, onClose, customerId, companyName }: IUnlinkCompanyProps) => {
  const message = useMessage();
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [validationStatus, setValidationStatus] = useState<Partial<ValidUnlinkOutDto & InvalidUnlinkOutDto> | null>(
    null,
  );
  const [step, setStep] = useState(UnlinkCompanyStep.Validate);
  const [loading, setLoading] = useState(false);

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsButtonDisabled(hasErrors);
  };

  const setInitialState = () => {
    setStep(UnlinkCompanyStep.Validate);
    setValidationStatus(null);
    setIsButtonDisabled(true);
  };

  const onModalClose = () => {
    onClose();
    setInitialState();
  };

  const onValidateClick = async () => {
    try {
      setLoading(true);

      const { billingEmail, chargebeeCustomerId } = await form.validateFields();
      const { body: data } = await validateCustomerUnlink({
        billingEmail,
        chargebeeCustomerId,
        permissionCustomerId: customerId,
      });
      setValidationStatus(data);

      if (data.isValid) {
        setStep(UnlinkCompanyStep.Confirm);
      }
    } catch (error) {
      setValidationStatus({ isValid: false });
      setIsButtonDisabled(true);
      message.error(error.response?.data.error?.msg);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmClick = async () => {
    try {
      setLoading(true);
      const { billingEmail, chargebeeCustomerId } = await form.validateFields();
      await unlinkCustomer({ billingEmail, chargebeeCustomerId, permissionCustomerId: customerId });

      onModalClose();

      message.success(`Successfully unlinked from account ${chargebeeCustomerId}`);
    } catch (error) {
      setInitialState();
      message.error('Confirmation failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: 0,
          },
        },
      }}
    >
      <Modal
        open={open}
        title="Unlink from Account"
        primaryButton={{
          label: step === UnlinkCompanyStep.Validate ? 'Validate' : 'Confirm',
          disabled: isButtonDisabled,
          loading,
          onClick: step === UnlinkCompanyStep.Validate ? onValidateClick : onConfirmClick,
        }}
        secondaryButton={{
          label: 'Cancel',
          onClick: onModalClose,
        }}
        width={740}
        onClose={onModalClose}
        destroyOnClose
      >
        <Title level={3}>Account Validation</Title>
        <Paragraph>
          Enter valid information from Chargebee below to validate a connection with{' '}
          <strong>{companyName.toUpperCaseFirst()}</strong> before unlink.
        </Paragraph>
        {validationStatus?.isValid ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Alert
              message={<>Chargebee Customer [{form.getFieldValue('chargebeeCustomerId')}] successfully validated. </>}
              type="success"
              showIcon
              data-testid="validation-successful"
            />
            {Boolean(validationStatus?.settingSubscriptions?.length) && (
              <Alert
                type="info"
                showIcon
                description={
                  <Collapse
                    ghost
                    expandIconPosition="end"
                    defaultActiveKey="1"
                    items={[
                      {
                        key: '1',
                        label: 'Please note that following configurations will be unassigned from the subscription(s):',
                        children: (
                          <Space wrap>
                            {validationStatus.settingSubscriptions?.map(({ settingId }) => (
                              <Tag color="blue" key={settingId}>
                                {settingId}
                              </Tag>
                            ))}
                          </Space>
                        ),
                      },
                    ]}
                  />
                }
              />
            )}
          </Space>
        ) : (
          validationStatus?.reason && (
            <Alert
              message={`Validation failed. ${validationStatus.reason}. Please try again.`}
              type="error"
              showIcon
              data-testid="validation-error"
            />
          )
        )}
        <Form
          preserve={false}
          form={form}
          name="account-validation"
          onFieldsChange={onFieldsChange}
          style={{ marginTop: 20 }}
        >
          <Item
            label="Chargebee Customer ID"
            name="chargebeeCustomerId"
            tooltip="Identifier for the customer in Chargebee"
            rules={[{ required: true, message: 'Input required' }]}
            data-testid="input:chargebee-customer-id"
          >
            <Input name="chargebeeCustomerId" placeholder="Customer ID" />
          </Item>
          <Item
            label="Chargebee Email"
            name="billingEmail"
            tooltip="Email Address of the customer in Chargebee"
            rules={[{ required: true, message: 'Input required' }]}
            data-testid="input:chargebee-customer-email"
          >
            <Input name="billingEmail" placeholder="Email ID" />
          </Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default UnlinkCompany;
