/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  FailResponseDto,
  RuleSetControllerCreate200,
  RuleSetControllerCreateParams,
  RuleSetControllerDelete200,
  RuleSetControllerDeleteParams,
  RuleSetControllerGetById200,
  RuleSetControllerGetByIdParams,
  RuleSetControllerUpdate200,
  RuleSetControllerUpdateParams,
  RuleSetInDto,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Creates rule set for particular company
 */
export const ruleSetControllerCreate = (ruleSetInDto: RuleSetInDto, params: RuleSetControllerCreateParams) => {
  return customInstance<RuleSetControllerCreate200>({
    url: `/ruleSet`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: ruleSetInDto,
    params,
  });
};

export const getRuleSetControllerCreateMutationFetcher = (params: RuleSetControllerCreateParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<RuleSetControllerCreate200> => {
    return ruleSetControllerCreate(arg as RuleSetInDto, params);
  };
};
export const getRuleSetControllerCreateMutationKey = () => `/ruleSet` as const;

export type RuleSetControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ruleSetControllerCreate>>>;
export type RuleSetControllerCreateMutationError = FailResponseDto;

/**
 * @summary Creates rule set for particular company
 */
export const useRuleSetControllerCreate = <TError = FailResponseDto>(
  params: RuleSetControllerCreateParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof ruleSetControllerCreate>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof ruleSetControllerCreate>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getRuleSetControllerCreateMutationKey();
  const swrFn = getRuleSetControllerCreateMutationFetcher(params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns rule set by id
 */
export const ruleSetControllerGetById = (id: string, params?: RuleSetControllerGetByIdParams) => {
  return customInstance<RuleSetControllerGetById200>({ url: `/ruleSet/${id}`, method: 'GET', params });
};

export const getRuleSetControllerGetByIdKey = (id: string, params?: RuleSetControllerGetByIdParams) =>
  [`/ruleSet/${id}`, ...(params ? [params] : [])] as const;

export type RuleSetControllerGetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof ruleSetControllerGetById>>>;
export type RuleSetControllerGetByIdQueryError = FailResponseDto;

/**
 * @summary Returns rule set by id
 */
export const useRuleSetControllerGetById = <TError = FailResponseDto>(
  id: string,
  params?: RuleSetControllerGetByIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof ruleSetControllerGetById>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getRuleSetControllerGetByIdKey(id, params) : null));
  const swrFn = () => ruleSetControllerGetById(id, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Updates rule set by id
 */
export const ruleSetControllerUpdate = (
  id: string,
  ruleSetInDto: RuleSetInDto,
  params?: RuleSetControllerUpdateParams,
) => {
  return customInstance<RuleSetControllerUpdate200>({
    url: `/ruleSet/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: ruleSetInDto,
    params,
  });
};

export const getRuleSetControllerUpdateMutationFetcher = (id: string, params?: RuleSetControllerUpdateParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<RuleSetControllerUpdate200> => {
    return ruleSetControllerUpdate(id, arg as RuleSetInDto, params);
  };
};
export const getRuleSetControllerUpdateMutationKey = (id: string) => `/ruleSet/${id}` as const;

export type RuleSetControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ruleSetControllerUpdate>>>;
export type RuleSetControllerUpdateMutationError = FailResponseDto;

/**
 * @summary Updates rule set by id
 */
export const useRuleSetControllerUpdate = <TError = FailResponseDto>(
  id: string,
  params?: RuleSetControllerUpdateParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof ruleSetControllerUpdate>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof ruleSetControllerUpdate>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getRuleSetControllerUpdateMutationKey(id);
  const swrFn = getRuleSetControllerUpdateMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Removes rule set by id
 */
export const ruleSetControllerDelete = (id: string, params?: RuleSetControllerDeleteParams) => {
  return customInstance<RuleSetControllerDelete200>({ url: `/ruleSet/${id}`, method: 'DELETE', params });
};

export const getRuleSetControllerDeleteMutationFetcher = (id: string, params?: RuleSetControllerDeleteParams) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<RuleSetControllerDelete200> => {
    return ruleSetControllerDelete(id, params);
  };
};
export const getRuleSetControllerDeleteMutationKey = (id: string) => `/ruleSet/${id}` as const;

export type RuleSetControllerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof ruleSetControllerDelete>>>;
export type RuleSetControllerDeleteMutationError = FailResponseDto;

/**
 * @summary Removes rule set by id
 */
export const useRuleSetControllerDelete = <TError = FailResponseDto>(
  id: string,
  params?: RuleSetControllerDeleteParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof ruleSetControllerDelete>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof ruleSetControllerDelete>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getRuleSetControllerDeleteMutationKey(id);
  const swrFn = getRuleSetControllerDeleteMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
