import { Form } from 'antd';

import { ICustomizationPrivacyTrigger } from 'lib/contexts/Onboarding';
import useOnboarding from 'lib/hooks/useOnboarding';

import PrivacyButton from './PrivacyButton';
import PrivacyColor from './PrivacyColor';
import Size from './Size';
import TabTemplate from '../../TabTemplate';

const PrivacyTriggerTab = () => {
  const [form] = Form.useForm<ICustomizationPrivacyTrigger>();
  const { customizationDetails } = useOnboarding();

  return (
    <Form form={form} initialValues={customizationDetails?.privacyTrigger}>
      <TabTemplate
        items={[
          { title: 'Privacy Button', content: <PrivacyButton />, key: 'privacyBanner' },
          {
            title: 'Color',
            key: 'color',
            content: <PrivacyColor />,
          },
          {
            title: 'Size',
            key: 'size',
            content: <Size />,
          },
        ]}
      />
    </Form>
  );
};

export default PrivacyTriggerTab;
