import { css } from 'styled-components';

export const pr = css`
  position: relative;
`;
export const pa = css`
  position: absolute;
`;

export const resetButton = css`
  background: none;
  border: none;
  outline: none;
`;

export const center = css`
  ${pa}
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexBetween = css`
  display: flex;
  justify-content: space-between;
`;
