import { Form } from 'antd';
import { useState, useEffect } from 'react';

import { RuleSetInDto, RuleSetOutDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';
import { IRule } from 'interfaces/IRuleset';
import ISetting from 'interfaces/ISetting';
import useMessage from 'lib/hooks/useMessage';

import RegionalRule from './Content/RegionalRule';

interface IEditRegionalRulesProps {
  onClose: () => Promise<void>;
  onUpdate: (rulesetId: string, rulesetList: RuleSetInDto) => Promise<void>;
  ruleset: RuleSetOutDto;
  configurations: ISetting[];
}

const EditRegionalRules = ({ onClose, ruleset, onUpdate, configurations }: IEditRegionalRulesProps) => {
  const message = useMessage();
  const [isRuleDisabled, setIsRuleDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedRuleset, setUpdatedRuleset] = useState<RuleSetOutDto | null>(null);
  const [rulesForm] = Form.useForm<{ rules: IRule[] }>();

  useEffect(() => {
    (async () => {
      try {
        setUpdatedRuleset(ruleset);
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    })();
  }, []);

  const onButtonClick = async () => {
    try {
      setIsLoading(true);
      await onUpdate(ruleset.ruleSetId, updatedRuleset || ruleset);
      message.success('Regional Settings updated successfully');
      await onClose();
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setIsLoading(false);
    }
  };

  const onValuesChange = () => {
    const formValues = rulesForm.getFieldsValue();
    setUpdatedRuleset({ ...ruleset, rules: formValues.rules });
  };

  return (
    <Modal
      open
      title="Edit Ruleset"
      primaryButton={{
        label: 'Save changes',
        disabled: isRuleDisabled || !rulesForm.isFieldsTouched(),
        'data-testid': 'button:save-regional-rule',
        loading: isLoading,
        onClick: onButtonClick,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      width={760}
      bodyHeight={500}
      onClose={onClose}
    >
      {updatedRuleset && (
        <Form
          form={rulesForm}
          initialValues={{ rules: ruleset.rules }}
          name="rules"
          onValuesChange={onValuesChange}
          style={{ minHeight: 340 }}
        >
          <RegionalRule
            rulesetSetting={ruleset.defaultRule.settingsId}
            configurationsList={configurations}
            isRuleDisabled={isRuleDisabled}
            setIsRuleDisabled={setIsRuleDisabled}
          />
        </Form>
      )}
    </Modal>
  );
};

export default EditRegionalRules;
