/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  AttachSettingsToSubscriptionInDto,
  CreateSubscriptionBasedOnPreviousInDto,
  EntitlementControllerGetEntitlementBySettingId200,
  EntitlementControllerGetEntitlementBySettingIdParams,
  EntitlementControllerGetEntitlementBySubscriptionId200,
  EntitlementControllerGetEntitlementBySubscriptionIdParams,
  EntitlementControllerGetQuotaBySettingId200,
  EntitlementControllerGetQuotaBySettingIdParams,
  EntitlementControllerGetQuotaBySubscriptionId200,
  EntitlementControllerGetQuotaBySubscriptionIdParams,
  EstimateUpgradeInDto,
  EstimationControllerEstimateSubscription200,
  EstimationControllerEstimateSubscriptionParams,
  EstimationControllerEstimateUpgrade200,
  EstimationControllerEstimateUpgradeParams,
  FailResponseDto,
  RecommendedPricePlanControllerGetRecommendedPlan200,
  RecommendedPricePlanControllerGetRecommendedPlanParams,
  SubscriptionControllerAttachSettingsToSubscription200,
  SubscriptionControllerAttachSettingsToSubscriptionParams,
  SubscriptionControllerCreateSubscriptionBasedOnPrevious200,
  SubscriptionControllerListSubscriptions200,
  SubscriptionControllerListSubscriptionsParams,
  SubscriptionTierControllerGetSubscriptionTiers200,
  SubscriptionTierControllerGetSubscriptionTiersParams,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns list of subscriptions of billing account of company
 */
export const subscriptionControllerListSubscriptions = (params: SubscriptionControllerListSubscriptionsParams) => {
  return customInstance<SubscriptionControllerListSubscriptions200>({ url: `/subscriptions`, method: 'GET', params });
};

export const getSubscriptionControllerListSubscriptionsKey = (params: SubscriptionControllerListSubscriptionsParams) =>
  [`/subscriptions`, ...(params ? [params] : [])] as const;

export type SubscriptionControllerListSubscriptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerListSubscriptions>>
>;
export type SubscriptionControllerListSubscriptionsQueryError = FailResponseDto;

/**
 * @summary Returns list of subscriptions of billing account of company
 */
export const useSubscriptionControllerListSubscriptions = <TError = FailResponseDto>(
  params: SubscriptionControllerListSubscriptionsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof subscriptionControllerListSubscriptions>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSubscriptionControllerListSubscriptionsKey(params) : null));
  const swrFn = () => subscriptionControllerListSubscriptions(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Attaches settings to subscription
 */
export const subscriptionControllerAttachSettingsToSubscription = (
  id: string,
  attachSettingsToSubscriptionInDto: AttachSettingsToSubscriptionInDto,
  params?: SubscriptionControllerAttachSettingsToSubscriptionParams,
) => {
  return customInstance<SubscriptionControllerAttachSettingsToSubscription200>({
    url: `/subscriptions/${id}/settings`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: attachSettingsToSubscriptionInDto,
    params,
  });
};

export const getSubscriptionControllerAttachSettingsToSubscriptionMutationFetcher = (
  id: string,
  params?: SubscriptionControllerAttachSettingsToSubscriptionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SubscriptionControllerAttachSettingsToSubscription200> => {
    return subscriptionControllerAttachSettingsToSubscription(id, arg as AttachSettingsToSubscriptionInDto, params);
  };
};
export const getSubscriptionControllerAttachSettingsToSubscriptionMutationKey = (id: string) =>
  `/subscriptions/${id}/settings` as const;

export type SubscriptionControllerAttachSettingsToSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerAttachSettingsToSubscription>>
>;
export type SubscriptionControllerAttachSettingsToSubscriptionMutationError = FailResponseDto;

/**
 * @summary Attaches settings to subscription
 */
export const useSubscriptionControllerAttachSettingsToSubscription = <TError = FailResponseDto>(
  id: string,
  params?: SubscriptionControllerAttachSettingsToSubscriptionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof subscriptionControllerAttachSettingsToSubscription>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof subscriptionControllerAttachSettingsToSubscription>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSubscriptionControllerAttachSettingsToSubscriptionMutationKey(id);
  const swrFn = getSubscriptionControllerAttachSettingsToSubscriptionMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Creates subscription based on previous. Used during upgrade in grace period based on trial subscription
 */
export const subscriptionControllerCreateSubscriptionBasedOnPrevious = (
  billingAccountId: string,
  subscriptionId: string,
  createSubscriptionBasedOnPreviousInDto: CreateSubscriptionBasedOnPreviousInDto,
) => {
  return customInstance<SubscriptionControllerCreateSubscriptionBasedOnPrevious200>({
    url: `/accounts/${billingAccountId}/subscriptions/${subscriptionId}/new`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSubscriptionBasedOnPreviousInDto,
  });
};

export const getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationFetcher = (
  billingAccountId: string,
  subscriptionId: string,
) => {
  return (
    _: string,
    { arg }: { arg: Arguments },
  ): Promise<SubscriptionControllerCreateSubscriptionBasedOnPrevious200> => {
    return subscriptionControllerCreateSubscriptionBasedOnPrevious(
      billingAccountId,
      subscriptionId,
      arg as CreateSubscriptionBasedOnPreviousInDto,
    );
  };
};
export const getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationKey = (
  billingAccountId: string,
  subscriptionId: string,
) => `/accounts/${billingAccountId}/subscriptions/${subscriptionId}/new` as const;

export type SubscriptionControllerCreateSubscriptionBasedOnPreviousMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerCreateSubscriptionBasedOnPrevious>>
>;
export type SubscriptionControllerCreateSubscriptionBasedOnPreviousMutationError = FailResponseDto;

/**
 * @summary Creates subscription based on previous. Used during upgrade in grace period based on trial subscription
 */
export const useSubscriptionControllerCreateSubscriptionBasedOnPrevious = <TError = FailResponseDto>(
  billingAccountId: string,
  subscriptionId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof subscriptionControllerCreateSubscriptionBasedOnPrevious>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof subscriptionControllerCreateSubscriptionBasedOnPrevious>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ??
    getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationKey(billingAccountId, subscriptionId);
  const swrFn = getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationFetcher(
    billingAccountId,
    subscriptionId,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns entitlement by setting id
 */
export const entitlementControllerGetEntitlementBySettingId = (
  settingId: string,
  params?: EntitlementControllerGetEntitlementBySettingIdParams,
) => {
  return customInstance<EntitlementControllerGetEntitlementBySettingId200>({
    url: `/config/${settingId}/entitlement`,
    method: 'GET',
    params,
  });
};

export const getEntitlementControllerGetEntitlementBySettingIdKey = (
  settingId: string,
  params?: EntitlementControllerGetEntitlementBySettingIdParams,
) => [`/config/${settingId}/entitlement`, ...(params ? [params] : [])] as const;

export type EntitlementControllerGetEntitlementBySettingIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof entitlementControllerGetEntitlementBySettingId>>
>;
export type EntitlementControllerGetEntitlementBySettingIdQueryError = FailResponseDto;

/**
 * @summary Returns entitlement by setting id
 */
export const useEntitlementControllerGetEntitlementBySettingId = <TError = FailResponseDto>(
  settingId: string,
  params?: EntitlementControllerGetEntitlementBySettingIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof entitlementControllerGetEntitlementBySettingId>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!settingId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEntitlementControllerGetEntitlementBySettingIdKey(settingId, params) : null));
  const swrFn = () => entitlementControllerGetEntitlementBySettingId(settingId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns quota by setting id
 */
export const entitlementControllerGetQuotaBySettingId = (
  settingId: string,
  params: EntitlementControllerGetQuotaBySettingIdParams,
) => {
  return customInstance<EntitlementControllerGetQuotaBySettingId200>({
    url: `/config/${settingId}/quota`,
    method: 'GET',
    params,
  });
};

export const getEntitlementControllerGetQuotaBySettingIdKey = (
  settingId: string,
  params: EntitlementControllerGetQuotaBySettingIdParams,
) => [`/config/${settingId}/quota`, ...(params ? [params] : [])] as const;

export type EntitlementControllerGetQuotaBySettingIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof entitlementControllerGetQuotaBySettingId>>
>;
export type EntitlementControllerGetQuotaBySettingIdQueryError = FailResponseDto;

/**
 * @summary Returns quota by setting id
 */
export const useEntitlementControllerGetQuotaBySettingId = <TError = FailResponseDto>(
  settingId: string,
  params: EntitlementControllerGetQuotaBySettingIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof entitlementControllerGetQuotaBySettingId>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!settingId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEntitlementControllerGetQuotaBySettingIdKey(settingId, params) : null));
  const swrFn = () => entitlementControllerGetQuotaBySettingId(settingId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns entitlement by subscription id
 */
export const entitlementControllerGetEntitlementBySubscriptionId = (
  subscriptionId: string,
  params?: EntitlementControllerGetEntitlementBySubscriptionIdParams,
) => {
  return customInstance<EntitlementControllerGetEntitlementBySubscriptionId200>({
    url: `/subscription/${subscriptionId}/entitlement`,
    method: 'GET',
    params,
  });
};

export const getEntitlementControllerGetEntitlementBySubscriptionIdKey = (
  subscriptionId: string,
  params?: EntitlementControllerGetEntitlementBySubscriptionIdParams,
) => [`/subscription/${subscriptionId}/entitlement`, ...(params ? [params] : [])] as const;

export type EntitlementControllerGetEntitlementBySubscriptionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof entitlementControllerGetEntitlementBySubscriptionId>>
>;
export type EntitlementControllerGetEntitlementBySubscriptionIdQueryError = FailResponseDto;

/**
 * @summary Returns entitlement by subscription id
 */
export const useEntitlementControllerGetEntitlementBySubscriptionId = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: EntitlementControllerGetEntitlementBySubscriptionIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof entitlementControllerGetEntitlementBySubscriptionId>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEntitlementControllerGetEntitlementBySubscriptionIdKey(subscriptionId, params) : null));
  const swrFn = () => entitlementControllerGetEntitlementBySubscriptionId(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns quota by subscription id
 */
export const entitlementControllerGetQuotaBySubscriptionId = (
  subscriptionId: string,
  params: EntitlementControllerGetQuotaBySubscriptionIdParams,
) => {
  return customInstance<EntitlementControllerGetQuotaBySubscriptionId200>({
    url: `/subscription/${subscriptionId}/quota`,
    method: 'GET',
    params,
  });
};

export const getEntitlementControllerGetQuotaBySubscriptionIdKey = (
  subscriptionId: string,
  params: EntitlementControllerGetQuotaBySubscriptionIdParams,
) => [`/subscription/${subscriptionId}/quota`, ...(params ? [params] : [])] as const;

export type EntitlementControllerGetQuotaBySubscriptionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof entitlementControllerGetQuotaBySubscriptionId>>
>;
export type EntitlementControllerGetQuotaBySubscriptionIdQueryError = FailResponseDto;

/**
 * @summary Returns quota by subscription id
 */
export const useEntitlementControllerGetQuotaBySubscriptionId = <TError = FailResponseDto>(
  subscriptionId: string,
  params: EntitlementControllerGetQuotaBySubscriptionIdParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof entitlementControllerGetQuotaBySubscriptionId>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEntitlementControllerGetQuotaBySubscriptionIdKey(subscriptionId, params) : null));
  const swrFn = () => entitlementControllerGetQuotaBySubscriptionId(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns estimate for particular subscription (upgrade from trial/grace period)
 */
export const estimationControllerEstimateSubscription = (
  subscriptionId: string,
  params?: EstimationControllerEstimateSubscriptionParams,
) => {
  return customInstance<EstimationControllerEstimateSubscription200>({
    url: `/subscriptions/${subscriptionId}/estimate`,
    method: 'GET',
    params,
  });
};

export const getEstimationControllerEstimateSubscriptionKey = (
  subscriptionId: string,
  params?: EstimationControllerEstimateSubscriptionParams,
) => [`/subscriptions/${subscriptionId}/estimate`, ...(params ? [params] : [])] as const;

export type EstimationControllerEstimateSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof estimationControllerEstimateSubscription>>
>;
export type EstimationControllerEstimateSubscriptionQueryError = FailResponseDto;

/**
 * @summary Returns estimate for particular subscription (upgrade from trial/grace period)
 */
export const useEstimationControllerEstimateSubscription = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: EstimationControllerEstimateSubscriptionParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof estimationControllerEstimateSubscription>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEstimationControllerEstimateSubscriptionKey(subscriptionId, params) : null));
  const swrFn = () => estimationControllerEstimateSubscription(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns estimate for subscription upgrade
 */
export const estimationControllerEstimateUpgrade = (
  subscriptionId: string,
  estimateUpgradeInDto: EstimateUpgradeInDto,
  params?: EstimationControllerEstimateUpgradeParams,
) => {
  return customInstance<EstimationControllerEstimateUpgrade200>({
    url: `/subscriptions/${subscriptionId}/upgrade-new/estimate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: estimateUpgradeInDto,
    params,
  });
};

export const getEstimationControllerEstimateUpgradeMutationFetcher = (
  subscriptionId: string,
  params?: EstimationControllerEstimateUpgradeParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<EstimationControllerEstimateUpgrade200> => {
    return estimationControllerEstimateUpgrade(subscriptionId, arg as EstimateUpgradeInDto, params);
  };
};
export const getEstimationControllerEstimateUpgradeMutationKey = (subscriptionId: string) =>
  `/subscriptions/${subscriptionId}/upgrade-new/estimate` as const;

export type EstimationControllerEstimateUpgradeMutationResult = NonNullable<
  Awaited<ReturnType<typeof estimationControllerEstimateUpgrade>>
>;
export type EstimationControllerEstimateUpgradeMutationError = FailResponseDto;

/**
 * @summary Returns estimate for subscription upgrade
 */
export const useEstimationControllerEstimateUpgrade = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: EstimationControllerEstimateUpgradeParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof estimationControllerEstimateUpgrade>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof estimationControllerEstimateUpgrade>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getEstimationControllerEstimateUpgradeMutationKey(subscriptionId);
  const swrFn = getEstimationControllerEstimateUpgradeMutationFetcher(subscriptionId, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of tiers for particular subscription
 */
export const subscriptionTierControllerGetSubscriptionTiers = (
  subscriptionId: string,
  params?: SubscriptionTierControllerGetSubscriptionTiersParams,
) => {
  return customInstance<SubscriptionTierControllerGetSubscriptionTiers200>({
    url: `/subscriptions/${subscriptionId}/tiers`,
    method: 'GET',
    params,
  });
};

export const getSubscriptionTierControllerGetSubscriptionTiersKey = (
  subscriptionId: string,
  params?: SubscriptionTierControllerGetSubscriptionTiersParams,
) => [`/subscriptions/${subscriptionId}/tiers`, ...(params ? [params] : [])] as const;

export type SubscriptionTierControllerGetSubscriptionTiersQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionTierControllerGetSubscriptionTiers>>
>;
export type SubscriptionTierControllerGetSubscriptionTiersQueryError = FailResponseDto;

/**
 * @summary Returns list of tiers for particular subscription
 */
export const useSubscriptionTierControllerGetSubscriptionTiers = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: SubscriptionTierControllerGetSubscriptionTiersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof subscriptionTierControllerGetSubscriptionTiers>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getSubscriptionTierControllerGetSubscriptionTiersKey(subscriptionId, params) : null));
  const swrFn = () => subscriptionTierControllerGetSubscriptionTiers(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns the recommended price plans
 */
export const recommendedPricePlanControllerGetRecommendedPlan = (
  subscriptionId: string,
  params: RecommendedPricePlanControllerGetRecommendedPlanParams,
) => {
  return customInstance<RecommendedPricePlanControllerGetRecommendedPlan200>({
    url: `/recommended-price-plan/${subscriptionId}`,
    method: 'GET',
    params,
  });
};

export const getRecommendedPricePlanControllerGetRecommendedPlanKey = (
  subscriptionId: string,
  params: RecommendedPricePlanControllerGetRecommendedPlanParams,
) => [`/recommended-price-plan/${subscriptionId}`, ...(params ? [params] : [])] as const;

export type RecommendedPricePlanControllerGetRecommendedPlanQueryResult = NonNullable<
  Awaited<ReturnType<typeof recommendedPricePlanControllerGetRecommendedPlan>>
>;
export type RecommendedPricePlanControllerGetRecommendedPlanQueryError = FailResponseDto;

/**
 * @summary Returns the recommended price plans
 */
export const useRecommendedPricePlanControllerGetRecommendedPlan = <TError = FailResponseDto>(
  subscriptionId: string,
  params: RecommendedPricePlanControllerGetRecommendedPlanParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof recommendedPricePlanControllerGetRecommendedPlan>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getRecommendedPricePlanControllerGetRecommendedPlanKey(subscriptionId, params) : null));
  const swrFn = () => recommendedPricePlanControllerGetRecommendedPlan(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
