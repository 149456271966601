import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <defs>
      <path
        d="M10,10 C11.8416667,10 13.3333333,8.50833333 13.3333333,6.66666667 C13.3333333,4.825 11.8416667,3.33333333 10,3.33333333 C8.15833333,3.33333333 6.66666667,4.825 6.66666667,6.66666667 C6.66666667,8.50833333 8.15833333,10 10,10 Z M10,11.6666667 C7.775,11.6666667 3.33333333,12.7833333 3.33333333,15 L3.33333333,15.8333333 C3.33333333,16.2916667 3.70833333,16.6666667 4.16666667,16.6666667 L15.8333333,16.6666667 C16.2916667,16.6666667 16.6666667,16.2916667 16.6666667,15.8333333 L16.6666667,15 C16.6666667,12.7833333 12.225,11.6666667 10,11.6666667 Z"
        id="path-133"
      />
    </defs>
    <g xmlns="http://www.w3.org/2000/svg" id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="page/sidebar-user-inactive" transform="translate(-8, -8)">
        <g id="icon" transform="translate(8.000000, 10.000000)">
          <mask id="mask-233" fill="white">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#path-133" />
          </mask>
          <g id="icon/social/person_24px" fillRule="nonzero" />
          <g id="↳-Color" mask="url(#mask-233)" fill="#303030">
            <rect id="user-management" x="0" y="0" width="20" height="20" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
