import { useAccountConsumption, useGetMyAccounts } from 'api/requests';
import { Permission } from 'api/requests/generated/generated.schemas';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';

const useUsageStatus = () => {
  const { isCSA, hasAccounts } = usePrivileges();
  const { data: fetchedAccounts, isLoading: fetchingAccounts } = useGetMyAccounts({ swr: { enabled: hasAccounts } });
  const { id, billingCustomerId, isLoading } = useSelectedCompany();
  const isBillingAdmin =
    fetchedAccounts?.body?.find((acc) => acc.id === billingCustomerId)?.permission === Permission.ADMIN;

  const { data: consumptionData, isLoading: isConsumptionLoading } = useAccountConsumption(
    billingCustomerId,
    {
      periodicity: 'month',
      periods: 12,
    },
    { swr: { enabled: Boolean(!fetchingAccounts && isBillingAdmin && !isLoading && id && billingCustomerId) } },
  );

  const consumption = consumptionData?.body?.consumption || {};
  const subsWithOveruse = Object.keys(consumption).filter((sub) => consumption[sub].overuse.shouldNotifyAboutOveruse);

  return {
    shouldShowUsageBar: subsWithOveruse.length > 0 && !isCSA,
    shouldRedirect: subsWithOveruse.length > 1,
    consumption,
    isConsumptionLoading,
  };
};

export default useUsageStatus;
