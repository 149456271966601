import { Col, Flex, Form, FormInstance, Row, Typography } from 'antd';

import {
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
} from 'api/requests/generated/generated.schemas';
import { PremiumTagIfOnePricing } from 'components/Tag/PremiumTagIfOnePricing';
import { Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import BannerLayout from './BannerLayout';
import ButtonColors from './ButtonsColors';
import PrivacyButton from './PrivacyButton';
import { Box } from '../../styled';
import { ITrialCustomisationForm } from '../../types';

const { Paragraph } = Typography;

const sections = [
  {
    title: 'Layout',
    key: 'layout',
    description: 'Choose the layout for your consent banner.',
    content: (form: FormInstance) => <BannerLayout form={form} />,
  },
  {
    title: 'Button Colors',
    key: 'button_colors',
    description: 'Choose the color for buttons.',
    content: (form: FormInstance) => <ButtonColors form={form} />,
  },
  {
    title: 'Privacy Button',
    key: 'privacy_button',
    description: 'Choose an icon. The button allows your users to access the banner',
    content: () => <PrivacyButton />,
  },
];

const initialValues: ITrialCustomisationForm = {
  firstLayerVariant: CreateSettingBodyFirstLayerVariant.WALL,
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant.fingerprint,
  buttonBackgroundColor: colors.primary,
  buttonTextColor: colors.white,
};

interface ICustomizationProps {
  form: FormInstance;
}

const CustomizationStep = ({ form }: ICustomizationProps) => {
  return (
    <Flex vertical gap={24}>
      {sections.map((section) => (
        <Row gutter={[40, 0]} wrap justify="space-between" key={section.key}>
          <Col span={7}>
            <Flex vertical gap={8}>
              <Flex>
                <PremiumTagIfOnePricing />
              </Flex>
              <Title level={3} $noMargin>
                {section.title}
              </Title>
              <Paragraph type="secondary">{section.description}</Paragraph>
            </Flex>
          </Col>
          <Col span={17}>
            <Form form={form} initialValues={initialValues}>
              <Box>{section.content(form)}</Box>
            </Form>
          </Col>
        </Row>
      ))}
    </Flex>
  );
};

export default CustomizationStep;
