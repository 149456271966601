import { Form } from 'antd';
import { FormItemProps } from 'antd/lib';
import styled from 'styled-components';

import { Tooltip as TooltipIcon } from 'components/Icons';
import { pa } from 'shared-components/styled';

import { overlayInnerStyle } from './Tooltip';

type TWeight = 'bold' | 'medium';

interface IStyledItemProps {
  requiredMark?: boolean;
  weight?: TWeight;
}

const StyledItem = styled(Form.Item)<IStyledItemProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 8px;

  & .ant-form-item-row {
    flex-wrap: wrap;
  }

  & .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }

  & .ant-form-item-label {
    text-align: left;
    margin-bottom: 4px;
    font-weight: ${(props) => (props.weight === 'medium' ? 500 : 600)};
  }

  & .ant-form-item-control {
    min-width: auto;
  }

  & div {
    width: 100%;
  }

  & .ant-form-item-explain {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red};
    margin-top: 4px;
  }

  &&&& .ant-form-item-required {
    &:after {
      content: ${(props) => (props.requiredMark ? '"*"' : ' ')};
      visibility: visible;
    }

    &:before {
      content: '';
    }
  }

  & .ant-form-item-tooltip {
    ${pa}
    left: 100%;

    & svg path {
      fill: ${({ theme }) => theme.colors.gray[24]};
    }
  }
`;

interface IItemProps extends Omit<FormItemProps, 'tooltip'> {
  tooltip?: string | React.ReactElement;
}

const Item = ({ children, requiredMark = true, weight = 'bold', tooltip, ...props }: IItemProps & IStyledItemProps) => (
  <StyledItem
    requiredMark={requiredMark}
    weight={weight}
    tooltip={tooltip ? { icon: <TooltipIcon />, placement: 'right', overlayInnerStyle, title: tooltip } : null}
    {...props}
  >
    {children}
  </StyledItem>
);

export default Item;
