import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { DotsWrapper, Wave } from './styled';

interface IDotsLoaderProps {
  open: boolean;
  text?: string;
}

const Portal = ({ ...props }) => {
  const [modalContainer] = useState(document.createElement('div'));
  useEffect(() => {
    let modalRoot = document.getElementById('dots-loader');
    if (!modalRoot) {
      const tempEl = document.createElement('div');
      tempEl.id = 'dots-loader';
      document.body.append(tempEl);
      modalRoot = tempEl;
    }

    modalRoot.appendChild(modalContainer);
    return function cleanup() {
      modalRoot?.removeChild(modalContainer);
    };
  }, []);
  return createPortal(props.children, modalContainer);
};

const DotsLoader = ({ open, text }: IDotsLoaderProps) => (
  <Portal>
    {open && (
      <div>
        <DotsWrapper>
          <Wave>
            <p>{text}</p>
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </Wave>
        </DotsWrapper>
      </div>
    )}
  </Portal>
);

export default DotsLoader;
