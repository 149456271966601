import { Row } from 'antd';
import { Outlet } from 'react-router-dom';

import Footer from 'components/Footer';
import Logo from 'components/Logo';

import { Layout, NotAuthorizedContent } from './styled';

const { Header } = Layout;

const NotAuthorizedLayout = () => {
  return (
    <Layout>
      <Header>
        <Row justify="center">
          <Logo />
        </Row>
      </Header>
      <NotAuthorizedContent>
        <Outlet />
      </NotAuthorizedContent>
      <Footer />
    </Layout>
  );
};

export default NotAuthorizedLayout;
