import { Divider, Flex } from 'antd';

import Button from 'components/Button';
import { PremiumTagIfOnePricing } from 'components/Tag/PremiumTagIfOnePricing';
import { Title, Text } from 'components/Typography';

import {
  TabsContentContainer,
  CustomizationItem,
  ItemsContainer,
  Footer,
  PreviewContainer,
  StyledCollapse,
} from './styled';
import Preview from '../Preview';

interface ITabLayoutProps {
  items: {
    title: string;
    content: React.ReactNode;
    key: string;
    collapsible?: boolean;
  }[];
}

const TabTemplate = ({ items }: ITabLayoutProps) => {
  return (
    <TabsContentContainer>
      <ItemsContainer>
        {items.map((item, index) => (
          <div key={item.key}>
            {item.collapsible ? (
              <>
                <StyledCollapse
                  bordered={false}
                  items={[
                    {
                      label: (
                        <Flex justify="space-between">
                          {item.title} <PremiumTagIfOnePricing />
                        </Flex>
                      ),
                      children: item.content,
                      key: item.key,
                    },
                  ]}
                  expandIconPosition="end"
                />
                <Divider />
              </>
            ) : (
              <>
                <CustomizationItem gap={16} vertical>
                  <Flex justify="space-between">
                    <Title level={4} $noMargin>
                      {item.title}
                    </Title>
                    <PremiumTagIfOnePricing />
                  </Flex>
                  {item.content}
                </CustomizationItem>
                {index !== items.length - 1 && <Divider />}
              </>
            )}
          </div>
        ))}
      </ItemsContainer>
      <PreviewContainer vertical>
        <Preview />
        <Footer gap={16} align="center" justify="space-between">
          <Text size="sm" type="secondary">
            If you don’t want to customize right now you can skip this step and continue with the default settings.
          </Text>
          <Flex gap={16}>
            <Button size="large">Skip</Button>
            <Button size="large" type="primary">
              Save and next
            </Button>
          </Flex>
        </Footer>
      </PreviewContainer>
    </TabsContentContainer>
  );
};

export default TabTemplate;
