import { Tag as AntTag } from 'antd';
import styled, { css } from 'styled-components';

import { ITagProps } from '.';

const newStyles = css`
  color: white;
  background: ${(props) => props.theme.colors.green[100]};
  border-radius: 14px;
  padding: 3px 8px;

  & svg {
    margin-right: 6px;
  }
`;

const minmaxSize = (size: number) => css`
  min-width: ${size}px;
  max-width: ${size}px;
  min-height: ${size}px;
  max-height: ${size}px;
`;

const square = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const smallSquare = css`
  ${square}
  ${minmaxSize(20)}
`;

const mediumSquare = css`
  ${square}
  ${minmaxSize(32)}
`;

const largeSquare = css`
  ${square}
  ${minmaxSize(36)}
`;

export const StyledTag = styled(AntTag)<ITagProps>`
  font-weight: ${(props) => (props.$bolder ? 600 : props.$bold ? 500 : 400)};
  max-height: 32px;
  display: inline-flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray[12]};

  ${(props) => props.$noMargin && 'margin-inline-end: 0;'}

  ${(props) => props.size === 'small' && 'padding: 1px 3px;'}

  ${(props) => props.size === 'normal' && 'padding: 2px 6px;'}

  ${(props) => props.size === 'medium' && 'padding: 5px 8px; line-height: 16px;'}

  ${(props) => props.size === 'middle' && 'padding: 7px; font-size: 14px;'}

  ${(props) => props.size === 'large' && 'padding: 8px 12px; font-size: 13px;  max-width: 76px;'}

  ${(props) => props.size === 'square-large' && largeSquare}

  ${(props) => props.size === 'square-medium' && mediumSquare}

  ${(props) => props.size === 'square-small' && smallSquare}

  &.ant-tag-red {
    color: ${({ theme }) => theme.colors.red[50]};
    background-color: ${({ theme }) => theme.colors.red[8]};
  }

  &.ant-tag-green {
    color: ${({ theme }) => theme.colors.green[200]};
    background-color: ${({ theme }) => theme.colors.green[8]};
    border: 1px solid ${({ theme }) => theme.colors.green[100] + '1A'};
  }

  &.ant-tag-gold {
    color: ${({ theme }) => theme.colors.yellow[100]};
    background-color: ${({ theme }) => theme.colors.yellow[8]};
  }

  &.ant-tag-blue {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ant-tag-blue-inverse {
    border: ${(props) => (props.bordered ? `1px solid ${props.theme.colors.primary}1a` : 'none')};
    background-color: ${({ theme }) => theme.colors.blue[8]};
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ant-tag-geekblue-inverse {
    border: none;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ant-tag-purple {
    color: ${({ theme }) => theme.colors.purple[100]};
  }

  &.ant-tag-default {
    color: ${({ theme }) => theme.colors.blue[300]};
    background: ${({ theme }) => theme.colors.gray[12]};
  }

  &.ant-tag-orange {
    color: ${({ theme }) => theme.colors.primaryText};
    background: ${({ theme }) => theme.colors.white}9a;
    box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.black}11;
    border-radius: 4px;
    position: relative;
    border: none;
    background: none;
    z-index: 1;

    &:after {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: white;
      border-radius: inherit;
      position: absolute;
      top: 1px;
      left: 1px;
      z-index: -1;
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: inherit;
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.colors.yellow[60]} 0%,
        ${({ theme }) => theme.colors.yellow[100]} 100%
      );
    }
  }

  &.ant-tag-orange-inverse {
    color: ${({ theme }) => theme.colors.primaryText};
    background: ${({ theme }) => theme.colors.white};
    border: none;
    border-radius: 4px;
  }

  ${(props) => props.transparent && `color: ${props.theme.colors.gray[80]}; background: transparent;margin: 0;`}

  & > a + span {
    margin-left: 8px;
  }

  svg {
    max-width: 17px;
    max-height: 17px;
  }

  & .anticon {
    vertical-align: text-bottom;
  }

  ${(props) => props.$newStyle && newStyles};

  ${(props) => props.$rounded && 'border-radius: 1.25rem;padding: 0 0.5rem;'}
`;
