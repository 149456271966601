import { Form } from 'antd';

import { assignToCompany, moveSettingToCompany } from 'api/requests';
import { SearchSettingOutDto, UnassignedSettingsOutDto } from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import SelectCompany from 'components/SelectCompany/SelectCompany';
import { useMessage } from 'lib/hooks';

import MoveOrUnassignConfigurationConfirm from './MoveOrUnassignConfigurationConfirm';

const { useForm, useWatch } = Form;

interface IMoveToSettingProps {
  open: boolean;
  onClose: () => void;
  withoutPermissionsStep?: boolean;
  setting: SearchSettingOutDto | UnassignedSettingsOutDto;
}

const MoveTo = ({ open, withoutPermissionsStep = false, onClose, setting }: IMoveToSettingProps) => {
  const message = useMessage();

  const [selectCompanyForm] = useForm();
  const [moveConfirmForm] = useForm();
  const selectedCompany = useWatch(['company'], { form: selectCompanyForm, preserve: true });

  const moveConfigurations = async () => {
    try {
      if (selectedCompany) {
        const { id, name } = JSON.parse(selectedCompany);
        const { copyWithPermissions: isCopyWithPermissions } = moveConfirmForm.getFieldsValue(true);

        if (!('parentId' in setting) || !setting.parentId) {
          await assignToCompany(id, setting.id, {
            copyWithPermissions: isCopyWithPermissions,
            skipAttachToSubscription: false,
          });
        } else {
          await moveSettingToCompany(setting.parentId, setting.id, {
            targetCustomerId: id,
            withPermissions: isCopyWithPermissions,
          });
        }

        message.success(`[${setting.id}] moved to ${name}`);

        onClose();
      }
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    }
  };

  return (
    <StepModal
      open={open}
      onClose={onClose}
      okText="Move To Company"
      steps={[
        {
          content: <SelectCompany form={selectCompanyForm} />,
          title: <>Move Configuration [{setting.id}]</>,
          handleNext: withoutPermissionsStep ? moveConfigurations : undefined,
          primaryButton: {
            disabled: !selectedCompany,
          },
        },
        !withoutPermissionsStep
          ? {
              content: <MoveOrUnassignConfigurationConfirm form={moveConfirmForm} />,
              title: 'Move Configuration?',
              handleNext: moveConfigurations,
            }
          : undefined,
      ]}
    />
  );
};

export default MoveTo;
