import { Form } from 'antd';

import { ICustomizationStyling } from 'lib/contexts/Onboarding';
import useOnboarding from 'lib/hooks/useOnboarding';

import BackgroundOverlay from './BackgroundOverlay';
import { buttonColors, layoutColors, toggleColors } from './colorOptions';
import Colors from './Colors';
import TabTemplate from '../../TabTemplate';

const StylingTab = () => {
  const [layoutForm] = Form.useForm<ICustomizationStyling>();
  const { customizationDetails } = useOnboarding();

  const initialValues = {
    ...customizationDetails?.styling,
    overlayOpacity: customizationDetails?.styling.overlayOpacity * 100,
  };

  return (
    <Form form={layoutForm} initialValues={initialValues}>
      <TabTemplate
        items={[
          {
            title: 'Background Overlay',
            key: 'backgroundOverlay',
            content: <BackgroundOverlay />,
          },
          {
            title: 'Button Colors',
            key: 'buttonColors',
            content: <Colors colors={buttonColors} />,
          },
          {
            title: 'Layout Colors',
            key: 'layoutColors',
            collapsible: true,
            content: <Colors colors={layoutColors} />,
          },
          {
            title: 'Toggle Colors',
            key: 'toggleColors',
            collapsible: true,
            content: <Colors colors={toggleColors} />,
          },
        ]}
      />
    </Form>
  );
};

export default StylingTab;
