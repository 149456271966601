import { Spin } from 'antd';
import React, { createContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetMyAccounts } from 'api/requests';
import {
  AccountOutDto,
  RecommendedPricePlanBySubscriptionEnrichedOutDto,
  SubscriptionDto,
} from 'api/requests/generated/generated.schemas';
import { isTrialExpired } from 'lib/helpers';

export enum ManageSubscriptionSteps {
  SELECT = 'select',
  PAYMENT = 'payment',
  SUCCESS = 'success',
}

const defaultState = {
  step: ManageSubscriptionSteps.SELECT,
  subscription: null,
  account: null,
  selectedPricePlan: null,
};

export const ManageSubscriptionContext = createContext<{
  open: (subscriptionId: string) => void;
  back: () => void;
  forward: (step?: ManageSubscriptionSteps) => void;
  setSelectedPricePlan: React.Dispatch<React.SetStateAction<RecommendedPricePlanBySubscriptionEnrichedOutDto | null>>;
  selectedPricePlan: RecommendedPricePlanBySubscriptionEnrichedOutDto | null;
  step: ManageSubscriptionSteps;
  account: Omit<AccountOutDto, 'subscriptions'> | null;
  subscription: SubscriptionDto | null;
}>({
  ...defaultState,
  open: () => null,
  back: () => null,
  forward: () => null,
  setSelectedPricePlan: () => null,
});

const ManageSubscriptionProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setStep] = useState(ManageSubscriptionSteps.SELECT);
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [selectedPricePlan, setSelectedPricePlan] = useState<RecommendedPricePlanBySubscriptionEnrichedOutDto | null>(
    null,
  );

  const { data: accounts, isLoading } = useGetMyAccounts();

  if (isLoading) {
    return <Spin fullscreen />;
  }

  const { account, subscription } = (accounts?.body || []).reduce<{
    subscription: SubscriptionDto | null;
    account: Omit<AccountOutDto, 'subscriptions'> | null;
  }>(
    (acc, curr) => {
      const { subscriptions, ...currentAccount } = curr;

      const sub = subscriptions.find((currentsub) => currentsub.id === id);

      if (sub) {
        return {
          account: currentAccount as Omit<AccountOutDto, 'subscriptions'>,
          subscription: sub,
        };
      }
      return acc;
    },
    { account: null, subscription: null },
  );

  const open = (subId: string) => {
    setStep(ManageSubscriptionSteps.SELECT);
    navigate(`/subscription/${subId}/manage`);
  };

  const back = () => {
    switch (step) {
      case ManageSubscriptionSteps.PAYMENT: {
        setStep(ManageSubscriptionSteps.SELECT);
        return;
      }

      default: {
        setSelectedPricePlan(null);
        if (subscription && isTrialExpired(subscription)) {
          return navigate('/companies');
        }
        return window.history.length > 1 ? navigate(-1) : navigate('/billing/account');
      }
    }
  };

  const forward = (prefferedStep?: ManageSubscriptionSteps) => {
    if (prefferedStep) {
      setStep(prefferedStep);
      return;
    }

    switch (step) {
      case ManageSubscriptionSteps.SELECT: {
        setStep(ManageSubscriptionSteps.PAYMENT);
        return;
      }
      case ManageSubscriptionSteps.PAYMENT: {
        setStep(ManageSubscriptionSteps.SUCCESS);
        return;
      }

      case ManageSubscriptionSteps.SUCCESS: {
        setStep(ManageSubscriptionSteps.SELECT);
        navigate('/companies');
        return;
      }
    }
  };

  return (
    <ManageSubscriptionContext.Provider
      value={{ open, back, account, subscription, forward, step, selectedPricePlan, setSelectedPricePlan }}
    >
      {children}
    </ManageSubscriptionContext.Provider>
  );
};

export default ManageSubscriptionProvider;
