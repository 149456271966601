import { Alert, Form, Flex } from 'antd';
import { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { estimateUpgradeNew, useGetMyAccounts } from 'api/requests';
import { accountControllerUpgradeSubscription } from 'api/requests/generated/account/account';
import {
  EstimationOutDto,
  SubscriptionDto,
  SubscriptionType,
  SubscriptionPricePlan,
} from 'api/requests/generated/generated.schemas';
import Image from 'assets/confetti.svg';
import Button from 'components/Button';
import {
  Card as CardIcon,
  Clock as ClockIcon,
  Lightning as LightningIcon,
  Tada as TadaIcon,
  Wallet as WalletIcon,
} from 'components/Icons';
import { Link, Text } from 'components/Typography';
import { TrackingEvents, TrackingVariables, Links, OnboardingType } from 'interfaces/enums';
import { UpgradePlanStep } from 'interfaces/steps';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { getPlanName } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import { useChargebee, useMessage, usePrivileges, useTrial, useUpgradePlan } from 'lib/hooks';

import AccountDetails from './Steps/AccountDetails';
import PaymentDetailsForm, { RefType } from './Steps/PaymentDetails';
import Tiers from './Steps/Tiers';
import Upgrade from './Steps/Upgrade';
import { UpgradeModal } from './styled';

interface IUpgradePlan {
  readonly?: boolean;
  open: boolean;
  trial?: IUpgradeSubscription;
  isAllFeaturesVisible?: boolean;
  onClose: () => void;
}

const getModalTitle = (step: UpgradePlanStep, isLockout: boolean, exceeded: object | null) => {
  if (isLockout && step !== UpgradePlanStep.Success) {
    return (
      <Flex align="center">
        <ClockIcon />
        <span data-testid="lockout-header">Your trial period has expired</span>
      </Flex>
    );
  }

  if (step === UpgradePlanStep.Upgrade) {
    return (
      <>
        <LightningIcon color="gradient-blue" /> <span>{exceeded ? ' Upgrade required' : 'Upgrade your plan'}</span>
      </>
    );
  }

  if (step === UpgradePlanStep.AccountDetails) {
    return (
      <>
        <WalletIcon /> <span>Enter account information</span>
      </>
    );
  }

  if (step === UpgradePlanStep.Payment) {
    return (
      <>
        <CardIcon color="gradient-blue" /> <span>Enter payment details</span>
      </>
    );
  }

  if (step === UpgradePlanStep.Success) {
    return (
      <>
        <TadaIcon /> <span>Thank you for your purchase</span>
      </>
    );
  }
};

const UpgradePlan = ({ readonly, open, trial, onClose, isAllFeaturesVisible }: IUpgradePlan) => {
  const scriptStatus = useChargebee();
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const message = useMessage();
  const { subscription: subscriptionToUpgrade, exceeded, onboardingType } = useUpgradePlan();
  const { expiredTrial, userHasMoreCompaniesInAccounts } = useTrial();
  const { data: accounts } = useGetMyAccounts();
  const paymentDetailsRef = useRef<RefType>(null);
  const [selectedTier, setSelectedTier] = useState<number>(exceeded?.recommendedTier || 0);
  const [isBuyNowButtonDisabled, setIsBuyNowButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(UpgradePlanStep.Upgrade);
  const [estimation, setEstimation] = useState<EstimationOutDto>();
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);
  const [nameForm] = Form.useForm();
  const { isCSA } = usePrivileges();

  const isLockout = Boolean(trial);
  const canCloseModal = userHasMoreCompaniesInAccounts || !isLockout;
  const subscription = subscriptionToUpgrade || trial;
  const isOneClickOnboarding = onboardingType === OnboardingType.ONE_CLICK;

  if (!subscription) return null;

  const getUpdatedAccounts = async () => {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await mutate('/accounts/my');
  };

  const onModalClose = async () => {
    setIsLoading(true);
    await getUpdatedAccounts();
    setIsLoading(false);
    onClose();
    navigate('/');
  };

  const onConfirmPaymentClick = async () => {
    try {
      if (paymentDetailsRef.current) {
        setIsLoading(true);
        const result = await paymentDetailsRef.current.onConfirmPayment();

        if (result) {
          setStep(UpgradePlanStep.Success);

          /**
           * Tracking events
           */
          trackEvent(TrackingEvents.TRIAL_CONVERTED, {
            [TrackingVariables.PLAN_NAME]: getPlanName(subscription?.pricePlan, subscription?.type),
          });

          TagManager.dataLayer({
            dataLayer: {
              event: TrackingEvents.TRIAL_CONVERTED,
              [TrackingVariables.PLAN_NAME]: getPlanName(subscription?.pricePlan, subscription?.type),
              [TrackingVariables.PLATFORM]: expiredTrial?.type || SubscriptionType.web,
            },
          });
          /**
           * ====
           */
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onUpgrade = async () => {
    setIsLoading(true);
    try {
      await accountControllerUpgradeSubscription(subscription.id, { newQuantity: selectedTier });
      /**
       * Tracking events
       */
      trackEvent(TrackingEvents.SUBSCRIPTION_UPGRADED, {
        [TrackingVariables.SUBSCRIPTION_ID]: subscription.id,
      });
      /**
       * ====
       */
      await new Promise((resolve) => setTimeout(resolve, 1000));
      mutate(['/accounts/my']);
      const acc = accounts?.body?.find((account) => account.subscriptions.find((sub) => sub.id === subscription.id));
      if (acc) {
        mutate([
          `/accounts/${acc.id}/consumption`,
          {
            periodicity: 'month',
            periods: 12,
          },
        ]);
      }
      location.state = { subscriptionUpgraded: subscription.id };
      setIsLoading(false);
      onClose();
    } catch (e) {
      message.error(e.response?.data.error?.msg);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (exceeded) return;
      const initialEstimation = (
        await estimateUpgradeNew(subscription.id, {
          quantity: (subscription.maxValue || subscription.quantity) as number,
          pricePlan: subscription.pricePlan as SubscriptionPricePlan,
        })
      ).body;
      setEstimation(initialEstimation);
    })();
  }, []);

  /**
   * Tracking events
   */
  useEffect(() => {
    if (step === UpgradePlanStep.Upgrade && exceeded) {
      trackEvent(TrackingEvents.UPGRADE_SUBSCRIPTION_VIEWED, {
        [TrackingVariables.SUBSCRIPTION_ID]: subscription.id,
        [TrackingVariables.FORCIBLY_OPENED]: exceeded.forcedUpgrade,
      });
    }
    if (step === UpgradePlanStep.Payment) {
      trackEvent(TrackingEvents.PAYMENT_DETAILS_VIEWED, {
        [TrackingVariables.PLAN_NAME]: getPlanName(subscription?.pricePlan, subscription?.type),
      });
    }
    if (step === UpgradePlanStep.Success) {
      trackEvent(TrackingEvents.CREDIT_CARD_DATA_STORED, {
        [TrackingVariables.PLAN_NAME]: getPlanName(subscription?.pricePlan, subscription?.type),
      });
    }
  }, [step]);

  return (
    <UpgradeModal
      open={open}
      width={720}
      onCancel={isLockout ? () => navigate('/') : onClose}
      maskClosable={false}
      centered
      closable
      data-testid="modal:upgrade"
      title={getModalTitle(step, isLockout, exceeded)}
      hideCloseIcon={!canCloseModal}
      keyboard={canCloseModal}
      footer={
        <>
          {!readonly && step === UpgradePlanStep.Upgrade && exceeded?.recommendedTier && (
            <Flex vertical>
              <Button type="primary" data-testid="button:buy-now" onClick={onUpgrade} loading={isLoading}>
                Upgrade now
              </Button>
              <Text
                align="center"
                type="secondary"
                style={{ padding: '12px 10px', lineHeight: '1.43', fontSize: '0.68rem' }}
              >
                By clicking on the "Upgrade now" button, you are accepting the selected payment terms. The new price
                will be charged to your selected payment option starting from the next billing cycle as per our{' '}
                <Link href={Links.TERMS_AND_CONDITIONS} weight={500} size="sm">
                  Terms and Conditions
                </Link>
                . Please note that clicking on "Upgrade now" results in a binding contract amendment that cannot be
                cancelled.
              </Text>
            </Flex>
          )}
          {step === UpgradePlanStep.Payment && (
            <>
              <Button
                onClick={() => setStep(isOneClickOnboarding ? UpgradePlanStep.AccountDetails : UpgradePlanStep.Upgrade)}
              >
                Back
              </Button>
              <Button type="primary" loading={isLoading} onClick={onConfirmPaymentClick} disabled={isPaymentDisabled}>
                Confirm Payment
              </Button>
            </>
          )}
          {step === UpgradePlanStep.Success && (
            <Button loading={isLoading} type="primary" onClick={onModalClose}>
              Get Started
            </Button>
          )}
        </>
      }
    >
      {isCSA && (
        <>
          <Alert
            message={
              <Text>
                You are viewing this as a support role user.{' '}
                <Link onClick={onClose} strong>
                  Proceed to company
                </Link>
                <br />
              </Text>
            }
            type="info"
            showIcon
          />
          <br />
        </>
      )}
      {step === UpgradePlanStep.Upgrade &&
        (exceeded ? (
          <Tiers
            subscription={subscription as unknown as SubscriptionDto}
            selectedTier={selectedTier}
            setSelectedTier={setSelectedTier}
          />
        ) : (
          <Upgrade
            isLockout={isLockout}
            readonly={readonly}
            subscription={subscription}
            estimation={estimation}
            isButtonDisabled={isBuyNowButtonDisabled}
            setIsButtonDisabled={setIsBuyNowButtonDisabled}
            isAllFeaturesVisible={isAllFeaturesVisible}
            handleNext={() => setStep(isOneClickOnboarding ? UpgradePlanStep.AccountDetails : UpgradePlanStep.Payment)}
          />
        ))}
      {step === UpgradePlanStep.AccountDetails && (
        <AccountDetails
          handleNext={async () => {
            setStep(UpgradePlanStep.Payment);
            setEstimation(undefined);
          }}
          subscription={subscription}
        />
      )}
      <PaymentDetailsForm
        ref={paymentDetailsRef}
        isAvailable={step === UpgradePlanStep.Payment}
        nameForm={nameForm}
        scriptStatus={scriptStatus}
        expiredTrial={Boolean(expiredTrial)}
        subscription={subscription}
        setIsPaymentDisabled={setIsPaymentDisabled}
        initialEstimation={estimation}
      />
      {step === UpgradePlanStep.Success && <img src={Image} alt="Confetti" />}
    </UpgradeModal>
  );
};

export default UpgradePlan;
