import { useState } from 'react';

import { SearchSettingOutDto, UnassignedSettingsOutDto } from 'api/requests/generated/generated.schemas';
import Modal from 'components/Modal/Modal';

interface IEditCompanyDetailsProps {
  open: boolean;
  setting: SearchSettingOutDto | UnassignedSettingsOutDto;
  onClose: () => void;
  onDuplicate: (setting: string, isCheckboxChecked: boolean) => void;
}

const DuplicateSetting = ({ open, onClose, onDuplicate, setting }: IEditCompanyDetailsProps) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal
      open={open}
      title="Duplicate Configuration?"
      primaryButton={{
        label: 'Duplicate',
        'data-testid': 'button:duplicate-configuration',
        onClick: () => {
          onDuplicate(setting.id, isChecked);
          onClose();
        },
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      checkbox
      checkboxLabel="Copy User Permissions"
      isDefaultFooter={false}
      isConfirmation
      onClose={onClose}
      onCheckboxChange={(isCheckboxChecked) => {
        setIsChecked(isCheckboxChecked);
      }}
    >
      Please confirm that you want to duplicate this Setting. If you want to copy all existing users with their current
      permissions, please activate the checkbox below.
    </Modal>
  );
};

export default DuplicateSetting;
