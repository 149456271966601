import { Checkbox, Typography, Card, Col, Row, Form } from 'antd';
import { FormInstance } from 'antd/lib';

import { Item, Input, Tooltip } from 'components/Form';
import { StyledSelect as Select } from 'components/Select/Select';
import { Text } from 'components/Typography';
import ISetting from 'interfaces/ISetting';

const { Title, Paragraph } = Typography;

interface IProps {
  configurations: ISetting[];
  form: FormInstance;
  initialValues?: GlobalSettingFormType;
}

export type GlobalSettingFormType = {
  settingsId: string | undefined;
  noShow: boolean;
};

const INIT_DATA: GlobalSettingFormType = {
  settingsId: undefined,
  noShow: false,
};

const GlobalSetting = ({ configurations, form, initialValues }: IProps) => {
  return (
    <Form name="global-setting" form={form} initialValues={initialValues || INIT_DATA}>
      <Row justify="space-between" gutter={[0, 7]}>
        <Title level={3}>Global Settings</Title>
        <Paragraph>
          Each ruleset contains a <b>Global Rule</b>. It serves as a default if no other rules are specified. Therefore
          it is mandatory to be set. However you can define if the assigned configuration should be displayed to your
          users.
        </Paragraph>
        <Col span={7}>
          <Card size="small">
            <Text strong>Global Rule</Text>
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <Item colon={false} label="Rule Name">
              <Input placeholder="Rule Name" defaultValue="Default (Global)" disabled />
            </Item>
            <Item
              colon={false}
              label="Region"
              tooltip="The Global Rule applies in all regions by default. Specific regions can be defined by adding Regional Rules to your Ruleset."
            >
              <Input placeholder="Rule Name" defaultValue="All (Global)" disabled />
            </Item>
            <Item
              colon={false}
              rules={[{ required: true, message: 'Input required' }]}
              label="Global Configuration"
              name="settingsId"
              tooltip="The configuration assigned to the Global Rule serves as a mandatory default for this Ruleset."
            >
              <Select
                options={configurations.map((configuration) => ({
                  value: configuration.id,
                  label: `${configuration?.aliasName || ''} [${configuration.id}]`,
                }))}
                data-testid="select:global-configuration"
                placeholder="Select configuration"
                size="large"
              />
            </Item>
            <Item name="noShow" valuePropName="checked" noStyle>
              <Checkbox>Do not display CMP layer</Checkbox>
            </Item>
            <Tooltip
              placement="right"
              title="You can select if this configuration should not be displayed to users in all regions. If checked, the CMP will still run in the background with all services consented by default."
            />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default GlobalSetting;
